import {Box, Button, Container, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core"
import {Link as RouterLink} from "react-router-dom";
import {useEffect, useState} from "react";
import {Aircraft} from "../../models/Aircraft";
import {useTranslation} from "react-i18next";
import MyAircraftsCardsList from "../../components/aircraft/MyAircraftCardsList";
import PermissionsGuard from "../../components/PermissionGuard";
import PencilAlt from "../../icons/PencilAlt";
import {AircraftService} from "../../services/AircraftService";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import MyAircraftsCardsListMobile from "../../components/aircraft/MyAircraftCardsListMobile";


const MyAircrafts = () => {
    const currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down(1200));

    const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);
    const aircraftService = new AircraftService();
    const { t } = useTranslation();
    const [aircrafts, setAircrafts] = useState<Aircraft[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            let retrievedAircrafts = await aircraftService.getAllAircraftsById(loggedUser?.id ? loggedUser.id : "");
            setAircrafts(retrievedAircrafts);
            setIsLoading(false);
        })();
    }, []);

    return (
        <PermissionsGuard
            allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
            environment={'MyAircrafts'}
        >
            <Container >
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('AIRCRAFTS_DATA_LIST')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            <Button
                                color="primary"
                                component={RouterLink}
                                startIcon={<PencilAlt fontSize="small" />}
                                sx={{ m: 1 }}
                                to={`/myAircrafts/add`}
                                variant="contained"
                            >
                                {t('ADD_AIRCRAFT')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    {<Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                lg={12}
                                md={12}
                                xl={12}
                                xs={12}
                            >
                                {!mobileDevice
                                    ?
                                    <MyAircraftsCardsList aircrafts={aircrafts}
                                        isLoading={isLoading} />
                                    :
                                    <MyAircraftsCardsListMobile aircrafts={aircrafts}
                                        isLoading={isLoading} />}

                            </Grid>
                        </Grid>}
                </Box>
            </Container>
        </PermissionsGuard>
    );
}

export default MyAircrafts;
