import { FC, useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DocumentTypeService } from "../../services/DocumentTypeService";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../models/DocumentType";
import { FormHelperText, Skeleton } from "@mui/material";


interface PilotFormProps {
    formik: any,
}

const PilotForm: FC<PilotFormProps> = (props) => {
    const { formik } = props
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);

    const documentTypeService = new DocumentTypeService();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                let types = await documentTypeService.getAllDocumentTypes();
                setDocumentTypes(types);
                setIsLoading(false);
                let pilotType = types.find(docType => docType.id == formik.values.pilotDocumentTypeId);
                if (pilotType == null) {
                    let defaultFlightTypeId = types.find(docType => docType.name == 'LICENSE')?.id;
                    await formik.setValues(prevState => ({ ...prevState, pilotDocumentTypeId: defaultFlightTypeId }))
                }
            } catch (err) {
                console.log("Error");
            }
        })();
    }, []);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="pilot-full-name"
                        label={t("PILOT_NAME") + " *"}
                        name="pilotFullName"
                        onChange={formik.handleChange}
                        value={formik.values.pilotFullName}
                        error={Boolean(formik.errors.pilotFullName)}
                        helperText={formik.errors.pilotFullName}
                    />
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="pilot-phone-number"
                        label={t("PHONE_NUMBER") + " *"}
                        name="pilotPhoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.pilotPhoneNumber}
                        error={Boolean(formik.errors.pilotPhoneNumber)}
                        helperText={formik.errors.pilotPhoneNumber}
                    />
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="pilot-email"
                        label={t("EMAIL") + " *"}
                        name="pilotEmail"
                        onChange={formik.handleChange}
                        value={formik.values.pilotEmail}
                        error={Boolean(formik.errors.pilotEmail)}
                        helperText={formik.errors.pilotEmail}
                    />
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <FormControl fullWidth size='small'>
                        <InputLabel>{t('DOCUMENT_TYPES_LABEL')}</InputLabel>
                        <Select
                            label={t('DOCUMENT_TYPES_LABEL')}
                            name="pilotDocumentTypeId"
                            onChange={formik.handleChange}
                            value={formik.values.pilotDocumentTypeId}
                            error={Boolean(formik.errors.pilotDocumentTypeId)}
                        >
                            {documentTypes.map((documentType: DocumentType) => (
                                <MenuItem key={documentType.id} value={documentType.id}>
                                    {t(documentType.name)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{formik.errors.pilotDocumentTypeId}</FormHelperText>
                    </FormControl>
                )}
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        autoComplete="off"
                        id="pilot-document-value"
                        label={t('DOCUMENT_VALUE_LABEL')}
                        name="pilotDocumentValue"
                        onChange={formik.handleChange}
                        value={formik.values.pilotDocumentValue}
                        error={Boolean(formik.errors.pilotDocumentValue)}
                        helperText={formik.errors.pilotDocumentValue}
                    />
                )}
            </Grid>
        </Grid>
    </>
}
export default PilotForm;
