import {Card, Container, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const AccessDisabledPage = () => {
    const {t} = useTranslation();

    return (
        <Container maxWidth={false}>
            <Card>
                <div style={{width: "100%", textAlign: "center", padding: "30px"}}>
                    <Typography>
                        {t('USER_DISABLE_MSG')}
                    </Typography>
                    <img alt="unauthorized" src={'/static/unauthorized.svg'}
                         style={{width: "200px", height: "200px", padding: "30px"}}/>
                </div>
            </Card>
        </Container>
    );
};

export default AccessDisabledPage;
