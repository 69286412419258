import axios from 'axios';

export class DocumentTypeService {

async getAllDocumentTypes() {
    try {
        const response = await axios.post('getAllDocumentTypes');
        return response.data;
    } catch (error) {
        throw new Error("Error: " + error);
    }
}
}