import {useCallback, useState} from "react";
import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Plus from '../../icons/Plus';
import {Link as RouterLink} from 'react-router-dom';
import {PaginationResult} from "../../types/paginationResult";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import PermissionsGuard from "../../components/PermissionGuard";
import {Tenant} from "../../models/Tenant";
import {TenantService} from "../../services/TenantService";
import TenantsListTable from "../../components/tenant/TenantsListTable";

const TenantsList = () => {

    const tenantService = new TenantService();
    const {t} = useTranslation();

    const [tenants, setTenants] = useState<PaginationResult<Tenant>>();
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

    const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);

    const getTenants = useCallback(async (currentPage: number, recordLimit: number, searchFilter: string) => {
        try {
            setIsPageLoading(true);
            const retrievedTenants = await tenantService.getAllTenants(searchFilter, currentPage, recordLimit);
            setTenants(retrievedTenants);
            setIsPageLoading(false);
        } catch (error) {
            //TODO: Handle errors
            console.log(error);
        }
    }, []);

    return (
        <PermissionsGuard
            allowedRoles={['SYSTEMADMIN']}
            environment={'TenantsList'}
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('TENANTS_LIST')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{m: -1}}>
                            <Button
                                component={RouterLink}
                                to="add"
                                color="primary"
                                startIcon={<Plus fontSize="small"/>}
                                sx={{m: 1}}
                                variant="contained"
                            >
                                {t('ADD_TENANT')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{mt: 3}}>
                    <TenantsListTable
                        paginationResult={tenants}
                        getTenants={getTenants}
                        user={user}
                        isPageLoading={isPageLoading}
                    />
                </Box>
            </Container>
        </PermissionsGuard>
    );
}

export default TenantsList;
