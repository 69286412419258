import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";

export function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function StringIsNullOrEmpty(str: string) {
  return (!str || 0 === str.toString().trim().length);
}

export function StringIsNotNullOrEmpty(str: string) {
  return !(!str || 0 === str.toString().trim().length);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// export function toLocalDate(date: any, timezoneId: string) {
//     var offsetStart = getTimezoneOffset(timezoneId);
//     return new Date(new Date(date).getTime() + offsetStart);
// }
//
// export function toUTCDate(date: Date) {
//     return zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
// }



export function isToday(someDate: any) {
  const today = new Date()
  return someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
}

export const isEmpty = (value: any) => {
  if (value == null) return true;

  switch (typeof value) {
    case 'number':
      return value === 0;
    case 'string':
      return value.trim().length === 0;
    case 'object':
      return Object.keys(value).length === 0;
    default:
      return value === undefined;
  }
}

export const getFormValue = (e: any) => {
  let name = e.target.name;
  let value = "";

  if (e.target.type === "checkbox") {
    value = e.target.checked;
  } else {
    value = e.target.value;
  }
  return { name, value };
};

export const inArray = (value: any, array: Array<any>) => {
  return array.indexOf(value) > -1;
}

export function userCanUseIfRoles(roleName: string | undefined, acceptedRoles: string[]) {
  if (isEmpty(roleName) || acceptedRoles.length < 1)
    return false;

  return acceptedRoles.includes(roleName ?? '');
}

export function composeNotistackMessage(messageText: string, messageVariant: any) {
  enqueueSnackbar(messageText, {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    },
    variant: messageVariant
  });
}

export const getUTCDate = (value: string | Date) => {
  let dateToHandle: Date = typeof value === 'string' ? new Date(value) : value;

  return dateToHandle.toISOString();
  // .slice(0, -1);
}

export const getFormattedUTCDate = (value: string, dateFormat: string) => {
  if (!isEmpty(value)) {
    const date = new Date(getUTCDate(value));
    return format(date, dateFormat);
  }
  return '';
}

export const dateTimeFormats = {
  it: 'dd/MM/yyyy HH:mm',
  en: 'MM/dd/yyyy HH:mm',
  fr: 'dd/MM/yyyy HH:mm',
};

export const objectHasEmptyFields = (objectToCheck: object): boolean => {
  let presenceFilledFields = false;

  for (const [key, value] of Object.entries(objectToCheck)) {
    presenceFilledFields = !isEmpty(value) ? true : presenceFilledFields;
  }

  return !presenceFilledFields;
}

export const PilotRoleId = "65b7a7f04e7fca6c783b8f44";

export const PilotRoleName = "PILOT";
