import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../Scrollbar';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import {composeNotistackMessage} from '../../utils/common';
import TableRowsLoader from '../TableRowsLoader';
import {DeleteOutline} from '@material-ui/icons';
import {TenantService} from '../../services/TenantService';
import {Tenant} from '../../models/Tenant';

interface TenantsListTableProps {
	paginationResult: PaginationResult<Tenant> | undefined;
	getTenants(currentPage: number, recordLimit: number, searchFilter: string): void;
	user: any;
	isPageLoading: boolean;
}

const TenantsListTable: FC<TenantsListTableProps> = ({ paginationResult, getTenants, user, isPageLoading }) => {
	const { t } = useTranslation();
	const history = useNavigate();

	const [currentPage, setCurrentPage] = useState<number>(0);
	const [recordLimit, setRecordLimit] = useState<number>(10);
	const [searchFilter, setSearchFilter] = useState<string>('');

	const [tenantIdToRemove, setTenantIdToRemove] = useState<string | undefined>('');
	const [removeTenantModalOpen, setRemoveTenantModalOpen] = useState(false);

	const handleTenantModalOpen = () => setRemoveTenantModalOpen(true);
	const handleTenantModalClose = () => setRemoveTenantModalOpen(false);

	var currentTheme = useTheme();
	const mobileDevice = useMediaQuery(currentTheme.breakpoints.down(450));


	useEffect(() => {
		getTenants(currentPage + 1, recordLimit, searchFilter);
	}, [currentPage, recordLimit, searchFilter, getTenants]);


	const deleteTenant = async () => {
		if (tenantIdToRemove !== undefined) {
			const tenantService = new TenantService();

			let deleteTenantResult = await tenantService.deleteTenantById(tenantIdToRemove);

			setTenantIdToRemove('');
			getTenants(currentPage + 1, recordLimit, searchFilter);

			if (deleteTenantResult) {
				composeNotistackMessage(t('TENANT_DELETED'), 'success');

				// TODO: Used to change tenant in Sidebar. To fix
				history('/smartairfield');

				history('/tenants');
			} else {
				composeNotistackMessage(t('TENANT_NOT_DELETED'), 'error');
			}
		}
	}

	return (
		<Card>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					m: -1,
					p: 2
				}}
			>
				<Box
					sx={{
						m: 1,
						maxWidth: '100%',
						width: 500
					}}
				>
					<TextField
						fullWidth
						size='small'
						sx={{
							input: {
								color: 'white'
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fontSize="small" />
								</InputAdornment>
							)
						}}
						onChange={(e) => {
							setSearchFilter(e.target.value);
						}}
						placeholder={t('SEARCH_TENANTS')}
						value={searchFilter}
						variant="outlined"
					/>
				</Box>
			</Box>
			<Scrollbar>
				<Box sx={{ minWidth: 700 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									{t('NAME')}
								</TableCell>
								<TableCell>
									{t('ICAO_CODE')}
								</TableCell>
								<TableCell>
									{t('REGION')}
								</TableCell>
								<TableCell>
									{t('PROVINCE')}
								</TableCell>
								<TableCell>
									{t('MUNICIPALITY')}
								</TableCell>
								<TableCell align="right">
									{t('ACTIONS')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								isPageLoading ?
									(<TableRowsLoader rowsNum={recordLimit} colsNum={6} />) :
									(
										<>
											{paginationResult?.items.map((tenant) => (
												<TableRow hover key={tenant.id}>
													<TableCell>
														<Box
															sx={{
																alignItems: 'center',
																display: 'flex'
															}}
														>
															<Box sx={{ ml: 1 }}>
																{tenant.name}
															</Box>
														</Box>
													</TableCell>
													<TableCell>
														{tenant.icaoCode}
													</TableCell>
													<TableCell>
														{tenant.region}
													</TableCell>
													<TableCell>
														{tenant.province}
													</TableCell>
													<TableCell>
														{tenant.municipality}
													</TableCell>
													<TableCell align="right">
														<Dialog
															open={removeTenantModalOpen}
															onClose={handleTenantModalClose}
															aria-labelledby="alert-remove-tenant-title"
															aria-describedby="alert-remove-tenant-description"
														>
															<DialogTitle id="remove-tenant-title">
																{t('TITLE_MODAL_DELETE_TENANT')}
															</DialogTitle>
															<DialogActions>
																<Button onClick={handleTenantModalClose} variant="outlined" color="error">{t('CANCEL')}</Button>
																<Button onClick={() => {
																	handleTenantModalClose();
																	deleteTenant();
																}} variant="contained" color="error">{t('DELETE')}</Button>
															</DialogActions>
														</Dialog>
														<Tooltip title={t('DELETE') || ''} aria-label={t('DELETE') || ''}>
															<IconButton onClick={() => {
																handleTenantModalOpen();
																setTenantIdToRemove(tenant.id)
															}}>
																<DeleteOutline fontSize="small" />
															</IconButton>
														</Tooltip>
														<Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
															<IconButton
																component={RouterLink}
																to={`edit?tenantId=${tenant.id}`}
															>
																<PencilAltIcon fontSize="small" />
															</IconButton>
														</Tooltip>
														<Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
															<IconButton
																component={RouterLink}
																to={`detail?tenantId=${tenant.id}`}
															>
																<ArrowRightIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
										</>
									)
							}
						</TableBody>
					</Table>
				</Box>
			</Scrollbar>
			<TablePagination
				component="div"
				count={paginationResult?.totalCount ?? 10}
				onRowsPerPageChange={(e) => {
					setRecordLimit(parseInt(e.target.value, 10));
				}}
				onPageChange={(e, page) => {
					setCurrentPage(page);
				}}
				page={currentPage}
				rowsPerPage={recordLimit}
				rowsPerPageOptions={[5, 10, 25]}
				labelRowsPerPage={!mobileDevice && t('ROWS_PER_PAGE')}
			/>
		</Card>
	);
};

export default TenantsListTable;
