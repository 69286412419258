import { createSlice } from '@reduxjs/toolkit';

export type MenuProps = {
    selectedItem: string[];
    drawerOpen: boolean;
};

const initialState: MenuProps = {
    selectedItem: ['flights'],
    drawerOpen: true
};

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        }
    }
});

export default menu.reducer;

export const menuState = ( state ) => state.menu 

export const { activeItem, openDrawer } = menu.actions;