import {Box, Button, Grid, Modal, TextField, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import X from "../../icons/X";
import {FlightService} from "../../services/FlightService";
import {isEmpty} from "lodash";
import LoaderVariant from "../LoaderVariant";
import {DateRangePicker} from '@material-ui/lab';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import {composeNotistackMessage} from "../../utils/common";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: 550,
    maxHeight: "30rem",
    bgcolor: '#141414',
    border: '2px solid #000',
    boxShadow: 24,
};

interface FlightLogProps {
    modalIsOpen: boolean,
    setModalIsOpen: Function
}

const FlightReportDatesChooseModal = (props) => {
    const { t } = useTranslation();


    const flightService = new FlightService()
    const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [reportEmail, setReportEmail] = useState<string>(loggedUser?.email!);
    const [reportEmailIsValid, setReportEmailIsValid] = useState<boolean>(false)


    const { modalIsOpen, setModalIsOpen } = props

    const CloseModal = () => {
        setModalIsOpen(false);
    }
    const checkEmail = () => {
        setReportEmailIsValid(/^([a-zA-Z0-9._ %-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(reportEmail))
    }

    useEffect(() => {
        checkEmail()
    }, [])


    const getFlightsReportFile = async () => {
        if (endDate != null && startDate != null) {

            setIsLoadingReport(true);
            var result = await flightService.getFlightsReport(
                loggedUser?.id!, loggedUser?.currentTenantId!,
                reportEmail,
                {
                    startDate: startDate,
                    endDate: endDate
                }, false
            );
            setIsLoadingReport(false);
            composeNotistackMessage(t('REPORT_EMAIL_SENDED'), 'success');

        }
    }

    const getFlightsReportFileShort = async () => {
        if (endDate != null && startDate != null) {

            setIsLoadingReport(true);
            var result = await flightService.getFlightsReport(
                loggedUser?.id!, loggedUser?.currentTenantId!,
                reportEmail,
                {
                    startDate: startDate,
                    endDate: endDate
                },
                true,
            );
            setModalIsOpen(false);
            setIsLoadingReport(false);
            composeNotistackMessage(t('REPORT_EMAIL_SENDED'), 'success');

        }
    }

    return (<>
        {isLoadingReport ?
            <LoaderVariant isLoading={isLoadingReport} />
            :
            <>
                <Modal open={modalIsOpen} onClose={CloseModal} >
                    <Box sx={style}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: "row",
                            mx: 2, mt: 1, mb: 1
                        }}>


                            <Button sx={{ p: 0, m: 0 }}
                                startIcon={<X fontSize='small' />}
                                color="primary"
                                onClick={CloseModal}
                            >
                            </Button>

                        </Box>

                        <Box sx={{ mb: 2, mx: 2 }}>
                            <Typography sx={{ mb: 2 }} id="modal-modal-title" variant="h6" component="h2">
                                {t("SELECT_DATA_REPORT")}
                            </Typography>
                            <DateRangePicker

                                startText={t("START_DATE_LABEL_TEXT")}
                                endText={t("END_DATE_LABEL_TEXT")}
                                value={[startDate, endDate]}
                                onChange={(newRange) => {
                                    if (newRange[0] != null && !isEmpty(newRange[0])
                                        && newRange[0].toString() != "Invalid Date") {
                                        setStartDate(newRange[0]);

                                    } else { setStartDate(null); }

                                    if (newRange[1] != null
                                        && !isEmpty(newRange[1])
                                        && newRange[1].toString() != "Invalid Date") {
                                        setEndDate(newRange[1]);
                                    } else { setEndDate(null) }
                                }}
                                inputFormat='DD/MM/YYYY'

                                renderInput={(startProps, endProps) =>
                                    <>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                xs={6}
                                            >
                                                <TextField {...startProps} fullWidth sx={{ input: { color: 'white' } }} />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={6}
                                                xs={6}
                                            >
                                                <TextField {...endProps} fullWidth sx={{ input: { color: 'white' } }} />
                                            </Grid>
                                        </Grid>
                                    </>}
                            />
                        </Box>
                        <Box sx={{ px: 2 }}
                        >
                            <Typography sx={{ mb: 1 }} variant="h6" >
                                {t("INSERT_REPORT_EMAIL")}
                            </Typography>
                            <TextField
                                fullWidth
                                size='small'
                                label={t('EMAIL')}
                                name="reportEmail"
                                onBlur={checkEmail}
                                error={!reportEmailIsValid}
                                required
                                onChange={(e) => { setReportEmail(e.target.value); }}
                                value={reportEmail}
                            />
                        </Box>
                        <Box sx={{ mb: 3, display: "flex", flexDirection: "column" }}>

                            <Button sx={{ p: 0, m: 3, width: "90%" }}
                                variant="contained"
                                disabled={
                                    startDate == null ||
                                    endDate == null ||
                                    startDate > endDate ||
                                    !reportEmailIsValid
                                }
                                color="primary"
                                onClick={getFlightsReportFile}
                            >
                                <Typography sx={{ my: 1 }}>{t("CREATE_REPORT")}</Typography>
                            </Button>
                            <Button sx={{ p: 0, mx: 3, my: 1, width: "90%" }}
                                variant="contained"
                                disabled={
                                    startDate == null ||
                                    endDate == null ||
                                    startDate > endDate ||
                                    !reportEmailIsValid
                                }
                                color="primary"
                                onClick={getFlightsReportFileShort}
                            >
                                <Typography sx={{ my: 1 }}>{t("CREATE_REPORT_SHORT")}</Typography>
                            </Button>
                        </Box>

                    </Box>
                </Modal>
            </>}
    </>)
}

export default FlightReportDatesChooseModal
