import {Button, TableCell, Typography} from "@material-ui/core";
import SortDescending from "../../icons/SortDescending";
import SortAscending from "../../icons/SortAscending";
import {FC} from "react";
import {useTranslation} from "react-i18next";

interface IFilters {
    searchFilter: string,
    startDepartureDate: string | null,
    endDepartureDate: string | null,
    startArrivalDate: string | null,
    endArrivalDate: string | null,
    tenantIsDeparture: boolean | null,
    orderFieldName: string | null,
    isDescending: boolean | null
}

interface FlightsListHeaderProps {
    value: string;
    name: string;
    filters: IFilters;
    setAllFilters(value: string | boolean, name: string);
}

const FlightsListHeader: FC<FlightsListHeaderProps> = ({value, name, setAllFilters, filters}) => {
    const {t} = useTranslation();
    return (<>
        <TableCell align="left">
            <Button onClick={() => {
                setAllFilters(value, "orderFieldName");
                setAllFilters(!filters.isDescending, "isDescending")
            }}>
                <Typography variant="subtitle2" sx={{color: "white", ml: 1}}>{t(name)}</Typography>
                {filters.orderFieldName == value && (
                    (filters.isDescending) == true ? (<SortDescending sx={{color: "white", ml: 1}}/>) : (
                        <SortAscending sx={{color: "white", ml: 1}}/>))}
            </Button>
        </TableCell>
    </>)


}
export default FlightsListHeader;
