import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {DefaultTenant, Tenant} from "../../models/Tenant";
import {isEmpty} from "../../utils/common";
import {TenantService} from "../../services/TenantService";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import TenantDetailsBox from "../../components/tenant/TenantDetailsBox";
import PencilAlt from "../../icons/PencilAlt";
import {Link as RouterLink} from 'react-router-dom';
import PermissionsGuard from "../../components/PermissionGuard";

const TenantDetails = () => {
  const [tenant, setTenant] = useState<Tenant>(DefaultTenant);
  const { t } = useTranslation();
  const tenantService = new TenantService();
  const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);
  const tenantId = window.location.href.split('tenantId=')[1] ?? '';

  useEffect(() => {
    (async () => {
      if (!isEmpty(tenantId)) {
        let retrievedTenant: Tenant = await tenantService.getTenantById(tenantId!);
        setTenant(retrievedTenant);
      }
    })();
  }, [user]);

  return (
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN']}
      environment={'TenantDetails'}
      needTenant={true}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('TENANT_DATA')}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PencilAlt fontSize="small" />}
                sx={{ m: 1 }}
                to={`/tenants/edit?tenantId=${tenantId}`}
                variant="contained"
              >
                {t('EDIT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          {
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <TenantDetailsBox tenant={tenant} />
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default TenantDetails;
