import { Role, DefaultRole } from "./Role"

export interface TenantUser {
  id: string,
  userId: string,
  tenantId: string,
  roleId: string,
  avatar?: string,
  role: Role
}

export const DefaultTenantUser: TenantUser = {
    id: '',
    userId: '',
    tenantId: '',
    roleId: '',
    role: DefaultRole
  }