import {isEmpty} from "../utils/common";
import {Tenant} from "../models/Tenant";
import {hasErrors} from "../utils/validation";

export class TenantValidator {
  public errors = {
    airfieldId: '',
    name: '',
    type: '',
    code: '',
    latitude: '',
    longitude: '',
    region: '',
    province: '',
    municipality: '',
    isPublic: '',
    managerFullName: '',
    managerFiscalCode: '',
    managerPhoneNumber: '',
  };

  private t: Function;

  constructor(t: Function) {
    this.t = t;
  }

  validateTenant(tenant: Tenant, fieldsNotToCheck: Array<string> = []): boolean {
    this.validateAirfieldId(tenant.airfieldId);
    this.validateName(tenant.name);
    this.validateIcaoCode(tenant.icaoCode);
    this.validateLatitude(tenant.latitude);
    this.validateLongitude(tenant.longitude);
    this.validateName(tenant.name);
    this.validateRegion(tenant.region);
    this.validateProvince(tenant.province);
    this.validateMunicipality(tenant.municipality);
    this.validateIsPublic(tenant.isPublic);
    this.validateManagerFullName(tenant.managerFullName);
    this.validateManagerFiscalCode(tenant.managerFiscalCode);
    this.validateManagerPhoneNumber(tenant.managerPhoneNumber);

    return !hasErrors(this.errors, fieldsNotToCheck);
  }

  validateAirfieldId(airfieldId: string | null) {
    if (isEmpty(airfieldId)) {
      this.errors.airfieldId = this.t('AIRFIELD_REQUIRED');
    }
  }

  validateIcaoCode(icaoCode?: string) {
  }

  validateLatitude(latitude: number) {
    if (isEmpty(latitude)) {
      this.errors.latitude = this.t('LATITUDE_REQUIRED');
    }
  }

  validateLongitude(longitude: number) {
    if (isEmpty(longitude)) {
      this.errors.longitude = this.t('LONGITUDE_REQUIRED');
    }
  }

  validateName(name: string) {
    if (isEmpty(name)) {
      this.errors.name = this.t('TENANT_NAME_REQUIRED');
    }
  }

  validateRegion(region: string) {
    if (isEmpty(region)) {
      this.errors.region = this.t('REGION_REQUIRED');
    }
  }

  validateProvince(province: string) {
    if (isEmpty(province)) {
      this.errors.province = this.t('PROVINCE_REQUIRED');
    }
  }

  validateMunicipality(municipality: string) {
    if (isEmpty(municipality)) {
      this.errors.municipality = this.t('MUNICIPALITY_REQUIRED');
    }
  }

  validateIsPublic(isPublic: boolean) {
  }

  validateManagerFullName(managerFullName: string) {
    if (isEmpty(managerFullName)) {
      this.errors.managerFullName = this.t('MANAGER_FULL_NAME_REQUIRED');
    }
  }

  validateManagerFiscalCode(managerFiscalCode: string) {
    if (isEmpty(managerFiscalCode)) {
      this.errors.managerFiscalCode = this.t('MANAGER_FISCAL_CODE_REQUIRED');
    }
  }

  validateManagerPhoneNumber(managerPhoneNumber: string) {
    if (isEmpty(managerPhoneNumber)) {
      this.errors.managerPhoneNumber = this.t('MANAGER_PHONE_NUMBER_REQUIRED');
    }
  }
}
