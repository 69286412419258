import {cloneElement, FC, ReactElement, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import {User} from "../models/User";
import {useNavigate} from "react-router";
import {Tooltip} from "@material-ui/core";
import {userCanUseIfRoles} from "../utils/common";

interface PermissionsGuardProps {
    children: ReactElement;
    allowedRoles: string[],
    environment: string,
    errorProps?: any,
    needTenant?: boolean,
}

const PermissionsGuard: FC<PermissionsGuardProps> = (props) => {
    let {children, allowedRoles, environment, errorProps, needTenant} = props;

    const user = useSelector<AppState, User | undefined>((state) => state.appInit.user!);
    const history = useNavigate();

    const [returnClonedElement, setReturnClonedElement] = useState<boolean>(false)

    const hasPermissions = () => userCanUseIfRoles(user?.tenantUser?.role?.name, allowedRoles)

    useEffect(() => {
        if (!hasPermissions() && errorProps) {
            setReturnClonedElement(true)
        } else if (!hasPermissions()
            || (needTenant && user?.tenantUser.tenantId == "000000000000000000000000")) {
            history("/accessDisabled");
        }

        return () => {
            setReturnClonedElement(false)
        }

    }, [user]);

    if (!user)
        history("/accessDisabled");

    return returnClonedElement
        ? <Tooltip arrow title={"La tua utenza non ha i permessi per eseguire questa operazione."}>
            <span style={{cursor: "not-allowed"}}>
                {cloneElement(children, {...errorProps})}
            </span>
        </Tooltip>
        : children
}

export default PermissionsGuard;
