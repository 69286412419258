declare global {
  interface Window {
    odevConfig: any;
  }
}
const config = window.odevConfig;

interface ServiceConfig {
  serviceBaseUrl: string;
  aiInstrumentationKey: string;
  auth0ClientId: string;
  auth0Domain: string;
}

const serviceBaseUrl: string = config?.AppServiceBaseUrl || process.env.REACT_APP_SERVICE_BASE_URL || "";
const auth0Domain: string = config?.Auth0Domain || process.env.REACT_APP_AUTH0_DOMAIN || "";
const auth0ClientId: string = config?.Auth0ClientId || process.env.REACT_APP_AUTH0_CLIENT_ID || "";
const aiInstrumentationKey: string = config?.AiInstrumentationKey || process.env.REACT_APP_AI_IK || "";

if (!serviceBaseUrl) throw new Error("Missing env REACT_APP_SERVICE_BASE_URL or config value 'AppServiceBaseUrl'");

const serviceConfig: ServiceConfig = {
  serviceBaseUrl: `${serviceBaseUrl}/api`,
  aiInstrumentationKey: aiInstrumentationKey,
  auth0Domain: auth0Domain,
  auth0ClientId: auth0ClientId,
};

export default serviceConfig;
