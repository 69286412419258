import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // Quando si modifica il nome di un ruolo, bisogna aggiornare anche il suo complementare
      'ADMINISTRATOR': 'Administrator',
      'SYSTEMADMIN': 'SystemAdmin',
      'USER': 'User',
      'VIEWER': 'Viewer',
      // ----------------------------------------------------------------------------
      // Days of week
      'MONDAY': 'Monday',
      'TUESDAY': 'Tuesday',
      'WEDNESDAY': 'Wednesday',
      'THURSDAY': 'Thursady',
      'FRIDAY': 'Friday',
      'SATURDAY': 'Saturday',
      'SUNDAY': 'Sunday',
      'MON': 'MON',
      'TUE': 'TUE',
      'WED': 'WED',
      'THU': 'THU',
      'FRI': 'FRI',
      'SAT': 'SAT',
      'SUN': 'SUN',
      'TODAY': 'Today',
      // ----------------------------------------------------------------------------
      //Month
      'JANUARY': 'January',
      'FEBRUARY': 'February',
      'MARCH': 'March',
      'APRIL': 'April',
      'MAY': 'May',
      'JUNE': 'June',
      'JULY': 'July',
      'AUGUST': 'August',
      'SEPTEMBER': 'September',
      'OCTOBER': 'October',
      'NOVEMBER': 'November',
      'DECEMBER': 'December',
      'JANUARY_SHORT': 'Jan',
      'FEBRUARY_SHORT': 'Feb',
      'MARCH_SHORT': 'Mar',
      'APRIL_SHORT': 'Apr',
      'MAY_SHORT': 'May',
      'JUNE_SHORT': 'Jun',
      'JULY_SHORT': 'Jul',
      'AUGUST_SHORT': 'Aug',
      'SEPTEMBER_SHORT': 'Sep',
      'OCTOBER_SHORT': 'Oct',
      'NOVEMBER_SHORT': 'Nov',
      'DECEMBER_SHORT': 'Dec',
      // ----------------------------------------------------------------------------
      // Flight types
      'BUSINESS': 'Business',
      'CARGO': 'Cargo',
      'TOURISTIC': 'Touristic',
      // ----------------------------------------------------------------------------
      // Nation
      'ITALY': 'Italy',
      // ----------------------------------------------------------------------------
      'ACCOUNT_NOT_REQUESTED': 'An error occurred while requesting a new account',
      'ACCOUNT_REQUESTED': 'Your request has been taken care of. You will be contacted as soon as possible',
      'ACTIONS': 'Actions',
      'ADD_AIRCRAFT': 'Add aircraft',
      'ADD_AIRFIELD': 'Add airfield',
      'ADD_FLIGHT': 'Add',
      'ADD_TENANT': 'Add tenant',
      'ADD_USER': 'Add user',
      'ADVANCED_FILTERS': 'Advanced filters',
      'AIRCRAFT': 'Aircraft',
      'AIRCRAFT_ADDED': 'Aircraft added correctly',
      'AIRCRAFT_CATEGORY': 'Aircraft category',
      'AIRCRAFT_CATEGORY_SHORT': 'Category',
      'AIRCRAFTS_DATA': 'Aircraft data',
      'AIRCRAFTS_DATA_LIST': 'My Aircrafts',
      'AIRCRAFTS_DELETED': 'Aircraft deleted correctly',
      'AIRCRAFT_NOT_ADDED': 'An error occurred while adding the aircraft',
      'AIRCRAFT_NOT_DELETED': 'An error occurred while deleting the aircraft',
      'AIRCRAFT_NOT_UPDATED': 'An error occurred while updating the aircraft',
      'AIRCRAFT_REGISTRATION': 'Aircraft registration',
      'AIRCRAFT_REGISTRATION_SHORT': 'Registration',
      'AIRCRAFT_TIPOLOGY': "Aircraft type",
      'AIRCRAFT_TIPOLOGY_SHORT': "Type",
      'AIRCRAFT_TYPE': 'Aircraft model',
      'AIRCRAFT_TYPE_SHORT': 'Model',
      'AIRCRAFT_UPDATED': 'Aircraft updated correctly',
      'AIRFIELD_ADDED': 'Airfield added correctly',
      'AIRFIELD_DATA': 'Airfield data',
      'AIRFIELD_DELETED': 'Airfield deleted correctly',
      'AIRFIELD_DETAILS': 'Airfield details',
      'AIRFIELD_NOT_ADDED': 'An error occurred while adding the airfield',
      'AIRFIELD_NOT_DELETED': 'An error occurred while deleting the airfield',
      'AIRFIELD_NOT_UPDATED': 'An error occurred while updating the airfield',
      'AIRFIELD_RULES_PHRASE': "I have read the landing procedure",
      'AIRFIELD_RULES_LINK': "Link to the landing procedure",
      'AIRFIELD_UPDATED': 'Airfield updated correctly',
      'AIRFIELDS': 'Airfield list',
      'AIRFIELD': 'Airfield',
      'AIRFIELD_VISIBILITY': 'Airfield visibility',
      'AIRFIELDS_LIST': 'Airfields list',
      'AIRPLANE': 'Airplane',
      'APPROVE': 'Approve',
      'ARE_YOU_MANAGER': 'I\'m an airfield manager',
      'ARE_YOU_PILOT': 'Are you a pilot?',
      'APPROVED': 'Approved',
      'ARRIVAL': 'Arrival',
      'ARRIVAL_AIRFIELD': 'Arrival airfield',
      'ARRIVAL_DATA': 'Arrival data',
      'ARRIVAL_PLACE': 'Arrival airfield',
      'ARRIVAL_TIME': 'Arrival time',
      'ARRIVAL_TO_TENANT': 'Flights arriving at this runway',
      'ARRIVING': 'Arriving',
      'ASCENDING': 'Ascending',
      'AUTHORIZATION_REQUIRED': 'Required authorization',
      'AUTHORIZATION_REQUIRED_DESCRIPTION': 'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
      'AVATAR': 'Avatar',
      'BACK_BUTTON': 'Back',
      'BACK_BUTTON_SHORT': 'Back',
      'BACK_TO_DASHBOARD': 'Back to the dashboard',
      'BIRTHDATE': 'Birthdate',
      'CALENDAR': 'Calendar',
      'CANCEL': 'Cancel',
      'CHANGE_TENANT': 'Change tenant',
      'CLOSE': 'Close',
      'CODE': 'Code',
      'COLOR': 'Color',
      'CONFIGURATION': 'Configuration',
      'CONFIRM': 'Confirm',
      'CONTINENT': 'Continent',
      'COPILOT': 'Copilot',
      'COPILOT_DATA': 'Co-pilot data',
      'COPILOT_DOCUMENT': 'Copilot document',
      'COPILOT_EMAIL': 'Copilot Email',
      'COPILOT_NAME': 'Co-pilot name',
      'COPILOT_PHONE_NUMBER': 'Copilot phone number',
      'CREATE_REPORT': 'Download report (Full)',
      'CREATE_REPORT_SHORT': 'Download report (Light)',
      'DELETE': 'Delete',
      'DELETE_AIRFIELD_PHOTOS_BUTTON': 'Delete photo',
      'DELETE_PHOTO': 'Delete photo',
      'DEPARTURE': 'Departure',
      'DEPARTURE_AIRFIELD': 'Departure airfield',
      'DEPARTURE_FROM_TENANT': 'Flights departing from this airfield',
      'DEPARTURE_PLACE': 'Departure airfield',
      'DEPARTURE_TIME': 'Departure time',
      'DEPARTURING': 'Departuring',
      'DESCENDING': 'Descending',
      'DETAILS': 'Details',
      'DOCUMENT': 'Document',
      'DOCUMENT_TYPES_LABEL': 'Document type',
      'DOCUMENT_VALUE_LABEL': 'Document number',
      'EDIT': 'Edit',
      'ELEVATION_FT': 'Elevation feet',
      'EMAIL': 'Email',
      'END_ARRIVAL_TIME': 'Arrival date to',
      'END_DATE_LABEL_TEXT': 'Final date',
      'END_DEPARTURE_TIME': 'Departure date to',
      'ENGLISH': 'English',
      'EXPORT': 'Export',
      'EXPORT_PDF': 'Export to PDF',
      'FILTERS': 'Filters',
      'FIRST_NAME': 'Name',
      'FISCAL_CODE': 'Fiscal code',
      'FLIGHT_ADDED': 'Flight added correctly',
      'FLIGHT_APPROVATION_NOTIFICATION_TEXT': 'Flight state updated by: ',
      'FLIGHT_DATA': 'Flight data',
      'FLIGHT_DELETED': 'Flight deleted correctly',
      'FLIGHT_DETAILS': 'Flight details',
      'FLIGHT_DIRECTION': 'Direction',
      'FLIGHT_DIRECTION_SHORT': 'Dir.',
      'FLIGHT_LICENSE': 'Flight license',
      'FLIGHT_NOT_ADDED': 'An error occurred while adding the flight',
      'FLIGHT_NOT_DELETED': 'An error occurred while deleting the flight',
      'FLIGHT_NOT_UPDATED': 'An error occurred while updating the flight',
      'FLIGHT_TYPE': 'Flight type',
      'FLIGHT_UPDATED': 'Flight updated correctly',
      'FLIGHTS': 'Flights',
      'FLIGHTS_LIST': 'Flights',
      'FRENCH': 'French',
      'GENERAL_AVIATION': 'General aviation',
      'GET_LOCATION': 'Get location',
      'GLIDER': 'Glider',
      'GPS_CODE': 'GPS code',
      'GYROCOPTER': 'Gyroplane',
      'HANG_GLIDER': 'Trike',
      'HAVE_RADIO': "Radio",
      'HAVE_TRANSPONDER': "Transponder",
      'HOME_LINK': 'Link to home',
      'ICAO_CODE': 'ICAO',
      'IDCARD': 'Identity card',
      'IDENT': 'Ident',
      'INTELLIGENT_SEARCH_PHOTO': 'Intelligent photo research',
      'HELICOPTER': 'Helicopter',
      'INSERT_REPORT_EMAIL': 'Enter email to send the report to',
      'IS_CARRIED': 'Carried',
      'IS_NOT_CARRIED': 'Canceled',
      'IS_NULL_CARRIED': 'State',
      'ISO_COUNTRY': 'Iso country',
      'ISO_REGION': 'Iso region',
      'ITALIAN': 'Italian',
      'KEYWORDS': 'Keywords',
      'LANDING': 'Landing',
      'LATITUDE_DEG': 'Latitude',
      'LICENSE': 'License',
      'LOADING': 'Loading',
      'LOCAL_CODE': 'Local code',
      'LOG_ACTION_DATE_LABEL': 'Action date',
      'LOG_ACTION_TEXT_LABEL': 'Action',
      'LOG_ACTION_USERID_LABEL': 'User',
      'LOG_APPROVED_FLIGHT': 'Approved Flight',
      'LOG_INSERT_NEW_PUBLIC_FLIGHT': "Insert new flight from public form",
      'LOG_INSERT_NEW_FLIGHT': 'Insert new flight',
      'LOG_IS_CARRIED_FLIGHT': 'Flight is carried',
      'LOG_IS_NOT_CARRIED_FLIGHT': 'Flight is not carried',
      'LOG_NOT_APPROVED_FLIGHT': 'Rejected flight',
      'LOG_DELETE_FLIGHT': 'Cancelled Flight',
      'LOG_TEXT_LABEL': 'Logs',
      'LOG_UPDATE_FLIGHT': 'Updated Flight',
      'LOGO': 'Logo',
      'LOGOUT': 'Logout',
      'LONGITUDE_DEG': 'Longitude',
      'MANAGER_FISCAL_CODE': 'Manager fiscal code',
      'MANAGER_NAME': 'Manager name',
      'MANAGER_PHONE_NUMBER': 'Manager phone number',
      'MORE_OPTIONS': 'More',
      'MOVEMENT': 'Movement',
      'MUNICIPALITY': 'Municipality',
      'MY_AIRCRAFTS': 'My aircrafts',
      'MY_CALENDAR': 'My calendar',
      'MY_FLIGHTS': 'My flights',
      'MY_OWN_AIRIFELD': 'My airfield',
      'MY_PROFILE': 'My profile',
      'NAME': 'Name',
      'NEXT_ARRIVAL_TIME': 'Next arrival date',
      'NEXT_BUTTON': 'Next',
      'NEXT_DEPARTURE_TIME': 'Next departure date',
      'NO': 'No',
      'NO_NOTIFICATION': 'There is not notifications',
      'NOT_APPROVED': 'Not approved',
      'NOTE': 'Note',
      'NOTIFICATION_MARKED_ALL_BUTTON': 'Mark all as read',
      'NOTIFICATIONS': 'Notifications',
      'ORDER_BY': 'Order by',
      'ORDER_METHOD': "Order method",
      'PASSPORT': 'Passport',
      'PEOPLE_ON_BOARD': 'People on board',
      'PHONE_NUMBER': 'Phone number',
      'PHOTOS_NOT_FOUND': 'There are no photos for this airfield',
      'PILOT_NAME': 'Pilot name',
      'PILOT': 'Pilot',
      'PILOT_ACCOUNT_REQUESTED': 'Registration completed. You will be redirected to the login page.',
      'PILOT_DATA': 'Pilot data',
      'PILOT_DOCUMENT': 'Pilot document',
      'PILOT_EMAIL': 'Pilot email',
      'PILOT_INSERT_FLIGHT': 'Book land',
      'PILOT_INSERT_FLIGHT_NOTIFICATION_TEXT': 'New flight request from:',
      'PILOT_PHONE_NUMBER': 'Pilot phone number',
      "PROVINCE": "Province",
      'PUBLIC': 'Public',
      'PRIVATE': 'Private',
      'RECLAIM_AIRFIELD': 'Reclaim',
      'RECLAIM_AIRFIELD_FORM': 'Reclaim form',
      'RECLAIM_FLIGHT': 'Reclaim',
      'REGION': 'Region',
      'REGULATION': 'Regulation',
      'REGULATION_LINK_LABEL': 'Airfield regulation link',
      'REJECT': 'Reject',
      'REJECTED': 'Rejected',
      'REPORT_EMAIL_SENDED': 'The report has been successfully sent via email',
      'REQUEST_ACCOUNT': 'Sign in',
      'RETURN_FLIGHT': 'Return flight',
      'REMOVE': 'Remove',
      'REMOVE_FILTERS': 'Remove filters',
      'REMOVE_PHOTO': 'Remove photo',
      'RESIDENCE_ADDRESS': 'Residence address',
      'ROLE': 'Role',
      'ROWS_PER_PAGE': 'Rows per page',
      'SAVE': 'Save',
      'SCHEDULED_SERVICE': 'Scheduled service',
      'SCHOOL': 'School',
      'SEARCH_AIRFIELDS': 'Search airfields',
      'SEARCH': 'Search',
      'SEARCH_TENANTS': 'Search tenant',
      'SEARCH_USERS': 'Search users',
      'SELECT_AIRCRAFT': 'Select aircraft',
      'SELECT_AIRCRAFT_TYPE': 'Select the aircraft type',
      'SELECT_AVATAR': 'Select avatar',
      'SELECT_DATA_REPORT': 'Select range for creating the report',
      'SELECT_DOCUMENT_TYPE_OPTION': 'Select option',
      'SELECT_FLIGHT_TYPE': 'Select the flight type',
      'SELECT_ROLE': 'Select the role',
      'SELECT_TENANT': 'Select the tenant',
      'SELECT_TENANTS': 'Select tenants',
      'SIGNUP_TEXT': 'Fill the form with requested data',
      'START_ARRIVAL_TIME': 'Arrival date from',
      'START_DATE_LABEL_TEXT': 'Initial date',
      'START_DEPARTURE_TIME': 'Departure date from',
      'SUMMARY': 'Summary',
      'SURNAME': 'Surname',
      'TENANT': 'Tenant',
      'TENANT_ADDED': 'Tenant correctly added',
      'TENANT_APIKEY_BUTTON': "Generate ApiKey",
      'TENANT_APIKEY_ERROR': "An error occurred while generating the ApiKey",
      'TENANT_APIKEY_GENERATED': "ApiKey was generated. Click on 'Save' for complete the operation",
      'TENANT_APIKEY_LABEL': "ApiKey",
      'TENANT_CHANGED': 'Tenant correctly changed',
      'TENANT_DATA': 'Tenant data',
      'TENANT_DELETED': 'Tenant correctly deleted',
      'TENANT_DETAILS': 'Tenant details',
      'TENANT_IFRAME_URL_BUTTON': "Generate Iframe Url",
      'TENANT_IFRAME_URL_ERROR': "An error occurred while generating the Iframe Url",
      'TENANT_IFRAME_URL_GENERATED': "Url was generated. Click on 'Save' for complete the operation",
      'TENANT_IFRAME_URL_LABEL': "Url",
      'TENANT_UPDATED': 'Tenant correctly updated',
      'TENANT_NOT_ADDED': 'An error occurred while creating the tenant',
      'TENANT_NOT_CHANGED': 'An error occurred while changing the tenant',
      'TENANT_NOT_DELETED': 'An error occurred while deleting the tenant',
      'TENANT_NOT_UPDATED': 'An error occurred while updating the tenant',
      'TENANTS': 'Tenants',
      'TENANTS_LIST': 'Tenants list',
      'THERE_IS_COPILOT': 'There is copilot?',
      'TIMETABLE': 'Timetable',
      'TITLE_MODAL_DELETE_AIRFIELD': 'Are you sure you want to delete this airfield?',
      'TITLE_MODAL_DELETE_FLIGHT': 'Are you sure you want to delete this flight?',
      'TITLE_MODAL_DELETE_TENANT': 'Are you sure you want to delete this tenant?',
      'TITLE_MODAL_DELETE_USER': 'Are you sure you want to delete this user?',
      'TITLE_MODAL_FILTER_FLIGHTS': 'Specify the filters to apply',
      'TYPE': 'Type',
      'ULTRALIGHT': 'Ultralight',
      'UPDATE_AIRFIELD': 'Update airfield',
      'UPDATE_AIRFIELD_PHOTOS_BUTTON': 'Upload photo',
      'UPDATE_FLIGHT': 'Update flight',
      'UPDATE_FLIGHT_STATE': 'Validate',
      'UPDATE_TENANT': 'Update tenant',
      'UPDATE_USER': 'Update user',
      'UPLOAD': 'Upload',
      'UPLOAD_PHOTO': 'Upload photo',
      'USER_DATA': 'User data',
      'USER_DELETED': 'User deleted correctly',
      'USER_DETAILS': 'User details',
      'USERNAME': 'Username',
      'USER_ADDED': 'User correctly added',
      'USER_DISABLE_MSG': 'You\'re trying to reach a not reachable resource.',
      'USER_UPDATED': 'User correctly updated',
      'USER_NOT_ADDED': 'An error occurred while adding the user',
      'USER_NOT_DELETED': 'An error occurred while deleting the user',
      'USER_NOT_UPDATED': 'An error occurred while updating the user',
      'USERS': 'Users',
      'USERS_LIST': 'Users list',
      'VISIBILITY_TEXT': 'A public airfield will be shown in the list of public airfields\' on page: https://www.smartairfield.cloud/#/list',
      'WAITING_FOR_APPROVAL': 'Waiting for approval',
      'WIKIPEDIAINK': 'WIKIPEDIAINK',
      'YES': 'Yes',
      // ----------------------------------------------------------------------------
      // Message errors
      'AIRCRAFT_REGISTRATION_REQUIRED': 'Aircraft registration is required',
      'AIRCRAFT_TYPE_REQUIRED': 'Aircraft type is required',
      'AIRFIELD_NAME_REQUIRED': 'Airfield name is required',
      'AIRFIELD_REQUIRED': 'Airfield is required',
      'AIRFIELD_TYPE_REQUIRED': 'Airfield type is required',
      'ARRIVAL_AIRFIELD_REQUIRED': 'Arrival airfield is required',
      'ARRIVAL_TIME_REQUIRED': 'Arrival time is required',
      'CONTINENT_REQUIRED': 'Continent is required',
      'COPILOT_DOCUMENT_TYPE_REQUIRED': 'Co-pilot document type is required',
      'COPILOT_DOCUMENT_VALUE_REQUIRED': 'Co-pilot document number is required',
      'COPILOT_EMAIL_REQUIRED': 'Co-pilot e-mail was not written correctly',
      'COPILOT_FIELDS_REQUIRED': 'Insert all co-pilot fields',
      'COPILOT_NAME_REQUIRED': 'Co-pilot name is required',
      'COPILOT_PHONE_REQUIRED': 'Co-pilot phone number is required',
      'DEPARTURE_AIRFIELD_REQUIRED': 'Departure airfield is required',
      'DEPARTURE_TIME_REQUIRED': 'Departure time is required',
      'DOCUMENT_TYPE_REQUIRED': 'Type document is required',
      'DOCUMENT_VALUE_REQUIRED': 'Document number is required',
      'DATE': 'Date',
      'ELEVATION_FT_REQUIRED': 'Elevation ft is required',
      'EMAIL_REQUIRED': 'Email is required',
      'FLIGHT_TYPE_REQUIRED': 'Flight type is required',
      'GPS_CODE_REQUIRED': 'Gps code is required',
      'CODE_REQUIRED': 'Code is required',
      "ICAOCODE_REQUIRED": 'Icao Code is required',
      'ISO_COUNTRY_REQUIRED': 'Iso Country is required',
      'ISO_REGION_REQUIRED': 'ISO region is required',
      'LATITUDE_REQUIRED': 'Latitude degree is required',
      'LOCAL_CODE_REQUIRED': 'Local code is required',
      'LONGITUDE_REQUIRED': 'Longitude degree is required',
      'MANAGER_FULL_NAME_REQUIRED': 'Manager full name is required',
      'MANAGER_FISCAL_CODE_REQUIRED': 'Manager fiscal code is required',
      'MANAGER_PHONE_NUMBER_REQUIRED': 'Manager phone number is required',
      'MUNICIPALITY_REQUIRED': 'Municipality is required',
      'NAME_REQUIRED': 'Name is required',
      'PEOPLE_ON_BOARD_MIN_NUMBER': 'People on board must be at least ',
      'PEOPLE_ON_BOARD_REQUIRED': 'People on board is required',
      'PHONE_NUMBER_REQUIRED': 'Phone number is required',
      'PILOT_DOCUMENT_TYPE_REQUIRED': 'Pilot document type is required',
      'PILOT_DOCUMENT_VALUE_REQUIRED': 'Pilot document number is required',
      'PILOT_EMAIL_REQUIRED': 'Pilot e-mail was not written correctly',
      'PILOT_NAME_REQUIRED': 'Pilot name is required',
      'PILOT_PHONE_REQUIRED': 'Pilot phone number is required',
      'PROVINCE_REQUIRED': 'Province is required',
      'REGION_REQUIRED': 'Region is required',
      'ROLE_REQUIRED': 'Role is required',
      'SCHEDULED_SERVICE_REQUIRED': 'Scheduled service is required',
      'SURNAME_REQUIRED': 'Surname is required',
      'TENANT_NAME_REQUIRED': 'Tenant name is required',
      'TENANT_TYPE_REQUIRED': 'Tenant type is required',
      'TOO_MANY_PHOTOS': 'You can upload a maximum of 10 pictures'

    }
  },
  it: {
    translation: {
      // Quando si modifica il nome di un ruolo, bisogna aggiornare anche il suo complementare
      'ADMINISTRATOR': 'Amministratore',
      'SYSTEMADMIN': 'Amministratore di sistema',
      'USER': 'Utente',
      'VIEWER': 'Visualizzatore',
      // ----------------------------------------------------------------------------
      // Days of week
      'MONDAY': 'Lunedì',
      'TUESDAY': 'Martedì',
      'WEDNESDAY': 'Mercoledì',
      'THURSDAY': 'Giovedì',
      'FRIDAY': 'Venerdì',
      'SATURDAY': 'Sabato',
      'SUNDAY': 'Domenica',
      'MON': 'LUN',
      'TUE': 'MAR',
      'WED': 'MER',
      'THU': 'GIO',
      'FRI': 'VEN',
      'SAT': 'SAB',
      'SUN': 'DOM',
      'TODAY': 'Oggi',
      // ----------------------------------------------------------------------------
      //Month
      'JANUARY': 'Gennaio',
      'FEBRUARY': 'Febbraio',
      'MARCH': 'Marzo',
      'APRIL': 'Aprile',
      'MAY': 'Maggio',
      'JUNE': 'Giugno',
      'JULY': 'Luglio',
      'AUGUST': 'Agosto',
      'SEPTEMBER': 'Settembre',
      'OCTOBER': 'Ottobre',
      'NOVEMBER': 'Novembre',
      'DECEMBER': 'Dicembre',
      'JANUARY_SHORT': 'Gen',
      'FEBRUARY_SHORT': 'Feb',
      'MARCH_SHORT': 'Mar',
      'APRIL_SHORT': 'Apr',
      'MAY_SHORT': 'Mag',
      'JUNE_SHORT': 'Giu',
      'JULY_SHORT': 'Lug',
      'AUGUST_SHORT': 'Ago',
      'SEPTEMBER_SHORT': 'Set',
      'OCTOBER_SHORT': 'Ott',
      'NOVEMBER_SHORT': 'Nov',
      'DECEMBER_SHORT': 'Dic',
      // ----------------------------------------------------------------------------
      // Flight types
      'BUSINESS': 'Lavorativo',
      'CARGO': 'Trasporto merci',
      'TOURISTIC': 'Turistico',
      // Nation
      'ITALY': 'Italia',
      // ----------------------------------------------------------------------------
      'ACCOUNT_NOT_REQUESTED': 'Si è presentato un errore durante la richiesta di creazione account',
      'ACCOUNT_REQUESTED': 'La tua richiesta è stata presa in carico. Verrai contattato al più presto',
      'ACTIONS': 'Azioni',
      'ADD_AIRCRAFT': 'Aggiungi aereomobile',
      'ADD_AIRFIELD': 'Aggiungi aviosuperficie',
      'ADD_FLIGHT': 'Aggiungi',
      'ADD_TENANT': 'Aggiungi tenant',
      'ADD_USER': 'Aggiungi utente',
      'AIRCRAFT': 'Aeromobile',
      'AIRCRAFT_ADDED': 'Aeromobile aggiunto correttamente',
      'AIRCRAFT_CATEGORY': 'Categoria di aereomobile',
      'AIRCRAFT_CATEGORY_SHORT': 'Categoria',
      'AIRCRAFT_DATA': 'Dati aeromobile',
      'AIRCRAFTS_DATA_LIST': 'I miei aeromobili',
      'AIRCRAFT_DELETED': 'Aeromobile rimosso correttamente',
      'ADVANCED_FILTERS': 'Filtri avanzati',
      'AIRCRAFT_NOT_ADDED': 'Si è presentato un errore durante l\'inserimento del nuovo aeromobile',
      'AIRCRAFT_NOT_DELETED': 'Si è presentato un errore durante la modifica dell\' aeromobile',
      'AIRCRAFT_NOT_UPDATED': 'Si è presentato un errore durante l\'eliminazione dell\' aeromobile',
      'AIRCRAFT_REGISTRATION': 'Marche aeromobile',
      'AIRCRAFT_REGISTRATION_SHORT': 'Marche',
      'AIRCRAFT_TYPE': 'Modello aeromobile',
      'AIRCRAFT_TYPE_SHORT': 'Modello',
      'AIRCRAFT_TIPOLOGY': 'Tipologia velivolo',
      'AIRCRAFT_TIPOLOGY_SHORT': 'Tipologia',
      'AIRCRAFT_UPDATED': 'Aeromobile aggiornato correttamente',
      'AIRFIELD_ADDED': 'Aviosuperficie aggiunta correttamente',
      'AIRFIELD_DATA': 'Dati aviosuperficie',
      'AIRFIELD_DELETED': 'Aviosuperficie rimossa correttamente',
      'AIRFIELD_DETAILS': 'Dettagli aviosuperficie',
      'AIRFIELD_NOT_ADDED': 'Si è presentato un errore durante l\'inserimento della nuova aviosuperficie',
      'AIRFIELD_NOT_DELETED': 'Si è presentato un errore durante l\'eliminazione dell\'aviosuperficie',
      'AIRFIELD_NOT_UPDATED': 'Si è presentato un errore durante la modifica dell\'aviosuperficie',
      'AIRFIELD_RULES_PHRASE': "Ho letto la procedura di atterraggio",
      'AIRFIELD_RULES_LINK': "Link alla procedura di atterraggio",
      'AIRFIELD_UPDATED': 'Aviosuperficie aggiornata correttamente',
      'AIRFIELD_VISIBILITY': 'Visibilità aviosuperficie',
      'AIRFIELDS': 'Elenco piste',
      'AIRFIELD': 'Aviosuperficie',
      'AIRFIELDS_LIST': 'Lista piste',
      'AIRPLANE': 'Aereo',
      'APPROVE': 'Approva',
      'ARE_YOU_MANAGER': 'Sono il gestore di un\'aviosuperficie',
      'ARE_YOU_PILOT': 'Sei un pilota?',
      'APPROVED': 'Approvato',
      'ARRIVAL': 'Arrivo',
      'ARRIVAL_AIRFIELD': 'Aviosuperficie di destinazione',
      'ARRIVAL_DATA': 'Dati dell\'atterraggio',
      'ARRIVAL_PLACE': 'Destinazione',
      'ARRIVAL_TIME': 'Data e orario di arrivo',
      'ARRIVAL_TO_TENANT': 'Voli in arrivo su questa pista',
      'ARRIVING': 'In arrivo',
      'ASCENDING': 'Crescente',
      'AUTHORIZATION_REQUIRED': 'Autorizzazione necessaria',
      'AVATAR': 'Avatar',
      'AUTHORIZATION_REQUIRED_DESCRIPTION': 'Hai provato ad accedere ad un percorso per il quale non possiedi le autorizzazioni. Prova a contattare il tuo amministratore di sistema per ulteriori info',
      'BACK_BUTTON': 'Torna indietro',
      'BACK_BUTTON_SHORT': 'Indietro',
      'BACK_TO_DASHBOARD': 'Torna alla dashboard',
      'BIRTHDATE': 'Data di nascita',
      'CALENDAR': "Calendario",
      'CANCEL': 'Annulla',
      'CHANGE_TENANT': 'Cambia tenant',
      'CLOSE': 'Chiudi',
      'CODE': 'Codice',
      'COLOR': 'Colore',
      'CONFIGURATION': 'Configurazione',
      'CONFIRM': 'Conferma',
      'CONTINENT': 'Continente',
      'COPILOT': 'Copilota',
      'COPILOT_DATA': 'Dati copilota',
      'COPILOT_DOCUMENT': 'Documento del copilota',
      'COPILOT_EMAIL': 'Email del copilota',
      'COPILOT_NAME': 'Nominativo copilota',
      'COPILOT_PHONE_NUMBER': 'Numero di telefono del copilota',
      'CREATE_REPORT': 'Scarica report (Full)',
      'CREATE_REPORT_SHORT': 'Scarica report (Light)',
      'DATE': 'Data',
      'DELETE': 'Elimina',
      'DELETE_AIRFIELD_PHOTOS_BUTTON': 'Cancella foto',
      'DELETE_PHOTO': 'Cancella foto',
      'DEPARTURE': 'Partenza',
      'DEPARTURE_AIRFIELD': 'Aviosuperficie di partenza',
      'DEPARTURE_FROM_TENANT': 'Voli in partenza da questa pista',
      'DEPARTURE_PLACE': 'Provenienza',
      'DEPARTURE_TIME': 'Data e orario di partenza',
      'DEPARTURING': 'In partenza',
      'DESCENDING': 'Decrescente',
      'DETAILS': 'Dettagli',
      'DOCUMENT': 'Documento',
      'DOCUMENT_TYPES_LABEL': 'Tipo di documento',
      'DOCUMENT_VALUE_LABEL': 'Numero di documento',
      'EDIT': 'Modifica',
      'ELEVATION_FT': 'Altezza in Piedi',
      'EMAIL': 'Email',
      'END_ARRIVAL_TIME': 'Data di arrivo al',
      'END_DATE_LABEL_TEXT': 'Data finale',
      'END_DEPARTURE_TIME': 'Data di partenza al',
      'ENGLISH': 'Inglese',
      'EXPORT': 'Esporta',
      'EXPORT_PDF': 'Esporta in PDF',
      'FILTERS': 'Filtri',
      'FIRST_NAME': 'Nome',
      'FISCAL_CODE': 'Codice fiscale',
      'FLIGHT_ADDED': 'Movimento aggiunto correttamente',
      'FLIGHT_APPROVATION_NOTIFICATION_TEXT': 'Aggiornamento stato volo da: ',
      'FLIGHT_DATA': 'Dati movimento',
      'FLIGHT_DELETED': 'Movimento rimosso correttamente',
      'FLIGHT_DETAILS': 'Dettagli movimento',
      'FLIGHT_DIRECTION': 'Direzione',
      'FLIGHT_DIRECTION_SHORT': 'Dir.',
      'FLIGHT_NOT_ADDED': 'Si è presentato un errore durante l\'inserimento del nuovo movimento',
      'FLIGHT_NOT_DELETED': 'Si è presentato un errore durante l\'eliminazione del movimento',
      'FLIGHT_NOT_UPDATED': 'Si è presentato un errore durante la modifica del movimento',
      'FLIGHT_TYPE': 'Tipo movimento',
      'FLIGHT_UPDATED': 'Movimento aggiornato correttamente.',
      'FLIGHTS': 'Movimenti',
      'FLIGHT_LICENSE': 'Licenza di volo',
      'FLIGHTS_LIST': 'Movimenti',
      'FRENCH': 'Francese',
      'GENERAL_AVIATION': 'Aviazione generale',
      'GET_LOCATION': 'Rileva posizione',
      'GLIDER': 'Aliante',
      'GPS_CODE': 'Codice GPS',
      'GYROCOPTER': 'Autogiro',
      'HANG_GLIDER': 'Deltaplano',
      'HAVE_RADIO': "Radio",
      'HAVE_TRANSPONDER': "Transponder",
      'HOME_LINK': 'Link alla home',
      'ICAO_CODE': 'ICAO',
      'IDCARD': "Documento d'identità",
      'IDENT': 'Identificativo',
      'HELICOPTER': 'Elicottero',
      'INSERT_REPORT_EMAIL': 'Inserisci l\'email a cui inviare il report',
      'INTELLIGENT_SEARCH_PHOTO': 'Ricerca intelligente della foto',
      'IS_CARRIED': 'Effettuato',
      'IS_NOT_CARRIED': 'Annullato',
      'IS_NULL_CARRIED': 'Stato',
      'ISO_COUNTRY': 'Codice ISO nazione',
      'ISO_REGION': 'Codice ISO regione',
      'ITALIAN': 'Italiano',
      'KEYWORDS': 'Parole chiave',
      'LANDING': 'Atterraggio',
      'LATITUDE_DEG': 'Latitudine',
      'LICENSE': 'Patente',
      'LOADING': 'Caricamento',
      'LOCAL_CODE': 'Codice locale',
      'LOG_ACTION_DATE_LABEL': 'Data azione',
      'LOG_ACTION_TEXT_LABEL': 'Azione',
      'LOG_ACTION_USERID_LABEL': 'Utente',
      'LOG_APPROVED_FLIGHT': 'Volo approvato',
      'LOG_DELETE_FLIGHT': 'Cancellazione del volo',
      'LOG_INSERT_NEW_FLIGHT': 'Inserimento volo',
      'LOG_INSERT_NEW_PUBLIC_FLIGHT': "Inserimento volo da form pubblico",
      'LOG_IS_CARRIED_FLIGHT': 'Volo effettuato',
      'LOG_IS_NOT_CARRIED_FLIGHT': 'Volo non effettuato',
      'LOG_NOT_APPROVED_FLIGHT': 'Volo rigettato',
      'LOG_TEXT_LABEL': 'Logs',
      'LOG_UPDATE_FLIGHT': 'Aggiornamento del volo',
      'LOGO': 'Logo',
      'LOGOUT': 'Esci',
      'LONGITUDE_DEG': 'Longitudine',
      'MANAGER_FISCAL_CODE': 'Codice fiscale gestore',
      'MANAGER_NAME': 'Nome completo gestore',
      'MANAGER_PHONE_NUMBER': 'Numero di telefono gestore',
      'MORE_OPTIONS': 'Altro',
      'MOVEMENT': 'Movimento',
      'MUNICIPALITY': 'Comune',
      'MY_AIRCRAFTS': 'I miei aeromobili',
      'MY_CALENDAR': 'Il mio calendario',
      'MY_FLIGHTS': 'I miei voli',
      'MY_OWN_AIRIFELD': 'La mia aviosuperficie',
      'MY_PROFILE': 'Il mio profilo',
      'NAME': 'Nome',
      'NEXT_ARRIVAL_TIME': 'Data e orario di ritorno',
      'NEXT_BUTTON': 'Avanti',
      'NEXT_DEPARTURE_TIME': 'Data e orario di ripartenza',
      'NO': 'No',
      'NO_NOTIFICATION': 'Non ci sono notifiche',
      'NOT_APPROVED': 'Non approvato',
      'NOTE': 'Note',
      'NOTIFICATION_MARKED_ALL_BUTTON': 'Segna come letti',
      'NOTIFICATIONS': 'Notifiche',
      'ORDER_BY': 'Ordina per',
      'ORDER_METHOD': "Metodo di ordinamento",
      'PASSPORT': 'Passporto',
      'PEOPLE_ON_BOARD': 'Numero di persone a bordo',
      'PHONE_NUMBER': 'Numero di telefono',
      'PHOTOS_NOT_FOUND': 'Non ci sono foto per questa aviosuperficie',
      'PILOT_NAME': 'Nominativo pilota',
      'PILOT': 'Pilota',
      'PILOT_ACCOUNT_REQUESTED': 'Registrazione completata. A breve sarai reindirizzato alla pagina di accesso.',
      'PILOT_DATA': 'Dati pilota',
      'PILOT_DOCUMENT': 'Documento del pilota',
      'PILOT_EMAIL': 'Email del pilota',
      'PILOT_INSERT_FLIGHT': 'Richiedi',
      'PILOT_INSERT_FLIGHT_NOTIFICATION_TEXT': 'Nuova richiesta di atterraggio da:',
      'PILOT_PHONE_NUMBER': 'Numero di telefono del pilota',
      "PROVINCE": "Provincia",
      'PUBLIC': 'Pubblica',
      'PRIVATE': 'Privata',
      'RECLAIM_AIRFIELD': 'Richiedi',
      'RECLAIM_AIRFIELD_FORM': 'Form di accreditamento',
      'RECLAIM_FLIGHT': 'Richiedi',
      'REGION': 'Regione',
      'REGULATION': 'Regolamento',
      'REGULATION_LINK_LABEL': 'Link regolamento aviosuperficie',
      'REJECT': 'Rigetta',
      'REJECTED': 'Rigettato',
      'REPORT_EMAIL_SENDED': 'Il report è stato inviato correttamente via email',
      'REQUEST_ACCOUNT': 'Registrati',
      'RESIDENCE_ADDRESS': 'Indirizzo di residenza',
      'RETURN_FLIGHT': 'Volo di ritorno',
      'REMOVE': 'Rimuovi',
      'REMOVE_FILTERS': 'Rimuovi filtri',
      'REMOVE_PHOTO': 'Rimuovi foto',
      'ROLE': 'Ruolo',
      'ROWS_PER_PAGE': 'Righe per pagina',
      'SAVE': 'Salva',
      'SCHEDULED_SERVICE': 'Servizio previsto',
      'SCHOOL': 'Scuola',
      'SEARCH_AIRFIELDS': 'Cerca aviosuperfici',
      'SEARCH': 'Cerca',
      'SEARCH_TENANTS': 'Cerca tenant',
      'SEARCH_USERS': 'Cerca utenti',
      'SELECT_AIRCRAFT': 'Seleziona l\'aeromobile',
      'SELECT_AIRCRAFT_TYPE': 'Seleziona il tipo di aeromobile',
      'SELECT_AVATAR': 'Seleziona avatar',
      'SELECT_DATA_REPORT': 'Seleziona periodo di esportazione dei movimenti',
      'SELECT_DOCUMENT_TYPE_OPTION': 'Seleziona opzione',
      'SELECT_FLIGHT_TYPE': 'Seleziona il tipo di volo',
      'SELECT_ROLE': 'Seleziona il ruolo',
      'SELECT_TENANT': 'Seleziona il tenant',
      'SELECT_TENANTS': 'Seleziona i tenant',
      'SIGNUP_TEXT': 'Crea il tuo profilo pilota',
      'START_ARRIVAL_TIME': 'Data di arrivo da',
      'START_DATE_LABEL_TEXT': 'Data iniziale',
      'START_DEPARTURE_TIME': 'Data di partenza da',
      'SUMMARY': 'Riepilogo',
      'SURNAME': 'Cognome',
      'TENANT': 'Tenant',
      'TENANT_ADDED': 'Tenant aggiunto correttamente',
      'TENANT_APIKEY_BUTTON': "Genera ApiKey",
      'TENANT_APIKEY_ERROR': "Si è presentato un errore durante la generazione dell'ApiKey",
      'TENANT_APIKEY_GENERATED': "ApiKey generata. Clicca su 'Salva' per completare l'operazione",
      'TENANT_APIKEY_LABEL': "ApiKey",
      'TENANT_CHANGED': 'Tenant cambiato correttamente',
      'TENANT_DATA': 'Dati aviosuperficie',
      'TENANT_DELETED': 'Tenant rimosso correttamente',
      'TENANT_DETAILS': 'Dettagli aviosuperficie',
      'TENANT_IFRAME_URL_BUTTON': "Genera l'Url dell'Iframe",
      'TENANT_IFRAME_URL_ERROR': "Si è presentato un errore durante la generazione dell'Url dell'Iframe",
      'TENANT_IFRAME_URL_GENERATED': "Url Iframe generato. Clicca su 'Salva' per completare l'operazione",
      'TENANT_IFRAME_URL_LABEL': "Url",
      'TENANT_UPDATED': 'Tenant modificato correttamente.',
      'TENANT_NOT_ADDED': 'Si è presentato un errore durante l\'aggiunta del tenant',
      'TENANT_NOT_CHANGED': 'Si è presentato un errore durante il cambio del tenant',
      'TENANT_NOT_DELETED': 'Si è presentato un errore durante la rimozione del tenant',
      'TENANT_NOT_UPDATED': 'Si è presentato un errore durante la modifica del tenant',
      'TENANTS': 'Tenants',
      'TENANTS_LIST': 'Lista tenant',
      'THERE_IS_COPILOT': 'C\'è il copilota?',
      'TIMETABLE': 'Orario',
      'TITLE_MODAL_DELETE_AIRFIELD': 'Sei sicuro di voler rimuovere questa aviosuperficie?',
      'TITLE_MODAL_DELETE_FLIGHT': 'Sei sicuro di voler rimuovere questo movimento?',
      'TITLE_MODAL_DELETE_TENANT': 'Sei sicuro di voler rimuovere questo tenant?',
      'TITLE_MODAL_DELETE_USER': 'Sei sicuro di voler rimuovere questo utente?',
      'TITLE_MODAL_FILTER_FLIGHTS': 'Specifica i filtri da applicare',
      'TYPE': 'Tipo',
      'ULTRALIGHT': 'Ultraleggero',
      'UPDATE_AIRFIELD': 'Aggiorna aviosuperficie',
      'UPDATE_AIRFIELD_PHOTOS_BUTTON': 'Carica foto',
      'UPDATE_FLIGHT': 'Aggiorna movimento',
      'UPDATE_FLIGHT_STATE': 'Convalida',
      'UPDATE_TENANT': 'Aggiorna aviosuperficie',
      'UPDATE_USER': 'Aggiorna utente',
      'UPLOAD': 'Carica',
      'UPLOAD_PHOTO': 'Carica foto',
      'USERNAME': 'Username',
      'USER_ADDED': 'Utente aggiunto correttamente',
      'USER_DATA': 'Dati utente',
      'USER_DELETED': 'Utente rimosso correttamente',
      'USER_DETAILS': 'Dettagli utente',
      'USER_DISABLE_MSG': 'Stai cercando di raggiungere una pagina non valida',
      'USER_NOT_ADDED': 'Si è presentato un errore durante l\'inserimento del nuovo utente',
      'USER_NOT_DELETED': 'Si è presentato un errore durante l\'eliminazione dell\'utente',
      'USER_NOT_UPDATED': 'Si è presentato un errore durante la modifica dell\'utente',
      'USER_UPDATED': 'Utente aggiornato correttamente',
      'USERS': 'Utenti',
      'USERS_LIST': 'Lista utenti',
      'VISIBILITY_TEXT': 'Un aviosuperficie pubblica verrà mostrata nella lista delle aviosuperfici pubbliche alla pagina: https://www.smartairfield.cloud/#/list',
      'WAITING_FOR_APPROVAL': 'Da approvare',
      'WIKIPEDIAINK': 'WIKIPEDIAINK',
      'YES': 'Si',
      // ----------------------------------------------------------------------------
      // Message errors
      'AIRCRAFT_REGISTRATION_REQUIRED': 'Le marche dell\'aeromobile sono obbligatorie',
      'AIRCRAFT_TYPE_REQUIRED': 'Il tipo di aeromobile è obbligatorio',
      'AIRFIELD_NAME_REQUIRED': 'Il nome della pista è obbligatorio',
      'AIRFIELD_REQUIRED': 'La pista è obbligatoria',
      'AIRFIELD_TYPE_REQUIRED': 'Il tipo di pista è obbligatorio',
      'ARRIVAL_AIRFIELD_REQUIRED': 'L\'aviosuperficie di destinazione è obbligatoria',
      'ARRIVAL_TIME_REQUIRED': 'La data di destinazione è obbligatoria',
      'CONTINENT_REQUIRED': 'Il continente è obbligatorio',
      'COPILOT_DOCUMENT_TYPE_REQUIRED': 'Il tipo di documento del copilota è obbligatorio',
      'COPILOT_DOCUMENT_VALUE_REQUIRED': 'Il numero del documento del copilota è obbligatorio',
      'COPILOT_EMAIL_REQUIRED': 'L\'email del copilota non è stata scritta correttamente',
      'COPILOT_FIELDS_REQUIRED': 'Inserire tutti i campi del copilota',
      'COPILOT_NAME_REQUIRED': 'Il nome del copilota è obbligatorio',
      'COPILOT_PHONE_REQUIRED': 'Il numero di telefono del copilota è obbligatorio',
      'DEPARTURE_AIRFIELD_REQUIRED': 'La pista di partenza è obbligatoria',
      'DEPARTURE_TIME_REQUIRED': 'La data e l\'orario di partenza sono obbligatori',
      'DOCUMENT_TYPE_REQUIRED': 'Il tipo di documento è obbligatorio',
      'DOCUMENT_VALUE_REQUIRED': 'Il numero di serie del documento è obbligatorio',
      'ELEVATION_FT_REQUIRED': 'I piedi di elevazione sono obbligatori',
      'EMAIL_REQUIRED': 'L\'email è obbligatoria',
      'FLIGHT_TYPE_REQUIRED': 'Il tipo di volo è obbligatorio',
      'GPS_CODE_REQUIRED': 'Il codice GPS è obbligatorio',
      'CODE_REQUIRED': 'L\'identificativo è obbligatorio',
      "ICAOCODE_REQUIRED": 'Il codice icao è obblitagorio',
      'ISO_COUNTRY_REQUIRED': 'Il codice ISO della nazione è obbligatorio',
      'ISO_REGION_REQUIRED': 'Il codice ISO della regione è obbligatorio',
      'LATITUDE_REQUIRED': 'La latitudine è obbligatoria',
      'LOCAL_CODE_REQUIRED': 'Il codice locale è obbligatorio',
      'LONGITUDE_REQUIRED': 'La longitudine è obbligatoria',
      'MANAGER_FULL_NAME_REQUIRED': 'Il nome completo del manager è obbligatorio',
      'MANAGER_FISCAL_CODE_REQUIRED': 'Il codice fiscale del manager è obbligatorio',
      'MANAGER_PHONE_NUMBER_REQUIRED': 'Il numero di telefono del manager è obbligatorio',
      'MUNICIPALITY_REQUIRED': 'Il comune è obbligatorio',
      'PEOPLE_ON_BOARD_MIN_NUMBER': 'Il numero di persone a bordo deve essere pari o uguale a ',
      'NAME_REQUIRED': 'Il nome è obbligatorio',
      'PEOPLE_ON_BOARD_REQUIRED': 'Il numero di persone a bordo è obbligatorio',
      'PHONE_NUMBER_REQUIRED': 'Il numero di telefono è obbligatorio',
      'PILOT_DOCUMENT_TYPE_REQUIRED': 'Il tipo di documento del pilota è obbligatorio',
      'PILOT_DOCUMENT_VALUE_REQUIRED': 'Il numero del documento del pilota è obbligatorio',
      'PILOT_EMAIL_REQUIRED': 'L\'email del pilota non è stata scritta correttamente',
      'PILOT_NAME_REQUIRED': 'Il nome del pilota è obbligatorio',
      'PILOT_PHONE_REQUIRED': 'Il numero di telefono del pilota è obbligatorio',
      'PROVINCE_REQUIRED': 'La provincia è obbligatoria',
      'REGION_REQUIRED': 'La regione è obbligatoria',
      'ROLE_REQUIRED': 'Il ruolo è obbligatorio',
      'SCHEDULED_SERVICE_REQUIRED': 'Il servizio previsto è obbligatorio',
      'SURNAME_REQUIRED': 'Il cognome è obbligatorio',
      'TENANT_NAME_REQUIRED': 'Il nome del tenant è obbligatorio',
      'TENANT_TYPE_REQUIRED': 'Il tipo tenant è obbligatorio',
      'TOO_MANY_PHOTOS': 'Puoi caricare al massimo 10 fotografie'
    }
  },
  fr: {
    translation: {
      // Quando si modifica il nome di un ruolo, bisogna aggiornare anche il suo complementare
      'ADMINISTRATOR': 'Administrateur',
      'SYSTEMADMIN': 'Administrateur système',
      'USER': 'Utilisateur',
      'VIEWER': 'Téléspectateur',
      // ----------------------------------------------------------------------------
      // Days of week
      'MONDAY': 'Lundi',
      'TUESDAY': 'Mardi',
      'WEDNESDAY': 'Mercredi',
      'THURSDAY': 'Jeudi',
      'FRIDAY': 'Vendredi',
      'SATURDAY': 'Samedi',
      'SUNDAY': 'Dimanche',
      'MON': 'MON',
      'TUE': 'MAR',
      'WED': 'MER',
      'THU': 'JEU',
      'FRI': 'VEN',
      'SAT': 'SAT',
      'SUN': 'DIM',
      'TODAY': 'Aujourd\'hui',
      // ----------------------------------------------------------------------------
      //Months
      'JANUARY': 'Janvier',
      'FEBRUARY': 'Février',
      'MARCH': 'Mars',
      'APRIL': 'Avril',
      'MAY': 'Mai',
      'JUNE': 'Juin',
      'JULY': 'Juillet',
      'AUGUST': 'Août',
      'SEPTEMBER': 'Septembre',
      'OCTOBER': 'Octobre',
      'NOVEMBER': 'Novembre',
      'DECEMBER': 'Décembre',
      'JANUARY_SHORT': 'Jan',
      'FEBRUARY_SHORT': 'Fév',
      'MARCH_SHORT': 'Mar',
      'APRIL_SHORT': 'Avr',
      'MAY_SHORT': 'Mai',
      'JUNE_SHORT': 'Juin',
      'JULY_SHORT': 'Juil',
      'AUGUST_SHORT': 'Août',
      'SEPTEMBER_SHORT': 'Sep',
      'OCTOBER_SHORT': 'Oct',
      'NOVEMBER_SHORT': 'Nov',
      'DECEMBER_SHORT': 'Déc',
      // ----------------------------------------------------------------------------
      // Flight types
      'BUSINESS': 'Travailler',
      'CARGO': 'Transport de marchandises',
      'TOURISTIC': 'Touristique',
      // Nation
      'ITALY': 'Italie',
      // ----------------------------------------------------------------------------
      'ACCOUNT_NOT_REQUESTED': 'Une erreur s\'est produite lors de la demande de création de compte',
      'ACCOUNT_REQUESTED': 'Votre demande a été traitée. Vous serez contacté dans les plus brefs délais',
      'ACTIONS': 'Actions',
      'ADD_AIRCRAFT': 'Ajouter un avion',
      'ADD_AIRFIELD': 'Ajouter un aérodrome',
      'ADD_FLIGHT': 'Ajouter',
      'ADD_TENANT': 'Ajouter un locataire',
      'ADD_USER': 'Ajouter un utilisateur',
      'AIRCRAFT': 'Avion',
      'AIRCRAFT_ADDED': 'Avion ajouté avec succès',
      'AIRCRAFT_CATEGORY': 'Catégorie d\'avion',
      'AIRCRAFT_CATEGORY_SHORT': 'Catégorie',
      'AIRCRAFT_DATA': 'Données de l\'avion',
      'AIRCRAFTS_DATA_LIST': 'Mon avion',
      'AIRCRAFT_DELETED': 'Avion supprimé avec succès',
      'ADVANCED_FILTERS': 'Filtres avancés',
      'AIRCRAFT_NOT_ADDED': 'Une erreur s\'est produite lors de l\'ajout du nouvel avion',
      'AIRCRAFT_NOT_DELETED': 'Une erreur s\'est produite lors de la modification de l\'avion',
      'AIRCRAFT_NOT_UPDATED': 'Une erreur s\'est produite lors de la suppression de l\'avion',
      'AIRCRAFT_REGISTRATION': 'Marques d\'avions',
      'AIRCRAFT_REGISTRATION_SHORT': 'Marques',
      'AIRCRAFT_TYPE': 'Modèle d\'avion',
      'AIRCRAFT_TYPE_SHORT': 'Modèle',
      'AIRCRAFT_TIPOLOGY': 'Type d\'avion',
      'AIRCRAFT_TIPOLOGY_SHORT': 'Typologie',
      'AIRCRAFT_UPDATED': 'Avion mis à jour avec succès',
      'AIRFIELD_ADDED': 'Aérodrome ajouté avec succès',
      'AIRFIELD_DATA': 'Données d\'aérodrome',
      'AIRFIELD_DELETED': 'Aérodrome supprimé avec succès',
      'AIRFIELD_DETAILS': 'Détails de l\'aérodrome',
      'AIRFIELD_NOT_ADDED': 'Une erreur s\'est produite lors de l\'ajout du nouvel aérodrome',
      'AIRFIELD_NOT_DELETED': 'Une erreur s\'est produite lors de la suppression de l\'aérodrome',
      'AIRFIELD_NOT_UPDATED': 'Une erreur s\'est produite lors de la modification de l\'aérodrome',
      'AIRFIELD_RULES_PHRASE': "J\'ai lu la procédure d\'atterrissage",
      'AIRFIELD_RULES_LINK': "Lien vers la procédure d\'atterrissage",
      'AIRFIELD_UPDATED': 'Aérodrome mis à jour avec succès',
      'AIRFIELD_VISIBILITY': 'Visibilité de l\'aérodrome',
      'AIRFIELDS': 'Liste des pistes',
      'AIRFIELD': 'Aérodrome',
      'AIRFIELDS_LIST': 'Liste des pistes',
      'AIRPLANE': 'Avion',
      'APPROVE': 'Approuver',
      'ARE_YOU_MANAGER': 'Je suis gestionnaire d\'un aérodrome',
      'ARE_YOU_PILOT': 'Es-tu pilote ?',
      'APPROVED': 'Approuvé',
      'ARRIVAL': 'Arrivée',
      'ARRIVAL_AIRFIELD': 'Aérodrome de destination',
      'ARRIVAL_DATA': 'Données d\'atterrissage',
      'ARRIVAL_PLACE': 'Destination',
      'ARRIVAL_TIME': 'Date et heure d\'arrivée (UTC)',
      'ARRIVAL_TO_TENANT': 'Vols arrivant sur cette piste',
      'ARRIVING': 'Bientôt',
      'ASCENDING': 'Ascendant',
      'AUTHORIZATION_REQUIRED': 'Autorisation requise',
      'AVATAR': 'Avatar',
      'AUTHORIZATION_REQUIRED_DESCRIPTION': 'Vous avez tenté d\'accéder à un emplacement pour lequel vous n\'avez pas d\'autorisations. Veuillez essayer de contacter votre administrateur système pour plus d\'informations',
      'BACK_BUTTON': 'Revenir en arrière',
      'BACK_BUTTON_SHORT': 'Retour',
      'BACK_TO_DASHBOARD': 'Retour au tableau de bord',
      'BIRTHDATE': 'Date de naissance',
      'CALENADR': 'Calendrier',
      'CANCEL': 'Annuler',
      'CHANGE_TENANT': 'Changer de locataire',
      'CLOSE': 'Fermer',
      'CODE': 'Code',
      'COLOR': 'Couleur',
      'CONFIGURATION': 'Configuration',
      'CONFIRM': 'Confirmer',
      'CONTINENT': 'Continent',
      'COPILOT': 'Copilote',
      'COPILOT_DATA': 'Données copilote',
      'COPILOT_DOCUMENT': 'Document copilote',
      'COPILOT_EMAIL': 'E-mail du copilote',
      'COPILOT_NAME': 'Nom du copilote',
      'COPILOT_PHONE_NUMBER': 'Numéro de téléphone du copilote',
      'CREATE_REPORT': 'Télécharger le rapport (Full)',
      'CREATE_REPORT_SHORT': 'Télécharger le rapport (Light)',
      'DATE': 'Date',
      'DELETE': 'Supprimer',
      'DELETE_AIRFIELD_PHOTOS_BUTTON': 'Supprimer la photo',
      'DELETE_PHOTO': 'Supprimer la photo',
      'DEPARTURE': 'Départ',
      'DEPARTURE_AIRFIELD': 'Aérodrome de départ',
      'DEPARTURE_FROM_TENANT': 'Vols au départ de cette piste',
      'DEPARTURE_PLACE': 'Provenance',
      'DEPARTURE_TIME': 'Date et heure de départ (UTC)',
      'DEPARTURING': 'Départ',
      'DESCENDING': 'Decroissant',
      'DETAILS': 'Détails',
      'DOCUMENT': 'Document',
      'DOCUMENT_TYPES_LABEL': 'Type de document',
      'DOCUMENT_VALUE_LABEL': 'Numéro du document',
      'EDIT': 'Modifica',
      'ELEVATION_FT': 'Hauteur en pieds',
      'EMAIL': 'Email',
      'END_ARRIVAL_TIME': 'Date d\'arrivée le',
      'END_DATE_LABEL_TEXT': 'Date finale',
      'END_DEPARTURE_TIME': 'Date de départ le',
      'ENGLISH': 'Anglais',
      'EXPORT': 'Exporter',
      'EXPORT_PDF': 'Exporter en PDF',
      'FILTERS': 'Filtres',
      'FIRST_NAME': 'Prénom',
      'FISCAL_CODE': 'Code fiscal',
      'FLIGHT_ADDED': 'Mouvement ajouté avec succès',
      'FLIGHT_APPROVATION_NOTIFICATION_TEXT': 'Mise à jour du statut du vol à partir de :',
      'FLIGHT_DATA': 'Données de mouvement',
      'FLIGHT_DELETED': 'Mouvement supprimé avec succès',
      'FLIGHT_DETAILS': 'Détails du mouvement',
      'FLIGHT_DIRECTION': 'Direction',
      'FLIGHT_DIRECTION_SHORT': 'Dir.',
      'FLIGHT_NOT_ADDED': 'Une erreur s\'est produite lors de l\'insertion du nouveau mouvement',
      'FLIGHT_NOT_DELETED': 'Une erreur s\'est produite lors de la suppression du mouvement',
      'FLIGHT_NOT_UPDATED': 'Une erreur s\'est produite lors de l\'édition du mouvement',
      'FLIGHT_TYPE': 'Type de mouvement',
      'FLIGHT_UPDATED': 'Mouvement mis à jour avec succès.',
      'FLIGHTS': 'Mouvements',
      'FLIGHT_LICENSE': 'Licence de vol',
      'FLIGHTS_LIST': 'Mouvements',
      'FRENCH': 'Français',
      'GENERAL_AVIATION': 'Aviation générale',
      'GET_LOCATION': 'Obtenir la position',
      'GLIDER': 'Planeur',
      'GPS_CODE': 'Code GPS',
      'GYROCOPTER': 'Autogiro',
      'HANG_GLIDER': 'Deltaplane',
      'HAVE_RADIO': "Radio",
      'HAVE_TRANSPONDER': "Transpondeur",
      'HOME_LINK': 'Lien vers la maison',
      'ICAO_CODE': 'ICAO',
      'IDCARD': "Pièce d'identité",
      'IDENT': 'Identifiant',
      'HELICOPTER': 'Hélicoptère',
      'INSERT_REPORT_EMAIL': 'Entrer l\'adresse e- mail à laquelle envoyer le rapport',
      'INTELLIGENT_SEARCH_PHOTO': 'Recherche intelligente de photos',
      'IS_CARRIED': 'Réalisé',
      'IS_NOT_CARRIED': 'Annulé',
      'IS_NULL_CARRIED': 'Statut',
      'ISO_COUNTRY': 'Code pays ISO',
      'ISO_REGION': 'Code de région ISO',
      'ITALIAN': 'Italien',
      'KEYWORDS': 'Mots clés',
      'LANDING': 'Atterrissage',
      'LATITUDE_DEG': 'Latitude',
      'LICENSE': 'Licence',
      'LOADING': 'Chargement',
      'LOCAL_CODE': 'Code local',
      'LOG_ACTION_DATE_LABEL': 'Date d\'action',
      'LOG_ACTION_TEXT_LABEL': 'Action',
      'LOG_ACTION_USERID_LABEL': 'Utilisateur',
      'LOG_APPROVED_FLIGHT': 'Vol approuvé',
      'LOG_DELETE_FLIGHT': 'Suppression de vol',
      'LOG_INSERT_NEW_FLIGHT': 'Insérer un vol',
      'LOG_INSERT_NEW_PUBLIC_FLIGHT': "Insertion de vol depuis formulaire public",
      'LOG_IS_CARRIED_FLIGHT': 'Vol terminé',
      'LOG_IS_NOT_CARRIED_FLIGHT': 'Vol non effectué',
      'LOG_NOT_APPROVED_FLIGHT': 'Vol rejeté',
      'LOG_TEXT_LABEL': 'Journaux',
      'LOG_UPDATE_FLIGHT': 'Mise à jour du vol',
      'LOGO': 'Logo',
      'LOGOUT': 'Déconnexion',
      'LONGITUDE_DEG': 'Longitude',
      'MANAGER_FISCAL_CODE': 'Code fiscal du gestionnaire',
      'MANAGER_NAME': 'Nom complet du responsable',
      'MANAGER_PHONE_NUMBER': 'Numéro de téléphone du responsable',
      'MORE_OPTIONS': 'Plus',
      'MOVEMENT': 'Mouvement',
      'MUNICIPALITY': 'Commune',
      'MY_AIRCRAFTS': 'Mon avion',
      'MY_CALENDAR': 'Mon calendrier',
      'MY_FLIGHTS': 'Mes vols',
      'MY_OWN_AIRIFELD': 'Mon aérodrome',
      'MY_PROFILE': 'Mon profil',
      'NAME': 'Nome',
      'NEXT_ARRIVAL_TIME': 'Date et heure de retour',
      'NEXT_BUTTON': 'Suivant',
      'NEXT_DEPARTURE_TIME': 'Date et heure de départ',
      'NO': 'No',
      'NO_NOTIFICATION': 'Il n\'y a aucune notification',
      'NOT_APPROVED': 'Non approuvé',
      'NOTE': 'Remarques',
      'NOTIFICATION_MARKED_ALL_BUTTON': 'Marquer comme lu',
      'NOTIFICATIONS': 'Notifications',
      'ORDER_BY': 'Trier par',
      'ORDER_METHOD': "Méthode de commande",
      'PASSPORT': 'Passeport',
      'PEOPLE_ON_BOARD': 'Nombre de personnes à bord',
      'PHONE_NUMBER': 'Numéro de téléphone',
      'PHOTOS_NOT_FOUND': 'Il n\'y a pas de photos pour cet aérodrome',
      'PILOT_NAME': 'Nom du pilote',
      'PILOT': 'Pilote',
      'PILOT_ACCOUNT_REQUESTED': 'Inscription terminée. Vous serez bientôt redirigé vers la page de connexion.',
      'PILOT_DATA': 'Données pilotes',
      'PILOT_DOCUMENT': 'Document pilote',
      'PILOT_EMAIL': 'E-mail pilote',
      'PILOT_INSERT_FLIGHT': 'Demande',
      'PILOT_INSERT_FLIGHT_NOTIFICATION_TEXT': 'Nouvelle demande d\'atterrissage de :',
      'PILOT_PHONE_NUMBER': 'Numéro de téléphone du pilote',
      "PROVINCE": "Province",
      'PUBLIC': 'Publier',
      'PRIVATE': 'Privé',
      'RECLAIM_AIRFIELD': 'Demande',
      'RECLAIM_AIRFIELD_FORM': 'Formulaire d\'accréditation',
      'RECLAIM_FLIGHT': 'Demande',
      'REGION': 'Région',
      'REGULATION': 'Règlement',
      'REGULATION_LINK_LABEL': 'Lien de régulation d\'aérodrome',
      'REJECT': 'Rejeter',
      'REJECTED': 'Rejeté',
      'REPORT_EMAIL_SENDED': 'Le rapport a été envoyé avec succès par email',
      'REQUEST_ACCOUNT': 'S\'inscrire',
      'RESIDENCE_ADDRESS': 'Adresse de résidence',
      'REMOVE': 'Supprimer',
      'REMOVE_FILTERS': 'Supprimer les filtres',
      'REMOVE_PHOTO': 'Retirer photo',
      'RETURN_FLIGHT': 'Vol de retour',
      'ROLE': 'Rôle',
      'ROWS_PER_PAGE': 'Lignes par page',
      'SAVE': 'Sauvegarder',
      'SCHEDULED_SERVICE': 'Prestation attendue',
      'SCHOOL': 'École',
      'SEARCH_AIRFIELDS': 'Rechercher des aérodromes',
      'SEARCH': 'Près',
      'SEARCH_TENANTS': 'Recherche de locataires',
      'SEARCH_USERS': 'Rechercher des utilisateurs',
      'SELECT_AIRCRAFT': 'Sélectionnez l\'avion',
      'SELECT_AIRCRAFT_TYPE': 'Sélectionnez le type d\'avion',
      'SELECT_AVATAR': 'Sélectionnez un avatar',
      'SELECT_DATA_REPORT': 'Sélectionnez la plage pour créer le rapport',
      'SELECT_DOCUMENT_TYPE_OPTION': 'Sélectionnez une option',
      'SELECT_FLIGHT_TYPE': 'Sélectionnez le type de vol',
      'SELECT_ROLE': 'Sélectionnez le rôle',
      'SELECT_TENANT': 'Sélectionnez votre locataire',
      'SELECT_TENANTS': 'Sélectionnez les locataires',
      'SIGNUP_TEXT': 'Créez votre profil de pilote',
      'START_ARRIVAL_TIME': 'Date d\'arrivée à partir de',
      'START_DATE_LABEL_TEXT': 'Date initiale',
      'START_DEPARTURE_TIME': 'Date de départ du',
      'SUMMARY': 'Résumé',
      'SURNAME': 'Nom',
      'TENANT': 'Locataire',
      'TENANT_ADDED': 'Locataire ajouté avec succès',
      'TENANT_APIKEY_BUTTON': "Générer une clé API",
      'TENANT_APIKEY_ERROR': "Une erreur s\'est produite lors de la génération de l'ApiKey",
      'TENANT_APIKEY_GENERATED': "ApiKey générée. Cliquez sur \'Enregistrer\' pour terminer l\'opération",
      'TENANT_APIKEY_LABEL': "ApiKey",
      'TENANT_CHANGED': 'Le locataire a changé avec succès',
      'TENANT_DATA': 'Données d\'aérodrome',
      'TENANT_DELETED': 'Locataire supprimé avec succès',
      'TENANT_DETAILS': 'Détails de l\'aérodrome',
      'TENANT_IFRAME_URL_BUTTON': "Générer l'URL Iframe",
      'TENANT_IFRAME_URL_ERROR': "Une erreur s'est produite lors de la génération de l'URL Iframe",
      'TENANT_IFRAME_URL_GENERATED': "URL Iframe générée. Cliquez sur 'Enregistrer' pour terminer l'opération",
      'TENANT_IFRAME_URL_LABEL': "Url",
      'TENANT_UPDATED': 'Locataire modifié avec succès.',
      'TENANT_NOT_ADDED': 'Une erreur s\'est produite lors de l\'ajout du locataire',
      'TENANT_NOT_CHANGED': 'Une erreur s\'est produite lors du changement de locataire',
      'TENANT_NOT_DELETED': 'Une erreur s\'est produite lors de la suppression du locataire',
      'TENANT_NOT_UPDATED': 'Une erreur s\'est produite lors du changement de locataire',
      'TENANTS': 'Locataires',
      'TENANTS_LIST': 'Liste des locataires',
      'THERE_IS_COPILOT': 'Y a-t-il un copilote ?',
      'TIMETABLE': 'Heure (UTC)',
      'TITLE_MODAL_DELETE_AIRFIELD': 'Êtes-vous sûr de vouloir supprimer cet aérodrome ?',
      'TITLE_MODAL_DELETE_FLIGHT': 'Êtes-vous sûr de vouloir supprimer ce mouvement ?',
      'TITLE_MODAL_DELETE_TENANT': 'Êtes-vous sûr de vouloir supprimer ce locataire ?',
      'TITLE_MODAL_DELETE_USER': 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      'TITLE_MODAL_FILTER_FLIGHTS': 'Spécifiez les filtres à appliquer',
      'TYPE': 'Gars',
      'ULTRALIGHT': 'Ultraléger',
      'UPDATE_AIRFIELD': 'Mettre à jour l\'aérodrome',
      'UPDATE_AIRFIELD_PHOTOS_BUTTON': 'Télécharger des photos',
      'UPDATE_FLIGHT': 'Mettre à jour le mouvement',
      'UPDATE_FLIGHT_STATE': 'Valider',
      'UPDATE_TENANT': 'Mettre à jour l\'aérodrome',
      'UPDATE_USER': 'Mettre à jour l\'utilisateur',
      'UPLOAD': 'Charger',
      'UPLOAD_PHOTO': 'Télécharger des photos',
      'USERNAME': 'Nom d\'utilisateur',
      'USER_ADDED': 'Utilisateur ajouté avec succès',
      'USER_DATA': 'Données d\'utilisateur',
      'USER_DELETED': 'Utilisateur supprimé avec succès',
      'USER_DETAILS': 'Détails de l\'utilisateur',
      'USER_DISABLE_MSG': 'Vous essayez d\'accéder à une page invalide',
      'USER_NOT_ADDED': 'Une erreur s\'est produite lors de l\'ajout du nouvel utilisateur',
      'USER_NOT_DELETED': 'Une erreur s\'est produite lors de la suppression de l\'utilisateur',
      'USER_NOT_UPDATED': 'Une erreur s\'est produite lors de la modification de l\'utilisateur',
      'USER_UPDATED': 'L\'utilisateur a été mis à jour avec succès',
      'USERS': 'Utilisateurs',
      'USERS_LIST': 'Liste d\'utilisateur',
      'VISIBILITY_TEXT': 'Un aérodrome public sera affiché dans la liste des aérodromes publics sur la page : https://www.smartairfield.cloud/#/list',
      'WAITING_FOR_APPROVAL': 'Doit être approuvé',
      'WIKIPEDIAINK': 'WIKIPEDIAINK',
      'YES': 'Oui',
      // ----------------------------------------------------------------------------
      // Message errors
      'AIRCRAFT_REGISTRATION_REQUIRED': 'Le marquage des avions est obligatoire',
      'AIRCRAFT_TYPE_REQUIRED': 'Le type d\'avion est obligatoire',
      'AIRFIELD_NAME_REQUIRED': 'Le nom de la piste est obligatoire',
      'AIRFIELD_REQUIRED': 'La piste est obligatoire',
      'AIRFIELD_TYPE_REQUIRED': 'Le type de piste est obligatoire',
      'ARRIVAL_AIRFIELD_REQUIRED': 'L\'aérodrome de destination est obligatoire',
      'ARRIVAL_TIME_REQUIRED': 'La date cible est obligatoire',
      'CONTINENT_REQUIRED': 'Le continent est obligatoire',
      'COPILOT_DOCUMENT_TYPE_REQUIRED': 'Le type de document copilote est obligatoire',
      'COPILOT_DOCUMENT_VALUE_REQUIRED': 'Le numéro de document copilote est obligatoire',
      'COPILOT_EMAIL_REQUIRED': 'L\'e-mail du copilote n\'a pas été rédigé correctement',
      'COPILOT_FIELDS_REQUIRED': 'Entrez tous les champs du copilote',
      'COPILOT_NAME_REQUIRED': 'Le nom du copilote est obligatoire',
      'COPILOT_PHONE_REQUIRED': 'Le numéro de téléphone du copilote est obligatoire',
      'DEPARTURE_AIRFIELD_REQUIRED': 'La piste de départ est obligatoire',
      'DEPARTURE_TIME_REQUIRED': 'La date et l\'heure de départ sont obligatoires',
      'DOCUMENT_TYPE_REQUIRED': 'Le type de document est obligatoire',
      'DOCUMENT_VALUE_REQUIRED': 'Le numéro du document est obligatoire',
      'ELEVATION_FT_REQUIRED': 'La position debout est obligatoire',
      'EMAIL_REQUIRED': 'L\'e-mail est requis',
      'FLIGHT_TYPE_REQUIRED': 'Le type de vol est obligatoire',
      'GPS_CODE_REQUIRED': 'Le code GPS est obligatoire',
      'CODE_REQUIRED': 'L\'identifiant est obligatoire',
      "ICAOCODE_REQUIRED": 'Le code ICAO est obligatoire',
      'ISO_COUNTRY_REQUIRED': 'Le code pays ISO est obligatoire',
      'ISO_REGION_REQUIRED': 'Le code région ISO est obligatoire',
      'LATITUDE_REQUIRED': 'La latitude est obligatoire',
      'LOCAL_CODE_REQUIRED': 'Le code local est obligatoire',
      'LONGITUDE_REQUIRED': 'La longitude est obligatoire',
      'MANAGER_FULL_NAME_REQUIRED': 'Le nom complet du gérant est obligatoire',
      'MANAGER_FISCAL_CODE_REQUIRED': 'Le code fiscal du gérant est obligatoire',
      'MANAGER_PHONE_NUMBER_REQUIRED': 'Le numéro de téléphone du gestionnaire est requis',
      'MUNICIPALITY_REQUIRED': 'La commune est obligatoire',
      'PEOPLE_ON_BOARD_MIN_NUMBER': 'Le nombre de personnes à bord doit être égal ou égal à',
      'NAME_REQUIRED': 'Le nom est obligatoire',
      'PEOPLE_ON_BOARD_REQUIRED': 'Le nombre de personnes à bord est obligatoire',
      'PHONE_NUMBER_REQUIRED': 'Le numéro de téléphone est obligatoire',
      "PILOT_DOCUMENT_TYPE_REQUIRED": "Le type de document du pilote est requis",
      "PILOT_DOCUMENT_VALUE_REQUIRED": "Le numéro de document du pilote est requis",
      "PILOT_EMAIL_REQUIRED": "L'e-mail du pilote n'a pas été correctement écrit",
      "PILOT_NAME_REQUIRED": "Le nom du pilote est requis",
      "PILOT_PHONE_REQUIRED": "Le numéro de téléphone du pilote est requis",
      "PROVINCE_REQUIRED": "La province est requise",
      "REGION_REQUIRED": "La région est requise",
      "ROLE_REQUIRED": "Le rôle est requis",
      "SCHEDULED_SERVICE_REQUIRED": "Le service programmé est requis",
      "SURNAME_REQUIRED": "Le nom de famille est requis",
      "TENANT_NAME_REQUIRED": "Le nom du locataire est requis",
      "TENANT_TYPE_REQUIRED": "Le type de locataire est requis",
      "TOO_MANY_PHOTOS": "Vous pouvez télécharger au maximum 10 photos"
    }
  }
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
