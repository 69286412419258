import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import {userCanUseIfRoles} from "../../utils/common";
import FlightsListCard from "../../components/flight/FlightsListCard";
import {Description, MoreVert, PictureAsPdf} from "@material-ui/icons";
import {useState} from "react";
import PermissionsGuard from "../../components/PermissionGuard";
import FlightReportDatesChooseModal from "../../components/flight/FlightReportDatesChooseModal";

const FlightsList = () => {
  const { t } = useTranslation();
  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const currentTheme = useTheme();
  const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('sm'));

  return (
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'VIEWER']}
      environment={'FlightsList'}
      needTenant={true}
    ><>
        <FlightReportDatesChooseModal
          modalIsOpen={reportModalIsOpen}
          setModalIsOpen={setReportModalIsOpen} />

        <Container maxWidth={false}>
          <Grid
            container
          >
            <Grid
              item
              lg={6}
              md={6}
              xs={6}
            >
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t('FLIGHTS_LIST')}
              </Typography>
            </Grid>

            <Grid
              item
              lg={6}
              md={6}
              xs={6}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Stack direction="row" spacing={mobileDevice ? 0 : 1}>
                <Menu
                  id="flights-table-actions-menu"
                  MenuListProps={{
                    'aria-labelledby': 'flights-table-action-button',
                  }}
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={() => { setMenuAnchorEl(null) }}
                >
                  <MenuItem key={'export-pdf'} onClick={() => {
                    setReportModalIsOpen(true);
                    setMenuAnchorEl(null)
                  }} >
                    <PictureAsPdf fontSize="small" sx={{ mr: '3px' }} />
                    <Typography variant="subtitle1">{t("EXPORT_PDF")}</Typography>
                  </MenuItem>
                </Menu>

                {!mobileDevice &&
                  <Button variant="contained" color="primary"
                    onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                  >
                    <Description />
                  </Button>}
                {userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) ?
                    (<>
                        <Grid
                          item
                          lg={2}
                          md={3}
                          xs={10}
                        >
                          <Box sx={{ m: -1 }}>
                            <Button
                              component={RouterLink}
                              to="add"
                              color="primary"
                              sx={{ m: 1, textAlign: 'center', width: '90px' }}
                              variant="contained"
                            >
                              {t('ADD_FLIGHT')}
                            </Button>
                          </Box>
                        </Grid>
                      </>
                    ) :
                    (<></>)}
                {mobileDevice &&
                  <IconButton
                    onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>}
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <FlightsListCard
              loggedUser={loggedUser}
            />
          </Box>
        </Container>
      </>
    </PermissionsGuard>
  );
}

export default FlightsList;
