import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    debounce,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField
} from "@material-ui/core";
import {Aircraft, DefaultAircraft} from "../../models/Aircraft";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {composeNotistackMessage, getFormValue} from "../../utils/common";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {AircraftCategory} from "../../models/AircraftCategory";
import {AircraftTipology} from "../../models/AircraftTipology";
import {AircraftCategoryService} from "../../services/AircraftCategoryService";
import {AircraftTipologyService} from "../../services/AircraftTipologyService";
import {AircraftService} from "../../services/AircraftService";
import {User} from "../../models/User";
import TableRowLoaderVariant from "../../components/TableRowsLoaderVariant";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {AircraftValidator} from "../../validators/AircraftValidator";
import LoaderVariant from "../../components/LoaderVariant";
import PermissionsGuard from "../../components/PermissionGuard";
import Resizer from "react-image-file-resizer";

interface UpsertAircraftProps {
    isAdd: boolean,
}

const UpsertAircraft: FC<UpsertAircraftProps> = (props) => {
    const { isAdd } = props;

    const history = useNavigate();

    const fieldsNotToCheck = [];
    const { t } = useTranslation();

    const aircraftValidator = new AircraftValidator(t);
    const aircraftCategoryService = new AircraftCategoryService();
    const aircraftTipologyService = new AircraftTipologyService();
    const aircraftService = new AircraftService();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
    const [aircraft, setAircraft] = useState<Aircraft>(DefaultAircraft);
    const [aircraftCategoryList, setAircraftCategoryList] = useState<Array<AircraftCategory>>([]);
    const [aircraftTipologyList, setAircraftTipologyList] = useState<Array<AircraftTipology>>([]);
    const [isLoadingAircraftPhoto, setIsLoadingAircraftPhoto] = useState<boolean>(true);

    const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);

    function base64ToBlob(base64String) {
        const parts = base64String.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const byteCharacters = atob(parts[1]);

        const arrayBuffer = new ArrayBuffer(byteCharacters.length);
        const byteArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: contentType });
    }

    const searchAircraftPhoto = useCallback(debounce(async (airReg: string) => {
        setIsLoadingAircraftPhoto(true);

        let aircraftPhotoTemp = await aircraftService.GetDefualtPhoto(airReg);
        setAircraft((prev) => ({ ...prev, aircraftPhoto: aircraftPhotoTemp }));
        setIsLoadingAircraftPhoto(false);

    }, 700), []);

    const handleDrop = async (event) => {

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            var blobImage = base64ToBlob(reader.result);
            Resizer.imageFileResizer(
                blobImage,
                498,
                280,
                'JPEG',
                100,
                0,
                uri => {
                    setAircraft((prev) => ({ ...prev, aircraftPhoto: uri as string }));
                },
                'base64',
                280,
                187);
        }
    };


    const handleRemove = (): void => {
        setAircraft((prev) => ({ ...prev, aircraftPhoto: undefined }));
        aircraftService.DeleteAircraftPhoto(aircraft.id);
    };

    useEffect(() => {
        (async () => {
            let aircraftId = window.location.href.split('aircraftId=')[1] ?? '';
            if (!isAdd) {
                let retrieveAircraft = await aircraftService.GetAircraft(aircraftId);
                setAircraft(retrieveAircraft);
            }
            await getAircraftTipology();
            await getAircraftCategory();
            setIsLoadingAircraftPhoto(false);
            setIsLoading(false);
        })();
    }, []);



    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setAircraft((currentFormData: any) => ({
            ...currentFormData,
            [name]: value
        }))
    }

    const aircraftUpsert = async () => {
        setIsLoadingForm(true);
        let validateAircraft: boolean = aircraftValidator.validateAircraft(aircraft, fieldsNotToCheck);
        if (validateAircraft) {
            let aircraftTemp = aircraft;
            aircraftTemp.userId = user?.id ? user.id : "";
            let upsertResult = await aircraftService.UpsertAircraft(aircraft);
            if (upsertResult) {
                composeNotistackMessage(isAdd ? t('AIRCRAFT_ADDED') : t('AIRCRAFT_UPDATED'), 'success');
                setIsLoadingForm(false);
                history('/myAircrafts');
            } else {
                composeNotistackMessage(isAdd ? t('AIRCRAFT_NOT_ADDED') : t('AIRCRAFT_NOT_UPDATED'), 'error');
            }

        } else {
            composeNotistackMessage(isAdd ? t('AIRCRAFT_NOT_ADDED') : t('AIRCRAFT_NOT_UPDATED'), 'error');
        }
        setIsLoadingForm(false);
    };

    const deleteUpsert = async () => {
        setIsLoadingForm(true);

        if (aircraft.id != "" && !isAdd) {

            let deleteResult = await aircraftService.DeleteAircraft(aircraft.id);
            if (deleteResult) {
                composeNotistackMessage(t('AIRCRAFT_DELETED'), 'success');
                setIsLoadingForm(false);
                history('/myAircrafts');
            }
            else {
                composeNotistackMessage(t('AIRCRAFT_NOT_DELETED'), 'error');

            }
            setIsLoadingForm(false);

        };

    }

    const getAircraftCategory = async () => {
        let retrievedAircraftCategory = await aircraftCategoryService.getAllAircraftCategory();
        setAircraftCategoryList(retrievedAircraftCategory);

        if (isAdd) {
            let defaultAircraftCategoryId = retrievedAircraftCategory.find(aircraftCategory => aircraftCategory
                .name == 'ULTRALIGHT')?.id;
            setAircraft(prevState => ({ ...prevState, aircraftCategoryId: defaultAircraftCategoryId }))
        }
    }

    const getAircraftTipology = async () => {
        let retrievedAircraftTipology = await aircraftTipologyService.getAllAircraftTipology();
        setAircraftTipologyList(retrievedAircraftTipology);

        if (isAdd) {
            let defaultAircraftTipologyId = retrievedAircraftTipology.find(aircraftTypology => aircraftTypology
                .name == 'AIRPLANE')?.id;
            setAircraft(prevState => ({ ...prevState, aircraftTipologyId: defaultAircraftTipologyId }))
        }
    }

    return (<>
        {isLoadingForm && <LoaderVariant isLoading={isLoadingForm} />}
        <PermissionsGuard
            allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
            environment={'UpsertAircraft'}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={4}
                    md={6}
                    xl={3}
                    xs={12}
                >
                    <Card sx={{ mx: 2 }}>
                        <CardHeader title={t('AIRCRAFT')} />
                        <Divider />
                        <CardContent>
                            <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center", flexDirection: 'column', width: "100%" }}>
                                {isLoadingAircraftPhoto == true ?
                                    <>
                                        <Skeleton sx={{ height: 250, mb: 2 }} animation="wave" variant="rectangular" ></Skeleton>
                                    </>
                                    :
                                    <>
                                        <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center", mb: 3, mt: 1 }}>
                                            {(aircraft.aircraftPhoto != undefined && aircraft.aircraftPhoto != "")
                                                ?

                                                <img src={aircraft.aircraftPhoto} style={{
                                                    maxHeight: 250,
                                                    objectFit: 'contain',
                                                }} />
                                                :
                                                <NoPhotographyIcon />
                                            }
                                        </Box>
                                    </>
                                }
                                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center" }}>
                                    <Button variant="contained" disabled={isLoading || isLoadingForm} onClick={handleRemove}>
                                        {t("REMOVE_PHOTO")}
                                    </Button>
                                    <Button sx={{ my: 2 }} variant="contained" disabled={isLoading || isLoadingForm}
                                        onClick={() => searchAircraftPhoto(aircraft.aircraftRegistration)}>
                                        {t("INTELLIGENT_SEARCH_PHOTO")}
                                    </Button>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        type="file"
                                        onChange={handleDrop}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button variant="contained" sx={{ width: "100%" }} component="span" disabled={isLoading || isLoadingForm}>
                                            {t("UPLOAD_PHOTO")}
                                        </Button>
                                    </label>
                                </Box>
                            </Box>

                        </CardContent>
                    </Card>
                </Grid>

                <Grid
                    item
                    lg={8}
                    md={6}
                    xl={9}
                    xs={12}
                >
                    <Card sx={{ mx: 2 }}>
                        {isLoading ? <TableRowLoaderVariant rowsNum={5} height={50} hasDivider={false} /> : <>

                            <CardHeader title={t('AIRCRAFT_DATA')} />
                            <Divider />
                            <CardContent sx={{ input: { color: 'white' } }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('AIRCRAFT_REGISTRATION')}
                                            name="aircraftRegistration"

                                            required
                                            onChange={handleChange}
                                            value={aircraft.aircraftRegistration}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('AIRCRAFT_TYPE')}
                                            name="aircraftType"
                                            required
                                            onChange={handleChange}
                                            value={aircraft.aircraftType}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <FormControl fullWidth required size='small'>
                                            <InputLabel>{t('AIRCRAFT_CATEGORY')}</InputLabel>
                                            <Select
                                                label={t('AIRCRAFT_CATEGORY')}
                                                name="aircraftCategoryId"
                                                onChange={handleChange}
                                                sx={{ color: 'white' }}
                                                value={aircraft.aircraftCategoryId}
                                            >
                                                {aircraftCategoryList.map((aircraftCategory: AircraftCategory) => (
                                                    <MenuItem key={aircraftCategory.id} value={aircraftCategory.id}>
                                                        {t(aircraftCategory.name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <FormControl fullWidth required size='small'>
                                            <InputLabel>{t('AIRCRAFT_TIPOLOGY')}</InputLabel>
                                            <Select
                                                label={t('AIRCRAFT_TIPOLOGY')}
                                                name="aircraftTipologyId"
                                                onChange={handleChange}
                                                sx={{ color: 'white' }}
                                                value={aircraft.aircraftTipologyId}
                                            >
                                                {aircraftTipologyList.map((aircraftTiplogy: AircraftTipology) => (
                                                    <MenuItem key={aircraftTiplogy.id} value={aircraftTiplogy.id}>
                                                        {t(aircraftTiplogy
                                                            .name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={4}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('COLOR')}
                                            name="color"
                                            required
                                            onChange={handleChange}
                                            value={aircraft.color}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        lg={3}
                                        md={6}
                                        xs={12}
                                    >
                                        <FormControlLabel control=
                                            {
                                                <Checkbox
                                                    checked={aircraft.haveRadio ? true : false}
                                                    onChange={handleChange}
                                                    name="haveRadio"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={t("HAVE_RADIO")}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={3}
                                        md={6}
                                        xs={12}
                                    >
                                        <FormControlLabel control=
                                            {
                                                <Checkbox
                                                    checked={aircraft.haveTransponder ? true : false}
                                                    onChange={handleChange}
                                                    name="haveTransponder"
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={t("HAVE_TRANSPONDER")}
                                        />
                                    </Grid>


                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: isAdd ? 'flex-end' : 'space-between',
                                p: 2
                            }}>
                                {
                                    !isAdd
                                    &&
                                    <Button
                                        color="error"
                                        type="submit"
                                        variant="contained"
                                        disabled={isLoading || isLoadingForm}
                                        onClick={deleteUpsert}
                                    >
                                        {t('DELETE')}
                                    </Button>
                                }
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isLoading || isLoadingForm}
                                    variant="contained"
                                    onClick={aircraftUpsert}
                                >
                                    {t('SAVE')}
                                </Button>
                            </Box>
                        </>}
                    </Card>

                </Grid>

            </Grid >
        </PermissionsGuard >
    </>
    );


};
export default UpsertAircraft;
