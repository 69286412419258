import {CircularProgress} from "@material-ui/core";
import {Box} from "@material-ui/system";

const Loader: React.FC = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress/>
        </Box>
    );
};

export default Loader;
