import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../Scrollbar';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import {Airfield} from '../../models/Airfield';
import {composeNotistackMessage} from '../../utils/common';
import TableRowsLoader from '../TableRowsLoader';
import {DeleteOutline} from '@material-ui/icons';
import {AirfieldService} from '../../services/AirfieldService';

interface AirfieldsListTableProps {
	paginationResult: PaginationResult<Airfield> | undefined;
	getAirfields(currentPage: number, recordLimit: number, searchFilter: string): void;
	user: any;
	isPageLoading: boolean;
}

const AirfieldsListTable: FC<AirfieldsListTableProps> = ({ paginationResult, getAirfields, user, isPageLoading }) => {
	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState<number>(0);
	const [recordLimit, setRecordLimit] = useState<number>(10);
	const [searchFilter, setSearchFilter] = useState<string>('');

	const [airfieldIdToRemove, setAirfieldIdToRemove] = useState<string | undefined>('');
	const [removeAirfieldModalOpen, setRemoveAirfieldModalOpen] = useState(false);
	const handleAirfieldModalOpen = () => setRemoveAirfieldModalOpen(true);
	const handleAirfieldModalClose = () => setRemoveAirfieldModalOpen(false);

	var currentTheme = useTheme();
	const mobileDevice = useMediaQuery(currentTheme.breakpoints.down(450));


	useEffect(() => {
		getAirfields(currentPage + 1, recordLimit, searchFilter);
	}, [currentPage, recordLimit, searchFilter, getAirfields]);


	const deleteAirfield = async () => {
		if (airfieldIdToRemove !== undefined) {
			const airfieldService = new AirfieldService();

			let deleteAirfieldResult = await airfieldService.deleteAirfieldById(airfieldIdToRemove);

			setAirfieldIdToRemove('');
			getAirfields(currentPage + 1, recordLimit, searchFilter);

			if (deleteAirfieldResult) {
				composeNotistackMessage(t('AIRFIELD_DELETED'), 'success');
			} else {
				composeNotistackMessage(t('AIRFIELD_NOT_DELETED'), 'error');
			}
		}
	}

	return (
		<Card>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					m: -1,
					p: 2
				}}
			>
				<Box
					sx={{
						m: 1,
						maxWidth: '100%',
						width: 500
					}}
				>
					<TextField
						fullWidth
						size='small'
						sx={{
							input: {
								color: 'white'
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fontSize="small" />
								</InputAdornment>
							)
						}}
						onChange={(e) => {
							setSearchFilter(e.target.value);
						}}
						placeholder={t('SEARCH_AIRFIELDS')}
						value={searchFilter}
						variant="outlined"
					/>
				</Box>
			</Box>
			<Scrollbar>
				<Box sx={{ minWidth: 700 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									{t('NAME')}
								</TableCell>
								<TableCell>
									{t('ICAO_CODE')}
								</TableCell>
								<TableCell>
									{t('REGION')}
								</TableCell>
								<TableCell>
									{t('PROVINCE')}
								</TableCell>
								<TableCell>
									{t('MUNICIPALITY')}
								</TableCell>
								<TableCell align="right">
									{t('ACTIONS')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								isPageLoading ?
									(<TableRowsLoader rowsNum={recordLimit} colsNum={6} />) :
									(
										<>
											{paginationResult?.items.map((airfield) => (
												<TableRow hover key={airfield.id}>
													<TableCell>
														<Box
															sx={{
																alignItems: 'center',
																display: 'flex'
															}}
														>
															<Box sx={{ ml: 1 }}>
																{airfield.name}
															</Box>
														</Box>
													</TableCell>
													<TableCell>
														{airfield.icaoCode}
													</TableCell>
													<TableCell>
														{airfield.region}
													</TableCell>
													<TableCell>
														{airfield.province}
													</TableCell>
													<TableCell>
														{airfield.municipality}
													</TableCell>
													<TableCell align="right">
														<Dialog
															open={removeAirfieldModalOpen}
															onClose={handleAirfieldModalClose}
															aria-labelledby="alert-remove-airfield-title"														>
															<DialogTitle id="remove-airfield-title">
																{t('TITLE_MODAL_DELETE_AIRFIELD')}
															</DialogTitle>
															<DialogActions>
																<Button onClick={handleAirfieldModalClose} variant="outlined" color="error">{t('CANCEL')}</Button>
																<Button onClick={() => {
																	handleAirfieldModalClose();
																	deleteAirfield();
																}} variant="contained" color="error">{t('DELETE')}</Button>
															</DialogActions>
														</Dialog>
														<Tooltip title={t('DELETE') || ''} aria-label={t('DELETE') || ''}>
															<IconButton onClick={() => {
																handleAirfieldModalOpen();
																setAirfieldIdToRemove(airfield.id)
															}}>
																<DeleteOutline fontSize="small" />
															</IconButton>
														</Tooltip>
														<Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
															<IconButton
																component={RouterLink}
																to={`edit?airfieldId=${airfield.id}`}
															>
																<PencilAltIcon fontSize="small" />
															</IconButton>
														</Tooltip>
														<Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
															<IconButton
																component={RouterLink}
																to={`detail?airfieldId=${airfield.id}`}
															>
																<ArrowRightIcon fontSize="small" />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
										</>
									)
							}
						</TableBody>
					</Table>
				</Box>
			</Scrollbar>
			<TablePagination
				component="div"
				count={paginationResult?.totalCount ?? 10}
				onRowsPerPageChange={(e) => {
					setRecordLimit(parseInt(e.target.value, 10));
				}}
				onPageChange={(e, page) => {
					setCurrentPage(page);
				}}
				page={currentPage}
				rowsPerPage={recordLimit}
				rowsPerPageOptions={[5, 10, 25]}
				labelRowsPerPage={!mobileDevice && t('ROWS_PER_PAGE')}
			/>
		</Card>
	);
};

export default AirfieldsListTable;
