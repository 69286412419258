import {type FC, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import {Flight} from '../../models/Flight';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import TableRowsLoader from '../TableRowsLoader';
import {dateTimeFormats, getFormattedUTCDate, userCanUseIfRoles} from '../../utils/common';
import {CheckCircle, Circle, FlightLand, FlightTakeoff, RemoveCircle} from '@material-ui/icons';
import {isNull} from 'lodash';
import InformationCircle from '../../icons/InformationCircle';
import SortDescending from '../../icons/SortDescending';
import SortAscending from '../../icons/SortAscending';
import FlightsListHeader from './FlightsListHeader';
import FlightLogModal from './FlightLogModal';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IFilters {
    searchFilter: string,
    startDepartureDate: string | null,
    endDepartureDate: string | null,
    startArrivalDate: string | null,
    endArrivalDate: string | null,
    tenantIsDeparture: boolean | null,
    orderFieldName: string | null,
    isDescending: boolean | null
}

interface FlightsListTableProps {
    paginationResult: PaginationResult<Flight> | undefined;
    isPageLoading: boolean;
    loggedUser: any;
    tenantIsDeparture: boolean | null;

    getMoreFlights(): void;

    updateApprovedFlight(flightId: string, approved: boolean): void;

    updateIsCarriedFlight(flightId: string, IsCarried: boolean): void;

    setAllFilters(value: string | boolean, name: string);

    isRecordsLoading: boolean;
    filters: IFilters;

}

interface FlightListRowProps {
    flight: Flight;
    loggedUser: any;
    OpenModal: Function,
    setLogListWieved: Function,
    index: number,

    updateApprovedFlight(flightId: string, approved: boolean): void;

    updateIsCarriedFlight(flightId: string, IsCarried: boolean): void;
}

const FlightsListTable: FC<FlightsListTableProps> = (props) => {

    const {
        paginationResult,
        isPageLoading,
        loggedUser,
        tenantIsDeparture,
        updateApprovedFlight,
        updateIsCarriedFlight,
        setAllFilters,
        getMoreFlights,
        isRecordsLoading,
        filters,

    } = props;

    const {t} = useTranslation();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [logListWieved, setLogListWieved] = useState<Number>(0);
    const [paginatedFlights, setPaginatedFlights] = useState<PaginationResult<Flight>>();

    const OpenModal = () => {
        setModalIsOpen(true);
    }

    useEffect(() => {
        if (paginationResult) {
            setPaginatedFlights(paginationResult)
        }
    }, [paginationResult])

    return (
        <>
            {modalIsOpen &&
                <FlightLogModal
                    airfieldId={paginatedFlights?.items[logListWieved.toString()].id}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}/>
            }
            <InfiniteScroll
                dataLength={paginatedFlights?.items.length ? paginatedFlights?.items.length : 10}
                next={getMoreFlights}
                style={{overflow: "hidden"}}
                hasMore={!isRecordsLoading &&
                    (paginatedFlights?.currentPage || 0) <= (paginationResult?.totalPages || 0)}
                loader={<></>}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <FlightsListHeader
                                value="TenantIsDeparture"
                                name="FLIGHT_DIRECTION_SHORT"
                                setAllFilters={setAllFilters}
                                filters={filters}/>
                            <FlightsListHeader
                                value="AircraftRegistration"
                                name="AIRCRAFT_REGISTRATION"
                                setAllFilters={setAllFilters}
                                filters={filters}/>
                            <FlightsListHeader
                                value="PilotFullName"
                                name="PILOT_NAME"
                                setAllFilters={setAllFilters}
                                filters={filters}/>

                            <TableCell>
                                <Button
                                    onClick={() => {
                                        if (tenantIsDeparture != null) {
                                            setAllFilters((tenantIsDeparture ? "ArrivalAirfieldName" : "DepartureAirfieldName"), "orderFieldName");
                                            setAllFilters(!filters.isDescending, "isDescending")
                                        }
                                    }}>
                                    <Typography variant="subtitle2" sx={{color: "white", ml: 1}}>
                                        {tenantIsDeparture == true && t('ARRIVAL_PLACE')}

                                        {tenantIsDeparture == false && t('DEPARTURE_PLACE')}

                                        {isNull(tenantIsDeparture) && <>{t('DEPARTURE_PLACE')} / {t('ARRIVAL_PLACE')}</>}
                                    </Typography>
                                    {(!isNull(tenantIsDeparture)
                                            && (filters.orderFieldName == "ArrivalAirfieldName" || (filters.orderFieldName == "DepartureAirfieldName")))
                                        && ((filters.isDescending) == true
                                            ? (<SortDescending sx={{color: "white", ml: 1}}/>)
                                            : (<SortAscending sx={{color: "white", ml: 1}}/>))}
                                </Button>
                            </TableCell>
                            <FlightsListHeader
                                value="Date"
                                name="TIMETABLE"
                                setAllFilters={setAllFilters}
                                filters={filters}/>
                            <TableCell align="right">
                                {t('ACTIONS')}
                            </TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {isPageLoading
                            ? <TableRowsLoader rowsNum={10} colsNum={6}/>
                            : <>
                                {paginatedFlights?.items.map((flight, index) => (
                                    <FlightListRow
                                        flight={flight}
                                        updateApprovedFlight={updateApprovedFlight}
                                        updateIsCarriedFlight={updateIsCarriedFlight}
                                        loggedUser={loggedUser}
                                        OpenModal={OpenModal}
                                        setLogListWieved={setLogListWieved}
                                        index={index}/>))}
                            </>}

                    </TableBody>
                </Table>
                {isRecordsLoading &&
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        mt: 4, mb: 1
                    }}>
                        <CircularProgress/>
                    </Box>
                }
            </InfiniteScroll>
        </>
    );
};

export default FlightsListTable;


const FlightListRow: FC<FlightListRowProps> = (props) => {

    const {
        flight,
        updateApprovedFlight,
        loggedUser,
        OpenModal,
        updateIsCarriedFlight,
        setLogListWieved,
        index
    } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [carriedMenuAnchorEl, setCarriedMenuAnchorEl] = useState<null | HTMLElement>(null);

    const {t, i18n} = useTranslation();
    console.log(flight)
    return (<>
        <TableRow hover key={flight.id}>
            <TableCell>
                <Grid item>
                    {flight.tenantIsDeparture
                        ? <IconButton
                            onClick={(event: any) => setCarriedMenuAnchorEl(event.currentTarget)}>
                            <FlightTakeoff
                                fontSize='small'
                                sx={{
                                    color: flight.isCarried == true
                                        ? '#4CAF50'
                                        : flight.isCarried == false
                                            ? '#F44336'
                                            : '#808080'
                                }}/>
                        </IconButton>
                        : <IconButton onClick={(event: any) => setCarriedMenuAnchorEl(event.currentTarget)}>
                            <FlightLand
                                fontSize='small'
                                sx={{
                                    color: flight.isCarried == true
                                        ? '#4CAF50'
                                        : flight.isCarried == false
                                            ? '#F44336'
                                            : '#808080'
                                }}/>
                        </IconButton>}
                    <Menu
                        id="flights-carried-menu"
                        MenuListProps={{
                            'aria-labelledby': 'flight-carried-button',
                        }}
                        anchorEl={carriedMenuAnchorEl}
                        open={Boolean(carriedMenuAnchorEl)}
                        onClose={() => {
                            setCarriedMenuAnchorEl(null)
                        }}
                    >
                        <MenuItem disabled={flight.isCarried == true} key={'isCarriedTrue'} onClick={() => {
                            setCarriedMenuAnchorEl(null)
                            updateIsCarriedFlight(flight.id!, true)
                        }}>

                            <FlightTakeoff fontSize='small' sx={{mr: 1, color: '#4CAF50'}}/>
                            <Typography variant="subtitle1">{t('IS_CARRIED')}</Typography>
                        </MenuItem>
                        <MenuItem disabled={flight.isCarried == false} key={'isCarriedFalse'} onClick={() => {
                            setCarriedMenuAnchorEl(null)
                            updateIsCarriedFlight(flight.id!, false)
                        }}>

                            <FlightTakeoff fontSize='small' sx={{mr: 1, color: '#F44336'}}/>
                            <Typography variant="subtitle1">{t('IS_NOT_CARRIED')}</Typography>
                        </MenuItem>
                    </Menu>
                </Grid>
            </TableCell>
            <TableCell>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <Box sx={{ml: 1}}>
                        {flight.aircraftRegistration} [{t(flight.aircraftTipologyName)}]
                    </Box>
                </Box>
            </TableCell>
            <TableCell>
                {flight.pilotFullName}
            </TableCell>
            <TableCell>
                <Grid container direction="row" columnSpacing={1}>
                    <Grid item>
                        {flight.tenantIsDeparture ? flight.arrivalAirfieldName : flight.departureAirfieldName}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                {getFormattedUTCDate(flight.date, dateTimeFormats[i18n.language])}
            </TableCell>
            <TableCell align="right">
                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER'])
                    && <>
                        {flight.approved == null ?
                            <IconButton
                                onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                            >
                                <Circle sx={{color: "#808080"}} fontSize="small"/>
                            </IconButton> :
                            <Tooltip title="" aria-label="">
                                <IconButton
                                    onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                                >
                                    {!flight.approved ? <RemoveCircle fontSize="small" color="error"/> :
                                        <CheckCircle fontSize="small" color="success"/>}
                                </IconButton>
                            </Tooltip>}

                        <Menu
                            id="flights-approved-menu"
                            MenuListProps={{
                                'aria-labelledby': 'flight-approved-button',
                            }}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => {
                                setMenuAnchorEl(null)
                            }}
                        >

                            <MenuItem disabled={flight.approved == true} key={'flightApproved'} onClick={() => {
                                setMenuAnchorEl(null);
                                updateApprovedFlight(flight.id!, true);
                            }}>
                                {<CheckCircle sx={{mt: 0.5, mb: 0.5}} fontSize="small" color="success"/>}
                                <Typography sx={{mt: 0.5, mb: 0.5, ml: 1}}
                                            fontSize="small">{" " + t('APPROVE')}</Typography>

                            </MenuItem>
                            <MenuItem disabled={flight.approved == false} key={'flightNotApproved'} onClick={() => {
                                setMenuAnchorEl(null);
                                updateApprovedFlight(flight.id!, false)
                            }}>
                                {<RemoveCircle sx={{mt: 0.5, mb: 0.5}} fontSize="small" color="error"/>}
                                <Typography sx={{mt: 0.5, mb: 0.5, ml: 1}}
                                            fontSize="small">{" " + t('REJECT')}</Typography>
                            </MenuItem>
                        </Menu>

                    </>
                }

                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'VIEWER'])
                    && <Tooltip title={t('LOG_TEXT_LABEL')} aria-label={t('LOG_TEXT_LABEL')}>

                        <IconButton
                            onClick={() => {
                                setLogListWieved(index);
                                OpenModal();
                            }}
                        >
                            <InformationCircle fontSize="small"/>
                        </IconButton>
                    </Tooltip>}

                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER'])
                    &&
                    <Tooltip title={t('RETURN_FLIGHT')} aria-label={t('RETURN_FLIGHT')}>
                        <IconButton
                            component={RouterLink}
                            to={`/flights/reverseFlightEdit?flightId=${flight.id}`}>
                            {flight.tenantIsDeparture ?
                                <FlightLand fontSize="small"/>
                                :
                                <FlightTakeoff fontSize="small"/>
                            }
                        </IconButton>
                    </Tooltip>
                }


                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER'])
                    && <Tooltip title={t('EDIT')} aria-label={t('EDIT')}>

                        <IconButton
                            component={RouterLink}
                            to={`edit?flightId=${flight.id}`}
                        >
                            <PencilAltIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>

                }
                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'VIEWER'])
                    && <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                        <IconButton
                            component={RouterLink}
                            to={`detail?flightId=${flight.id}`}
                        >
                            <ArrowRightIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>}
            </TableCell>
        </TableRow>
    </>)
}
