import type {FC} from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import {
  Box,
  Card,
  Container,
  debounce,
  Grid,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@material-ui/core';
import {alpha, experimentalStyled} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CalendarToolbar from '../../components/calendar/CalendarToolbar';
import {useSelector} from "react-redux";
import type {CalendarEvent, CalendarView} from '../../types/calendar';
import {useTheme} from '@material-ui/system';
import {Flight} from '../../models/Flight';
import {FlightService} from '../../services/FlightService';
import {AppState} from '../../store';
import {User} from '../../models/User';
import {FlightsCalendar} from '../../models/FlightsCalendar';
import FlightsCalendarModal from '../../components/flight/FlightsCalendarModal';
import {useTranslation} from 'react-i18next';
import {menuState} from '../../store/slices/menu';
import {FlightLand, FlightTakeoff} from '@material-ui/icons';
import PermissionsGuard from '../../components/PermissionGuard';


const FullCalendarWrapper = experimentalStyled('div')(
  ({ theme }) => (
    {
      '& .fc-license-message': {
        display: 'none'
      },
      '& .fc': {
        '--fc-bg-event-opacity': 1,
        '--fc-border-color': theme.palette.divider,
        '--fc-daygrid-event-dot-width': '10px',
        '--fc-event-text-color': theme.palette.text.primary,
        '--fc-list-event-hover-bg-color': theme.palette.background.default,
        '--fc-neutral-bg-color': theme.palette.background.default,
        '--fc-page-bg-color': theme.palette.background.default,
        '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.25),
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily
      },
      '& .fc .fc-col-header-cell-cushion': {
        paddingBottom: '10px',
        paddingTop: '10px'
      },
      '& .fc .fc-day-other .fc-daygrid-day-top': {
        color: theme.palette.text.secondary
      },
      '& .fc-daygrid-event': {
        padding: 0
      },
      '& .fc-h-event':
      {
        border: "transparent",
        backgroundColor: "transparent"
      },


    }
  )
);

interface DateRange {
  start: number,
  end: number,
}

interface calendarProps {
  flights: Flight[] | undefined,
}

const PilotCalendar: FC<calendarProps> = (props) => {
  const { flights } = props;
  const flightService = new FlightService();
  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);
  const { drawerOpen } = useSelector(menuState)
  const navigate = useNavigate();
  const theme = useTheme();
  const calendarRef = useRef<FullCalendar | null>(null);
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t, i18n } = useTranslation();

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEventsId, setSelectedEventsId] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tenantIsDeparture, setTenantIsDeparutre] = useState<boolean | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<CalendarView>('dayGridMonth');


  const ConvertFlightCalendarToEvent = (flightsCal: FlightsCalendar): any => {
    var allIds = "";
    if (flightsCal.date != null) {
      flightsCal.flightsId.forEach((x) => {
        allIds = allIds + x + ",";
      })

      var event = {
        id: allIds,
        description: allIds,
        start: new Date(flightsCal.date),
        title: flightsCal.numberOfFlight.toString(),
      }
      return event;
    }
    return {
      id: "",
      description: "",
      start: new Date(),
      title: "",
    };
  }


  const ConvertFlightsCalendarToEvents = (flightsCal: FlightsCalendar[]) => {
    var newEvents = new Array<CalendarEvent>();
    if (flightsCal != undefined) {
      flightsCal!.forEach((flightCal) => {
        var temp = ConvertFlightCalendarToEvent(flightCal)
        newEvents = [...newEvents, temp];
      })
      setEvents(newEvents);

    }
  }


  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = 'dayGridMonth';

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.setOption('locale', i18n.language);
    }
  }, [i18n.language])

  useEffect(() => {
    (async () => {
      setEvents([]);
      setIsLoading(true);
      const retrieveFlights = await flightService.getCalendarPilotFlights(loggedUser!.id!,
        {
          searchFilter: "",
          orderFieldName: "Date",
          isDescending: true,
          startDate: new Date(date.getFullYear(), date.getMonth() - 1),
          endDate: new Date(date.getFullYear(), date.getMonth() + 2),
          tenantIsDeparture: tenantIsDeparture,
        });
      ConvertFlightsCalendarToEvents(retrieveFlights);
      setIsLoading(false);
      const calendarEl = calendarRef.current;

      if (calendarEl) {
        const calendarApi = calendarEl.getApi();
        calendarApi.setOption('locale', i18n.language);
      }

    })();
  }, [flights, date, tenantIsDeparture]);


  useEffect(() => {
    resizeCalendar()
  }, [drawerOpen])

  useEffect(() => {
    const handleResize = () => {
      resizeCalendar()
    };
    window.addEventListener('resize', handleResize);
  }, [])

  const resizeCalendar = useCallback(debounce(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.updateSize()
      calendarApi.setOption('height', window.innerHeight - 270);
    }
  }, 250), [])

  const handleDateToday = (): void => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: CalendarView): void => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = (): void => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = (): void => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleEventSelect = (arg: any): void => {
    var idsArray = arg.event.id.split(",");
    idsArray.pop();
    setSelectedDate(new Date(arg.event.start));
    setSelectedEventsId(idsArray);
    setIsModalOpen(true);
  };

  const handleEventDrop = async ({ event }: any): Promise<void> => {
    navigate(`flights/edit?flightId=${event.id}`)
  };

  function renderEventContent(eventInfo) {
    return (
      <Box sx={{ display: "flex", mt: "30px", justifyContent: "flex-start", alignItems: "flex-end", alignContent: 'flex-end' }}>
        <Box onClick={() => { handleEventSelect(eventInfo) }} sx={{
          display: "flex", mt: -1,
          alignItems: 'center', justifyContent: "center", height: "30px", width: "30px",
          bgcolor: "#4CB071", borderRadius: "50%"
        }}>
          <Typography fontSize={14}>{eventInfo.event.title}</Typography>
        </Box>
      </Box >

    )
  }

  return (
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
      environment={'Calendar'}
      needTenant={true}
    >
      <>
        {isModalOpen && (
          <FlightsCalendarModal
            flightIds={selectedEventsId}
            date={Math.floor(selectedDate!.getTime() / 1)}
            modalIsOpen={isModalOpen}
            setModalIsOpen={setIsModalOpen}
          />
        )
        }

        <Box
          sx={{
            minHeight: '100%',
            py: 2
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              justifyContent="flex-end"
              spacing={3}
            >
              <Grid item xs={8} >
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  {t("MY_CALENDAR")}
                </Typography>
              </Grid>
              <Grid item xs={4} >
                <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" }}>

                  <ToggleButtonGroup
                    color="primary"
                    value={tenantIsDeparture}
                    exclusive
                    onChange={(event: any, value: boolean) => { setTenantIsDeparutre(value) }}
                  >
                    <ToggleButton value={true} size='small' sx={{ p: 1 }}>
                      <FlightTakeoff fontSize='small' />
                      {mobileDevice ? <></> : <Typography sx={{ ml: 1 }} variant="inherit">{t('DEPARTURING')}</Typography>}
                    </ToggleButton>
                    <ToggleButton value={false} size='small' sx={{ p: 1 }}>
                      <FlightLand fontSize='small' />
                      {mobileDevice ? <></> : <Typography sx={{ ml: 1 }} variant="inherit">{t('ARRIVING')}</Typography>}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              <CalendarToolbar
                date={date}
                onDateNext={handleDateNext}
                onDatePrev={handleDatePrev}
                onDateToday={handleDateToday}
                onViewChange={handleViewChange}
                view={view}
              />
            </Box>
            <Card
              sx={{
                mt: 3,
                p: 2
              }}
            >
              {isLoading ? <Skeleton height="500px" width="100%" variant="rectangular" />
                :
                <>
                  <FullCalendarWrapper>
                    <FullCalendar
                      allDayMaintainDuration
                      dayMaxEventRows={3}
                      eventDisplay="block"
                      eventDrop={handleEventDrop}
                      eventResizableFromStart
                      events={events}
                      eventContent={renderEventContent}
                      headerToolbar={false}
                      height={window.innerHeight - 270}
                      initialDate={date}
                      initialView={view}
                      firstDay={1}
                      handleWindowResize
                      expandRows
                      plugins={[
                        dayGridPlugin,
                        interactionPlugin,
                        listPlugin,
                        timeGridPlugin,
                        timelinePlugin
                      ]}
                      ref={calendarRef}
                      rerenderDelay={10}
                      select={() => { }}
                      selectable
                      weekends
                    />
                  </FullCalendarWrapper>
                </>}

            </Card>

          </Container>
        </Box >
      </>
    </PermissionsGuard>
  );
};

export default PilotCalendar;
