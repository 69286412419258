import {FC, useEffect, useState} from "react";
import {AircraftService} from "../../services/AircraftService";
import {Aircraft} from "../../models/Aircraft";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import TableRowsLoaderVariant from "../TableRowsLoaderVariant";
import {useTranslation} from "react-i18next";
import {AircraftCategoryService} from "../../services/AircraftCategoryService";
import {AircraftTipologyService} from "../../services/AircraftTipologyService";
import {AircraftTipology} from "../../models/AircraftTipology";
import {AircraftCategory} from "../../models/AircraftCategory";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {User} from "../../models/User";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {StringIsNullOrEmpty} from "../../utils/common";
import PerfectScrollBar from "react-perfect-scrollbar";


interface AircraftChooseProps {
    formik: any,
}

const AircraftChoose: FC<AircraftChooseProps> = (props) => {
    const {formik} = props;
    const {t} = useTranslation();
    const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

    const [aircrafts, setAircrafts] = useState<Aircraft[]>([]);
    const [aircraftTipologyList, setAircraftTipologyList] = useState<Array<AircraftTipology>>([]);
    const [aircraftCategoryList, setAircraftCategoryList] = useState<Array<AircraftCategory>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [aircraftChoosenIndex, setAircraftChoosenIndex] = useState<number>(-1);

    const aircraftService = new AircraftService();
    const aircraftCategoryService = new AircraftCategoryService();
    const aircraftTipologyService = new AircraftTipologyService();

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            let [_1, _2, retrievedAircrafts] = await Promise.all([
                getAircraftTipology(),
                getAircraftCategory(),
                aircraftService.getAllAircraftsById(loggedUser?.id || "")])

            setAircrafts(retrievedAircrafts);

            retrievedAircrafts.map((aircraft, index) => {
                if (!StringIsNullOrEmpty(formik.values.aircraftRegistration) && retrievedAircrafts.length > 0) {
                    if (aircraft.aircraftRegistration == formik.values.aircraftRegistration) {
                        setAircraftChoosenIndex(index);
                    }
                }
            })

            setIsLoading(false);

        })();
    }, [])

    useEffect(() => {
        if (aircrafts.length == 1) {
            setAircraftChoosenIndex(0);
            setAircraftInFlight(aircrafts[0]);
        }
    }, [aircrafts])

    const getAircraftCategory = async () => {
        let retrievedAircraftCategory = await aircraftCategoryService.getAllAircraftCategory();
        setAircraftCategoryList(retrievedAircraftCategory);
    }

    const getAircraftTipology = async () => {
        let retrievedAircraftTipology = await aircraftTipologyService.getAllAircraftTipology();
        setAircraftTipologyList(retrievedAircraftTipology);
    }
    const setAircraftInFlight = (aircraftChoosen: Aircraft) => {
        const {aircraftRegistration, aircraftType, aircraftCategoryId, aircraftTipologyId} = aircraftChoosen;

        formik.setValues({
            ...formik.values,
            aircraftRegistration: aircraftRegistration,
            aircraftType: aircraftType,
            aircraftCategoryId: aircraftCategoryId,
            aircraftTipologyId
        })
    }

    return (<>

            {isLoading
                ? <>
                    <TableRowsLoaderVariant rowsNum={1} height={300} hasDivider={false}/>
                </>
                :
                <Grid container spacing={2} justifyContent="center">

                    {aircrafts.length === 0 ?
                        <Grid
                            item
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={t('AIRCRAFT_REGISTRATION')}
                                    name="aircraftRegistration"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.aircraftRegistration}
                                    error={Boolean(formik.errors.aircraftRegistration)}
                                    helperText={formik.errors.aircraftRegistration}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={t('AIRCRAFT_TYPE')}
                                    name="aircraftType"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.aircraftType}
                                    error={Boolean(formik.errors.aircraftType)}
                                    helperText={formik.errors.aircraftType}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                            >
                                <FormControl fullWidth required size='small'>
                                    <InputLabel>{t('AIRCRAFT_CATEGORY')}</InputLabel>
                                    <Select
                                        label={t('AIRCRAFT_CATEGORY')}
                                        name="aircraftCategoryId"
                                        onChange={formik.handleChange}
                                        value={formik.values.aircraftCategoryId}
                                        error={Boolean(formik.errors.aircraftCategoryId)}
                                        sx={{color: 'white'}}
                                    >
                                        {aircraftCategoryList.map((aircraftCategory: AircraftCategory) => (
                                            <MenuItem key={aircraftCategory.id} value={aircraftCategory.id}>
                                                {t(aircraftCategory.name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xs={12}
                            >
                                <FormControl fullWidth required size='small'>
                                    <InputLabel>{t('AIRCRAFT_TIPOLOGY')}</InputLabel>
                                    <Select
                                        label={t('AIRCRAFT_TIPOLOGY')}
                                        name="aircraftTipologyId"
                                        onChange={formik.handleChange}
                                        value={formik.values.aircraftTipologyId}
                                        error={Boolean(formik.errors.aircraftTipologyId)}
                                        sx={{color: 'white'}}
                                    >
                                        {aircraftTipologyList.map((aircraftTiplogy: AircraftTipology) => (
                                            <MenuItem key={aircraftTiplogy.id} value={aircraftTiplogy.id}>
                                                {t(aircraftTiplogy
                                                    .name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={12}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label={t('COLOR')}
                                    name="color"
                                    //onChange={handleChange}
                                    //value={aircraft.color}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={6}
                                xs={12}
                            >
                                <FormControlLabel
                                    control={<Checkbox
                                        //checked={aircraft.haveRadio ? true : false}
                                        //onChange={handleChange}
                                        name="haveRadio"
                                        inputProps={{'aria-label': 'controlled'}}
                                    />}
                                    label={t("HAVE_RADIO")}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={3}
                                md={6}
                                xs={12}
                            >
                                <FormControlLabel control=
                                                      {
                                                          <Checkbox
                                                              //checked={aircraft.haveTransponder ? true : false}
                                                              //onChange={handleChange}
                                                              name="haveTransponder"
                                                              inputProps={{'aria-label': 'controlled'}}
                                                          />
                                                      }
                                                  label={t("HAVE_TRANSPONDER")}
                                />
                            </Grid>


                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Box sx={{"& .ps__rail-y": {display: "none"}}}>
                                <PerfectScrollBar style={{maxHeight: 'calc(100vh - 280px)', overflowX: "hidden"}}>
                                    <Grid container spacing={2}>
                                        {aircrafts.map((aircraft: Aircraft, index) => (
                                            <Grid
                                                xs={12}
                                                md={5}
                                                lg={4}

                                                onClick={() => {
                                                    setAircraftInFlight(aircraft)
                                                    setAircraftChoosenIndex(index)
                                                }}
                                            >
                                                <Container>
                                                    <Card sx={{
                                                        mt: 3,
                                                        mb: 2,
                                                        border: Boolean(formik.errors.aircraftRegistration && aircraftChoosenIndex == -1) ? "solid" : aircraftChoosenIndex == index ? 'solid' : 'none',
                                                        borderColor: Boolean(formik.errors.aircraftRegistration && aircraftChoosenIndex == -1) ? '#FF0505' : '#688EFF'


                                                    }}
                                                    >
                                                        {aircraft.aircraftPhoto == undefined
                                                            ? (<>
                                                                <Box sx={{alignItems: 'flex-start'}}>
                                                                    <Skeleton variant="rectangular" animation="wave"
                                                                              height={150}/>
                                                                </Box>
                                                            </>)
                                                            : (<>
                                                                {!(aircraft.aircraftPhoto == "" || aircraft.aircraftPhoto == "https://cdn.jetphotos.com/full/null")
                                                                    ? <CardMedia
                                                                        component="img"
                                                                        sx={{height: 150}}
                                                                        image={aircraft.aircraftPhoto}
                                                                        title=""

                                                                    />
                                                                    : <Box sx={{
                                                                        mt: 7,
                                                                        mb: 7,
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignContent: "center"
                                                                    }}>
                                                                        <NoPhotographyIcon sx={{fontSize: 70}}/>
                                                                    </Box>}
                                                            </>)}

                                                        <CardContent>
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Box sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                    }}>
                                                                        <Typography align='center' sx={{
                                                                            mt: 4,
                                                                            mb: 1
                                                                        }}><strong>{aircraft.aircraftRegistration.toUpperCase()}</strong></Typography>

                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={12} justifyContent="center">
                                                                    <Typography align='center'
                                                                                sx={{mt: 2}}>{t("AIRCRAFT_TYPE_SHORT")}: {aircraft.aircraftType}</Typography>

                                                                    <Typography align='center' sx={{mt: 1}}>
                                                                        {t("AIRCRAFT_TIPOLOGY_SHORT")}: {t(aircraftTipologyList.find(aircraftT => aircraftT.id == aircraft.aircraftTipologyId)?.name || "")}
                                                                    </Typography>

                                                                    <Typography align='center' sx={{mt: 1}}>
                                                                        {t("AIRCRAFT_CATEGORY_SHORT")}: {t(aircraftCategoryList.find(aircraftC => aircraftC.id == aircraft.aircraftCategoryId)?.name || "")}
                                                                    </Typography>

                                                                    <Grid item xs={12} justifyContent="center">
                                                                        <Typography align='center'
                                                                                    sx={{mt: 1}}>{t("COLOR")}: {aircraft.color}</Typography>
                                                                        <Typography align='center'
                                                                                    sx={{mt: 1}}>{t("HAVE_RADIO")}: {aircraft.haveRadio ? t("YES") : t("NO")}</Typography></Grid>
                                                                    <Typography align='center'
                                                                                sx={{mt: 1}}>{t("HAVE_TRANSPONDER")}: {aircraft.haveTransponder ? t("YES") : t("NO")}</Typography></Grid>

                                                            </>
                                                        </CardContent>
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"

                                                        >
                                                            <Tooltip title={t('SELECT_AIRCRAFT') || ''}
                                                                     aria-label={t('SELECT_AIRCRAFT') || ''}>
                                                                <Button variant="contained" fullWidth
                                                                        sx={{height: 40, color: "white"}}
                                                                        style={{
                                                                            borderRadius: 0,
                                                                            backgroundColor: index === aircraftChoosenIndex ? "#688EFF" : "#AAAAAA",
                                                                        }}
                                                                >
                                                                    {t("SELECT_AIRCRAFT")}

                                                                </Button>
                                                            </Tooltip>
                                                        </Box>
                                                    </Card>
                                                </Container>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </PerfectScrollBar>
                            </Box>
                        </Grid>
                    }
                </Grid>
            }

        </>
    )
}

export default AircraftChoose;
