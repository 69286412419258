/* eslint-disable linebreak-style */
import {type FC} from 'react';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Flight} from '../../models/Flight';
import {dateTimeFormats, getFormattedUTCDate, StringIsNotNullOrEmpty} from '../../utils/common';

interface FlightDetailsBoxProps {
    flight: Flight,
    aircraftPhoto: string | undefined,
    isLoadingAircraftPhoto: boolean,

}

const FlightDetailsBox: FC<FlightDetailsBoxProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { flight, aircraftPhoto, isLoadingAircraftPhoto } = props;

    return (
        <Card>
            <CardHeader title={t('FLIGHT_DETAILS')} />
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                {isLoadingAircraftPhoto ?
                    <>
                        <Skeleton sx={{ height: 250, }} animation="wave" variant="rectangular" ></Skeleton>
                    </>
                    :
                    <>
                        {(aircraftPhoto != undefined)
                            &&
                            <Box sx={{ mb: 3, mt: 1 }}>
                                <img src={aircraftPhoto} style={{
                                    maxHeight: 250,
                                    objectFit: 'contain',
                                }} />
                            </Box>
                        }
                    </>
                }
            </Box>
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('AIRCRAFT_REGISTRATION')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.aircraftRegistration}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('AIRCRAFT_TYPE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.aircraftType}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('AIRCRAFT_TIPOLOGY')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(flight.aircraftTipologyName!)}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('AIRCRAFT_CATEGORY')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(flight.aircraftCategoryName!)}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('PILOT_NAME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.pilotFullName}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t("PILOT_EMAIL")}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.pilotEmail}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t("PILOT_PHONE_NUMBER")}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.pilotPhoneNumber}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t("PILOT_DOCUMENT")}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(flight.pilotDocumentTypeName) + ": " + flight.pilotDocumentValue}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {StringIsNotNullOrEmpty(flight.copilotFullName!) &&
                        <>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('COPILOT_NAME')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {flight.copilotFullName}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t("COPILOT_EMAIL")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {flight.copilotEmail}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t("COPILOT_PHONE_NUMBER")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {flight.copilotPhoneNumber}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t("COPILOT_DOCUMENT")}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {t(flight.copilotDocumentTypeName!) + ": " + flight.copilotDocumentValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </>
                    }


                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('FLIGHT_TYPE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(flight.flightType.name)}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('PEOPLE_ON_BOARD')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.peopleOnBoard}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('DEPARTURE_PLACE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.departureAirfieldName}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {flight.tenantIsDeparture ?
                                    t('DEPARTURE_TIME') :
                                    t('ARRIVAL_TIME')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {getFormattedUTCDate(flight.date, dateTimeFormats[i18n.language])}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t('ARRIVAL_PLACE')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.arrivalAirfieldName}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {t("NOTE")}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {flight.note}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Card>
    );
};

export default FlightDetailsBox;
