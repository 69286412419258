import {useCallback, useState} from "react";
import {User} from "../../models/User";
import {UserService} from "../../services/UserService";
import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Plus from '../../icons/Plus';
import {Link as RouterLink} from 'react-router-dom';
import {PaginationResult} from "../../types/paginationResult";
import UsersListTable from "../../components/user/UsersListTable";
import {AppState} from "../../store";
import {useSelector} from "react-redux";
import PermissionsGuard from "../../components/PermissionGuard";

const UsersList = () => {
  const [users, setUsers] = useState<PaginationResult<User>>();
  const { t } = useTranslation();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  const getUsers = useCallback(async (currentPage: number, recordLimit: number, searchFilter: string) => {
    try {
      setIsPageLoading(true);
      const userService = new UserService();

      const retrievedUsers = await userService.getAllUsers(currentPage, recordLimit, loggedUser?.currentTenantId ?? '', searchFilter);
      setUsers(retrievedUsers);

      setIsPageLoading(false);
    } catch (error) {
      //TODO: Handle errors
      console.log(error);
    }
  }, []);

  return (
    <PermissionsGuard
      allowedRoles={['SYSTEMADMIN', 'ADMINISTRATOR']}
      environment={'UsersList'}
      needTenant={true}
    >
      <Container maxWidth={false}>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('USERS_LIST')}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                component={RouterLink}
                to="add"
                color="primary"
                startIcon={<Plus fontSize="small" />}
                sx={{ m: 1 }}
                variant="contained"
              >
                {t('ADD_USER')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <UsersListTable
            paginationResult={users}
            getUsers={getUsers}
            isPageLoading={isPageLoading}
            loggedUser={loggedUser}
          />
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default UsersList;
