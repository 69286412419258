import {
    Box,
    Button,
    Divider,
    IconButton,
    Modal,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import ArrowRightIcon from '../../icons/ArrowRight';
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Scrollbar from "../../components/Scrollbar";
import X from "../../icons/X";
import { FlightService } from "../../services/FlightService";
import { userCanUseIfRoles } from "../../utils/common";
import LoaderVariant from "../LoaderVariant";
import { Flight } from "../../models/Flight";
import { AppState } from "@auth0/auth0-react";
import { User } from "../../models/User";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FlightLand, FlightTakeoff } from "@material-ui/icons";
import FlightsCalendarModalRow from "./FlightsCalendarModalRow";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: 550,
    maxHeight: "25rem",
    bgcolor: '#141414',
    border: '2px solid #000',
    boxShadow: 24,
    py: 1,
};

interface FlightsCalendarModalProps {
    flightIds: string[],
    date: number,
    modalIsOpen: boolean,
    setModalIsOpen: Function
}

const FlightsCalendarModal: FC<FlightsCalendarModalProps> = (props) => {
    const { flightIds, date, modalIsOpen, setModalIsOpen } = props
    const { t } = useTranslation();
    const navigate = useNavigate();

    var currentTheme = useTheme();
    const flightService = new FlightService()
    const isMobile = useMediaQuery(currentTheme.breakpoints.down(500));
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [flightList, setFlightList] = useState<Flight[]>([]);
    const loggedUser = useSelector<AppState, User>((state) => state.appInit.user);

    const CloseModal = () => {
        setModalIsOpen(false);
    }
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    useEffect(() => {
        (async () => {
            if ((flightIds != null && flightIds != undefined)) {
                await getFlightsDetails(flightIds)
                setIsLoading(false);
            }
        })();
    }, []);

    const getFlightsDetails = async (flightIds: string[]) => {
        var flightListTemp = new Array<Flight>();

        var flights = await flightService.getFlightsById(flightIds);
        setFlightList(flights);
    }

    return (<>
        {isLoading ? <>
            <LoaderVariant isLoading={isLoading} />
        </> :
            isMobile ? <>
                <Modal sx={{ p: 0, m: 0 }} open={modalIsOpen} onClose={CloseModal} >
                    <Box sx={style}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}>
                            <Button
                                startIcon={<X fontSize='small' />}
                                color="primary"
                                onClick={CloseModal}
                            >
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            px: 2,
                            mb: 2,
                        }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {
                                    new Date(date).toLocaleString('it-IT', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    })}
                            </Typography>
                        </Box>
                        <Scrollbar>
                            <TableContainer sx={{
                                maxHeight: "20rem",
                                overflow: "auto",
                                px: 4
                            }}>
                                {flightList?.map((value, index) => (
                                    <>
                                        <Divider />
                                        <FlightsCalendarModalRow flight={value} />
                                    </>
                                ))}
                            </TableContainer>
                        </Scrollbar>

                    </Box>
                </Modal>
            </> :

                <>
                    <Modal open={modalIsOpen} onClose={CloseModal} >
                        <>
                            <Box sx={style}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                    <Button
                                        startIcon={<X fontSize='small' />}
                                        color="primary"
                                        onClick={CloseModal}
                                    >
                                    </Button>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    px: 3,
                                    mb: 2,
                                }}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {
                                            new Date(date).toLocaleString('it-IT', {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                            })}
                                    </Typography>
                                </Box>
                                <Scrollbar>
                                    <TableContainer sx={{
                                        maxHeight: "19rem",
                                        overflow: "auto",
                                        px: 4,
                                        py: 1,
                                    }}>
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography id="modal-modal-description" sx={{ minWidth: 25 }}>
                                                        {t("FLIGHT_DIRECTION_SHORT")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography id="modal-modal-description" sx={{ minWidth: 45 }}>
                                                        {t("TIMETABLE")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>

                                                    <Typography id="modal-modal-description" sx={{ minWidth: 70 }}>
                                                        {t("AIRFIELD")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography id="modal-modal-description" sx={{ minWidth: 70 }}>
                                                        {t("PILOT")}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography id="modal-modal-description" sx={{ minWidth: 25 }}>
                                                        {t("ACTIONS")}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            {
                                                flightList?.map((value, index) => (

                                                    <>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Tooltip title={t(value.tenantIsDeparture ?
                                                                    "DEPARTURE" : "ARRIVAL_PLACE")}
                                                                    aria-label={t(value.tenantIsDeparture ?
                                                                        "ARRIVAL_PLACE" : "DEPARTURE")} >
                                                                    {value.tenantIsDeparture ?
                                                                        <FlightTakeoff fontSize="small" sx={{ mt: 1 }} />
                                                                        :
                                                                        <FlightLand fontSize="small" sx={{ mt: 1 }} />
                                                                    }
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                    {new Date(value.date).toLocaleString('it-IT', {
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                    })}
                                                                </Typography>

                                                            </TableCell>

                                                            <TableCell>
                                                                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                    {value.tenantIsDeparture ?
                                                                        value.arrivalAirfieldName :
                                                                        value.departureAirfieldName}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell>
                                                                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                    {value.pilotFullName}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'VIEWER'])
                                                                    && <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                navigate(`/flights/detail?flightId=${value.id}`)
                                                                            }}
                                                                        >
                                                                            <ArrowRightIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>}
                                                            </TableCell>
                                                        </TableRow >
                                                    </>

                                                ))
                                            }
                                        </Table>

                                    </TableContainer>
                                </Scrollbar>

                            </Box>
                        </>
                    </Modal>
                </>
        }
    </>)
}

export default FlightsCalendarModal
