import axios from 'axios';
import { Aircraft } from '../models/Aircraft';

export class AircraftService {

    async getAllAircraftsById(id: string) {
        try {
            const response = await axios.post('GetAllAircraftsById', { UserId: id });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async UpsertAircraft(aircraft: Aircraft) {
        try {
            const response = await axios.post('UpsertAircraft', aircraft);
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetAircraft(id: string) {
        try {
            const response = await axios.post('GetAircraft', { id: id });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetAircraftPhoto(userId: string, aircraftRegistration: string) {
        try {
            const response = await axios.post('GetAircraftPhoto',
                {
                    userId: userId,
                    aircraftRegistration: aircraftRegistration
                });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetAircraftPhotoUrl(userId: string, aircraftRegistration: string) {
        try {
            const response = await axios.post('GetAircraftPhotoUrl',
                {
                    userId: userId,
                    aircraftRegistration: aircraftRegistration
                });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetDefualtPhoto(airReg: string) {
        try {
            const response = await axios.post('GetAircraftStandardPhoto',
                { aircraftRegistration: airReg });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async DeleteAircraft(id: string) {
        try {
            const response = await axios.post('DeleteAircraft', { id: id });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async DeleteAircraftPhoto(id: string) {
        try {
            const response = await axios.post('DeleteAircraftPhoto', { id: id });
            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }
}