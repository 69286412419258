import {Flight} from "../models/Flight";
import {isEmpty} from "../utils/common";
import {hasErrors} from "../utils/validation";

export class FlightValidator {
    public errors = {
        aircraftRegistration: '',
        aircraftType: '',
        flightTypeId: '',
        peopleOnBoard: '',
        departureAirfieldName: '',
        date: '',
        arrivalAirfieldName: '',

        pilotFullName: '',
        pilotPhoneNumber: '',
        pilotEmail: '',
        pilotDocumentTypeId: '',
        pilotDocumentValue: '',

        copilotCheckFields: '',

        copilotFullName: '',
        copilotPhoneNumber: '',
        copilotEmail: '',
        copilotDocumentTypeId: '',
        copilotDocumentValue: '',
    };

    private t: Function;

    constructor(t: Function) {
        this.t = t;
    }

    validateFlight(flight: Flight, fieldsNotToCheck: Array<string>): boolean {

        this.validateAircraftRegistration(flight.aircraftRegistration);
        this.validateAircraftType(flight.aircraftType);
        this.validateFlightTypeId(flight.flightTypeId);
        this.validatePeopleOnBoard(flight.peopleOnBoard, isEmpty(flight.copilotFullName));
        this.validateDepartureAirfieldName(flight.departureAirfieldName);
        this.validateDate(flight.date);
        this.validateArrivalAirfieldName(flight.arrivalAirfieldName);

        this.validatePilotFullName(flight.pilotFullName);
        this.validatePilotPhoneNumber(flight.pilotPhoneNumber);
        this.validatePilotEmail(flight.pilotEmail);

        this.checkCopilotField(flight);

        this.validateCopilotFullName(flight.copilotFullName);
        this.validateCopilotPhoneNumber(flight.copilotPhoneNumber);
        this.validateCopilotEmail(flight.copilotEmail);

        return !hasErrors(this.errors, fieldsNotToCheck);
    }

    validateAircraftRegistration(aircraftRegistration: string) {
        if (isEmpty(aircraftRegistration)) {
            this.errors.aircraftRegistration = this.t('AIRCRAFT_REGISTRATION_REQUIRED');
        }
    }

    validateAircraftType(aircraftType: string) {
        if (isEmpty(aircraftType)) {
            this.errors.aircraftType = this.t('AIRCRAFT_TYPE_REQUIRED');
        }
    }

    validateFlightTypeId(flightTypeId: string) {
        if (isEmpty(flightTypeId)) {
            this.errors.flightTypeId = this.t('FLIGHT_TYPE_REQUIRED');
        }
    }

    validatePeopleOnBoard(peopleOnBoard: number, isCopilotEmpty: boolean = true) {
        let minPeopleOnBoard: number = isCopilotEmpty ? 1 : 2;

        if (isEmpty(peopleOnBoard)) {
            this.errors.peopleOnBoard = this.t('People on board is required');
        } else if (peopleOnBoard < minPeopleOnBoard) {
            this.errors.peopleOnBoard = this.t('PEOPLE_ON_BOARD_MIN_NUMBER') + minPeopleOnBoard;
        }
    }

    validateDepartureAirfieldName(departureAirfieldName: string) {
        if (isEmpty(departureAirfieldName)) {
            this.errors.departureAirfieldName = this.t('DEPARTURE_AIRFIELD_REQUIRED');
        }
    }

    validateDate(date: string) {
        if (isEmpty(date)) {
            this.errors.date = this.t('DEPARTURE_TIME_REQUIRED');
        }
    }

    validateArrivalAirfieldName(arrivalAirfieldName: string) {
        if (isEmpty(arrivalAirfieldName)) {
            this.errors.arrivalAirfieldName = this.t('ARRIVAL_AIRFIELD_REQUIRED');
        }
    }

    validatePilotFullName(pilotFullName: string) {
        if (isEmpty(pilotFullName)) {
            this.errors.pilotFullName = this.t('PILOT_NAME_REQUIRED');
        }
    }

    validatePilotPhoneNumber(pilotPhoneNumber: string) {
        if (isEmpty(pilotPhoneNumber)) {
            this.errors.pilotPhoneNumber = this.t('PILOT_PHONE_REQUIRED');
        }
    }

    validatePilotEmail(pilotEmail: string) {
        if ((isEmpty(pilotEmail) || !(/^([a-zA-Z0-9._ %-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(pilotEmail)))) {
            this.errors.pilotEmail = this.t('PILOT_EMAIL_REQUIRED');
        }
    }

    checkCopilotField(flight: Flight): boolean {
        if (!(flight.copilotFullName == null &&
            flight.copilotPhoneNumber == null &&
            flight.copilotEmail == null
        )) {
            if (((flight.copilotFullName != null) &&
                (flight.copilotPhoneNumber != null))
            ) {
                return true;
            } else {
                this.errors.copilotCheckFields = this.t('COPILOT_FIELDS_REQUIRED');
                return false;
            }
        }
        return true;
    }


    validateCopilotFullName(copilotFullName: string | undefined | null) {
        if (copilotFullName == null && this.errors.copilotCheckFields != '') {
            this.errors.copilotFullName = this.t('COPILOT_NAME_REQUIRED');
        }
    }

    validateCopilotPhoneNumber(copilotPhoneNumber: string | undefined | null) {
        if (copilotPhoneNumber == null && this.errors.copilotCheckFields != '') {
            this.errors.copilotPhoneNumber = this.t('COPILOT_PHONE_REQUIRED');
        }
    }

    validateCopilotEmail(copilotEmail: string | undefined | null) {
        if (!(isEmpty(copilotEmail) || (!isEmpty(copilotEmail) &&
            ((/^([a-zA-Z0-9._ %-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(copilotEmail ? copilotEmail : "")))))) {
            this.errors.copilotEmail = this.t('COPILOT_EMAIL_REQUIRED');
        }
    }
}
