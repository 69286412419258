import {type FC, useEffect, useState} from 'react';
import {Card, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {DocumentTypeService} from '../../services/DocumentTypeService';
import {FlightTypeService} from '../../services/FlightTypeService';
import {FlightType} from '../../models/FlightType';
import {DocumentType} from '../../models/DocumentType';
import {dateTimeFormats, getFormattedUTCDate, isEmpty} from '../../utils/common';
import {CardContent, useMediaQuery, useTheme} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface SummaryFormProps {
    formik: any,
}

const SummaryForm: FC<SummaryFormProps> = (props) => {
    const {formik} = props;
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [flightTypes, setFlightTypes] = useState<FlightType[]>([]);
    const flightTypeService = new FlightTypeService();
    const documentTypeService = new DocumentTypeService();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                let [types, _] = await Promise.all([
                    documentTypeService.getAllDocumentTypes(),
                    getFlightTypes()
                ]);

                setDocumentTypes(types);

                setIsLoading(false);
            } catch (err) {
                console.log("Error");
            }

            if (formik.values.thereIsCopilot == false) {
                await formik.setValues({
                    ...formik.values,
                    copilotFullName: null,
                    copilotEmail: null,
                    copilotPhoneNumber: null,
                    copilotDocumentTypeId: "000000000000000000000000",
                    copilotDocumentValue: null,
                });

            }
        })();
    }, []);


    const getFlightTypes = async () => {
        let retrievedFlightTypes = await flightTypeService.getAllFlightTypes();
        setFlightTypes(retrievedFlightTypes);
    }


    const infoTableRow = (title: string, value: string) => {

        return <TableRow>
            <TableCell>
                <Typography
                    color="textPrimary"
                    variant="subtitle2"
                >
                    {title}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    color="textSecondary"
                    variant="body2"
                >
                    {value}
                </Typography>

            </TableCell>
        </TableRow>
    }

    return (
        <Card sx={{height: "100% !important", mb: 2}}>
            <CardContent sx={{p: 0, "& .ps__rail-y": {display: "none"}}}>
                <PerfectScrollbar
                    style={{maxHeight: isMobile ? 'calc(100vh - 380px)' : 'calc(100vh - 350px)', overflowX: "hidden",}}>
                    <Table>
                        <TableBody>
                            {infoTableRow(t("PILOT_NAME"), formik.values.pilotFullName)}
                            {infoTableRow(t("EMAIL"), formik.values.pilotEmail)}
                            {infoTableRow(t("DOCUMENT_TYPES_LABEL"),
                                t(documentTypes.find(aircraftCategory => aircraftCategory
                                    .id == formik.values.pilotDocumentTypeId)?.name!))}
                            {infoTableRow(t("DOCUMENT_VALUE_LABEL"), formik.values.pilotDocumentValue)}
                            {formik.values.thereIsCopilot == true &&
                                <>
                                    {infoTableRow(t("COPILOT_NAME"), formik.values.copilotFullName)}

                                    {infoTableRow(t("EMAIL"), formik.values.copilotEmail)}
                                    {infoTableRow(t("DOCUMENT_TYPES_LABEL"), t(documentTypes.find(aircraftCategory => aircraftCategory
                                        .id == formik.values.copilotDocumentTypeId)?.name!))}

                                    {infoTableRow(t("DOCUMENT_VALUE_LABEL"), formik.values.copilotDocumentValue)}
                                </>
                            }
                            {infoTableRow(t("DEPARTURE_AIRFIELD"), formik.values.departureAirfieldName)}
                            {infoTableRow(t("ARRIVAL_AIRFIELD"), formik.values.arrivalAirfieldName)}
                            {infoTableRow(t("DATE"), getFormattedUTCDate(formik.values.date, dateTimeFormats[i18n.language]))}
                            {!isEmpty(formik.values.nextMovementDate)
                                && infoTableRow(t("NEXT_DEPARTURE_TIME"),
                                    getFormattedUTCDate(formik.values.nextMovementDate, dateTimeFormats[i18n.language]))}
                            {infoTableRow(t("AIRCRAFT"), formik.values.aircraftRegistration)}
                            {infoTableRow(t("FLIGHT_TYPE"), t(flightTypes.find(flightType => flightType
                                .id == formik.values.flightTypeId)?.name!))}
                            {infoTableRow(t("NOTE"), formik.values.note)}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </CardContent>

        </Card>
    );
};


export default SummaryForm;
