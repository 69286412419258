import {CardContent, Grid, Skeleton} from "@mui/material";

const CardContentLoader = () => {
    return (
        <CardContent>
            <Grid container direction="row" rowSpacing={2} columnSpacing={4}>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 5 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>

                <Grid
                    item
                    lg={8}
                    md={8}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 5 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>

                <Grid
                    item
                    lg={8}
                    md={8}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 5 }} animation="wave" />
                </Grid>

                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>

                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} animation="wave" />
                </Grid>

                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                >
                    <Skeleton sx={{ mb: 2 }} height={100} animation="wave" />
                </Grid>
            </Grid>
        </CardContent>

    );

};



export default CardContentLoader;
