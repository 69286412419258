import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableRow,
    Typography,
} from '@material-ui/core';
import PencilAltIcon from '../../icons/PencilAlt';
import {Flight} from '../../models/Flight';
import ArrowRightIcon from '../../icons/ArrowRight';
import Scrollbar from '../Scrollbar';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import TableRowsLoader from '../TableRowsLoader';
import {FlightLand, FlightTakeoff, MoreHoriz, SupportAgent, ThumbDown, ThumbUp} from '@material-ui/icons';
import {userCanUseIfRoles} from '../../utils/common';
import InformationCircle from '../../icons/InformationCircle';
import Airfield from '../../icons/Airfield';
import LicensePlate from '../../icons/LicensePlate';
import FlightLogModal from './FlightLogModal';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import {Divider} from '@mui/material';
import {format, isToday} from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import {FlightService} from '../../services/FlightService';


interface PilotFlightWithMenuProps {
    flight: Flight;
    updateApprovedFlight(flightId: string, approved: boolean): void;

    loggedUser: any;
}

const PilotFlightWithMenu: FC<PilotFlightWithMenuProps> = ({ flight, updateApprovedFlight, loggedUser }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const OpenModal = () => {
        setModalIsOpen(true);
    }


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);


    return (
        <>
            <Divider />
            {modalIsOpen &&
                <FlightLogModal airfieldId={flight.id!}
                    modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            }



            <TableRow key={flight.id} sx={{ display: "block" }}>

                <Grid container spacing={1} sx={{ mt: 2, px: 0 }}>
                    <Grid item xs={9} onClick={() => navigate(`detail?flightId=${flight.id}`)}>
                        <Grid container direction="column">
                            <Grid container direction="row" style={{ flexFlow: "noWrap" }} spacing={1} sx={{ mb: 1 }}>
                                <Grid
                                    item
                                >
                                    {flight.tenantIsDeparture ? <FlightTakeoff fontSize='small' /> :
                                        <FlightLand fontSize='small' />}
                                </Grid>
                                <Grid
                                    item
                                >
                                    <Typography variant="subtitle2"
                                        align="center"> {flight.tenantIsDeparture ? t('DEPARTURING') : t('ARRIVING')} </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ flexFlow: "noWrap" }} spacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <Airfield fontSize='small' />
                                </Grid>
                                <Grid item>

                                    {windowWidth < 450 ?
                                        <>
                                            <div style={{
                                                paddingLeft: "0px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                minWidth: '4rem',
                                                //width: (windowWidth / 50) + "rem"
                                            }}>
                                                <Typography variant="body2">
                                                    {flight.arrivalAirfieldName}
                                                </Typography>
                                            </div>
                                        </>
                                        : <Typography variant="body2" align="center">

                                            {flight.tenantIsDeparture ? flight.arrivalAirfieldName : flight.departureAirfieldName}

                                        </Typography>}

                                </Grid>
                            </Grid>

                            {/* <Grid container direction="row" spacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <DateRange fontSize='small' />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center">
                                        {getFormattedUTCDate(flight.date, dateTimeFormats[i18n.language])}
                                    </Typography>
                                </Grid>
                            </Grid> */}

                            <Grid container direction="row" style={{ flexFlow: "noWrap" }} spacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <SupportAgent fontSize='small' />
                                </Grid>
                                <Grid item>
                                    {windowWidth < 450 ?
                                        <>
                                            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                                <Typography variant="body2">
                                                    {flight.pilotFullName}
                                                </Typography>
                                            </div>
                                        </>
                                        : <Typography variant="body2" align="center">
                                            {flight.pilotFullName}
                                        </Typography>}
                                </Grid>
                            </Grid>

                            <Grid container direction="row" style={{ flexFlow: "noWrap" }} spacing={1} sx={{ mb: 1 }}>
                                <Grid item>
                                    <LicensePlate fontSize='small' />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center"> {flight.aircraftRegistration} </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={3}>

                        <Box sx={{
                            backgroundColor: "#202427", borderRadius: "12px",
                            p: "4px 2px"
                        }}>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                align="right">
                                {<>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {!isToday(new Date(flight.date))
                                            ? t(format(new Date(flight.date), 'EEEE').toUpperCase())
                                            : t("TODAY")}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {t(format(new Date(flight.date), 'LLLL').toUpperCase() + "_SHORT")}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {format(new Date(flight.date), 'd')}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {format(new Date(flight.date), 'HH:mm').toUpperCase()}
                                    </Typography>

                                </>}
                            </Typography>
                        </Box>

                    </Grid>



                    <Divider sx={{ mx: 3 }} />
                    <Grid container sx={{
                        verticalAlign: 'bottom', textAlign: 'center', mt: 0.5, mb: 0.5
                    }}>
                        {
                            userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'PILOT']) &&
                            (<>
                                <Grid
                                    item xs={4}
                                >
                                    {flight.approved == null ?

                                        <Button
                                            size="small"
                                            disableFocusRipple
                                            color="primary"
                                        >
                                            <Box sx={{
                                                display: "flex", flexDirection: "column",
                                                alignItems: "center",
                                                color: "#AAAAAA"
                                            }}>
                                                <ThumbUp />
                                                {t("UPDATE_FLIGHT_STATE")}
                                            </Box>
                                        </Button>
                                        :
                                        <Button
                                            size="small"
                                            sx={{ width: "100%" }}
                                            disableFocusRipple
                                        >
                                            <Box sx={{
                                                display: "flex", flexDirection: "column",
                                                alignItems: "center",
                                                color: "#AAAAAA"
                                            }}>
                                                {flight.approved ?
                                                    <>
                                                        <ThumbUp sx={{ color: "#4CB071" }} />
                                                        {t('APPROVED')}
                                                    </> :
                                                    <>
                                                        <ThumbDown sx={{ color: "#F44336" }} />
                                                        {t('REJECTED')}
                                                    </>}


                                            </Box>
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={4} >

                                    <Button
                                        sx={{ width: "100%" }}
                                        id="isCarriedButton"
                                        size="small"
                                        disableFocusRipple
                                    >
                                        <Box sx={{
                                            display: "flex", flexDirection: "column",
                                            alignItems: "center",
                                            color: "#AAAAAA"
                                        }}>
                                            <AirplanemodeActiveIcon sx={{
                                                color: flight.isCarried == true ?
                                                    "#4CAF50" : flight.isCarried == false ?
                                                        "#F44336" :
                                                        "#808080"

                                            }} />
                                            {flight.isCarried == true ? t("IS_CARRIED") :
                                                flight.isCarried == false ? t("IS_NOT_CARRIED") :
                                                    flight.tenantIsDeparture ? t("DEPARTURE") : t("LANDING")}
                                        </Box>
                                    </Button>
                                </Grid>

                                <Grid item xs={4} >

                                    <Button
                                        size="small"
                                        onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                                        sx={{ mr: 1, width: "100%" }}
                                        disableFocusRipple
                                    >
                                        <Box sx={{
                                            display: "flex", flexDirection: "column",
                                            alignItems: "center",
                                            color: "#AAAAAA"
                                        }}>
                                            <MoreHoriz />
                                            {t("MORE_OPTIONS")}
                                        </Box>
                                    </Button>
                                    <Menu
                                        id="flights-actions-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'flight-action-button',
                                        }}
                                        anchorEl={menuAnchorEl}
                                        open={Boolean(menuAnchorEl)}
                                        onClose={() => {
                                            setMenuAnchorEl(null)
                                        }}
                                    >
                                        {
                                            userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) && flight.approved == null &&
                                            (
                                                <MenuItem key={'edit'} onClick={() => {
                                                    setMenuAnchorEl(null)
                                                }}
                                                    component={RouterLink}
                                                    to={`edit?flightId=${flight.id}`}>

                                                    <PencilAltIcon fontSize="small" sx={{ mr: '3px' }} />
                                                    <Typography variant="subtitle1">{t('EDIT')}</Typography>
                                                </MenuItem>
                                            )
                                        }
                                        {
                                            userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'VIEWER']) &&
                                            (
                                                <MenuItem key={'flightLog'} onClick={() => {
                                                    setMenuAnchorEl(null)
                                                    OpenModal();
                                                }}>

                                                    <InformationCircle fontSize="small" sx={{ mr: '3px' }} />
                                                    <Typography variant="subtitle1">{t('LOG_TEXT_LABEL')}</Typography>
                                                </MenuItem>
                                            )
                                        }

                                        <MenuItem key={'details'}
                                            component={RouterLink}
                                            to={`detail?flightId=${flight.id}`}
                                            onClick={() => {
                                                setMenuAnchorEl(null)
                                            }}>

                                            <ArrowRightIcon fontSize="small" sx={{ mr: '3px' }} />
                                            <Typography variant="subtitle1">{t('DETAILS')}</Typography>
                                        </MenuItem>
                                    </Menu>
                                </Grid>

                            </>)
                        }

                    </Grid>


                </Grid>
            </TableRow>
        </>
    );
}

interface PilotFlightsListTableMobileProps {
    paginationResult: PaginationResult<Flight> | undefined;
    isPageLoading: boolean;
    loggedUser: any;
    tenantIsDeparture: boolean | null;
    filters: any;
    updateApprovedFlight(flightId: string, approved: boolean): void;
    getMoreFlights(): void;
    isRecordsLoading: boolean;

}

const PilotFlightsListTableMobile: FC<PilotFlightsListTableMobileProps> = (props) => {

    const {
        paginationResult,
        isPageLoading,
        loggedUser,
        getMoreFlights,
        updateApprovedFlight,
        isRecordsLoading,
        filters } = props;

    const flightService = new FlightService();
    const [paginatedFlights, setPaginatedFlights] = useState<PaginationResult<Flight>>();
    useEffect(() => {
        if (paginationResult) {
            setPaginatedFlights(paginationResult)
        }
    }, [paginationResult])


    return (
        <>
            <Scrollbar>
                <Table>
                    <TableBody>
                        {
                            isPageLoading ?
                                (
                                    <TableRowsLoader rowsNum={3} colsNum={1} />
                                ) :
                                (<Box sx={{ overflow: "hidden" }}>
                                    <InfiniteScroll
                                        style={{ overflow: "hidden" }}
                                        dataLength={paginatedFlights?.items.length ? paginatedFlights?.items.length : 10}
                                        next={getMoreFlights}
                                        hasMore={!isRecordsLoading &&
                                            (paginatedFlights?.currentPage || 0) <= (paginatedFlights?.totalPages || 0)}
                                        loader={<></>}
                                    >
                                        {paginatedFlights?.items.map((flight) => (
                                            <PilotFlightWithMenu
                                                flight={flight}
                                                updateApprovedFlight={updateApprovedFlight}
                                                loggedUser={loggedUser}
                                            />
                                        ))}
                                    </InfiniteScroll>
                                </Box>)
                        }
                    </TableBody>
                </Table>
                {isRecordsLoading &&
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        mt: 4, mb: 3
                    }}>
                        <CircularProgress />
                    </Box>
                }
            </Scrollbar>
        </>
    );
};

export default PilotFlightsListTableMobile;
