import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { DefaultUser, User } from "../../models/User";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/UserService";
import { UserValidator } from "../../validators/UserValidator";
import { composeNotistackMessage, getFormValue, isEmpty } from "../../utils/common";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import AvatarDropzone from "../AvatarDropzone";
import { DocumentTypeService } from "../../services/DocumentTypeService";
import { DocumentType } from "../../models/DocumentType";
import LoaderVariant from "../LoaderVariant";
import PermissionsGuard from "../PermissionGuard";
import Resizer from "react-image-file-resizer";
import { useNavigate } from 'react-router-dom';




interface PilotUserFormProps {
    isAdd: boolean,
}

const PilotUserForm: FC<PilotUserFormProps> = (props) => {
    const { isAdd } = props;
    const { t } = useTranslation();
    const currentTheme = useTheme();
    const isMobile = useMediaQuery(currentTheme.breakpoints.down(550));
    const [user, setUser] = useState<User>(DefaultUser);

    const fieldsNotToCheck = [
        //TODO: Remove all from here
        'id',
        'currentTenantId',
        'tenants',
        'tenantName',
        'preferredLanguage',
        'avatar',
        'role',
        'tenantUser',
        'tenantId',
    ];

    const userService = new UserService();
    const userValidator = new UserValidator(t);
    const history = useNavigate();

    const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);
    const [files, setFiles] = useState<any[]>([]);
    const [documentTypes, setDocumentTypes] = useState<Array<DocumentType>>([]);

    const documentTypeService = new DocumentTypeService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);

    useEffect(() => {
        (async () => {

            await getDocumentTypes();

            let retrievedUser: User = await userService.getUserById(loggedUser?.id!, loggedUser?.currentTenantId);
            retrievedUser.roleId = retrievedUser.tenantUser.roleId;

            if (isEmpty(retrievedUser.documentTypeId)) {
                retrievedUser.documentTypeId = documentTypes.find(document => document.name == "LICENSE")?.id;
            }
            retrievedUser.tenantId = loggedUser?.currentTenantId;

            setUser(retrievedUser);

            setAvatar(retrievedUser.tenantUser.avatar);

            setIsLoading(false);

        })();
    }, []);


    const setAvatar = (avatar: string | undefined) => {
        setUser((currentFormData: any) => ({
            ...currentFormData,
            avatar: avatar
        }));
    }

    const getDocumentTypes = async () => {
        let retrievedDocumentTypes = await documentTypeService.getAllDocumentTypes();
        setDocumentTypes(retrievedDocumentTypes);
    }

    async function userUpsert() {
        let validateUser: boolean = userValidator.validateUserPilot(user, fieldsNotToCheck);

        if (validateUser) {
            setIsLoadingForm(true);
            let userUpserResult: boolean = await userService.userUpsert(user);

            if (userUpserResult) {
                composeNotistackMessage(isAdd ? t('USER_ADDED') : t('USER_UPDATED'), 'success');


                history("/pilotflights");
                window.location.reload();
            } else {
                composeNotistackMessage(isAdd ? t('USER_NOT_ADDED') : t('USER_NOT_UPDATED'), 'error');
            }
            setIsLoadingForm(false);
        }
    }

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setUser((currentFormData: any) => ({
            ...currentFormData,
            [name]: value
        }))
    }

    function base64ToBlob(base64String) {
        const parts = base64String.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const byteCharacters = atob(parts[1]);

        const arrayBuffer = new ArrayBuffer(byteCharacters.length);
        const byteArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: contentType });
    }

    const handleDrop = (newFiles: any): void => {
        var reader = new FileReader();
        reader.readAsDataURL(newFiles[0]);
        reader.onload = () => {
            var blobImage = base64ToBlob(reader.result);
            Resizer.imageFileResizer(
                blobImage,
                171,
                128,
                'JPEG',
                100,
                0,
                uri => {
                    setAvatar(uri as string);
                },
                'base64',
                64,
                64);


        }
    };


    const handleRemove = (): void => {
        setFiles([]);
        setAvatar(undefined);
    };

    return (<>
        {isLoadingForm
            && <LoaderVariant isLoading={isLoadingForm} />}
        <PermissionsGuard
            allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT','USER']}
            environment={'PilotUserForm'}
        >
            <Grid container>
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ mb: 2, ml: 3 }}
                >
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {t('MY_PROFILE')}
                    </Typography>
                </Grid>

                <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={12}
                    sx={{ mx: 1, mb: 2, ml: isMobile ? 1 : 3 }}

                >
                    {
                        isLoading ?
                            <Skeleton variant="rectangular" width={"100%"} height={280} />
                            : <>
                                <Card>
                                    <CardHeader title={t('AVATAR')} />
                                    <Divider />
                                    <CardContent>
                                        {user.avatar
                                            ? <div style={{ textAlign: 'center' }}>
                                                <img src={user.avatar} alt="avatar" style={{
                                                    padding: '20px', objectFit: 'cover',
                                                    borderRadius: "100%", minHeight: '128px',
                                                    width: '150px', height: '150px'
                                                }} />
                                                <Divider />
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    mt: 2
                                                }}>
                                                    <Button
                                                        sx={{
                                                            backgroundColor: 'error.main',
                                                            color: 'error.contrastText',
                                                            '&:hover': {
                                                                backgroundColor: 'error.dark'
                                                            }
                                                        }}
                                                        variant="contained"
                                                        onClick={handleRemove}
                                                        disabled={isLoadingForm}
                                                    >
                                                        {t('DELETE')}
                                                    </Button>
                                                </Box>
                                            </div>
                                            : <Box sx={{ mt: 3 }}>
                                                <AvatarDropzone
                                                    accept="image/*"
                                                    files={files}
                                                    maxFiles={1}
                                                    maxSize={5000000}
                                                    onDrop={handleDrop}
                                                    onRemove={handleRemove}
                                                />
                                            </Box>}
                                    </CardContent>
                                </Card>
                            </>
                    }
                </Grid>

                <Grid
                    item
                    lg={8}
                    md={8}
                    xl={8}
                    xs={12}
                    sx={{ mx: 1 }}

                >

                    {isLoading
                        ? <Skeleton variant={"rectangular"} width={"100%"} height={320} />
                        : <Card>
                            <CardHeader title={t('USER_DATA')} />
                            <Divider />
                            <CardContent sx={{ input: { color: 'white' } }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('EMAIL')}
                                            name="email"
                                            disabled

                                            required
                                            onChange={handleChange}
                                            value={user.email}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('FIRST_NAME')}
                                            name="name"
                                            required
                                            onChange={handleChange}
                                            value={user.name}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('SURNAME')}
                                            name="surname"
                                            required
                                            onChange={handleChange}
                                            value={user.surname}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('PHONE_NUMBER')}
                                            name="phoneNumber"
                                            required
                                            onChange={handleChange}
                                            value={user.phoneNumber}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <FormControl fullWidth size='small'>
                                            <InputLabel>{t('DOCUMENT_TYPES_LABEL')}</InputLabel>
                                            <Select
                                                label={t('DOCUMENT_TYPES_LABEL')}
                                                name="documentTypeId"
                                                onChange={handleChange}
                                                sx={{ color: 'white' }}
                                                value={user.documentTypeId}
                                            >
                                                <MenuItem key={"000000000000000000000000"}
                                                    value={"000000000000000000000000"}>
                                                    {t("SELECT_DOCUMENT_TYPE_OPTION")}
                                                </MenuItem>
                                                {documentTypes.map((documentType: DocumentType) => (
                                                    <MenuItem key={documentType.id} value={documentType.id}>
                                                        {t(documentType.name)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={6}
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('DOCUMENT_VALUE_LABEL')}
                                            name="documentValue"
                                            onChange={handleChange}
                                            value={user.documentValue}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}>
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    disabled={isLoadingForm}
                                    onClick={userUpsert}
                                >
                                    {t('SAVE')}
                                </Button>
                            </Box>
                        </Card>}
                </Grid>
            </Grid>
        </PermissionsGuard>
    </>);
}

export default PilotUserForm;
