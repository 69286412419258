import { FC, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Scrollbar from "../Scrollbar";
import { useTranslation } from "react-i18next";
import { User } from "../../models/User";
import { AppState } from "../../store";
import NavSection from "./NavSection";
import { CalendarViewMonth, CorporateFare, FlightTakeoff, PersonAddAlt, PinDrop } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import getInitials from "../../utils/getInitials";
import Airfield from "../../icons/Airfield";
import ModalChangeTenant from "../ModalChangeTenant";
import { userCanUseIfRoles } from "../../utils/common";
import { menuState, openDrawer } from "../../store/slices/menu";
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AccountBoxIcon from '@mui/icons-material/AccountBox';


interface SidebarProps {
}

const Sidebar: FC<SidebarProps> = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [currentTenantLogo, setCurrentTenantLogo] = useState<string | undefined>(undefined);
  const [haveTenant, setHaveTenant] = useState<boolean>(false);


  var currentTheme = useTheme();
  const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

  let { drawerOpen } = useSelector(menuState)
  const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  useEffect(() => {
    let currentTenant = user?.tenants?.find(tenant => tenant.id == user?.currentTenantId);
    setCurrentTenantLogo(currentTenant?.logo);
    setHaveTenant(user?.tenantId != "000000000000000000000000")
  }, []);

  useEffect(() => {
    if (mobileDevice) {
      dispatch(openDrawer(false))
    }
  }, [mobileDevice])


  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sections = [
    {
      title: t("AIRFIELD"),
      visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER', 'VIEWER']) && haveTenant,
      items: [
        {
          title: t("FLIGHTS"),
          path: "/flights",
          icon: <FlightTakeoff fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER', 'VIEWER']) && haveTenant
        },
        {
          title: t("CALENDAR"),
          path: `/flights/calendar`,
          icon: <CalendarViewMonth fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER', 'VIEWER']) && haveTenant
        },
        {
          title: t("USERS"),
          path: "/users",
          icon: <PersonAddAlt fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR']) && haveTenant
        },
        {
          title: t("MY_OWN_AIRIFELD"),
          path: `/tenants/detail?tenantId=${user?.currentTenantId}`,
          icon: <PinDrop fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR']) && haveTenant
        },
      ]
    },

    {
      title: t("PILOT"),
      visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['PILOT', 'SYSTEMADMIN', 'ADMINISTRATOR', 'USER']),
      items: [
        {
          title: t("MY_FLIGHTS"),
          path: "/pilotflights",
          icon: <FlightTakeoff fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['PILOT', 'SYSTEMADMIN', 'ADMINISTRATOR', 'USER']),
        },
        {
          title: t("MY_CALENDAR"),
          path: "/pilotflights/calendar",
          icon: <CalendarViewMonth fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['PILOT', 'SYSTEMADMIN', 'ADMINISTRATOR', 'USER']),
        },
        {
          title: t("MY_PROFILE"),
          path: `/pilotUser/edit`,
          icon: <AccountBoxIcon fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['PILOT', 'SYSTEMADMIN', 'ADMINISTRATOR', 'USER'])
        },
        {
          title: t("MY_AIRCRAFTS"),
          path: `/myAircrafts`,
          icon: <AirplanemodeActiveIcon fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['PILOT', 'SYSTEMADMIN', 'ADMINISTRATOR', 'USER'])
        },]
    },
    {
      title: t("CONFIGURATION"),
      visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN']),
      items: [

        {
          title: t("AIRFIELDS"),
          path: "/airfields",
          icon: <Airfield fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN'])
        },
        {
          title: t("TENANTS"),
          path: "/tenants",
          icon: <CorporateFare fontSize="small" />,
          visible: userCanUseIfRoles(user?.tenantUser?.role?.name, ['SYSTEMADMIN'])
        },
      ],
    },

  ];

  const handleAddClick = (): void => {
    setIsModalOpen(true);
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  const content = (
    <Box
      sx={{
        pt: 5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Scrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 2,
              }}
            >
              <RouterLink to="/"></RouterLink>
            </Box>
          </Hidden>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                alignItems: "center",
                backgroundColor: "#363e48",
                borderRadius: 1,
                display: "flex",
                overflow: "hidden",
                p: 2,
              }}
            >
              <RouterLink to="/">
                <Avatar
                  src={user?.tenantUser?.avatar}
                  sx={{
                    height: 48,
                    width: 48,
                  }}
                  //@ts-ignore
                  {...(user ? getInitials(`${user.name} ${user.surname}`) : "")}
                />
              </RouterLink>
              <Box sx={{ ml: 2 }}>
                <Typography color="textPrimary" variant="subtitle2">
                  {user?.name} {user?.surname}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {user && user.tenants && user.tenants.length > 1 && (
                    <Button onClick={handleAddClick}>{user?.tenantName}</Button>
                  )}
                  {user && user?.tenants?.length === 1 && user?.tenantName}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            {sections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                {...section}
              />
            ))}
          </Box>
        </Scrollbar>
      </Box>
      {haveTenant == true &&
        <Box sx={{ p: 2 }}>
          <Avatar
            src={currentTenantLogo}
            sx={{
              height: 35,
              width: 35,
            }}
          />
        </Box>
      }
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(false))}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open={drawerOpen}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog fullWidth maxWidth="sm" onClose={handleModalClose} open={isModalOpen}>
        {isModalOpen && <ModalChangeTenant onAddComplete={handleModalClose} onCancel={handleModalClose} />}
      </Dialog>
    </>
  );
};

export default Sidebar;
