import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {
    Autocomplete,
    CircularProgress,
    debounce,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import {DocumentTypeService} from "../../services/DocumentTypeService";
import {useTranslation} from "react-i18next";
import {FormHelperText, Skeleton} from "@mui/material";
import {TenantService} from "../../services/TenantService";
import {AirfieldService} from "../../services/AirfieldService";
import {Airfield} from "../../models/Airfield";
import {MobileDateTimePicker} from "@material-ui/lab";
import {isEmpty} from "../../utils/common";
import {FlightType} from "../../models/FlightType";
import {FlightTypeService} from "../../services/FlightTypeService";
import PerfectScrollbar from 'react-perfect-scrollbar';


interface AirfieldChooseProps {
    formik: any,
}

const AirfieldChoose: FC<AirfieldChooseProps> = (props) => {
    const { formik } = props
    const { t } = useTranslation();

    const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
    const [options, setOptions] = useState<Airfield[]>([]);
    const [openArrival, setOpenArrival] = useState(false);
    const [onFocusArrival, setOnFocusArrival] = useState(false);
    const [departureLoadingOptions, setDepartureLoadingOptions] = useState<boolean>(false);
    const [departureOptions, setDepartureOptions] = useState<string[]>([]);
    const [onFocusDeparture, setOnFocusDeparture] = useState(false);
    const [openDeparture, setOpenDeparture] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [flightTypes, setFlightTypes] = useState<FlightType[]>([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const airfieldService = new AirfieldService();
    const flightTypeService = new FlightTypeService();

    const handleAutocompleteChange = (airfield: Airfield, backupValue: string) => {
        if (airfield != null) {
            formik.setValues({ ...formik.values, arrivalAirfieldName: airfield.name, tenantId: airfield.tenantId })
        }
        else {
            formik.setValues({ ...formik.values, arrivalAirfieldName: backupValue })
        }
    }

    const handleDepartureAutocompleteChange = (backupValue: string) => {
        formik.setValues({ ...formik.values, departureAirfieldName: backupValue })
    }

    const getRegistredAirfields = useCallback(debounce(async (searchFilter: string = '') => {
        let retrievedAirfields = await airfieldService.getAllRegistredAirfields(searchFilter);
        setOptions(retrievedAirfields.items);
        setLoadingOptions(false);
        setIsLoading(false)
    }, 700
    ), [])

    const getAirfields = useCallback(debounce(async (searchFilter: string = '') => {
        let retrievedAirfields = await airfieldService.getAllAirfields(searchFilter);
        let airfieldOptions: Array<string> = retrievedAirfields.items
            .map((airfield: Airfield) => airfield.name);
        setDepartureOptions(airfieldOptions);
        setDepartureLoadingOptions(false);
        setIsLoading(false)
    }, 700
    ), [])


    const handleDateChange = async (date: any, name: string) => {
        let value = isEmpty(date) ? '' : date.format();
        await formik.setValues({ ...formik.values, [name]: value })
    }

    const getFlightTypes = async () => {
        let retrievedFlightTypes = await flightTypeService.getAllFlightTypes();
        setFlightTypes(retrievedFlightTypes);
        let defaultFlightTypeId = retrievedFlightTypes.find(flightType => flightType.name == 'TOURISTIC')?.id;
        await formik.setValues({ ...formik.values, flightTypeId: defaultFlightTypeId })
    }

    useEffect(() => {
        (async () => {
            setLoadingOptions(true);
            await getRegistredAirfields(formik.values.arrivalAirfieldName);
        })()
    }, [formik.values.arrivalAirfieldName])

    useEffect(() => {
        (async () => {
            setDepartureLoadingOptions(true);
            await getAirfields(formik.values.departureAirfieldName);
        })()
    }, [formik.values.departureAirfieldName])


    useEffect(() => {
        (async () => {
            await getFlightTypes();
        })()
    }, [])

    return <>
        <PerfectScrollbar style={{ maxHeight: isMobile ? 'calc(100vh - 255px)' : 'calc(100vh - 255px)', overflowX: "hidden", }} >
            <Grid item xs={12} sx={{ mb: 2 }}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={70} />
                ) : (
                    <Autocomplete
                        freeSolo
                        id="departureAirfield"
                        options={departureOptions}
                        getOptionLabel={departureOption => departureOption}
                        renderInput={(params) =>
                            <TextField {...params} required name="departureAirfield"
                                error={Boolean(formik.errors.departureAirfieldName)}
                                helperText={formik.errors.departureAirfieldName}
                                fullWidth label={t('DEPARTURE_PLACE')} size='medium'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <Fragment>
                                            {departureLoadingOptions && onFocusDeparture ? <CircularProgress color="info" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </Fragment>
                                    ),
                                }}
                            />}
                        // onKeyUp={(event: any) => {
                        //     setDepartureSearchFilter(event.target.value);
                        // }}
                        onInputChange={(event: any, value: string) => {
                            handleDepartureAutocompleteChange(value)
                        }}
                        onFocus={() => { setOnFocusDeparture(true) }}
                        onBlur={() => { setOnFocusDeparture(false); }}
                        onOpen={() => { setOpenDeparture(true) }}
                        onClose={() => { setOpenDeparture(false) }}
                        open={openDeparture}
                        value={formik.values.departureAirfieldName}
                        isOptionEqualToValue={(option, value) => option === value}

                        filterOptions={(options, params) => {
                            const { inputValue } = params;
                            const filtered = [...options];

                            if (inputValue !== '') {
                                filtered.push(inputValue);
                            }

                            return filtered;
                        }
                        }
                    />
                )}
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }}>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={70} />
                ) : (
                    <Autocomplete
                        freeSolo
                        id="arrivalAirfield"
                        options={options}
                        getOptionLabel={option => option.name}
                        renderInput={(params) =>
                            <TextField {...params} required name="arrivalAirfield"
                                error={Boolean(formik.errors.arrivalAirfieldName)}
                                helperText={formik.errors.arrivalAirfieldName}
                                fullWidth label={t('ARRIVAL_PLACE')} size='medium'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <Fragment>
                                            {loadingOptions && onFocusArrival ? <CircularProgress color="info" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </Fragment>
                                    ),
                                }}
                            />}
                        // onKeyUp={(event: any) => {
                        //     setSearchFilter(event.target.value);
                        // }}
                        onInputChange={(event: any, value: string) => {
                            var airfieldFounded = options.find(option => option.name === value)
                            handleAutocompleteChange(airfieldFounded!, value)
                        }}
                        onFocus={() => { setOnFocusArrival(true) }}
                        onBlur={() => { setOnFocusArrival(false); }}
                        onOpen={() => { setOpenArrival(true) }}
                        onClose={() => { setOpenArrival(false) }}
                        open={openArrival}
                        value={options.find(option => option.name === formik.values.arrivalAirfieldName) || null}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                    />
                )}
            </Grid>



            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ mb: 1 }}
            >
                {isLoading ?
                    <Skeleton sx={{ mb: 1 }} animation="wave" variant="text" width="100%" height={70} />
                    :
                    <MobileDateTimePicker
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                        label={t('ARRIVAL_TIME')}
                        onChange={(date) => { handleDateChange(date, 'date'); }}
                        value={formik.values.date}
                        renderInput={(params) => (
                            <TextField name="date"
                                required fullWidth {...params}
                                error={Boolean(formik.errors.date)}
                                helperText={formik.errors.date}
                                size='medium'
                                sx={{ mt: 1 }}
                            />
                        )}
                    />
                }
            </Grid>

            <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ mb: 1 }}
            >
                {isLoading ?
                    <Skeleton sx={{ mb: 1 }} animation="wave" variant="text" width="100%" height={70} />
                    :
                    <MobileDateTimePicker
                        ampm={false}
                        inputFormat="DD/MM/YYYY HH:mm"
                        label={t('NEXT_DEPARTURE_TIME')}
                        onChange={(nextMovementDate) => {
                            handleDateChange(
                                nextMovementDate, 'nextMovementDate');
                        }}
                        value={formik.values.nextMovementDate}
                        renderInput={(params) => (
                            <TextField name="date"
                                fullWidth {...params}
                                error={Boolean(formik.errors.nextMovementDate)}
                                helperText={formik.errors.nextMovementDate}
                                size='medium'
                                sx={{ mt: 1 }}
                            />
                        )}
                    />
                }
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                {isLoading ? (
                    <Skeleton sx={{ mb: 1 }} animation="wave" variant="text" width="100%" height={70} />
                ) : (
                    <FormControl required fullWidth size='medium' sx={{ mt: 2 }}>
                        <InputLabel>{t('FLIGHT_TYPE')}</InputLabel>
                        <Select
                            label={t('FLIGHT_TYPE')}
                            name="flightTypeId"
                            onChange={formik.handleChange}
                            value={formik.values.flightTypeId}
                            error={Boolean(formik.errors.flightTypeId)}
                            size="medium"
                        >
                            {flightTypes.map((flightType: FlightType) => (
                                <MenuItem key={flightType.id} value={flightType.id}>
                                    {t(flightType.name)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{formik.errors.pilotDocumentTypeId}</FormHelperText>
                    </FormControl>
                )}
            </Grid>

            <Grid
                item
                lg={12}
                md={12}
                xs={12}
            >
                {isLoading ? (
                    <Skeleton sx={{ mb: 1 }} animation="wave" variant="text" width="100%" height={70} />
                ) :
                    <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        size='medium'
                        label={t('PEOPLE_ON_BOARD')}
                        name="peopleOnBoard"
                        type="number"
                        required
                        onChange={formik.handleChange}
                        value={formik.values.peopleOnBoard}
                        error={Boolean(formik.errors.peopleOnBoard)}
                        InputProps={{ inputProps: { min: 1 } }}
                    />

                }
            </Grid>
        </PerfectScrollbar>
    </ >
}
export default AirfieldChoose;
