import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const LicensePlate = createSvgIcon(
    <svg fill="#fffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
	 viewBox="0 0 512.001 512.001">
<g>
	<g>
		<path d="M466.765,122.029H45.235C20.292,122.029,0,142.322,0,167.264v177.474c0,24.943,20.292,45.235,45.235,45.235h421.531
			c24.942,0,45.235-20.292,45.235-45.235V167.264C512,142.322,491.708,122.029,466.765,122.029z M478.609,344.739
			c0,6.53-5.313,11.844-11.844,11.844H45.235c-6.53,0-11.844-5.313-11.844-11.844v-2.477h445.217V344.739z M478.609,308.871H33.391
			V203.132h445.217V308.871z M478.61,169.74H33.391v-2.477c0-6.53,5.313-11.844,11.844-11.844h421.531
			c6.53,0,11.844,5.313,11.844,11.844V169.74z"/>
	</g>
</g>
<g>
	<g>
		<path d="M115.594,222.468H102.39l-22.146,59.336h17.299l3.511-11.617h15.795l3.593,11.617h17.217L115.594,222.468z
			 M103.475,259.324l5.516-19.89l5.265,19.89H103.475z"/>
	</g>
</g>
<g>
	<g>
		<path d="M181.526,251.385c5.683-2.508,8.525-8.191,8.525-13.706c0-7.522-5.181-15.21-13.539-15.21H142.08v59.336h29.167
			c11.7-0.001,21.31-4.513,21.31-15.211C192.558,258.322,188.296,253.39,181.526,251.385z M158.377,236.341h11.617
			c2.089,0,3.928,1.253,3.928,4.512c0,3.677-2.257,4.597-4.43,4.597h-11.115V236.341z M171.247,268.099h-12.87v-9.945h13.456
			c2.423,0,4.178,2.173,4.178,5.098C176.011,266.345,173.922,268.099,171.247,268.099z"/>
	</g>
</g>
<g>
	<g>
		<path d="M235.008,260.578c-1.672,5.348-6.351,7.27-10.112,7.27c-7.522,0-11.951-7.437-11.951-15.712
			c0-7.437,3.594-15.545,11.784-15.545c3.677,0,8.608,1.588,10.53,7.355l12.452-8.859c-3.761-7.772-12.118-12.954-22.648-12.954
			c-18.052,0-28.665,15.044-28.665,29.501c-0.001,15.21,11.616,30.671,28.163,30.671c9.444,0,20.392-5.014,23.735-13.789
			L235.008,260.578z"/>
	</g>
</g>
<g>
	<g>
		<rect x="253.473" y="251.638" width="26.159" height="14.208"/>
	</g>
</g>
<g>
	<g>
		<path d="M317.316,267.598v-45.547h-16.214c-0.167,0.335-7.772,7.939-13.789,7.939v14.541c4.514,0,11.952-4.596,13.791-7.187
			v30.254h-11.867v14.208h38.694v-14.208H317.316z"/>
	</g>
</g>
<g>
	<g>
		<path d="M356.591,267.597c0-3.593,5.432-6.268,10.195-9.026c7.438-4.345,11.784-8.525,11.784-17.717
			c0-12.201-7.771-19.054-21.31-19.054c-10.864,0-19.89,4.43-24.32,9.444l9.778,10.614c3.176-3.092,7.939-6.017,12.285-6.017
			c2.842,0,5.432,1.253,5.432,5.265c0.001,6.101-6.016,9.026-12.954,12.285c-12.703,6.017-14.542,13.037-14.542,28.415h45.715
			v-14.208H356.591z"/>
	</g>
</g>
<g>
	<g>
		<path d="M416.509,250.047c6.269-1.254,10.781-5.683,10.781-12.118c0-9.194-9.193-16.213-21.562-16.213
			c-7.522,0-16.464,2.591-20.893,7.522l10.029,11.784c1.503-1.923,4.679-5.434,10.947-5.434c0.919,0,4.43,0.083,4.43,3.176
			c0,4.178-6.601,5.6-13.956,5.6h-2.675v13.121h2.758c9.862,0,15.377,1.337,15.377,5.683c0,3.511-3.677,4.847-7.437,4.847
			c-7.605,0-9.945-5.516-10.614-6.184l-8.106,11.031c3.844,6.686,10.195,9.695,19.055,9.695c11.115,0,23.651-4.764,23.651-17.215
			C428.292,257.903,423.78,251.217,416.509,250.047z"/>
	</g>
</g>
</svg>, 'LicensePlate'

);

export default LicensePlate;