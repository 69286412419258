import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { DefaultUser, User } from "../../models/User";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Role } from "../../models/Role";
import { RoleService } from "../../services/RoleService";
import { UserService } from "../../services/UserService";
import { UserValidator } from "../../validators/UserValidator";
import { composeNotistackMessage, getFormValue } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import AvatarDropzone from "../AvatarDropzone";
import PermissionsGuard from "../PermissionGuard";
import Resizer from "react-image-file-resizer";


interface UserUpsertFormProps {
  isAdd: boolean
}

const UserUpsertForm: FC<UserUpsertFormProps> = ({ isAdd }) => {
  const [user, setUser] = useState<User>(DefaultUser);

  const [roles, setRoles] = useState<Array<Role>>([]);

  const { t } = useTranslation();

  const fieldsNotToCheck = [
    //TODO: Remove all from here
    'id',
    'currentTenantId',
    'tenants',
    'tenantName',
    'preferredLanguage',
    'avatar',
    'role',
    'tenantUser',
    'tenantId',
  ];

  const userService = new UserService();
  const userValidator = new UserValidator(t);
  const roleService = new RoleService();

  const history = useNavigate();

  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      let retrievedRoles = await roleService.getAllRoles(loggedUser?.id, loggedUser?.currentTenantId);
      setRoles(retrievedRoles);

      let userId: string = window.location.href.split('userId=')[1] ?? '';
      if (!isAdd) {
        let retrievedUser: User = await userService.getUserById(userId, loggedUser?.currentTenantId);
        retrievedUser.roleId = retrievedUser.tenantUser.roleId;
        setUser(retrievedUser);
        setAvatar(retrievedUser.tenantUser.avatar);
      } else if (loggedUser !== undefined) {
        user.currentTenantId = loggedUser.currentTenantId;
      }
      setUser(prevState => ({ ...prevState, tenantId: loggedUser?.currentTenantId }));
    })();
  }, []);

  const setAvatar = (avatar: string | undefined) => {
    setUser((currentFormData: any) => ({
      ...currentFormData,
      avatar: avatar
    }));
  }

  async function userUpsert() {
    let validateUser: boolean = userValidator.validateUser(user, fieldsNotToCheck);

    if (validateUser) {
      let userUpserResult: boolean = await userService.userUpsert(user);

      if (userUpserResult) {
        composeNotistackMessage(isAdd ? t('USER_ADDED') : t('USER_UPDATED'), 'success');

        // TODO: Used to change permission without refresh. To fix

        history('/users');
      } else {
        composeNotistackMessage(isAdd ? t('USER_NOT_ADDED') : t('USER_NOT_UPDATED'), 'error');
      }
    }
  }

  const handleChange = (e: any) => {
    let { name, value } = getFormValue(e);
    setUser((currentFormData: any) => ({
      ...currentFormData,
      [name]: value
    }));
  }

  function base64ToBlob(base64String) {
    const parts = base64String.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const byteCharacters = atob(parts[1]);

    const arrayBuffer = new ArrayBuffer(byteCharacters.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: contentType });
  }

  const handleDrop = (newFiles: any): void => {
    var reader = new FileReader();
    reader.readAsDataURL(newFiles[0]);
    reader.onload = () => {
      var blobImage = base64ToBlob(reader.result);
      Resizer.imageFileResizer(
        blobImage,
        128,
        128,
        'JPEG',
        100,
        0,
        uri => {
          setAvatar(uri as string);
          //setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        },
        'base64',
        64,
        64);


    }
  };

  const handleRemove = (): void => {
    setFiles([]);
    setAvatar(undefined);
  };

  return <>
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
      environment={'FlightsList'}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <Card>
            <CardHeader title={t('AVATAR')} />
            <Divider />
            <CardContent>
              {user.avatar
                ? <div style={{ textAlign: 'center' }}>
                  <img src={user.avatar} alt="avatar"
                    style={{
                      padding: '20px', objectFit: 'cover', borderRadius: "100%", minHeight: '128px', width: '150px',
                      height: '150px'
                    }} />
                  <Divider />
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2
                  }}>
                    <Button
                      sx={{
                        backgroundColor: 'error.main',
                        color: 'error.contrastText',
                        '&:hover': {
                          backgroundColor: 'error.dark'
                        }
                      }}
                      variant="contained"
                      onClick={handleRemove}
                    >
                      {t('DELETE')}
                    </Button>
                  </Box>
                </div>
                : <Box sx={{ mt: 3 }}>
                  <AvatarDropzone
                    accept="image/*"
                    files={files}
                    maxFiles={1}
                    maxSize={5000000}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                  />
                </Box>}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={9}
          xs={12}
        >
          <Card>
            <CardHeader title={t('USER_DATA')} />
            <Divider />
            <CardContent sx={{ input: { color: 'white' } }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    disabled={!isAdd}
                    label={t('EMAIL')}
                    name="email"
                    required
                    onChange={handleChange}
                    value={user.email}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('FIRST_NAME')}
                    name="name"
                    required
                    onChange={handleChange}
                    value={user.name}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('SURNAME')}
                    name="surname"
                    required
                    onChange={handleChange}
                    value={user.surname}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <FormControl fullWidth required size='small'>
                    <InputLabel>{t('ROLE')}</InputLabel>
                    <Select
                      label={t('ROLE')}
                      name="roleId"
                      onChange={handleChange}
                      defaultValue={''}
                      sx={{ color: 'white' }}
                      value={user.roleId}
                    >
                      <MenuItem value={''}>{t('SELECT_ROLE')}</MenuItem>
                      {roles
                        .slice()
                        .sort((a: Role, b: Role) => a.name.localeCompare(b.name))
                        .map((option) => (
                          option.name != "PILOT" &&
                          <MenuItem key={option.id} value={option.id}>
                            {t(option.name)}
                          </MenuItem>

                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={userUpsert}
              >
                {t('SAVE')}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </PermissionsGuard>
  </>;
}

export default UserUpsertForm;
