const SET_LOADING_STATE = 'SET_LOADING_STATE';
const SET_TITLE_PAGE = 'SET_TITLE_PAGE';
const SET_LANGUAGE = 'SET_LANGUAGE';

// STATE - This defines the type of data maintained in the Redux store.
export interface AppStatusState {
  isLoading: boolean;
  language: string;
  titlePage: string;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface SetLoadingStateAction {
  type: typeof SET_LOADING_STATE;
  isLoading: boolean;
}
interface SetTitlePageAction {
  type: typeof SET_TITLE_PAGE;
  titlePage: string;
}
interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  language: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AppStatusActionTypes = SetLoadingStateAction | SetTitlePageAction | SetLanguageAction;

const initialState: AppStatusState = {
  isLoading: false,
  titlePage: "SmartAirfield.Portal",
  language: "it"
};

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  setIsLoading: (isLoading: boolean): AppStatusActionTypes => ({
    type: SET_LOADING_STATE,
    isLoading: isLoading
  }),
  setTitlePage: (titlePage: string): AppStatusActionTypes => ({
    type: SET_TITLE_PAGE,
    titlePage: titlePage
  }),
  setLanguage: (language: string): AppStatusActionTypes => ({
    type: SET_LANGUAGE,
    language: language
  }),
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer = (state = initialState, action: AppStatusActionTypes): AppStatusState => {
  switch (action.type) {
    case SET_LOADING_STATE:
      return { ...state, isLoading: action.isLoading };
    case SET_TITLE_PAGE:
      return { ...state, titlePage: action.titlePage };
    case SET_LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
};
