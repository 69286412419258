import {Outlet} from "react-router-dom";
import {Theme, useTheme} from "@material-ui/core/styles";
import Navbar from "./drawer/Navbar";
import Sidebar from "./drawer/Sidebar";
import {Box, styled} from "@material-ui/system";
import {useSelector} from "react-redux";
import {menuState} from "../store/slices/menu";
import {CssBaseline} from "@material-ui/core";

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

const drawerWidth = 280;

const Layout = () => {
    const theme = useTheme();

    const {drawerOpen} = useSelector(menuState)

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            {/* header */}
            <Navbar/>
            {/* day-selected-drawer */}
            <Sidebar/>
            {/* main content */}
            <Main theme={theme} open={drawerOpen}>
                <Outlet/>
            </Main>
        </Box>
    );
};

export default Layout;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}: MainStyleProps) => ({
    backgroundPositionY: 'bottom',
    backgroundRepeat: 'no-repeat',
    ...(!open && {
        marginTop: '80px !important',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            width: `100%`
        },
        [theme.breakpoints.down('md')]: {
            width: `100%`,
            padding: '0px'
        },
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            padding: '0px',
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginTop: '80px !important',
        marginLeft: `${drawerWidth}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        [theme.breakpoints.down('md')]: {
            marginLeft: "20px",
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));
