import { User } from "../models/User";
import { isEmpty } from "../utils/common";
import { hasErrors } from "../utils/validation";

export class UserValidator {
  public errors = {
    name: '',
    surname: '',
    roleId: '',
    email: '',
    phoneNumber: '',
    documentValue: '',
  };

  private t: Function;

  constructor(t: Function) {
    this.t = t;
  }

  validateUser = (user: User, fieldsNotToCheck: Array<string> = []): boolean => {
    this.validateName(user.name);
    this.validateSurname(user.surname);
    this.validateRoleId(user.roleId);
    this.validateEmail(user.email);


    return !hasErrors(this.errors, fieldsNotToCheck);
  }

  validateUserToCreate = (user: any): boolean => {
    this.validateName(user.name);
    this.validateSurname(user.surname);
    this.validateEmail(user.email);
    this.validatePhoneNumber(user.phoneNumber);

    return !hasErrors(this.errors);
  }

  validateUserPilot = (user: any, fieldsNotToCheck: Array<string> = []): boolean => {
    this.validateName(user.name);
    this.validateSurname(user.surname);
    this.validateEmail(user.email);
    //this.validatePhoneNumber(user.phoneNumber);
    this.validateDocumentValue(user.documentValue, user);
    this.validateDocumentType(user.documentTypeId, user);


    return !hasErrors(this.errors, fieldsNotToCheck);
  }

  validatePhoneNumber = (phoneNumber: string | undefined | null) => {
    if (isEmpty(phoneNumber)) {
      this.errors.phoneNumber = this.t('PHONE_NUMBER_REQUIRED');
    }
  }

  validateName = (name: string) => {
    if (isEmpty(name)) {
      this.errors.name = this.t('NAME_REQUIRED');
    }
  }

  validateSurname = (surname: string) => {
    if (isEmpty(surname)) {
      this.errors.surname = this.t('SURNAME_REQUIRED');
    }
  }

  validateRoleId = (roleId: string) => {
    if (isEmpty(roleId)) {
      this.errors.roleId = this.t('ROLE_REQUIRED');
    }
  }

  validateEmail = (email: string) => {
    if (isEmpty(email)) {
      this.errors.email = this.t('EMAIL_REQUIRED');
    }
  }

  validateDocumentValue = (documentValue: string, user: User) => {
    if (isEmpty(documentValue) && user.documentTypeId != "000000000000000000000000") {
      this.errors.email = this.t('DOCUMENT_VALUE_REQUIRED');
    }
  }
  validateDocumentType = (documentTypeId: string, user: User) => {
    if (!isEmpty(user.documentValue) && documentTypeId == "000000000000000000000000") {
      this.errors.email = this.t('DOCUMENT_TYPE_REQUIRED');
    }
  }
}
