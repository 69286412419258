import {Container} from "@material-ui/core";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import PermissionsGuard from "../../components/PermissionGuard";
import PilotFlightUpsertCard from "../../components/flight/PilotFlightUpsertCard";

interface PilotFlightUpsertProps {
    isAdd: boolean
}

const PilotFlightUpsert: FC<PilotFlightUpsertProps> = (props) => {
    const {isAdd} = props;
    const {t} = useTranslation();

    return (
        <PermissionsGuard
            allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
            environment={'PilotFlightUpsert'}
        >
            <Container maxWidth={false}>
                <PilotFlightUpsertCard isAdd={isAdd}/>
            </Container>
        </PermissionsGuard>
    );
}

export default PilotFlightUpsert;
