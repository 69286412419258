import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableRow,
    Typography,
} from '@material-ui/core';
import PencilAltIcon from '../../icons/PencilAlt';
import {Flight} from '../../models/Flight';
import ArrowRightIcon from '../../icons/ArrowRight';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import TableRowsLoader from '../TableRowsLoader';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

import {
    CheckCircle,
    FlightLand,
    FlightTakeoff,
    RemoveCircle,
    SupportAgent,
    ThumbDown,
    ThumbUp
} from '@material-ui/icons';
import {userCanUseIfRoles} from '../../utils/common';
import InformationCircle from '../../icons/InformationCircle';
import Airfield from '../../icons/Airfield';
import LicensePlate from '../../icons/LicensePlate';
import FlightLogModal from './FlightLogModal';
import {Divider} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {format, isToday} from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";


interface FlightWithMenuProps {
    flight: Flight;

    updateIsCarriedFlight(flightId: string, isCarried: boolean): void;

    updateApprovedFlight(flightId: string, approved: boolean): void;

    loggedUser: any;
}

const FlightWithMenu: FC<FlightWithMenuProps> = ({flight, updateApprovedFlight, updateIsCarriedFlight, loggedUser}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [approvedMenuAnchorEl, setApprovedMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [carriedMenuAnchorEl, setCarriedMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const OpenModal = () => {
        setModalIsOpen(true);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Divider/>
            {modalIsOpen &&
                <FlightLogModal
                    airfieldId={flight.id!}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}/>}

            <TableRow key={flight.id} sx={{display: "block"}}>

                <Grid container spacing={1} sx={{mt: 2, px: 0}}>
                    <Grid item xs={9} onClick={() => navigate(`detail?flightId=${flight.id}`)}>
                        <Grid container direction="column">
                            <Grid container direction="row" style={{flexFlow: "noWrap"}} spacing={1} sx={{mb: 1}}>
                                <Grid item>
                                    {flight.tenantIsDeparture
                                        ? <FlightTakeoff fontSize='small'/>
                                        : <FlightLand fontSize='small'/>}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        align="center">
                                        {flight.tenantIsDeparture ? t('DEPARTURING') : t('ARRIVING')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={1} style={{flexFlow: "noWrap"}} sx={{mb: 1}}>
                                <Grid item>
                                    <Airfield fontSize='small'/>
                                </Grid>
                                <Grid item>
                                    {windowWidth < 450
                                        ? <Typography variant="body2">
                                            {flight.tenantIsDeparture ? flight.arrivalAirfieldName : flight.departureAirfieldName}
                                        </Typography>
                                        : <Typography variant="body2" align="center">
                                            {flight.tenantIsDeparture ? flight.arrivalAirfieldName : flight.departureAirfieldName}
                                        </Typography>}

                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{flexFlow: "noWrap"}} spacing={1} sx={{mb: 1}}>
                                <Grid item>
                                    <SupportAgent fontSize='small'/>
                                </Grid>
                                <Grid item>
                                    {windowWidth < 450 ?
                                        <>
                                            <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                <Typography variant="body2">
                                                    {flight.pilotFullName}
                                                </Typography>
                                            </div>
                                        </>
                                        : <Typography variant="body2" align="center">
                                            {flight.pilotFullName}
                                        </Typography>}
                                </Grid>
                            </Grid>

                            <Grid container direction="row" style={{flexFlow: "noWrap"}} spacing={1} sx={{mb: 1}}>
                                <Grid item>
                                    <LicensePlate fontSize='small'/>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        align="center">
                                        [{t(flight.aircraftTipologyName)}] {flight.aircraftRegistration}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={3}>

                        <Box sx={{
                            backgroundColor: "#202427", borderRadius: "12px",
                            p: "4px 2px"
                        }}>
                            <Typography
                                color="textSecondary"
                                variant="body2"
                                align="right">
                                {<>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {!isToday(new Date(flight.date))
                                            ? t(format(new Date(flight.date), 'EEEE').toUpperCase())
                                            : t("TODAY")}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {t(format(new Date(flight.date), 'LLLL').toUpperCase() + "_SHORT")}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {format(new Date(flight.date), 'd')}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        color="textSecondary"
                                        variant="subtitle2"
                                    >
                                        {format(new Date(flight.date), 'HH:mm').toUpperCase()}
                                    </Typography>

                                </>}
                            </Typography>
                        </Box>

                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{mx: 3}}/>
                        <Grid container sx={{
                            verticalAlign: 'bottom', textAlign: 'center', mt: 0.5
                        }}>
                            {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) &&
                                (<>
                                        <Grid item xs={4} sx={{mb: 1}}>
                                            {flight.approved == null ?

                                                <Button
                                                    size="small"
                                                    sx={{mr: 1, width: "100%"}}
                                                    disableFocusRipple
                                                    color="primary"
                                                    onClick={(event: any) => setApprovedMenuAnchorEl(event.currentTarget)}
                                                >
                                                    <Box sx={{
                                                        display: "flex", flexDirection: "column",
                                                        alignItems: "center",
                                                        color: "#AAAAAA"
                                                    }}>
                                                        <ThumbUp/>
                                                        {t("UPDATE_FLIGHT_STATE")}
                                                    </Box>
                                                </Button>
                                                :
                                                <Button
                                                    size="small"
                                                    sx={{mr: 1, width: "100%"}}
                                                    disableFocusRipple
                                                    onClick={(event: any) => setApprovedMenuAnchorEl(event.currentTarget)}
                                                >
                                                    <Box sx={{
                                                        display: "flex", flexDirection: "column",
                                                        alignItems: "center",
                                                        color: "#AAAAAA"
                                                    }}>
                                                        {flight.approved ?
                                                            <>
                                                                <ThumbUp sx={{color: "#4CB071"}}/>
                                                                {t('APPROVED')}
                                                            </> :
                                                            <>
                                                                <ThumbDown sx={{color: "#F44336"}}/>
                                                                {t('REJECTED')}
                                                            </>}
                                                    </Box>
                                                </Button>}
                                        </Grid>
                                        <Menu
                                            id="flights-approved-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'flight-approved-button',
                                            }}
                                            anchorEl={approvedMenuAnchorEl}
                                            open={Boolean(approvedMenuAnchorEl)}
                                            onClose={() => {
                                                setApprovedMenuAnchorEl(null)
                                            }}>
                                            <MenuItem disabled={flight.approved == true} key={'flightApproved'}
                                                      onClick={() => {
                                                          setApprovedMenuAnchorEl(null);
                                                          updateApprovedFlight(flight.id!, true)
                                                      }}>
                                                {<CheckCircle fontSize="small" color="success"/>}
                                                <Typography sx={{ml: 1}}
                                                            fontSize="small">{" " + t('APPROVE')}</Typography>

                                            </MenuItem>
                                            <MenuItem disabled={flight.approved == false} key={'flightNotApproved'}
                                                      onClick={() => {
                                                          setApprovedMenuAnchorEl(null);
                                                          updateApprovedFlight(flight.id!, false)
                                                      }}>
                                                {<RemoveCircle fontSize="small" color="error"/>}
                                                <Typography sx={{ml: 1}}
                                                            fontSize="small">{" " + t('REJECT')}</Typography>
                                            </MenuItem>
                                        </Menu>

                                        <Grid item xs={4} sx={{mb: 1}}>

                                            <Button
                                                sx={{mr: 1, width: "100%"}}
                                                id="isCarriedButton"
                                                size="small"
                                                disableFocusRipple
                                                onClick={(event: any) => setCarriedMenuAnchorEl(event.currentTarget)}
                                            >
                                                <Box sx={{
                                                    display: "flex", flexDirection: "column",
                                                    alignItems: "center",
                                                    color: "#AAAAAA"
                                                }}>
                                                    <AirplanemodeActiveIcon sx={{
                                                        color: flight.isCarried == true ?
                                                            "#4CAF50" : flight.isCarried == false ?
                                                                "#F44336" :
                                                                "#808080"

                                                    }}/>
                                                    {flight.isCarried == true ? t("IS_CARRIED") :
                                                        flight.isCarried == false ? t("IS_NOT_CARRIED") :
                                                            flight.tenantIsDeparture ? t("DEPARTURE") : t("LANDING")}
                                                </Box>
                                            </Button>
                                        </Grid>
                                        <Menu
                                            id="flights-carried-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'flight-carried-button',
                                            }}
                                            anchorEl={carriedMenuAnchorEl}
                                            open={Boolean(carriedMenuAnchorEl)}
                                            onClose={() => {
                                                setCarriedMenuAnchorEl(null)
                                            }}
                                        >
                                            <MenuItem disabled={flight.isCarried == true} key={'isCarriedTrue'}
                                                      onClick={() => {
                                                          setCarriedMenuAnchorEl(null);
                                                          updateIsCarriedFlight(flight.id!, true);
                                                      }}>

                                                <FlightTakeoff fontSize='small' sx={{mr: 1, color: '#4CAF50'}}/>
                                                <Typography variant="subtitle1">{t('IS_CARRIED')}</Typography>
                                            </MenuItem>
                                            <MenuItem disabled={flight.isCarried == false} key={'isCarriedFalse'}
                                                      onClick={() => {
                                                          setCarriedMenuAnchorEl(null)
                                                          updateIsCarriedFlight(flight.id!, false)
                                                      }}>

                                                <FlightTakeoff fontSize='small' sx={{mr: 1, color: '#F44336'}}/>
                                                <Typography variant="subtitle1">{t('IS_NOT_CARRIED')}</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )
                            }
                            <Grid item xs={4} sx={{mb: 1}}>
                                <Button
                                    size="small"
                                    onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                                    sx={{mr: 1, width: "100%"}}
                                    disableFocusRipple
                                >
                                    <Box sx={{
                                        display: "flex", flexDirection: "column",
                                        alignItems: "center",
                                        color: "#AAAAAA"
                                    }}>
                                        <MoreHorizIcon/>
                                        {t("MORE_OPTIONS")}
                                    </Box>
                                </Button>
                            </Grid>
                        </Grid>
                        <Menu
                            id="flights-actions-menu"
                            MenuListProps={{
                                'aria-labelledby': 'flight-action-button',
                            }}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => {
                                setMenuAnchorEl(null)
                            }}
                        >
                            {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) &&
                                (<MenuItem
                                    component={RouterLink}
                                    to={`edit?flightId=${flight.id}`}
                                    key={'edit'}
                                    onClick={() => {
                                        setMenuAnchorEl(null)
                                    }}>

                                    <PencilAltIcon fontSize="small" sx={{mr: '3px'}}/>
                                    <Typography variant="subtitle1">{t('EDIT')}</Typography>
                                </MenuItem>)}
                            {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'VIEWER']) &&
                                (<MenuItem
                                    key={'flightLog'}
                                    onClick={() => {
                                        setMenuAnchorEl(null);
                                        OpenModal();
                                    }}>
                                    <InformationCircle fontSize="small" sx={{mr: '3px'}}/>
                                    <Typography variant="subtitle1">{t('LOG_TEXT_LABEL')}</Typography>
                                </MenuItem>)}
                            {userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) &&
                                (<MenuItem
                                        key={'createReverseFlight'}
                                        onClick={() => {
                                            setMenuAnchorEl(null)
                                            navigate(`/flights/reverseFlightEdit?flightId=${flight.id}`)

                                        }}>
                                        {flight.tenantIsDeparture
                                            ? <FlightLand fontSize="small" sx={{mr: '3px'}}/>
                                            : <FlightTakeoff fontSize="small" sx={{mr: '3px'}}/>}
                                        <Typography variant="subtitle1">{t('RETURN_FLIGHT')}</Typography>
                                    </MenuItem>
                                )
                            }

                            <MenuItem
                                key={'details'}
                                onClick={() => {
                                    setMenuAnchorEl(null)
                                }}
                                component={RouterLink}
                                to={`detail?flightId=${flight.id}`}>
                                <ArrowRightIcon fontSize="small" sx={{mr: '3px'}}/>
                                <Typography variant="subtitle1">{t('DETAILS')}</Typography>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </TableRow>
        </>
    );
}

interface FlightsListTableMobileProps {
    paginatedFlights: PaginationResult<Flight> | undefined;
    isPageLoading: boolean;
    loggedUser: any;
    tenantIsDeparture: boolean | null;
    isRecordsLoading: boolean;

    updateIsCarriedFlight(flightId: string, isCarried: boolean): void;

    updateApprovedFlight(flightId: string, approved: boolean): void;

    getMoreFlights(): void;
}

const FlightsListTableMobile: FC<FlightsListTableMobileProps> = (props) => {

    const {
        paginatedFlights,
        isPageLoading,
        loggedUser,
        updateIsCarriedFlight,
        updateApprovedFlight,
        getMoreFlights,
        isRecordsLoading
    } = props;

    return (
        <>
            <Table>
                <TableBody>
                    {isPageLoading
                        ? <TableRowsLoader rowsNum={3} colsNum={1}/>
                        : (<Box sx={{overflow: "hidden"}}>
                            <InfiniteScroll
                                style={{overflow: "hidden"}}
                                dataLength={paginatedFlights?.items.length ? paginatedFlights?.items.length : 10}
                                next={getMoreFlights}
                                hasMore={(!isRecordsLoading &&
                                    paginatedFlights?.currentPage || 0) <= (paginatedFlights?.totalPages || 0)}
                                loader={<></>
                                }
                            >
                                {paginatedFlights?.items.map((flight) => (
                                    <FlightWithMenu
                                        flight={flight}
                                        updateApprovedFlight={updateApprovedFlight}
                                        updateIsCarriedFlight={updateIsCarriedFlight}
                                        loggedUser={loggedUser}
                                    />
                                ))}
                            </InfiniteScroll>
                        </Box>)
                    }
                </TableBody>
            </Table>
            {isRecordsLoading &&
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    mt: 4, mb: 1
                }}>
                    <CircularProgress/>
                </Box>
            }
        </>
    );
};

export default FlightsListTableMobile;
