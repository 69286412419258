import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField} from "@material-ui/core";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Airfield, DefaultAirfield} from "../../models/Airfield";
import {AirfieldService} from "../../services/AirfieldService";
import {composeNotistackMessage, getFormValue} from "../../utils/common";
import {AirfieldValidator} from "../../validators/AirfieldValidator";
import {useNavigate} from "react-router-dom";

interface AirfieldUpsertFormProps {
  isAdd: boolean
}

const AirfieldUpsertForm: FC<AirfieldUpsertFormProps> = ({ isAdd }) => {
  const [airfield, setAirfield] = useState<Airfield>(DefaultAirfield);

  const { t } = useTranslation();

  const airfieldService = new AirfieldService();
  const airfieldValidator = new AirfieldValidator(t);

  const history = useNavigate();

  async function upsertAirfield() {
    let validateAirfield: boolean = airfieldValidator.validateAirfield(airfield, fieldsNotToCheck);

    if (validateAirfield) {
      let upsertAirfieldResult: boolean = await airfieldService.upsertAirfield(airfield);

      if (upsertAirfieldResult) {
        composeNotistackMessage(isAdd ? t('AIRFIELD_ADDED') : t('AIRFIELD_UPDATED'), 'success');
        history('/airfields');
      } else {
        composeNotistackMessage(isAdd ? t('AIRFIELD_NOT_ADDED') : t('AIRFIELD_NOT_UPDATED'), 'error');
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (!isAdd) {
        let airfieldId: string = window.location.href.split('airfieldId=')[1] ?? '';
        let retrievedAirfield: Airfield = await airfieldService.getAirfieldById(airfieldId);
        setAirfield(retrievedAirfield);
      }
    })();
  }, []);

  const fieldsNotToCheck = [
    'id',
    'iataCode',
    'homeLink',
    'wikipediaink',
    'keywords'
  ];

  const handleChange = (e: any) => {
    let { name, value } = getFormValue(e);
    setAirfield((currentFormData: any) => ({
      ...currentFormData,
      [name]: value
    }));
  }

  return <>
    <Card>
      <CardHeader title={t('AIRFIELD_DATA')} />
      <Divider />
      <CardContent sx={{ input: { color: 'white' } }}>
        <Grid
          container
          spacing={2}
        >

          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('ICAO_CODE')}
              name="icaoCode"
              required
              onChange={handleChange}
              value={airfield.icaoCode}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('LATITUDE_DEG')}
              name="latitude"
              required
              onChange={handleChange}
              value={airfield.latitude}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('LONGITUDE_DEG')}
              name="longitude"
              required
              onChange={handleChange}
              value={airfield.longitude}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('NAME')}
              name="name"
              required
              onChange={handleChange}
              value={airfield.name}
            />
          </Grid>

          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('REGION')}
              name="region"
              required
              onChange={handleChange}
              value={airfield.region}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('PROVINCE')}
              name="province"
              required
              onChange={handleChange}
              value={airfield.province}
            />
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            xs={12}
          >
            <TextField
              fullWidth
              size='small'
              label={t('MUNICIPALITY')}
              name="municipality"
              required
              onChange={handleChange}
              value={airfield.municipality}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={upsertAirfield}
        >
          {t('SAVE')}
        </Button>
      </Box>
    </Card>
  </>;
}

export default AirfieldUpsertForm;
