import axios from 'axios';

export class AircraftTipologyService {

async getAllAircraftTipology() {
    try {
        const response = await axios.post('GetAllAircraftTipology');
        return response.data;
    } catch (error) {
        throw new Error("Error: " + error);
    }
}
}