import {
    Box,
    Button,
    Grid,
    Menu,
    MenuItem,
    Typography,
} from "@material-ui/core";
import ArrowRightIcon from '../../icons/ArrowRight';
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flight } from "../../models/Flight";
import { useNavigate } from "react-router";
import { FlightLand, FlightTakeoff, SupportAgent } from "@material-ui/icons";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LicensePlate from "../../icons/LicensePlate";
import Airfield from "../../icons/Airfield";


interface FlightsCalendarModalRowProps {
    flight: Flight,
}

const FlightsCalendarModalRow: FC<FlightsCalendarModalRowProps> = (props) => {
    const { flight } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);


    return (

        <Grid container spacing={1} sx={{ mt: 2, px: 0 }}>
            <Grid item xs={12}>
                <Grid container direction="column">
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Grid container direction="row" spacing={1} sx={{ mb: 1 }}>

                            <Grid
                                item
                            >
                                {flight.tenantIsDeparture ? <FlightTakeoff fontSize='small' /> :
                                    <FlightLand fontSize='small' />}
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant="subtitle2"
                                    align="center"> {flight.tenantIsDeparture ? t('DEPARTURING') : t('ARRIVING')} </Typography>
                            </Grid>
                        </Grid>
                        <Button
                            size="small"
                            onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                            sx={{ mt: -2, width: "15%" }}
                            disableFocusRipple
                        >
                            <Box sx={{
                                display: "flex", flexDirection: "column",
                                alignItems: "center",
                                color: "#AAAAAA",

                            }}>
                                <MoreVertIcon />
                            </Box>
                        </Button>

                        <Menu
                            id="flights-actions-menu"
                            MenuListProps={{
                                'aria-labelledby': 'flight-action-button',
                            }}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => {
                                setMenuAnchorEl(null)
                            }}
                        >

                            <MenuItem key={'details'} onClick={() => {
                                setMenuAnchorEl(null)
                                navigate(`/flights/detail?flightId=${flight.id}`);
                            }}>

                                <ArrowRightIcon fontSize="small" sx={{ mr: '3px' }} />
                                <Typography variant="subtitle1">{t('DETAILS')}</Typography>
                            </MenuItem>
                        </Menu>


                    </Box>
                    <Grid container direction="row" spacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <Airfield fontSize='small' />
                        </Grid>
                        <Grid item>

                            <Typography variant="body2" align="center">

                                {flight.tenantIsDeparture ? flight.arrivalAirfieldName : flight.departureAirfieldName}

                            </Typography>

                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <SupportAgent fontSize='small' />
                        </Grid>
                        <Grid item>
                            {windowWidth < 450 ?
                                <>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Typography noWrap={true} variant="body2">
                                            {flight.pilotFullName}
                                        </Typography>
                                    </div>
                                </>
                                : <Typography noWrap={true} variant="body2" align="center">
                                    {flight.pilotFullName}
                                </Typography>}
                        </Grid>
                    </Grid>

                    <Grid container direction="row" spacing={1} sx={{ mb: 1 }}>
                        <Grid item>
                            <LicensePlate fontSize='small' />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" align="center"> {flight.aircraftRegistration} </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </Grid >

    )
}

export default FlightsCalendarModalRow
