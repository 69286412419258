import {Airfield} from "../models/Airfield";
import {isEmpty} from "../utils/common";
import {hasErrors} from "../utils/validation";

export class AirfieldValidator {
  public errors = {
    code: '',
    icaoCode: '',
    latitude: '',
    longitude: '',
    name: '',
    region: '',
    province: '',
    municipality: ''
  };

  private t: Function;

  constructor(t: Function) {
    this.t = t;
  }

  validateAirfield(airfield: Airfield, fieldsNotToCheck: Array<string> = []): boolean {

    this.validateIcaoCode(airfield.icaoCode);
    this.validateLatitude(airfield.latitude);
    this.validateLongitude(airfield.longitude);
    this.validateName(airfield.name);
    this.validateRegion(airfield.region);
    this.validateProvince(airfield.province);
    this.validateMunicipality(airfield.municipality);

    return !hasErrors(this.errors, fieldsNotToCheck);
  }

  validateIcaoCode(icaoCode?: string) {
  }

  validateLatitude(latitude: number) {
    if (isEmpty(latitude)) {
      this.errors.latitude = this.t('LATITUDE_REQUIRED');
    }
  }

  validateLongitude(longitude: number) {
    if (isEmpty(longitude)) {
      this.errors.longitude = this.t('LONGITUDE_REQUIRED');
    }
  }

  validateName(name: string) {
    if (isEmpty(name)) {
      this.errors.name = this.t('AIRFIELD_NAME_REQUIRED');
    }
  }

  validateRegion(region: string) {
    if (isEmpty(region)) {
      this.errors.region = this.t('REGION_REQUIRED');
    }
  }

  validateProvince(province: string) {
    if (isEmpty(province)) {
      this.errors.province = this.t('PROVINCE_REQUIRED');
    }
  }

  validateMunicipality(municipality: string) {
    if (isEmpty(municipality)) {
      this.errors.municipality = this.t('MUNICIPALITY_REQUIRED');
    }
  }
}
