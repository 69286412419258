
export interface Aircraft {
  id: string,
  userId: string,
  tenantId: string,
  aircraftRegistration: string,
  aircraftType: string,
  aircraftCategoryId: string,
  aircraftTipologyId: string,
  aircraftCategoryName?: string,
  aircraftTipologyName?: string,
  color: string,
  haveRadio: boolean,
  haveTransponder: boolean,
  aircraftPhoto?: string,
  aircraftPhotoUrl?: string,

}

export const DefaultAircraft: Aircraft = {
  id: "",
  userId: "",
  tenantId: "",
  aircraftRegistration: "",
  aircraftType: "",
  aircraftCategoryId: "",
  aircraftTipologyId: "",
  color: "",
  haveRadio: false,
  haveTransponder: false,

}