import {Box, Button, Container, Grid, IconButton, Skeleton, Tooltip, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {DefaultFlight, Flight} from "../../models/Flight";
import {isEmpty, userCanUseIfRoles} from "../../utils/common";
import {FlightService} from "../../services/FlightService";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import FlightDetailsBox from "../../components/flight/FlightDetailsBox";
import PencilAlt from "../../icons/PencilAlt";
import {useSelector} from "react-redux";
import {User} from "../../models/User";
import {AppState} from "../../store";
import InformationCircle from "../../icons/InformationCircle";
import FlightLogModal from "../../components/flight/FlightLogModal";
import ArrowLeftIcon from '../../icons/ArrowLeft';
import PermissionsGuard from "../../components/PermissionGuard";
import {AircraftCategoryService} from "../../services/AircraftCategoryService";
import {AircraftTipologyService} from "../../services/AircraftTipologyService";


const FlightDetails = () => {
  const [flight, setFlight] = useState<Flight>(DefaultFlight);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const flightService = new FlightService();
  const history = useNavigate();

  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);
  const [isLoadingAircraftPhoto, setIsLoadingAircraftPhoto] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [aircraftPhoto, setAircraftPhoto] = useState<string | undefined>('');

  const searchAircraftPhoto = async (_flight: Flight) => {

    setIsLoadingAircraftPhoto(true);
    var urlAircraftPhoto = await fetch("https://jet-photos.azurewebsites.net/api/get-jet-photo?query=" +
      _flight.aircraftRegistration,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => response.json())
      .then(result => { return result.url; })
      .catch(error => console.log('error', error));
    if (urlAircraftPhoto == "https://cdn.jetphotos.com/full/null" || urlAircraftPhoto == "" || _flight.aircraftRegistration == "")
      setAircraftPhoto(undefined);
    else
      setAircraftPhoto(urlAircraftPhoto);
    setIsLoadingAircraftPhoto(false);

  };

  useEffect(() => {
    (async () => {
      let flightId: string = window.location.href.split('flightId=')[1] ?? '';
      if (!isEmpty(flightId)) {
        let retrievedFlight: Flight = await flightService.getFlightById(flightId);
        setFlight(retrievedFlight);
        await searchAircraftPhoto(retrievedFlight);
      }
      setIsLoading(false);
    })();
  }, []);

  const OpenModal = () => {
    setModalIsOpen(true);
  }

  return (
    <PermissionsGuard
      allowedRoles={['SYSTEMADMIN', 'ADMINISTRATOR', 'USER', 'VIEWER', 'PILOT']}
      environment={'FlightDetails'}
    //needTenant={true}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: 'row' }}>
              <Tooltip sx={{ mr: 2 }} title={t('BACK_BUTTON') || ''} aria-label={t('BACK_BUTTON') || ''}>
                <IconButton onClick={() => { history(-1); }}
                >
                  <ArrowLeftIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {t('FLIGHT_DATA')}
              </Typography>
            </Box>
          </Grid>


          <Grid item>
            <Box sx={{ m: -1 }}>
              {userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'VIEWER']) &&
                <Button
                  color="primary"
                  startIcon={<InformationCircle fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={OpenModal}
                >
                  {t('LOG_TEXT_LABEL')}
                </Button>
              }
              {userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER']) &&

                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PencilAlt fontSize="small" />}
                  sx={{ m: 1 }}
                  to={`/flights/edit?flightId=${flight.id}`}
                  variant="contained"
                >
                  {t('EDIT')}
                </Button>}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          {
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                sx={{ mb: 2 }}
              >
                <FlightLogModal airfieldId={flight.id!} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                {isLoading
                  ? <Skeleton variant="rectangular" height={700} />
                  : <FlightDetailsBox flight={flight}
                    aircraftPhoto={aircraftPhoto}
                    isLoadingAircraftPhoto={isLoadingAircraftPhoto}
                  />}
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default FlightDetails;
