import type {ElementType, FC} from 'react';
import {useEffect, useRef, useState} from 'react';
import {AppState} from "../../store";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import BellIcon from '../../icons/Bell';
import {Notification} from '../../models/Notification';
import {NotificationService} from '../../services/NotificationService';
import {useSelector} from 'react-redux';
import {User} from '../../models/User';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {dateTimeFormats, getFormattedUTCDate, userCanUseIfRoles} from '../../utils/common';
import {CheckCircle, FlightTakeoff} from '@material-ui/icons';

const iconsMap: Record<number, ElementType> = {
  2: FlightTakeoff,
  3: CheckCircle,
  4: CheckCircle,
};

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t, i18n } = useTranslation();
  const history = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<Notification[]>([]);
  const [notificationsNumber, setNotificationsNumber] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  const TextMap: Record<number, string> = {
    1: t("CIPO"),
    2: t("PILOT_INSERT_FLIGHT_NOTIFICATION_TEXT"),
    3: t("FLIGHT_APPROVATION_NOTIFICATION_TEXT"),
    4: t("FLIGHT_APPROVATION_NOTIFICATION_TEXT")

  };

  const GetTextWithColor = (text: string) => {
    var color = "null";
    switch (text) {
      case "APPROVED":
        color = "#4CAF50"
        break;
      case "NOT_APPROVED":
        color = "#F44336"
        break;
    }

    if (color == "null") {
      return t(text);
    }
    else {
      return (<Box sx={{ ml: 0.5 }}>
        <Typography color={color} variant="subtitle2">
          {t(text)}
        </Typography >
      </Box>
      )
    }
  }


  const handleOpen = async () => {
    setIsLoading(true);
    setOpen(true);
    let retrieveNotifications = await notificationService.getNotificationByUserId(loggedUser?.id!);
    setNotificationsList(retrieveNotifications);
    setNotificationsNumber(retrieveNotifications.length);
    setIsLoading(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const notificationService = new NotificationService();
  const setNotificationsReaded = async () => {
    await notificationService.SetNotificationsReaded(loggedUser?.id!)
    setNotificationsList([]);
    setNotificationsNumber(0);
  }

  const setOneNotificationReaded = async (id: string) => {
    setNotificationsNumber(notificationsNumber > 0
      ? notificationsNumber - 1 : 0)
    await notificationService.SetOneNotificationReaded(id);

  }

  useEffect(() => {
    (async () => {
      let retrievedNumber = await notificationService.GetNumberOfNotification(loggedUser?.id!);
      setNotificationsNumber(retrievedNumber);
    })();
  }, []);


  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notificationsNumber}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {t("NOTIFICATIONS")}

          </Typography>
        </Box>
        {isLoading || isLoading == undefined ?
          <>
            <Box sx={{ mb: 3, mt: -2 }}>
              <Box sx={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress sx={{ m: 5, textAlign: 'center' }} color="primary" />
              </Box>
            </Box>
          </>
          :
          <>
            {
              notificationsList.length === 0
                ? (
                  <Box sx={{ p: 2 }}>
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t("NO_NOTIFICATION")}
                    </Typography>
                  </Box>
                )
                : (
                  <>
                    {!isLoading &&
                      <List disablePadding>
                        {notificationsList.map((notification, index) => {
                          const Icon = iconsMap[notification.messageType];

                          return (
                            <ListItem
                              divider
                              key={notification.id}
                              onClick={async () => {
                                handleClose();
                                await setOneNotificationReaded(notification.id);
                                if (userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER'])) {
                                  history(`/flights/edit?flightId=${notificationsList[index].operationId}`)
                                }
                              }
                              }
                            >
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'primary.contrastText'
                                  }}
                                >
                                  <Icon fontSize="small" />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {TextMap[notification.messageType] + " " + notificationsList[index].senderInfo}
                                  </Link>
                                )}
                                secondary={<Box sx={{ display: "flex", flexDirection: "row", textAlign: "center" }}>{getFormattedUTCDate(notification.dateOfInsert!, dateTimeFormats[i18n.language])
                                  + ":"} {GetTextWithColor(notificationsList[index].operationInfo!)}</Box>}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    }
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1
                      }}
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        onClick={setNotificationsReaded}
                      >
                        {t("NOTIFICATION_MARKED_ALL_BUTTON")}
                      </Button>
                    </Box>
                  </>
                )
            }
          </>}
      </Popover>

    </>

  );
};

export default NotificationsPopover;
