import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@material-ui/core';
import { UserService } from '../../services/UserService';
import { useSelector } from 'react-redux';
import { AppState, User } from '@auth0/auth0-react';

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);
  const userService = new UserService();


  const languageOptions = {
    en: {
      icon: '/static/uk_flag.svg',
      label: t('ENGLISH')
    },
    it: {
      icon: '/static/it_flag.svg',
      label: t('ITALIAN')
    },
    fr: {
      icon: '/static/fr_flag.svg',
      label: t('FRENCH')
    }
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = async (language: string) => {
    i18n.changeLanguage(language);
    await userService.changeLanguage(user?.id, language);
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton
        sx={{
          marginRight: 3
        }}
        onClick={handleOpen}
        ref={anchorRef}
      >
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%'
            }
          }}
        >
          <img
            alt={selectedOption.label}
            src={selectedOption.icon}
          />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
