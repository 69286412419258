import {FlightLog} from "./FlightLog";
import {DefaultFlightType, FlightType} from "./FlightType";
import {DefaultUser, User} from "./User";

export interface Flight {
    id?: string,
    userId?: string,
    loggedUserId: string,
    tenantId: string,
    aircraftRegistration: string,
    aircraftType: string,
    flightTypeId: string,
    peopleOnBoard: number,
    departureAirfieldName: string,
    date: string,
    nextMovementDate: string,
    arrivalAirfieldName: string,
    flightType: FlightType,
    user: User,
    tenantIsDeparture: boolean,
    approved: boolean | null,
    isCarried: boolean | null,
    note: string,
    aircraftCategoryId: string,
    aircraftTipologyId: string,
    aircraftCategoryName: string,
    aircraftTipologyName: string,
    flightLogList: FlightLog[],
    pilotFullName: string,
    pilotPhoneNumber: string,
    pilotEmail: string,
    pilotDocumentTypeId: string,
    pilotDocumentTypeName: string,
    pilotDocumentValue: string,
    thereIsCopilot?: boolean;
    copilotFullName?: string | null,
    copilotPhoneNumber?: string | null,
    copilotEmail?: string | null,
    copilotDocumentTypeId?: string | null,
    copilotDocumentValue?: string | null,
    copilotDocumentTypeName?: string | null,

}

export const DefaultFlight: Flight = {
    userId: '',
    loggedUserId: '',
    tenantId: '',
    aircraftRegistration: '',
    aircraftType: '',
    flightTypeId: '',
    peopleOnBoard: 1,
    departureAirfieldName: '',
    date: '',
    nextMovementDate: '',
    arrivalAirfieldName: '',
    flightType: DefaultFlightType,
    user: DefaultUser,
    tenantIsDeparture: false,
    approved: null,
    isCarried: null,
    note: "",
    aircraftCategoryId: "",
    aircraftTipologyId: "",
    aircraftCategoryName: "",
    aircraftTipologyName: "",
    flightLogList: [],

    //PilotField
    pilotFullName: "",
    pilotPhoneNumber: "",
    pilotEmail: "",
    pilotDocumentTypeId: "",
    pilotDocumentTypeName: "",
    pilotDocumentValue: "",

    //CopilotField
    thereIsCopilot: false,
    copilotFullName: "",
    copilotPhoneNumber: "",
    copilotEmail: "",
    copilotDocumentTypeId: "",
    copilotDocumentTypeName: "",
    copilotDocumentValue: "",
}

export const getDefaultFlightForm = (user: User) => {
    var form: Flight = {
        ...DefaultFlight,
        pilotFullName: `${user.name} ${user.surname}`,
        pilotDocumentTypeId: user.documentTypeId || "",
        pilotDocumentValue: user.documentValue || "",
        pilotEmail: user.email,
        pilotPhoneNumber: user.phoneNumber || "",
        userId: user.id,
        departureAirfieldName: "",
        tenantIsDeparture: false
    }

    return form
}
