/* eslint-disable linebreak-style */
import type {FC} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {User} from '../../models/User';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

interface UserDetailsBoxProps {
  user: User
}

const UserDetailsBox: FC<UserDetailsBoxProps> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <Card>
          <CardHeader title={t('AVATAR')} />
          <Divider />
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              {user.tenantUser.avatar
                ? <img src={user.tenantUser.avatar} alt="avatar" style={{ padding: '20px', borderRadius: '50%', maxWidth: '250px', maxHeight: '250px', width: 'auto', height: 'auto' }}></img>
                : <AccountCircleIcon style={{ padding: '20px', borderRadius: '50%', width: '250px', height: '250px' }} />}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={8}
        md={6}
        xl={9}
        xs={12}
      >
        <Card>
          <CardHeader title={t('USER_DETAILS')} />
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {t('EMAIL')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {user.email}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {t('FIRST_NAME')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {user.name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {t('SURNAME')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {user.surname}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {t('ROLE')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {t(user.tenantUser.role.name)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserDetailsBox;
