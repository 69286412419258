import React, {FC, forwardRef} from 'react';
import {AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";


const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide unmountOnExit direction="up" ref={ref} {...props} />;
});

const IFrameModal: FC<any> = (props) => {

    const {open, title, url, type, setOpen} = props;

    return (<>
        <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box sx={{alignSelf: 'center', textSelf: 'center'}}>

                <iframe
                    style={{
                        width: window.innerWidth - 20,
                        height: 'calc(100vh - 60px)'
                    }}
                    src={url}>
                </iframe>
            </Box>
        </Dialog>
    </>);
}

export default IFrameModal;
