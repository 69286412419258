import { Box, Card, CardContent, Divider, IconButton, Tab, Tabs, Tooltip } from "@material-ui/core";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import FlightUpsertForm from "./FlightUpsertForm";
import { FlightLand, FlightTakeoff } from "@material-ui/icons";
import { useNavigate } from "react-router";
import ArrowLeftIcon from '../../icons/ArrowLeft';


interface FlightUpsertCardProps {
  isAdd: boolean
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const FlightUpsertCard: FC<FlightUpsertCardProps> = ({ isAdd }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tenantIsDeparture, setTenantIsDeparture] = useState<boolean>(false);

  const history = useNavigate();
  const { t } = useTranslation();

  const a11yProps = (index: number) => {
    return {
      id: `flights-tab-${index}`,
      'aria-controls': `flights-tabpanel-${index}`,
    };
  }

  const FlightUpsertTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`flights-tabpanel-${index}`}
        aria-labelledby={`flights-tab-${index}`}
        {...other}
      >
        {value === index && (
          <>{children}</>
        )}
      </div>
    );
  }

  const getTabIndexByFlight = (tenantIsDeparture: boolean = false) => {
    setTabIndex(Number(!tenantIsDeparture));
  }

  const getTenantIsDepartureByFlight = (tenantIsDepartureByFlight: boolean) => {
    setTenantIsDeparture(tenantIsDepartureByFlight);
  }

  return <>
    <Card>
      <Box sx={{ marginLeft: '5px', marginBottom: '15px', display: "flex", justifyContent: "space-between" }} >
        <Box>
          {
            isAdd ?
              (
                <Tabs value={tabIndex} onChange={(event: any, newTabIndex: number) => { setTabIndex(newTabIndex) }} aria-label="Flights tabs">
                  <Tab
                    icon={<FlightTakeoff />}
                    label={t('DEPARTURE')}
                    {...a11yProps(0)}
                    disabled={!isAdd && !tenantIsDeparture}
                  />
                  <Tab
                    icon={<FlightLand />}
                    label={t('ARRIVAL')}
                    {...a11yProps(1)}
                    disabled={!isAdd && tenantIsDeparture}
                  />
                </Tabs>
              ) :
              <Tabs value={0} aria-label="Flights tabs">
                {
                  tenantIsDeparture ?
                    <Tab
                      icon={<FlightTakeoff />}
                      label={t('DEPARTURE')}
                      value={0}
                    /> :
                    <Tab
                      icon={<FlightLand />}
                      label={t('ARRIVAL')}
                      value={0}
                    />
                }
              </Tabs>
          }
        </Box>
        <Tooltip sx={{ mr: 2 }} title={t('BACK_BUTTON') || ''} aria-label={t('BACK_BUTTON') || ''}>
          <IconButton onClick={() => { history(-1); }}
          >
            <ArrowLeftIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <CardContent sx={{ input: { color: 'white' } }}>
        <FlightUpsertTabPanel value={tabIndex} index={0}>
          <FlightUpsertForm isAdd={isAdd} isDeparture={true} getTabIndexByFlight={getTabIndexByFlight} getTenantIsDepartureByFlight={getTenantIsDepartureByFlight} />
        </FlightUpsertTabPanel>
        <FlightUpsertTabPanel value={tabIndex} index={1}>
          <FlightUpsertForm isAdd={isAdd} isDeparture={false} getTabIndexByFlight={getTabIndexByFlight} getTenantIsDepartureByFlight={getTenantIsDepartureByFlight} />
        </FlightUpsertTabPanel>
      </CardContent>
    </Card>
  </>;
}

export default FlightUpsertCard;
