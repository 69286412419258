import {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {Flight, getDefaultFlightForm} from "../../models/Flight";
import {useSnackbar} from "notistack";
import {Button, Card, CardContent, Divider, Grid, MobileStepper, Stack, useTheme} from "@mui/material";
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import * as yup from "yup";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {useSelector} from "react-redux";
import {AppState} from "@auth0/auth0-react";
import {User} from "../../models/User";
import PilotForm from "../insertFlightForms/PilotForm";
import AircraftChoose from "../insertFlightForms/AircraftChoose";
import AirfieldChoose from "../insertFlightForms/AirfieldChoose";
import CopilotForm from "../insertFlightForms/CopilotForm";
import NoteForm from "../insertFlightForms/NoteForm";
import SummaryForm from "../insertFlightForms/SummaryForm";
import {Box, CardHeader, Checkbox, Typography} from "@material-ui/core";
import {FlightValidator} from "../../validators/FlightValidator";
import {FlightService} from "../../services/FlightService";
import {composeNotistackMessage, StringIsNullOrEmpty} from "../../utils/common";
import {DefaultTenant, Tenant} from "../../models/Tenant";
import {TenantService} from "../../services/TenantService";
import Link from '@mui/material/Link';
import {isEmpty} from "lodash";
import IFrameModal from "../IFrameModal";
import LoaderVariant from "../LoaderVariant";

const fieldsNotToCheck = [

    //TODO: Remove tenantId e userId from here
    'tenantId',
    'userId',
    'departureAirfieldName',

];

interface PilotFlightUpsertCardProps {
    isAdd: boolean
}

const PilotFlightUpsertCard: FC<PilotFlightUpsertCardProps> = (props) => {
    const { isAdd } = props;
    const { t } = useTranslation();

    const steps = ["AIRFIELD", "PILOT", "AIRCRAFT", "COPITLOT", "NOTE", "SUMMARY"];
    const headerNames = ["1/5 - " + t("ARRIVAL_DATA"),
    "2/5 - " + t("PILOT_DATA"),
    "3/5 - " + t("COPILOT_DATA"),
    "4/5 - " + t("AIRCRAFT_DATA"),
    "5/5 - " + t("NOTE"),
    t("SUMMARY")]

    const history = useNavigate();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [thereIsCopilot, setThereIsCopilot] = useState<boolean>(false);
    const flightValidator = new FlightValidator(t);
    const flightService = new FlightService();
    const tenantService = new TenantService();
    const loggedUser = useSelector<AppState, User>((state) => state.appInit.user);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpsertLoading, setIsUpsertLoading] = useState<boolean>(false);
    const [isRegulationReaded, setIsRegulationReaded] = useState(false);
    const [openIframeModal, setOpenIframeModal] = useState(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [errorIndex, setErrorIndex] = useState<number | null>(null);
    const [validationSchema, setValidationSchema] = useState<any>();
    const [arrivalTenant, setArrivalTenant] = useState<Tenant>(DefaultTenant);
    const [thereIsRegulation, setThereIsRegulation] = useState<boolean>(false);


    const formik = useFormik({
        initialValues: getDefaultFlightForm(loggedUser),
        validationSchema,
        validateOnMount: false,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            //FUNZIONE DI SALVATAGGIO
            setIsLoading(true);
            // await subjectService.upsertSubject(values);
            setIsLoading(false);
            enqueueSnackbar(t("SAVE_COMPLETED"), { variant: "success" });
        },
    });

    useEffect(() => {

        switch (activeStep) {
            case 0: {
                setValidationSchema(yup.object({
                    arrivalAirfieldName: yup.string().required(t("ARRIVAL_AIRFIELD_REQUIRED")),
                    departureAirfieldName: yup.string().required(t("DEPARTURE_AIRFIELD_REQUIRED")),
                    date: yup.date().required(t("ARRIVAL_TIME_REQUIRED")),
                    peopleOnBoard: yup.number().required(t("PEOPLE_ON_BOARD_REQUIRED"))
                }))
                return;
            }
            case 1: {
                setValidationSchema(yup.object({
                    pilotFullName: yup.string().required(t("PILOT_NAME_REQUIRED")),
                    pilotPhoneNumber: yup.string().required(t("PILOT_PHONE_REQUIRED")),
                    pilotEmail: yup.string().email(t("PILOT_EMAIL_REQUIRED")).required(t("PILOT_EMAIL_REQUIRED")),
                    pilotDocumentTypeId: yup.string().required(t("PILOT_DOCUMENT_TYPE_REQUIRED")),
                    pilotDocumentValue: yup.string().required(t("PILOT_DOCUMENT_VALUE_REQUIRED")),
                }))
                return;
            }

            case 2: {
                if (formik.values.thereIsCopilot) {
                    setValidationSchema(yup.object({
                        copilotFullName: yup.string().required(t("COPILOT_NAME_REQUIRED")),
                        copilotPhoneNumber: yup.string().required(t("COPILOT_PHONE_REQUIRED")),
                        copilotEmail: yup.string().email(t("COPILOT_EMAIL_REQUIRED")).required(t("COPILOT_EMAIL_REQUIRED")),
                        copilotDocumentTypeId: yup.string().required(t("COPILOT_DOCUMENT_TYPE_REQUIRED")),
                        copilotDocumentValue: yup.string().required(t("COPILOT_DOCUMENT_VALUE_REQUIRED")),
                    }))
                } else
                    setValidationSchema(yup.object({}));
                return;
            }
            case 3: {
                setValidationSchema(yup.object({
                    aircraftRegistration: yup.string().required(t("AIRCRAFT_REGISTRATION_REQUIRED")),
                    aircraftType: yup.string().required(t("AIRCRAFT_TYPE_REQUIRED")),
                    aircraftCategoryId: yup.string().required(t("AIRCRAFT_CATEGORY_REQUIRED")),
                    aircraftTipologyId: yup.string().required(t("AIRCRAFT_TIPOLOGY_REQUIRED")),
                }))
                return;
            }
            default: {
                setValidationSchema(yup.object({}));
                return;
            }
        }

    }, [activeStep, formik.values.thereIsCopilot])

    useEffect(() => {
        (async () => {
            if (!isAdd) {
                let flightId: string = window.location.href.split('flightId=')[1] ?? '';
                let retrivedFlight: Flight = await flightService.getFlightById(flightId);
                await formik.setValues(retrivedFlight);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!isEmpty(formik.values.tenantId!)) {
                let retrivedTenant: Tenant = await tenantService.getTenantById(formik.values.tenantId!);
                setArrivalTenant(retrivedTenant);
                setThereIsRegulation(!StringIsNullOrEmpty(retrivedTenant.regulationLink!));
            }
        })();
    }, [formik.values.tenantId!]);

    const handleNext = async () => {
        let res = await formik.validateForm()
        if (Object.values(res).length > 0) {
            setErrorIndex(0);
            return;
        }
        if (activeStep == 1 && formik.values.peopleOnBoard == 1)
            setActiveStep((prevStep) => prevStep + 2);
        else
            setActiveStep((prevStep) => prevStep + 1);

    }

    useEffect(() => {
        setThereIsCopilot(formik.values.copilotFullName == "")
    }, [formik.values])

    const handleBack = () => {
        if (activeStep == 3 && formik.values.peopleOnBoard == 1)
            setActiveStep(prev => prev - 2);
        else
            setActiveStep(prev => prev - 1);

    }


    const upsertFlight = async () => {

        setIsUpsertLoading(true);
        let validateFlight = flightValidator.validateFlight(formik.values, fieldsNotToCheck);

        if (validateFlight) {
            let upsertFlightResult = await flightService.upsertFlightByPilot(formik.values);

            if (upsertFlightResult) {
                composeNotistackMessage(t('FLIGHT_ADDED'), 'success');

                history('/pilotflights');
            } else {
                composeNotistackMessage(t('FLIGHT_NOT_ADDED'), 'error');
            }
        }
        setIsUpsertLoading(false);

    }

    const getStepContent = (step: number, formik: any) => {
        switch (step) {
            case 0:
                return <AirfieldChoose formik={formik} />
            case 1:
                return <PilotForm formik={formik} />
            case 2:
                if (formik.values.peopleOnBoard > 1)
                    return <CopilotForm formik={formik} />
                else
                    return
            case 3:
                return <AircraftChoose formik={formik} />
            case 4:
                return <NoteForm formik={formik} />
            case 5:
                return <SummaryForm formik={formik} />
            default:
                return <Typography>404</Typography>
        }

    }



    return <>
        <IFrameModal open={openIframeModal} title={t("REGULATION")} url={arrivalTenant.regulationLink} setOpen={setOpenIframeModal} />
        <Card >
            <CardHeader title={headerNames[activeStep]} sx={{ backgroundColor: '#688EFF' }} />
            <Divider />
            <CardContent sx={{ height: "calc(100vh - 170px)", position: "relative" }}>
                <Grid container direction="column" justifyContent="space-between" >
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <form onSubmit={formik.handleSubmit} id="add-flights">
                                <Box sx={{ "& .ps__rail-y": { display: "none" } }}>
                                    <LoaderVariant isLoading={isUpsertLoading} />

                                    {getStepContent(activeStep, formik)}
                                </Box>
                            </form>
                        </Grid>

                        {(activeStep == 5 && thereIsRegulation) &&
                            <Grid item container xs={12} spacing={0} justifyContent="center"  >
                                <Grid item xs={12} >
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="center" >
                                        <Checkbox
                                            sx={{ p: "0 4px " }}
                                            checked={isRegulationReaded ? true : false}
                                            onChange={() => setIsRegulationReaded(!isRegulationReaded)}
                                            name="isPublicFormReleased"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <Typography
                                            color="textSecondary"
                                            variant="body2"
                                            textAlign="center"
                                        >
                                            {t("AIRFIELD_RULES_PHRASE")}

                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Link sx={{ cursor: "pointer" }} fontSize={12} underline="none" onClick={() => { setOpenIframeModal(true) }}>{t("AIRFIELD_RULES_LINK")} </Link>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <div style={{ position: "absolute", bottom: 0, left: 0, width: "100%", padding: 16 }}>
                    <MobileStepper
                        steps={steps.length}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={activeStep >= steps.length - 1 ? upsertFlight : handleNext}
                                disabled={isUpsertLoading || activeStep === steps.length || (activeStep === steps.length - 1 && isRegulationReaded == false && thereIsRegulation == true)}
                            >
                                {activeStep >= steps.length - 1 ? t("PILOT_INSERT_FLIGHT") : t("NEXT_BUTTON")}
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                {t("BACK_BUTTON_SHORT")}
                            </Button>
                        }
                    />
                </div>
            </CardContent >
        </Card >
    </>
}

export default PilotFlightUpsertCard
