import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Container,
    debounce,
    Divider,
    FormControlLabel,
    Grid,
    TextField
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { composeNotistackMessage, getFormValue } from "../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { UserValidator } from "../validators/UserValidator";
import { UserService } from "../services/UserService";
import { Tenant } from "../models/Tenant";
import { TenantService } from "../services/TenantService";
import { Airfield } from "../models/Airfield";
import { AirfieldService } from "../services/AirfieldService";
import pilotWhite from '../images/pilot_white_icon.png'
import LoaderVariant from "../components/LoaderVariant";

interface UserToCreate {
    name: string;
    surname: string;
    email: string;
    airfieldName: string;
    phoneNumber: string;
    isManager: boolean;
    isPilot: boolean;
}

const Signup = () => {
    const { t } = useTranslation();

    const { isLoading, user, logout } = useAuth0();

    const [signupUser, setSignupUser] = useState<UserToCreate>({ name: '', surname: '', email: '', airfieldName: '', phoneNumber: '', isManager: false, isPilot: true });

    const userValidator = new UserValidator(t);
    const userService = new UserService();
    const tenantService = new TenantService();
    const airfieldService = new AirfieldService();
    const [isUpsertLoading, setIsUpsertLoading] = useState<boolean>(false);


    const handleLogout = async (event: any): Promise<void> => {
        await logout();
    };

    const handleChange = (e: any) => {
        let { name, value } = getFormValue(e);
        setSignupUser
            ((currentFormData: any) => ({
                ...currentFormData,
                [name]: value
            }));
    }

    const userUpsert = async () => {
        setIsUpsertLoading(true);
        let validateUser: boolean = userValidator.validateUserToCreate(signupUser);
        if (validateUser) {

            let userUpserResult: boolean = await userService.userUpsert(signupUser);
            if (userUpserResult) {
                composeNotistackMessage(signupUser.isPilot ? t('PILOT_ACCOUNT_REQUESTED') : t('ACCOUNT_REQUESTED'), 'success');

                setTimeout(async () => {
                    await logout();
                }, 5000);

            } else {
                composeNotistackMessage(signupUser.isPilot ? t('PILOT_ACCOUNT_NOT_REQUESTED') : t('ACCOUNT_NOT_REQUESTED'), 'error');
            }
        }
    }

    useEffect(() => {
        if (!isLoading) {
            if ((/^([a-zA-Z0-9._ %-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(user?.email != undefined ? user.email : ""))) {
                var temp = user?.email != undefined ? user.email : "";
                var usertemp = signupUser;
                usertemp.email = temp;
                setSignupUser(usertemp);
            }
        }

    }, [isLoading])

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<string>>([]);

    useEffect(() => {
        (async () => {
            setLoadingOptions(true);

            await getTenants(searchFilter);
        })()
    }, [searchFilter])

    const [openChooseTenant, setOpenChooseTenant] = useState<boolean>(false);

    useEffect(() => {
        if (openChooseTenant) {
            setLoadingOptions(true);

            getTenants();
        }
    }, [openChooseTenant])

    const getTenants = useCallback(debounce(async (searchFilter: string = '') => {
        let retrievedTenants = await tenantService.getAllTenants(searchFilter);
        let retrievedAirfields = await airfieldService.getAllAirfields(searchFilter);

        let tenantOptions: Array<string> = retrievedTenants.items
            .map((tenant: Tenant) => tenant.name + ' (SmartAirfield)');

        let airfieldOptions: Array<string> = retrievedAirfields.items
            .map((airfield: Airfield) => airfield.name);

        setOptions([...tenantOptions, ...airfieldOptions]);
        setLoadingOptions(false);
    }, 700), [])

    const handleAutocompleteChange = (value: string | undefined, name: string) => {
        setSignupUser
            (prevState => ({ ...prevState, [name]: value }));
    }

    return (<>
        {isUpsertLoading == true &&
            <LoaderVariant isLoading={isUpsertLoading} />
        }
        <Box sx={{ backgroundColor: '#222b36' }} display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Container maxWidth={false}>
                <Grid
                    container justifyContent="center" alignItems="center"
                >
                    <Grid
                        item
                        lg={5}
                        md={6}
                        xl={4}
                        sm={8}
                        xs={12}
                    >
                        <Card>
                            <CardHeader title={
                                <Box sx={{ display: "flex", flexDirection: "row" }}>

                                    <img src={pilotWhite} height={35} style={{ marginRight: 10 }} />
                                    {t('SIGNUP_TEXT')}
                                </Box>
                            } />
                            <Divider />
                            <CardContent sx={{ input: { color: 'white' } }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('NAME')}
                                            name="name"
                                            required
                                            onChange={handleChange}
                                            value={signupUser.name}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('SURNAME')}
                                            name="surname"
                                            required
                                            onChange={handleChange}
                                            value={signupUser.surname}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('EMAIL')}
                                            name="email"
                                            type="email"
                                            disabled
                                            required
                                            onChange={handleChange}
                                            value={signupUser.email}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label={t('PHONE_NUMBER')}
                                            name="phoneNumber"
                                            required
                                            onChange={handleChange}
                                            value={signupUser.phoneNumber}
                                        />
                                    </Grid>


                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('ARE_YOU_MANAGER')}
                                            name="isManager"
                                            labelPlacement="end"
                                            onChange={(e) => {
                                                handleChange(e);
                                                let { value } = getFormValue(e);
                                                setSignupUser
                                                    ((currentFormData: any) => ({
                                                        ...currentFormData,
                                                        isPilot: !value
                                                    }));
                                            }}
                                            value={!signupUser.isPilot}
                                        />
                                    </Grid>

                                    {
                                        !signupUser.isPilot
                                        &&
                                        <Grid
                                            item
                                            lg={12}
                                            md={12}
                                            xs={12}
                                        >
                                            <Autocomplete
                                                freeSolo
                                                id="tenant"
                                                options={options}
                                                renderInput={(params) =>
                                                    <TextField {...params} name="tenant"
                                                        fullWidth label={t('AIRFIELD')} size='small'
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {loadingOptions ? <CircularProgress sx={{ marginRight: '30px' }} color="info" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />}
                                                onKeyUp={(event: any) => {
                                                    setSearchFilter(event.target.value);
                                                }}
                                                onInputChange={(event: any, value: string) => {
                                                    handleAutocompleteChange(value, 'airfieldName')
                                                }}
                                                onOpen={() => { setOpenChooseTenant(true) }}
                                                onClose={() => { setOpenChooseTenant(false) }}
                                                open={openChooseTenant}
                                                value={signupUser.airfieldName}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                filterOptions={(options, params) => {
                                                    const { inputValue } = params;
                                                    const filtered = [...options];

                                                    if (inputValue !== '') {
                                                        filtered.push(inputValue);
                                                    }

                                                    return filtered;
                                                }}
                                            />
                                        </Grid>
                                    }

                                </Grid>
                            </CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 2,
                                input: { color: 'white' }
                            }}>
                                <Button
                                    color="error"
                                    type="submit"
                                    disabled={isUpsertLoading == true}
                                    variant="contained"
                                    onClick={handleLogout}
                                >
                                    {t('LOGOUT')}
                                </Button>

                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={isUpsertLoading == true}
                                    variant="contained"
                                    onClick={userUpsert}>
                                    {t('REQUEST_ACCOUNT')}
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </>);
}

export default Signup;
