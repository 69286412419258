import "./App.css";
import { LocalizationProvider } from "@material-ui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheTheme } from "./theme";
import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { createHashHistory } from "history";
import configureStore from "./configureStore";
import { SnackbarProvider } from "notistack";
import { Auth0Provider } from "@auth0/auth0-react";
import serviceConfig from "./ServiceConfig";
import { HashRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import { enUS, it } from "date-fns/locale";

const history = createHashHistory();
const store = configureStore(history);

const newtheme = createTheTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: "dark",
});

const onRedirectCallback = (appState: any) => {
    // eslint-disable-next-line no-restricted-globals
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const providerConfig = {
    domain: serviceConfig.auth0Domain,
    clientId: serviceConfig.auth0ClientId,
    audience: null,
    redirectUri: window.location.origin,
    onRedirectCallback,
};

const locales: any = { enUS, it };

const App = () => {
    return (
        <>
            <ThemeProvider theme={newtheme}>
                <Provider store={store}>
                    <SnackbarProvider dense maxSnack={3} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Auth0Provider {...providerConfig}>
                                <HashRouter>
                                    <Routes />
                                </HashRouter>
                            </Auth0Provider>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </Provider>
            </ThemeProvider>
        </>
    );
};

export default App;
