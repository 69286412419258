import { Box, Button, Card, Grid, Stack, Table, TableBody, Tooltip, Typography } from "@material-ui/core"
import ArrowRight from "../../icons/ArrowRight"
import { Link as RouterLink } from "react-router-dom";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { FC, useEffect, useState } from "react";
import { Aircraft } from "../../models/Aircraft";
import TableRowsLoaderVariant from "../../components/TableRowsLoaderVariant"
import { useTranslation } from "react-i18next";
import { AircraftCategory } from "../../models/AircraftCategory";
import { AircraftTipology } from "../../models/AircraftTipology";
import { AircraftCategoryService } from "../../services/AircraftCategoryService";
import { AircraftTipologyService } from "../../services/AircraftTipologyService";

interface MyAircraftsCardsListProps {
    aircrafts: Aircraft[];
    isLoading: boolean;
}

const MyAircraftsCardsList: FC<MyAircraftsCardsListProps> = (props) => {

    const { aircrafts, isLoading } = props;

    const { t } = useTranslation();

    const aircraftCategoryService = new AircraftCategoryService();
    const aircraftTipologyService = new AircraftTipologyService();

    const [aircraftCategoryList, setAircraftCategoryList] = useState<Array<AircraftCategory>>([]);
    const [aircraftTipologyList, setAircraftTipologyList] = useState<Array<AircraftTipology>>([]);

    useEffect(() => {
        (async () => {
            await getAircraftTipology();
            await getAircraftCategory();
        })();
    }, []);

    const getAircraftCategory = async () => {
        let retrievedAircraftCategory = await aircraftCategoryService.getAllAircraftCategory();
        setAircraftCategoryList(retrievedAircraftCategory);
    }

    const getAircraftTipology = async () => {
        let retrievedAircraftTipology = await aircraftTipologyService.getAllAircraftTipology();
        setAircraftTipologyList(retrievedAircraftTipology);
    }

    return <>
        <Box sx={{ minWidth: 700, mb: 2 }}>
            <Table>
                <TableBody>
                    {isLoading
                        ? <>
                            <TableRowsLoaderVariant rowsNum={20} height={350} hasDivider={false} />
                        </>
                        : <>
                            {aircrafts.map((aircraft: Aircraft, index) => (<>
                                <Card sx={{ mt: 2, display: 'flex' }} style={{ maxHeight: 350 }}>
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            width: "70%",
                                            height: 'calc(75vw / 4 * 3)', // 4:3 aspect ratio
                                            maxHeight: 350
                                        }}
                                    >
                                        {aircraft.aircraftPhoto == "https://cdn.jetphotos.com/full/null" ?
                                            <>
                                                <Box sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <Typography sx={{ ml: 2, mt: 16, fontSize: 40 }}>
                                                        {aircraft.aircraftRegistration == null ? "CODE_404" : aircraft.aircraftRegistration}</Typography>
                                                    <NoPhotographyIcon sx={{ mt: 15 }} />
                                                </Box>

                                            </>
                                            :
                                            <img
                                                loading="lazy"
                                                src={aircraft.aircraftPhoto}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        }
                                    </div>
                                    <Grid container columns={10} spacing={2} sx={{ ml: 4 }}>

                                        <Grid container columns={10}>
                                            <Grid item xs={10}>

                                                <Box sx={{ ml: 3, mt: 5 }}> </Box>
                                                <Box sx={{ ml: 2, mt: 3, mb: 2 }}>
                                                    <h1>{t("AIRCRAFT_REGISTRATION")}: {aircraft.aircraftRegistration.toUpperCase()}</h1>

                                                </Box>
                                            </Grid>

                                            <Grid container spacing={2}>

                                                <Grid item xs={5}>
                                                    <Typography sx={{ ml: 8, mt: -4 }} color="textPrimary"
                                                        variant="body1">{t("AIRCRAFT_TYPE_SHORT")}:<br />{aircraft.aircraftType}
                                                    </Typography>
                                                    <Typography sx={{ ml: 8, mt: 4 }} color="textPrimary"
                                                        variant="body1">{t("AIRCRAFT_TIPOLOGY_SHORT")}:<br />{t(aircraftTipologyList.find(aircraftT => aircraftT.id == aircraft.aircraftTipologyId)?.name || "")}
                                                    </Typography>
                                                    <Typography sx={{ ml: 8, mt: 4 }} color="textPrimary"
                                                        variant="body1">{t("HAVE_RADIO")}:<br />{aircraft.haveRadio ? t("YES") : t("NO")}
                                                    </Typography>

                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography sx={{ ml: 8, mt: -4 }} color="textPrimary"
                                                        variant="body1">{t("AIRCRAFT_CATEGORY_SHORT")}:<br />{"\n\n"} {t(aircraftCategoryList.find(aircraftC => aircraftC.id == aircraft.aircraftCategoryId)?.name || "")}
                                                    </Typography>
                                                    <Typography sx={{ ml: 8, mt: 4 }} color="textPrimary"
                                                        variant="body1">{t("COLOR")}:<br />{aircraft.color}
                                                    </Typography>
                                                    <Typography sx={{ ml: 8, mt: 4 }} color="textPrimary"
                                                        variant="body1">{t("HAVE_TRANSPONDER")}:<br />{aircraft.haveTransponder ? t("YES") : t("NO")}
                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ mt: 10, mr: 1 }}
                                    >
                                        <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
                                            <Button sx={{ height: 40, color: "white" }}
                                                component={RouterLink}
                                                to={`edit?aircraftId=${aircraft.id}`}>
                                                <ArrowRight fontSize='large' />
                                            </Button>
                                        </Tooltip>
                                        <Box sx={{ mt: 10 }}></Box>
                                    </Stack>

                                </Card>
                            </>))}
                        </>}
                </TableBody>
            </Table>
        </Box></>

}
export default MyAircraftsCardsList;
