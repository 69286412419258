import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  debounce,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {composeNotistackMessage, getFormValue} from "../../utils/common";
import {useNavigate} from "react-router-dom";
import {DefaultTenant, Tenant} from "../../models/Tenant";
import {TenantService} from "../../services/TenantService";
import {TenantValidator} from "../../validators/TenantValidator";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import {Info, Place} from "@material-ui/icons";
import AvatarDropzone from "../AvatarDropzone";
import {AirfieldService} from "../../services/AirfieldService";
import {Airfield} from "../../models/Airfield";
import {isNull} from "lodash";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

interface TenantUpsertFormProps {
  isAdd: boolean
}

interface IAirifeldOptions {
  id: string,
  label: string
}

const TenantUpsertForm: FC<TenantUpsertFormProps> = ({ isAdd }) => {
  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user!);
  const [tenant, setTenant] = useState<Tenant>(DefaultTenant);
  const [photos, setPhotos] = useState<string[]>([]);
  const [isLoadaingPhotos, setIsLoadaingPhotos] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  var Carousel = require('react-responsive-carousel').Carousel;
  const currentTheme = useTheme();

  const isMobile = useMediaQuery(currentTheme.breakpoints.down('md'));
  const [options, setOptions] = useState<Array<IAirifeldOptions>>([]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loadingOptions, setLoadingOptions] = useState<boolean>(true);
  const [openAirfieldAutocomplete, setOpenAirfieldAutocomplete] = useState<boolean>(false);
  const [currentAirfield, setCurrentAirfield] = useState<IAirifeldOptions | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isPhotosArrayModified, setIsPhotosArrayModified] = useState<boolean>(false);

  const { t } = useTranslation();

  const tenantService = new TenantService();
  const tenantValidator = new TenantValidator(t);
  const airfieldService = new AirfieldService();

  const history = useNavigate();

  useEffect(() => {
    const getPhotos = async () => {
      setIsLoadaingPhotos(true);
      if (!(tenant?.airfieldId == null || tenant?.airfieldId == undefined || tenant?.airfieldId == "")) {
        var tmpPhotos = await airfieldService.getAirfieldPhoto(tenant?.airfieldId || "");
        if (tmpPhotos[0] == "") { tmpPhotos = [] }
        setPhotos(tmpPhotos);
      }
      setIsLoadaingPhotos(false);

    }

    getPhotos();

  }, [tenant?.airfieldId]);

  const renderImmagini = () => {
    return photos?.map((immagine, index) => (
      <>
        {(immagine != "") && <>
          <div key={index}>
            <div
              style={{
                overflow: 'hidden',
                width: '100%',
                height: 'calc(75vw / 4 * 3)', // 4:3 aspect ratio
                maxHeight: isMobile ? 300 : 350, // Altezza massima dell'immagine
              }}
              key={index}

            >
              <img
                src={photos[index]}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Per ritagliare l'immagine mantenendo l'aspect ratio
                }}
              />
            </div>
          </div>
        </>}
      </>
    ));
  };

  useEffect(() => {
    (async () => {
      if (!isAdd) {
        let tenantId: string = window.location.href.split('tenantId=')[1] ?? '';
        let retrievedTenant: Tenant = await tenantService.getTenantById(tenantId);

        setTenant(retrievedTenant);

        setCurrentAirfield({
          id: retrievedTenant.airfieldId,
          label: retrievedTenant.airfieldName!
        });
      }
      setTenant(prevState => ({ ...prevState, userId: loggedUser?.id }));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setLoadingOptions(true);
    getAirfields(searchFilter);
  }, [searchFilter])

  useEffect(() => {
    if (openAirfieldAutocomplete) {
      setLoadingOptions(true);
      getAirfields();
    }
  }, [openAirfieldAutocomplete]);

  const [files, setFiles] = useState<any[]>([]);

  const setLogo = (logo: string | undefined) => {
    setTenant((currentFormData: any) => ({
      ...currentFormData,
      logo: logo
    }));
  }

  const handleDrop = (newFiles: any): void => {
    var reader = new FileReader();
    reader.readAsDataURL(newFiles[0]);
    reader.onload = () => {
      setLogo(reader.result as string);
    };

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (): void => {
    setFiles([]);
    setLogo(undefined);
  };

  const deletePhotos = (): void => {
    const temp = [...photos.slice(0, currentSlide), ...photos.slice(currentSlide >= photos.length ? currentSlide : currentSlide + 1)];
    setPhotos(temp);
    if (photos.length > 1)
      setCurrentSlide(currentSlide >= photos.length ? currentSlide - 1 : currentSlide > 0 ? currentSlide - 1 : 0)
    else
      setPhotos([]);
    setIsPhotosArrayModified(true);
  };

  const UploadPhotos = (e): void => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      if (e.target != null && e.target.result != null) {
        const content = e.target.result as string;
        setPhotos((prev) => [...prev, content])
        if (photos.length >= 1) {
          setCurrentSlide(photos.length);
        }
      }
    };
  };

  async function upsertTenant() {
    if (photos.length > 10) {
      composeNotistackMessage('TOO_MANY_PHOTOS', "error"); return;
    }

    let validateTenant: boolean = tenantValidator.validateTenant(tenant, fieldsNotToCheck);

    if (isPhotosArrayModified) {
      let upsertAirfieldPhotosResult: boolean = await airfieldService.upsertAirfieldPhotos(photos, tenant.airfieldId);
    }
    if (validateTenant) {
      let upsertTenantResult: boolean = await tenantService.upsertTenant(tenant);

      if (upsertTenantResult) {
        composeNotistackMessage(isAdd ? t('TENANT_ADDED') : t('TENANT_UPDATED'), 'success');

        // TODO: Used to change tenants in Sidebar. To fix
        history('/smartairfield');

        history('/tenants');
      } else {
        composeNotistackMessage(isAdd ? t('TENANT_NOT_ADDED') : t('TENANT_NOT_UPDATED'), 'error');
      }
    }
  }

  function getApiKey() {
    var tmp = "";
    //TODO: Implementare richiesta ad Azure per ricevere APIKEY
    setTenant((currentFormData: any) => ({
      ...currentFormData, isPublicFormReleased: tmp
    }));
    if (tmp != "Pippo") { //TODO: Controllo effettivo dell'APIKEY
      composeNotistackMessage(t('TENANT_APIKEY_GENERATED'), 'success');
    }
    else {
      composeNotistackMessage(t('TENANT_APIKEY_ERROR'), 'error');
    }

  }

  const fieldsNotToCheck = [
    'isPublic',
    'logo'
  ];

  const handleChange = (e: any) => {
    let { name, value } = getFormValue(e);
    setTenant((currentFormData: any) => ({
      ...currentFormData,
      [name]: value
    }));
  }

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(setLocation);
  }

  const setLocation = (position: any) => {
    setTenant((currentFormData: any) => ({
      ...currentFormData,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }));
  }

  const getAirfields = useCallback(debounce(async (searchFilter: string = '') => {
    let retrievedAirfields = await airfieldService.getAllAirfields(searchFilter);

    let airfieldOptions: Array<IAirifeldOptions> = retrievedAirfields.items.map((airfield: Airfield) => {
      return {
        id: airfield.id!,
        label: airfield.name
      }
    });

    setOptions(airfieldOptions);
    setLoadingOptions(false);
  }, 700
  ), [])

  return (<>
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        md={6}
        xl={3}
        xs={12}
      >
        <Card>
          <CardHeader title={t('LOGO')} />
          <Divider />
          <CardContent>
            {tenant.logo
              ? <div style={{ textAlign: 'center' }}>
                <img src={tenant.logo} alt="avatar" style={{ padding: '20px', borderRadius: '50%', maxWidth: '250px', maxHeight: '250px', width: 'auto', height: 'auto' }}></img>
                <Divider />
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mt: 2
                }}>
                  <Button
                    sx={{
                      backgroundColor: 'error.main',
                      color: 'error.contrastText',
                      '&:hover': {
                        backgroundColor: 'error.dark'
                      }
                    }}
                    variant="contained"
                    onClick={handleRemove}
                  >
                    {t('DELETE')}
                  </Button>
                </Box>
              </div>
              : <Box sx={{ mt: 3 }}>
                <AvatarDropzone
                  accept="image/*"
                  files={files}
                  maxFiles={1}
                  maxSize={5000000}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                />
              </Box>}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={8}
        md={6}
        xl={9}
        xs={12}
        sx={{ mb: 2 }}
      >
        {isLoading ?
          <Skeleton variant="rectangular" width={"100%"} height={"85vh"} />
          :
          <Card>
            <CardHeader title={t('TENANT_DATA')} />
            <Divider />

            <CardContent sx={{ input: { color: 'white' } }}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Stack direction="row" spacing={1}>
                    <FormLabel component="legend"> {t('AIRFIELD_VISIBILITY')} </FormLabel>
                    <Tooltip title={t('VISIBILITY_TEXT')}>
                      <Info fontSize="small" />
                    </Tooltip>
                  </Stack>
                  <FormGroup>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography> {t('PRIVATE')} </Typography>
                      <Switch
                        checked={tenant.isPublic}
                        name="isPublic"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      <Typography> {t('PUBLIC')} </Typography>
                    </Stack>
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="linkedAirfield"
                    forcePopupIcon={false}
                    options={options}
                    renderInput={(params) =>
                      <TextField {...params} required name="linkedAirfield"
                        fullWidth label={t('AIRFIELD')} size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadingOptions ? <CircularProgress sx={{ marginRight: '30px' }} color="info" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />}
                    onKeyUp={(event: any) => {
                      setSearchFilter(event.target.value);
                    }}
                    onChange={(event: any, value: any) => {
                      setTenant(prevState => ({ ...prevState, airfieldId: isNull(value) ? '' : value.id }));
                      setCurrentAirfield(value);
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    onOpen={() => { setOpenAirfieldAutocomplete(true) }}
                    onClose={() => { setOpenAirfieldAutocomplete(false) }}
                    open={openAirfieldAutocomplete}
                    value={currentAirfield}
                    filterOptions={(options) => options}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('NAME')}
                    name="name"
                    required
                    onChange={handleChange}
                    value={tenant.name}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('ICAO_CODE')}
                    name="icaoCode"
                    required
                    onChange={handleChange}
                    value={tenant.icaoCode}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('REGION')}
                    name="region"
                    required
                    onChange={handleChange}
                    value={tenant.region}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('PROVINCE')}
                    name="province"
                    required
                    onChange={handleChange}
                    value={tenant.province}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('MUNICIPALITY')}
                    name="municipality"
                    required
                    onChange={handleChange}
                    value={tenant.municipality}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('LATITUDE_DEG')}
                    name="latitude"
                    required
                    onChange={handleChange}
                    value={tenant.latitude}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('LONGITUDE_DEG')}
                    name="longitude"
                    required
                    onChange={handleChange}
                    value={tenant.longitude}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <Button component="label" variant="outlined" startIcon={<Place />} onClick={getLocation}>
                    {t('GET_LOCATION')}
                  </Button>
                </Grid>
              </Grid>
              <Divider sx={{ margin: '25px' }} />
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('MANAGER_NAME')}
                    name="managerFullName"
                    required
                    onChange={handleChange}
                    value={tenant.managerFullName}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('MANAGER_FISCAL_CODE')}
                    name="managerFiscalCode"
                    required
                    onChange={handleChange}
                    value={tenant.managerFiscalCode}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    size='small'
                    label={t('MANAGER_PHONE_NUMBER')}
                    name="managerPhoneNumber"
                    required
                    onChange={handleChange}
                    value={tenant.managerPhoneNumber}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ mt: 2 }}
              >
                <TextField
                  fullWidth
                  size='small'
                  label={t('REGULATION_LINK_LABEL')}
                  name="regulationLink"
                  onChange={handleChange}
                  value={tenant.regulationLink}
                  sx={{ input: { color: 'white' } }}
                />
              </Grid>






              <Grid
                container
                spacing={1} sx={{ mt: 1, mb: 2 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"

                  item
                  lg={3}
                  md={5}
                  xs={5}

                >
                  <FormGroup>
                    <FormControlLabel control=
                      {
                        <Checkbox
                          checked={tenant.isPublicFormReleased ? true : false}
                          onChange={handleChange}
                          name="isPublicFormReleased"
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="URL Iframe"
                    />
                  </FormGroup>
                </Grid>
                {tenant.isPublicFormReleased &&
                  <Grid
                    item
                    lg={9}
                    md={7}
                    xs={7}
                  >
                    <TextField
                      fullWidth
                      size='small'
                      label={t('TENANT_IFRAME_URL_LABEL')}
                      name="isPublicFormReleased"
                      required
                      disabled
                      value={"https://www.smartairfield.cloud/#/externalFlightInput/tenantId=" + tenant.id}
                      sx={{ input: { color: 'white' } }}
                    />
                  </Grid>}




              </Grid>


              <Divider />

              <Grid sx={{ mt: 2 }} container>
                <Grid
                  item
                  lg={7}
                  md={7}
                  xs={12}
                >

                  {
                    (photos && photos.length > 0 && photos[0] != "") ?
                      <Box sx={{ width: "100%" }}>
                        <Carousel
                          sx={{ mt: 2 }}
                          className="crsl"
                          autoPlay={false}
                          showThumbs={false}
                          selectedItem={currentSlide}
                          onChange={(index) => { setCurrentSlide(index); setIsPhotosArrayModified(true); }}>
                          {renderImmagini()}
                        </Carousel>
                      </Box>
                      :
                      isLoadaingPhotos ?
                        <Skeleton height={350} animation="wave" variant="rectangular" /> :
                        <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                          <Typography sx={{ mt: 3, ml: 1.5, fontSize: 35 }}>
                            {t('PHOTOS_NOT_FOUND')}<NoPhotographyIcon sx={{ mt: 1, ml: 2 }} /></Typography>

                        </Box>
                  }
                </Grid>
                <Grid
                  item
                  lg={5}
                  md={5}
                  xs={12}
                >
                  <Box sx={{ display: "flex", mt: isMobile ? 2 : 14, flexDirection: 'column', alignContent: 'center', alignItems: 'center' }}>

                    <Button sx={{ mb: 1 }} variant="contained" component="label">
                      {t('UPDATE_AIRFIELD_PHOTOS_BUTTON')}
                      <input hidden accept="image/*" multiple type="file" onChange={(e) => {
                        UploadPhotos(e); setIsPhotosArrayModified(true);
                      }} />
                    </Button>
                    <Button sx={{ mt: 1 }}
                      color="error"
                      type="button"
                      variant="contained"
                      onClick={() => { deletePhotos(); setIsPhotosArrayModified(true); }}
                    >
                      {t('DELETE_AIRFIELD_PHOTOS_BUTTON')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

            </CardContent>
            <Divider />
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2,
              input: { color: 'white' }
            }}>



              <Button
                color="primary"
                type="submit"
                variant="contained"
                onClick={upsertTenant}
              >
                {t('SAVE')}
              </Button>
            </Box>
          </Card>
        }
      </Grid>

    </Grid >

  </>
  );
}

export default TenantUpsertForm;
