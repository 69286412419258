import {type FC, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import {Flight} from '../../models/Flight';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import TableRowsLoader from '../TableRowsLoader';
import {dateTimeFormats, getFormattedUTCDate, userCanUseIfRoles} from '../../utils/common';
import {CheckCircle, Circle, FlightLand, FlightTakeoff, RemoveCircle} from '@material-ui/icons';
import {isNull} from 'lodash';
import SortDescending from '../../icons/SortDescending';
import SortAscending from '../../icons/SortAscending';
import FlightsListHeader from './FlightsListHeader';
import FlightLogModal from './FlightLogModal';
import {FlightService} from '../../services/FlightService';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IFilters {
	searchFilter: string,
	startDepartureDate: string | null,
	endDepartureDate: string | null,
	startArrivalDate: string | null,
	endArrivalDate: string | null,
	tenantIsDeparture: boolean | null,
	orderFieldName: string | null,
	isDescending: boolean | null
}

interface FlightsListTableProps {
	paginationResult: PaginationResult<Flight> | undefined;
	isPageLoading: boolean;
	loggedUser: any;
	tenantIsDeparture: boolean | null;
	updateApprovedFlight(flightId: string, approved: boolean): void;
	setAllFilters(value: string | boolean, name: string);
	filters: IFilters;
	getMoreFlights(): void;
	isRecordsLoading: boolean;


}

interface FlightListRowProps {
	flight: Flight;
	updateApprovedFlight(flightId: string, approved: boolean): void;
	loggedUser: any;
	OpenModal: Function,
	setLogListWieved: Function,
	index: number,
}

const FlightsListTable: FC<FlightsListTableProps> = (props) => {
	const { paginationResult,
		isPageLoading,
		loggedUser,
		tenantIsDeparture,
		updateApprovedFlight,
		getMoreFlights,
		setAllFilters,
		isRecordsLoading,
		filters } = props;
	const { t, i18n } = useTranslation();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [logListWieved, setLogListWieved] = useState<Number>(0);
	var currentTheme = useTheme();
	const [paginatedFlights, setPaginatedFlights] = useState<PaginationResult<Flight>>();

	const flightService = new FlightService();

	const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('sm'));
	const OpenModal = () => {
		setModalIsOpen(true);
	}
	useEffect(() => {
		if (paginationResult) {
			setPaginatedFlights(paginationResult)
		}
	}, [paginationResult])


	return (
		<>
			{modalIsOpen &&
				<FlightLogModal airfieldId={paginationResult?.items[logListWieved.toString()].id}
					modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
			}
			<InfiniteScroll
				dataLength={paginatedFlights?.items.length ? paginatedFlights?.items.length : 10}
				next={getMoreFlights}
				style={{ overflow: "hidden" }}
				hasMore={!isRecordsLoading &&
					(paginatedFlights?.currentPage || 0) <= (paginatedFlights?.totalPages || 0)}
				loader={<></>}
			>
				<Table>
					<TableHead>
						<TableRow>
							<FlightsListHeader value="TenantIsDeparture" name="FLIGHT_DIRECTION_SHORT" setAllFilters={setAllFilters} filters={filters} />
							<FlightsListHeader value="AircraftRegistration" name="AIRCRAFT_REGISTRATION" setAllFilters={setAllFilters} filters={filters} />
							<FlightsListHeader value="PilotFullName" name="PILOT_NAME" setAllFilters={setAllFilters} filters={filters} />

							<TableCell>
								<Button onClick={() => {
									if (tenantIsDeparture != null) {
										setAllFilters((tenantIsDeparture ? "ArrivalAirfieldName" : "DepartureAirfieldName"), "orderFieldName");
										setAllFilters(!filters.isDescending, "isDescending")
									}
								}}><Typography variant="subtitle2" sx={{ color: "white", ml: 1 }}>
										{t('ARRIVAL_PLACE')}</Typography>
									{(!isNull(tenantIsDeparture) && (filters.orderFieldName == "ArrivalAirfieldName" || (filters.orderFieldName == "DepartureAirfieldName"))) && (
										(filters.isDescending) == true ? (<SortDescending sx={{ color: "white", ml: 1 }} />) : (<SortAscending sx={{ color: "white", ml: 1 }} />))}

								</Button>
							</TableCell>

							<FlightsListHeader value="Date" name="TIMETABLE" setAllFilters={setAllFilters} filters={filters} />


							<TableCell align="right">
								{t('ACTIONS')}
							</TableCell>
						</TableRow>

					</TableHead>
					<TableBody>
						{
							isPageLoading ?
								(
									<TableRowsLoader rowsNum={10} colsNum={6} />
								) :
								(<>
									{paginatedFlights?.items.map((flight, index) => (
										<FlightListRow flight={flight} updateApprovedFlight={updateApprovedFlight} loggedUser={loggedUser}
											OpenModal={OpenModal} setLogListWieved={setLogListWieved} index={index} />
									))
									}
								</>)
						}
					</TableBody>
				</Table>
				{isRecordsLoading &&
					<Box sx={{
						display: "flex",
						justifyContent: "center",
						alignContent: "center",
						mt: 4, mb: 3
					}}>
						<CircularProgress />
					</Box>
				}
			</InfiniteScroll>
		</>
	);
};

export default FlightsListTable;


const FlightListRow: FC<FlightListRowProps> = ({ flight, updateApprovedFlight, loggedUser, OpenModal, setLogListWieved, index }) => {
	const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setMenuAnchorEl(null);
	};



	const { t, i18n } = useTranslation();




	return (<>
		<TableRow hover key={flight.id}>
			<TableCell>
				<Grid item>
					{flight.tenantIsDeparture ? <FlightTakeoff fontSize='small' /> : <FlightLand fontSize='small' />}
				</Grid>
			</TableCell>
			<TableCell>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex'
					}}
				>
					<Box sx={{ ml: 1 }}>
						{flight.aircraftRegistration}
					</Box>
				</Box>
			</TableCell>
			<TableCell>
				{flight.pilotFullName}
			</TableCell>
			<TableCell>
				<Grid container direction="row" columnSpacing={1}>
					<Grid item>
						{flight.arrivalAirfieldName}
					</Grid>
				</Grid>
			</TableCell>
			<TableCell>
				{getFormattedUTCDate(flight.date, dateTimeFormats[i18n.language])}
			</TableCell>
			<TableCell align="right">


				{
					userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'PILOT'])
					&&
					<>
						{flight.approved == null ?
							<IconButton
								onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
							>
								<Circle sx={{ color: "#808080" }} fontSize="small" />
							</IconButton> :
							<Tooltip title="" aria-label="">
								<IconButton
									onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
								>
									{!flight.approved ? <RemoveCircle fontSize="small" color="error" /> :
										<CheckCircle fontSize="small" color="success" />}
								</IconButton>
							</Tooltip>}
					</>
				}



				{userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']) && flight.approved == null
					&& <Tooltip title={t('EDIT')} aria-label={t('EDIT')}>

						<IconButton
							component={RouterLink}
							to={`edit?flightId=${flight.id}`}
						>
							<PencilAltIcon fontSize="small" />
						</IconButton>
					</Tooltip>}

				{userCanUseIfRoles(loggedUser?.tenantUser.role.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'USER', 'VIEWER'])
					&& <Tooltip title={t('DETAILS') || ''} aria-label={t('DETAILS') || ''}>
						<IconButton
							component={RouterLink}
							to={`detail?flightId=${flight.id}`}
						>
							<ArrowRightIcon fontSize="small" />
						</IconButton>
					</Tooltip>}


			</TableCell>


		</TableRow>
	</>)

}

