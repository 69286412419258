/* eslint-disable linebreak-style */
import {type FC, useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Tenant} from '../../models/Tenant';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AirfieldService} from '../../services/AirfieldService';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {MapContainer, Marker} from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "react-responsive-carousel/lib/styles/carousel.css";


interface TenantDetailsBoxProps {
    tenant: Tenant
}

const TenantDetailsBox: FC<TenantDetailsBoxProps> = ({ tenant }) => {
    const { t } = useTranslation();
    const [photo, setPhoto] = useState<string[]>();
    var Carousel = require('react-responsive-carousel').Carousel;
    const [isLoadaingPhoto, setIsLoadaingPhoto] = useState<boolean>(true);
    const airfieldService = new AirfieldService();

    const getPhoto = async () => {
        setIsLoadaingPhoto(true);
        if (!(tenant?.airfieldId == null || tenant?.airfieldId == undefined || tenant?.airfieldId == "")) {
            var tmpPhoto = await airfieldService.getAirfieldPhoto(tenant?.airfieldId || "");
            setPhoto(tmpPhoto);
        }
        setIsLoadaingPhoto(false);

    }

    useEffect(() => {

        (async () => {
            await getPhoto();
        })();

    }, [tenant?.airfieldId]);

    const renderImmagini = () => {
        return photo?.map((immagine, index) => (
            <div key={index}>
                <div
                    style={{
                        overflow: 'hidden',
                        width: '100%',
                        height: 'calc(75vw / 4 * 3)', // 4:3 aspect ratio
                        maxHeight: '350px', // Altezza massima dell'immagine
                    }}
                    key={index}

                >
                    <img
                        src={photo[index]}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Per ritagliare l'immagine mantenendo l'aspect ratio
                        }}
                    />
                </div>
            </div>
        ));
    };


    return (<>
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                lg={5}
                md={6}
                xl={3}
                xs={12}
            >
                <Card>
                    <CardHeader title={t('LOGO')} />
                    <Divider />
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            {tenant.logo
                                ? <img src={tenant.logo} alt="avatar" style={{
                                    padding: '20px',
                                    borderRadius: '50%',
                                    maxWidth: '250px',
                                    maxHeight: '250px',
                                    width: 'auto',
                                    height: 'auto'
                                }}></img>
                                : <AccountCircleIcon style={{
                                    padding: '20px',
                                    borderRadius: '50%',
                                    width: '250px',
                                    height: '250px'
                                }} />}
                        </div>
                    </CardContent>
                </Card>
                {(tenant.longitude != 0 && tenant.latitude != 0) &&
                    <Card sx={{ mt: 9.5, mb: 2 }}>
                        <MapContainer
                            style={{ height: "300px", width: "100%", padding: '50px' }}
                            zoom={14}
                            center={[
                                tenant.latitude,
                                tenant.longitude]}
                        >
                            <ReactLeafletGoogleLayer
                                googleMapsLoaderConf={{ apiKey: "AIzaSyALsCrt7x4tjRdrEBioDLa67ggN5Eq9uZ0" }}
                                type={'hybrid'}
                                useGoogMapsLoader={true}
                                continuousWorld={false} />
                            <Marker
                                position={[
                                    tenant.latitude,
                                    tenant.longitude]}
                            >

                            </Marker>
                        </MapContainer>
                    </Card>
                }
            </Grid>
            <Grid
                item
                lg={7}
                md={6}
                xl={9}
                xs={12}
            >
                <Card>
                    <CardHeader title={t('TENANT_DETAILS')} />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('NAME')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.name}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('LATITUDE_DEG')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.latitude}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('LONGITUDE_DEG')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.longitude}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('ICAO_CODE')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.icaoCode}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('MUNICIPALITY')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.municipality}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('REGION')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.region}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('PROVINCE')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.province}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('AIRFIELD_VISIBILITY')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.isPublic ? t('PUBLIC') : t('PRIVATE')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('MANAGER_NAME')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.managerFullName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('MANAGER_FISCAL_CODE')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.managerFiscalCode}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        {t('MANAGER_PHONE_NUMBER')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {tenant.managerPhoneNumber}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Grid>
        </Grid>
        {!(photo && photo.length > 0 && photo[0] == "") ?
            <Carousel
                sx={{ mt: 2 }}
                className="crsl"

                showThumbs={false}
                selectedIndex={0}>
                {renderImmagini()}
            </Carousel>
            : <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                <Typography sx={{ mt: 3, ml: 1.5, fontSize: 35 }}>
                    {t('PHOTOS_NOT_FOUND')}<NoPhotographyIcon sx={{ ml: 2 }} /></Typography>

            </Box>}
        <Box sx={{ mb: 3 }}></Box>
    </>
    );
};

export default TenantDetailsBox;
