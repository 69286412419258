import { isEmpty, inArray, composeNotistackMessage } from "./common";

export const hasErrors = (errors: object, fieldsNotToCheck: Array<string> = []) => {
	let errorPresence = false;

	for (const [key, value] of Object.entries(errors)) {
		if (!inArray(key, fieldsNotToCheck) && !isEmpty(value)) {
			composeNotistackMessage(value, 'error');
			errorPresence = true;
		}
	}

	return errorPresence;
}