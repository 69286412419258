import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {DefaultUser, User} from "../../models/User";
import {isEmpty} from "../../utils/common";
import {UserService} from "../../services/UserService";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import UserDetailsBox from "../../components/user/UserDetailsBox";
import PencilAlt from "../../icons/PencilAlt";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import PermissionsGuard from "../../components/PermissionGuard";

const UserDetails = () => {
  const [user, setUser] = useState<User>(DefaultUser);
  const { t } = useTranslation();
  const userService = new UserService();

  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  useEffect(() => {
    (async () => {
      let userId: string = window.location.href.split('userId=')[1] ?? '';
      if (!isEmpty(userId)) {
        let retrievedUser: User = await userService.getUserById(userId, loggedUser?.currentTenantId);
        setUser(retrievedUser);
      }
    })();
  }, []);

  return (
    <PermissionsGuard
      allowedRoles={['SYSTEMADMIN', 'ADMINISTRATOR']}
      environment={'UserDetails'}
      needTenant={true}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('USER_DATA')}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PencilAlt fontSize="small" />}
                sx={{ m: 1 }}
                to={`/users/edit?userId=${user.id}`}
                variant="contained"
              >
                {t('EDIT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          {
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <UserDetailsBox user={user} />
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default UserDetails;
