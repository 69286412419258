import {FC, useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import type {AppBarProps} from '@material-ui/core';
import {AppBar, Box, Hidden, IconButton, Toolbar} from '@material-ui/core';
import {experimentalStyled} from '@material-ui/core/styles';
import MenuIcon from "../../icons/Menu";
import LanguagePopover from './LanguagePopover';
import AccountPopover from './AccountPopover';
import logoImageApp from '../../images/sa-white.svg'
import logoNameApp from '../../images/sa-white-text.svg'
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {menuState, openDrawer} from '../../store/slices/menu';
import NotificationsPopover from '../notification/NotificationsPopover';
import {userCanUseIfRoles} from '../../utils/common';
import {AppState} from "@auth0/auth0-react";
import {User} from "../../models/User";

interface NavbarProps extends AppBarProps {
}

const NavbarRoot = experimentalStyled(AppBar)(
    ({theme}) => (
        {
            ...(
                theme.palette.mode === 'light' && {
                    backgroundColor: theme.palette.primary.main,
                    boxShadow: 'none',
                    color: theme.palette.primary.contrastText
                }
            ),
            ...(
                theme.palette.mode === 'dark' && {
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    boxShadow: 'none'
                }
            ),
            zIndex: theme.zIndex.drawer + 100
        }
    )
);

const Navbar: FC<NavbarProps> = (props) => {
    const dispatch = useDispatch();

    const {drawerOpen} = useSelector(menuState)

    const loggedUser = useSelector<AppState, User>((state) => state.appInit.user);

    const [actualTime, setActualTime] = useState<string>('');

    const {i18n} = useTranslation();

    const handleToggleMenu = () => {
        dispatch(openDrawer(!drawerOpen))
    }

    const updateActualTime = () => {
        const now = new Date();
        const day = now.getUTCDate().toString().padStart(2, '0');
        const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = now.getUTCFullYear();
        const hours = now.getUTCHours().toString().padStart(2, '0');
        const minutes = now.getUTCMinutes().toString().padStart(2, '0');

        const utcString = `${day}/${month}/${year} ${hours}:${minutes}`;

        setActualTime(utcString);
    }

    setInterval(updateActualTime, 10000);

    useEffect(() => {
        updateActualTime();
    }, [i18n.language]);

    return (
        <NavbarRoot>
            <Toolbar sx={{minHeight: 64}}>

                <Hidden mdDown>
                    <RouterLink to="/flights">
                        <Box
                            sx={{
                                mt: '6px',
                            }}
                            component="img"
                            src={logoImageApp}
                            height={40}
                        />
                        <Box
                            sx={{
                                mb: '15px',
                                mx: 1
                            }}
                            component="img"
                            src={logoNameApp}
                            height={14}
                        />
                    </RouterLink>
                </Hidden>
                <IconButton
                    color="primary"
                    onClick={handleToggleMenu}
                >
                    <MenuIcon fontSize="small"/>
                </IconButton>
                <Hidden mdUp>
                    <Box
                        sx={{

                            mx: 1
                        }}
                        component="img"
                        src={logoNameApp}
                        height={14}
                    />
                </Hidden>
                <Box
                    sx={{
                        flexGrow: 1,
                        ml: 2
                    }}
                />
                <Box sx={{ml: 1}}>
                    {userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER', 'PILOT'])
                        &&
                        <NotificationsPopover/>}
                </Box>
                <Box sx={{ml: 1}}>
                    <LanguagePopover/>
                </Box>

                <Box sx={{}}>
                    <AccountPopover/>
                </Box>
            </Toolbar>
        </NavbarRoot>
    );
};

export default Navbar;
