import {Aircraft} from "../models/Aircraft";
import {isEmpty} from "../utils/common";
import {hasErrors} from "../utils/validation";

export class AircraftValidator {
  public errors = {
    aircraftRegistration: '',
    aircraftType: '',
    aircraftCategory: '',
    aircraftTipology: '',
    color: '',
  };

  private t: Function;

  constructor(t: Function) {
    this.t = t;
  }

  validateAircraft(aircraft: Aircraft, fieldsNotToCheck: Array<string> = []): boolean {

    this.validateAircraftRegistration(aircraft.aircraftRegistration);
    this.validateAircraftType(aircraft.aircraftType);
    this.validateAircraftCategory(aircraft.aircraftCategoryId);
    this.validateAircraftTipology(aircraft.aircraftTipologyId);
    this.validateColor(aircraft.color);

    return !hasErrors(this.errors, fieldsNotToCheck);
  }

  validateAircraftRegistration(airReg?: string) {
    if (isEmpty(airReg)) {
      this.errors.aircraftRegistration = this.t('AIRCRAFT_REGISTRATION_REQUIRED');
    }
  }


  validateAircraftType(airType: string) {
    if (isEmpty(airType)) {
      this.errors.aircraftType = this.t('AIRCRAFT_TYPE_REQUIRED');
    }
  }

  validateAircraftCategory(category: string) {
    if (isEmpty(category)) {
      this.errors.aircraftCategory = this.t('AIRCRAFT_CATEGORY_REQUIRED');
    }
  }

  validateAircraftTipology(tipology: string) {
    if (isEmpty(tipology)) {
      this.errors.aircraftTipology = this.t('AIRCRAFT_TIPOLOGY_REQUIRED');
    }
  }

  validateColor(color: string) {
    if (isEmpty(color)) {
      this.errors.color = this.t('COLOR_REQUIRED');
    }
  }


}
