import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import {routerMiddleware} from 'connected-react-router';
import {History} from 'history';

import createRootReducer from './store';

const configureStore = (history: History) => {
  const allReducers = createRootReducer(history);

  return createStore(allReducers, applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
    logger
  ));
};

export default configureStore;
