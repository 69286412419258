import {FC, useEffect, useState} from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {DocumentTypeService} from "../../services/DocumentTypeService";
import {useTranslation} from "react-i18next";
import {DocumentType} from "../../models/DocumentType";
import {Box, FormHelperText, Skeleton, Typography} from "@mui/material";


interface CopilotFormProps {
    formik: any,
}

const CopilotForm: FC<CopilotFormProps> = (props) => {
    const { formik } = props
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [thereIsCopilot, setThereIsCopilot] = useState<boolean>(formik.values.copilotFullName != "");

    const documentTypeService = new DocumentTypeService();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                let types = await documentTypeService.getAllDocumentTypes();
                setDocumentTypes(types);
                setIsLoading(false);
            } catch (err) {
                console.log("Error");
            }
        })();
    }, []);

    const handleBooleanChange = async (e: any, value: boolean) => {
        setThereIsCopilot(value as unknown as boolean)
        await formik.setValues({ ...formik.values, thereIsCopilot: value as unknown as boolean });

    }

    return <Grid container spacing={3} sx={{ mt: -3 }}>
        <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: 'row' }}>
                <Typography sx={{ mt: 1, mr: 2 }}>{t('THERE_IS_COPILOT')}</Typography>
                <FormControlLabel control=
                    {
                        <Checkbox
                            name="thereIsCopilot"
                            onChange={(e) => handleBooleanChange(e, true)}
                            checked={formik.values.thereIsCopilot}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={t("YES")}
                />
                <FormControlLabel control=
                    {
                        <Checkbox
                            name="thereIsCopilot"
                            onChange={(e) => handleBooleanChange(e, false)}
                            checked={!formik.values.thereIsCopilot}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={t("NO")}
                />

            </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
            {formik.values.thereIsCopilot && <>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="copilot-full-name"
                        label={t("COPILOT_NAME") + " *"}
                        name="copilotFullName"
                        onChange={formik.handleChange}
                        value={formik.values.copilotFullName}
                        error={Boolean(formik.errors.copilotFullName)}
                        helperText={formik.errors.copilotFullName}
                    />
                )}</>}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
            {formik.values.thereIsCopilot && <>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="copilot-phone-number"
                        label={t("PHONE_NUMBER") + " *"}
                        name="copilotPhoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.copilotPhoneNumber}
                        error={Boolean(formik.errors.copilotPhoneNumber)}
                        helperText={formik.errors.copilotPhoneNumber}
                    />
                )}</>}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4}>
            {formik.values.thereIsCopilot && <>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        id="copilot-email"
                        label={t("EMAIL") + " *"}
                        name="copilotEmail"
                        onChange={formik.handleChange}
                        value={formik.values.copilotEmail}
                        error={Boolean(formik.errors.copilotEmail)}
                        helperText={formik.errors.copilotEmail}
                    />
                )}</>}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
            {formik.values.thereIsCopilot && <>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <FormControl fullWidth size='small'>
                        <InputLabel>{t('DOCUMENT_TYPES_LABEL')}</InputLabel>
                        <Select
                            label={t('DOCUMENT_TYPES_LABEL')}
                            name="copilotDocumentTypeId"
                            onChange={formik.handleChange}
                            value={formik.values.copilotDocumentTypeId}
                            error={Boolean(formik.errors.copilotDocumentTypeId)}
                        >
                            {documentTypes.map((documentType: DocumentType) => (
                                <MenuItem key={documentType.id} value={documentType.id}>
                                    {t(documentType.name)}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{formik.errors.copilotDocumentTypeId}</FormHelperText>
                    </FormControl>
                )}</>}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
            {formik.values.thereIsCopilot && <>
                {isLoading ? (
                    <Skeleton animation="wave" variant="text" width="100%" height={50} />
                ) : (
                    <TextField
                        fullWidth
                        size="small"
                        autoComplete="off"
                        id="copilot-document-value"
                        label={t('DOCUMENT_VALUE_LABEL')}
                        name="copilotDocumentValue"
                        onChange={formik.handleChange}
                        value={formik.values.copilotDocumentValue}
                        error={Boolean(formik.errors.copilotDocumentValue)}
                        helperText={formik.errors.copilotDocumentValue}
                    />
                )}</>}
        </Grid>

    </Grid >
}
export default CopilotForm;
