import { ReactElement, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserService } from "../../services/UserService";
import { useDispatch } from "react-redux";
import { actionCreators as appInitAction } from "../../store/AppInit";
import Loader from "../Loader";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Signup from "../../pages/Signup";
import { PilotRoleName } from "../../utils/common";

// /**
//  * Authentication guard for routes
//  * @param {PropTypes.node} children children element/node
//  */

type GuardProps = {
  children: ReactElement | null;
};

const AuthGuard = ({ children }: GuardProps) => {
  const { loginWithRedirect, user, isLoading, logout } = useAuth0();
  const dispatch = useDispatch();
  const userService = new UserService();
  const [appIsLoading, setAppIsLoading] = useState(false);
  const history = useNavigate();
  const { i18n } = useTranslation();
  const [userNotExists, setUserNotExists] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, user]);

  useEffect(() => {
    (async () => {
      setAppIsLoading(true);
      if (user) {
        try {
          let retrievedUser = await userService.getUserByEmail(user.email);
          if (retrievedUser !== null) {
            if (retrievedUser.id) {
              i18n.changeLanguage(retrievedUser.preferredLanguage);
              dispatch(appInitAction.setUser(retrievedUser));
              setAppIsLoading(false);
              if (retrievedUser.tenantUser.role.name === PilotRoleName) {
                history('/pilotflights');
              } else {
                history('/flights');
              }

            } else {
              await logout();
            }
          } else {
            setUserNotExists(true);
          }
        } catch (e: any) {
        }
      }
    })();
  }, [user, isLoading]);

  if (isLoading || !user) {
    return <Loader />;
  }

  if (!isLoading && !appIsLoading && user) {
    return children;
  }

  return userNotExists ? <Signup /> : <></>
};

export default AuthGuard;
