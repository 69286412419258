import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import {Flight} from '../../models/Flight';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import {CloseOutlined, FilterAlt, FlightLand, FlightTakeoff, HighlightOff} from '@material-ui/icons';
import {FlightService} from '../../services/FlightService';
import {composeNotistackMessage, getFormValue, isEmpty, objectHasEmptyFields} from '../../utils/common';
import {DateRangePicker} from '@material-ui/lab';
import FlightsListTableMobile from './FlightsListTableMobile';
import FlightsListTable from './FlightsListTable';
import {toUpper} from 'lodash';
import 'dayjs/plugin/timezone';


interface IFilters {
    searchFilter: string,
    startDepartureDate: string | null,
    endDepartureDate: string | null,
    startArrivalDate: string | null,
    endArrivalDate: string | null,
    tenantIsDeparture: boolean | null,
    orderFieldName: string | null,
    isDescending: boolean | null
}

const defaultFilters: IFilters = {
    searchFilter: '',
    startDepartureDate: null,
    endDepartureDate: null,
    startArrivalDate: null,
    endArrivalDate: null,
    tenantIsDeparture: null,
    orderFieldName: 'Date',
    isDescending: true,
};


interface FlightsListCardProps {
    loggedUser: any;
}

const FlightsListCard: FC<FlightsListCardProps> = ({loggedUser}) => {

    const flightService = new FlightService();

    const [flights, setFlights] = useState<PaginationResult<Flight>>();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [recordLimit, setRecordLimit] = useState<number>(10);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isRecordsLoading, setIsRecordsLoading] = useState<boolean>(true);
    const [fliterDialogModalOpen, setFliterDialogModalOpen] = useState(false);

    const {t} = useTranslation();
    const currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

    useEffect(() => {
        (async () => {
            setIsPageLoading(true);
            await getFlights(currentPage + 1, recordLimit, filters);
        })();
    }, [currentPage, recordLimit, filters]);

    const getFlights = useCallback(async (currentPage: number, recordLimit: number, filters: any) => {
        try {
            const retrievedFlights = await flightService.getAllflights(currentPage, recordLimit, loggedUser?.id, loggedUser?.currentTenantId ?? '', filters);
            setFlights(retrievedFlights);
            setIsPageLoading(false);
            setIsRecordsLoading(false);
        } catch (error) {
            //TODO: Handle errors
            console.log(error);
        }
    }, []);

    const setAllFilters = (value: string | boolean | null, name: string) => {
        setFilters((currentFormData: any) => ({...currentFormData, [name]: value}));
    }

    const areFiltersEmpty = () => {
        return objectHasEmptyFields(filters);
    }

    const updateApprovedFlight = async (flightId: string, approved: boolean) => {
        setIsPageLoading(true);
        const flightService = new FlightService();
        let updateApprovedFlightResult = await flightService.updateApprovedFlightById(flightId, approved, loggedUser?.id);

        if (updateApprovedFlightResult) {
            composeNotistackMessage(t('FLIGHT_UPDATED'), 'success');

        } else {
            composeNotistackMessage(t('FLIGHT_NOT_UPDATED'), 'error');
        }
        await updateFlightList()

    }

    const updateFlightList = async () => {
        await getFlights(currentPage + 1, recordLimit, filters);
    }

    const updateIsCarriedFlight = async (flightId: string, approved: boolean) => {
        setIsPageLoading(true);
        const flightService = new FlightService();
        let updateApprovedFlightResult = await flightService.updateIsCarriedFlightById(flightId, approved, loggedUser?.id);

        if (updateApprovedFlightResult) {
            composeNotistackMessage(t('FLIGHT_UPDATED'), 'success');

        } else {
            composeNotistackMessage(t('FLIGHT_NOT_UPDATED'), 'error');
        }
        await updateFlightList()
    }

    const handleChange = (e: any) => {
        let {name, value} = getFormValue(e);
        setFilters((currentFormData: any) => ({
            ...currentFormData,
            [name]: value
        }));
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);

    const getMoreFlights = async () => {
        setIsRecordsLoading(true);
        try {
            if (flights && flights.items.length! > 0 &&
                flights?.items.length! <= flights.totalCount!) {
                const retrievedFlights = await flightService.getAllflights(
                    flights?.currentPage ? flights?.currentPage + 1 : 2,
                    10,
                    loggedUser?.id,
                    loggedUser?.currentTenantId ?? '',
                    filters);

                if (retrievedFlights && flights)
                    retrievedFlights.items = flights.items.concat(retrievedFlights.items)

                //var moreFlight = paginatedFlights;
                setFlights(JSON.parse(JSON.stringify(retrievedFlights)));
            }
        } catch (error) {
            //TODO: Handle errors
            console.log(error);
        }
        setIsRecordsLoading(false);

    };


    return (
        <Card sx={{mb: 4}}>
            <CardContent sx={{mb: 2, width: "100%"}}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        py: 2,
                        maxWidth: '100%'
                    }}
                >
                    <Drawer
                        anchor='right'
                        open={fliterDialogModalOpen}
                        onClose={() => setFliterDialogModalOpen(false)}
                        PaperProps={{
                            sx: {width: {xs: '75%', md: '50%', lg: '25%'}},
                        }}
                    >
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            sx={{mt: '60px', p: '15px'}}
                        >
                            <Grid item justifyContent={"center"} textAlign={"center"}>
                                <Grid container direction={"row"} alignItems={"center"}>
                                    <Typography textAlign="center"
                                                variant="h5">{toUpper(t('ADVANCED_FILTERS'))}</Typography>
                                    <Tooltip title={t('CLOSE')}>
                                        <IconButton sx={{marginLeft: "auto", p: 0}} onClick={() => {
                                            setFliterDialogModalOpen(false);
                                        }}>
                                            <HighlightOff color='error' fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                            >
                                <DateRangePicker
                                    startText={t("START_DEPARTURE_TIME")}
                                    endText={t("END_DEPARTURE_TIME")}
                                    value={[filters.startDepartureDate, filters.endDepartureDate]}
                                    onChange={(newRange) => {
                                        var startDate, endDate;
                                        if (newRange[0] != null && !isEmpty(newRange[0])) {
                                            startDate = new Date(newRange[0].toString());

                                        } else {
                                            startDate = ""
                                        }

                                        if (newRange[1] != null && !isEmpty(newRange[1])) {
                                            endDate = new Date(newRange[1].toString());
                                        } else {
                                            endDate = ""
                                        }

                                        setAllFilters(startDate, 'startDepartureDate');
                                        setAllFilters(endDate, 'endDepartureDate');
                                        setAllFilters(true, "tenantIsDeparture");
                                        setAllFilters(null, 'startArrivalDate');
                                        setAllFilters(null, 'endArrivalDate');

                                    }}
                                    inputFormat='DD/MM/YYYY'
                                    renderInput={(startProps, endProps) =>
                                        <>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    xs={12}
                                                >
                                                    <TextField {...startProps} fullWidth
                                                               sx={{input: {color: 'white'}}}/>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    xs={12}
                                                >
                                                    <TextField {...endProps} fullWidth sx={{input: {color: 'white'}}}/>
                                                </Grid>
                                            </Grid>
                                        </>}
                                />

                            </Grid>
                            <Grid
                                item
                            >
                                <DateRangePicker
                                    startText={t("START_ARRIVAL_TIME")}
                                    endText={t("END_ARRIVAL_TIME")}
                                    value={[filters.startArrivalDate, filters.endArrivalDate]}
                                    onChange={(newRange) => {
                                        var startDate, endDate;
                                        if (newRange[0] != null && !isEmpty(newRange[0])) {
                                            startDate = new Date(newRange[0].toString());

                                        } else {
                                            startDate = ""
                                        }

                                        if (newRange[1] != null && !isEmpty(newRange[1])) {
                                            endDate = new Date(newRange[1].toString());
                                        } else {
                                            endDate = ""
                                        }

                                        setAllFilters(startDate, 'startArrivalDate');
                                        setAllFilters(endDate, 'endArrivalDate');
                                        setAllFilters(false, "tenantIsDeparture");
                                        setAllFilters(null, 'startDepartureDate');
                                        setAllFilters(null, 'endDepartureDate');

                                    }}

                                    inputFormat='DD/MM/YYYY'
                                    renderInput={(startProps, endProps) =>
                                        <>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    xs={12}
                                                >
                                                    <TextField {...startProps} fullWidth
                                                               sx={{input: {color: 'white'}}}/>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    xs={12}
                                                >
                                                    <TextField {...endProps} fullWidth sx={{input: {color: 'white'}}}/>
                                                </Grid>
                                            </Grid>
                                        </>}
                                />
                            </Grid>

                            <Grid
                                item
                            >
                                <FormControl fullWidth size='small'>
                                    <InputLabel>{t('ORDER_BY')}</InputLabel>
                                    <Select
                                        label={t('ORDER_BY')}
                                        name="orderFieldName"
                                        onChange={handleChange}
                                        sx={{color: 'white'}}
                                        value={filters.orderFieldName}
                                    >
                                        <MenuItem value={"TenantIsDeparture"}>
                                            {t("FLIGHT_DIRECTION")}
                                        </MenuItem>
                                        <MenuItem value={"AircraftRegistration"}>
                                            {t("AIRCRAFT_REGISTRATION")}
                                        </MenuItem>
                                        <MenuItem value={"PilotFullName"}>
                                            {t("PILOT_NAME")}
                                        </MenuItem>
                                        {filters.tenantIsDeparture != null && (
                                            <MenuItem
                                                value={(filters.orderFieldName == "ArrivalAirfieldName") ? "ArrivalAirfieldName" : "DepartureAirfieldName"}>
                                                {t("AIRFIELD")}
                                            </MenuItem>)}
                                        <MenuItem value={"Date"}>
                                            {t("TIMETABLE")}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                            >
                                <FormControl fullWidth size='small'>
                                    <InputLabel>{t('ORDER_METHOD')}</InputLabel>
                                    <Select
                                        label={t('ORDER_METHOD')}
                                        name="isDescending"
                                        onChange={handleChange}
                                        sx={{color: 'white'}}
                                        value={filters.isDescending}
                                    >
                                        <MenuItem value={false as any}>
                                            {t("ASCENDING")}
                                        </MenuItem>
                                        <MenuItem value={true as any}>
                                            {t("DESCENDING")}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                sx={{display: 'flex', justifyContent: 'center', mt: 2}}
                            >
                                <Button
                                    startIcon={<CloseOutlined fontSize='large'/>}
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setFilters(defaultFilters);
                                        setFliterDialogModalOpen(false);
                                    }}
                                >
                                    {t('REMOVE_FILTERS')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Drawer>
                    {/* END FILTER DRAWER */}

                    <Grid container spacing={1} sx={{mb: '1vh'}}
                    >
                        <Grid
                            item
                            lg={5}
                            md={5}
                            xs={windowWidth < 400 ? 5 : 5}
                            alignItems='center'
                            alignContent='center'
                        >
                            <TextField
                                fullWidth
                                size='small'
                                sx={{
                                    input: {
                                        color: 'white'
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small"/>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => {
                                    setAllFilters(e.target.value, 'searchFilter');
                                }}
                                placeholder={t('SEARCH')}
                                value={filters.searchFilter}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            lg={5}
                            md={5}
                            xs={windowWidth < 400 ? 4 : 3}
                        >
                            <ToggleButtonGroup
                                color="primary"
                                value={filters.tenantIsDeparture}
                                exclusive
                                onChange={(event: any, value: boolean) => {
                                    setAllFilters(value, 'tenantIsDeparture')
                                }}
                            >
                                <ToggleButton value={true} size='small' sx={{p: 1}}>
                                    <FlightTakeoff fontSize='small'/>
                                    {mobileDevice ? <></> :
                                        <Typography sx={{ml: 1}} variant="inherit">{t('DEPARTURING')}</Typography>}
                                </ToggleButton>
                                <ToggleButton value={false} size='small' sx={{p: 1}}>
                                    <FlightLand fontSize='small'/>
                                    {mobileDevice ? <></> :
                                        <Typography sx={{ml: 1}} variant="inherit">{t('ARRIVING')}</Typography>}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid
                            item
                            lg={2}
                            md={2}
                            xs={windowWidth < 400 ? 3 : 4}
                            sx={{display: 'flex', justifyContent: windowWidth < 400 ? "flex-start" : "flex-end"}}
                        >
                            <Stack
                                direction="row"
                                spacing={-1}
                            >
                                <Tooltip title={t('FILTERS')}>
                                    <IconButton onClick={() => setFliterDialogModalOpen(true)}>
                                        <FilterAlt/>
                                    </IconButton>
                                </Tooltip>
                                {areFiltersEmpty()
                                    ? <></>
                                    : <Tooltip title={t('REMOVE_FILTERS')}>
                                        <IconButton onClick={() => {
                                            setFilters(defaultFilters);
                                        }}>
                                            <HighlightOff fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                {mobileDevice
                    ? <FlightsListTableMobile
                        paginatedFlights={flights}
                        isPageLoading={isPageLoading}
                        loggedUser={loggedUser}
                        isRecordsLoading={isRecordsLoading}
                        getMoreFlights={getMoreFlights}
                        tenantIsDeparture={filters.tenantIsDeparture}
                        updateIsCarriedFlight={updateIsCarriedFlight}
                        updateApprovedFlight={updateApprovedFlight}
                    />
                    : <FlightsListTable
                        paginationResult={flights}
                        isPageLoading={isPageLoading}
                        loggedUser={loggedUser}
                        isRecordsLoading={isRecordsLoading}
                        getMoreFlights={getMoreFlights}
                        tenantIsDeparture={filters.tenantIsDeparture}
                        updateIsCarriedFlight={updateIsCarriedFlight}
                        updateApprovedFlight={updateApprovedFlight}
                        setAllFilters={setAllFilters}
                        filters={filters}
                    />}
            </CardContent>
        </Card>
    )
}

export default FlightsListCard;
