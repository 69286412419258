import axios from 'axios';
import { Tenant } from '../models/Tenant';

export class TenantService {
	async getAllTenants(searchFilter: string = '', page: number = 1, recordsPerPage: number = 20) {
		try {
			var postBody = {
				page: page,
				recordsPerPage: recordsPerPage,
				searchFilter: searchFilter
			};

			const response = await axios.post('getAllTenants', postBody);

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getTenantById(id: string): Promise<Tenant> {
		try {
			const response = await axios.post('getTenant', { id: id });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async upsertTenant(tenant: Tenant): Promise<boolean> {
		try {
			const response = await axios.post('upsertTenant', tenant);

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async deleteTenantById(id: string): Promise<boolean> {
		try {
			const response = await axios.post('deleteTenant', {id: id});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}
}