import type {ElementType, FC, ReactNode} from 'react';
import {format} from 'date-fns';
import {Button, ButtonGroup, Grid, Typography} from '@material-ui/core';
import type {CalendarView} from '../../types/calendar';
import {useTranslation} from 'react-i18next';

interface CalendarToolbarProps {
  children?: ReactNode;
  date: Date;
  onAddClick?: () => void;
  onDateNext?: () => void;
  onDatePrev?: () => void;
  onDateToday?: () => void;
  onViewChange?: (view: CalendarView) => void;
  view: CalendarView;
}

interface ViewOption {
  icon: ElementType;
  label: string;
  value: CalendarView;
}

const CalendarToolbar: FC<CalendarToolbarProps> = (props) => {
  const {
    date,
    onDateNext,
    onDatePrev,
    onDateToday,
    onViewChange,
    view,
    ...other
  } = props;

  const { t } = useTranslation();

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={3}
      {...other}
    >
      <Grid item>
        <ButtonGroup size="small">
          <Button onClick={onDatePrev}>
            {t("BACK_BUTTON_SHORT")}
          </Button>
          <Button onClick={onDateToday}>
            {t("TODAY")}
          </Button>
          <Button onClick={onDateNext}>
            {t("NEXT_BUTTON")}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item justifyContent={"flex-end"}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {t(format(date, 'MMMM').toUpperCase()) + " " + format(date, ' y')}
        </Typography>
      </Grid>

    </Grid>
  );
};

// CalendarToolbar.propTypes = {
//   children: PropTypes.node,
//   date: PropTypes.instanceOf(Date).isRequired,
//   onAddClick: PropTypes.func,
//   onDateNext: PropTypes.func,
//   onDatePrev: PropTypes.func,
//   onDateToday: PropTypes.func,
//   onViewChange: PropTypes.func,
//   view: PropTypes.oneOf([
//     'dayGridMonth',
//     'timeGridWeek',
//     'timeGridDay',
//     'listWeek'
//   ])
// };

export default CalendarToolbar;
