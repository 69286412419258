import {Container, Typography} from "@material-ui/core";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import TenantUpsertForm from "../../components/tenant/TenantUpsertForm";
import PermissionsGuard from "../../components/PermissionGuard";

interface TenantUpsertProps {
    isAdd: boolean
}

const TenantUpsert: FC<TenantUpsertProps> = ({isAdd}) => {
    const {t} = useTranslation();
    const allowedRoles: Array<string> = isAdd ? ['SYSTEMADMIN'] : ['ADMINISTRATOR', 'SYSTEMADMIN'];

    return (
        <PermissionsGuard
            allowedRoles={allowedRoles}
            environment={'TenantUpsert'}
            needTenant={true}
        >
            <Container maxWidth={false}>
                <Typography
                    color="textPrimary"
                    variant="h5"
                    sx={{mb: 5}}
                >
                    {isAdd ? t('ADD_TENANT') : t('UPDATE_TENANT')}
                </Typography>

                <TenantUpsertForm isAdd={isAdd}/>
            </Container>
        </PermissionsGuard>
    );
}

export default TenantUpsert;
