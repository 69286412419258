import { Aircraft } from "./Aircraft";
import { Tenant } from "./Tenant";
import { TenantUser, DefaultTenantUser } from "./TenantUser";

export interface User {
  id?: string,
  currentTenantId: string,
  name: string,
  surname: string,
  roleId: string,
  email: string,
  preferredLanguage?: string,
  tenantName?: string,
  tenants: Array<Tenant>,
  tenantUser: TenantUser,
  tenantId?: string,
  avatar?: string,
  phoneNumber?: string,
  documentTypeId?: string,
  documentValue?: string,
  aircrafts?: Array<Aircraft>,
  isPilot?: boolean,
}

export const DefaultUser: User = {
  id: '',
  currentTenantId: '',
  name: '',
  surname: '',
  roleId: '',
  email: '',
  tenants: [],
  tenantUser: DefaultTenantUser,
  documentTypeId: '',
}