import axios from 'axios';
import { Notification } from '../models/Notification';
import { userInfo } from 'os';
import { User } from '../models/User';

export class NotificationService {
    async getNotificationByUserId(id: string): Promise<Notification[]> {
        try {
            var postBody =
            {
                receiverId: id
            };
            const response = await axios.post('getNotificationByUserId', postBody);

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async SetNotificationsReaded(id: string): Promise<boolean> {
        try {
            const response = await axios.post('setNotificationsReaded', { receiverId: id });

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async SetOneNotificationReaded(id: string): Promise<boolean> {
        try {
            const response = await axios.post('setOneNotificationReaded', { notificationId: id });

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetOperetionNoteficationInfo(operationIds: string[], type: string): Promise<any[]> {
        try {
            const response = await axios.post('GetOperetionNoteficationInfo',
                {
                    operationIds: operationIds,
                    type: type,
                }
            );

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetSenderUserNoteficationInfo(senderUsers: string[]): Promise<User[]> {
        try {
            const response = await axios.post('GetSenderUserNoteficationInfo',
                {
                    senderUsers: senderUsers,
                }
            );

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }

    async GetNumberOfNotification(id: string): Promise<number> {
        try {
            const response = await axios.post('GetNumberOfNotefication',
                {
                    receiverId: id,
                }
            );

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }
}

