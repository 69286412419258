import axios from 'axios';
import { Flight } from "../models/Flight";
import { PaginationResult } from '../types/paginationResult';
import { FlightsCalendar } from '../models/FlightsCalendar';

import { FlightLog } from '../models/FlightLog';

export class FlightService {
	async upsertFlight(flight: Flight): Promise<boolean> {
		try {
			const response = await axios.post('upsertflight', flight);
			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}
	async upsertFlightByPilot(flight: Flight): Promise<boolean> {
		try {
			const response = await axios.post('upsertFlightByPilot', flight);
			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}


	async getFlightById(id: string): Promise<Flight> {
		try {
			const response = await axios.post('getFlight', { id: id });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getFlightsById(ids: string[]): Promise<Flight[]> {
		try {
			const response = await axios.post('getFlights', { ids });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getFlightLogById(id: string): Promise<FlightLog[]> {
		try {
			const response = await axios.post('getFlightLog', { id: id });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAllflightsByUserId(page: number, recordsPerPage: number, userId: string, filters: any): Promise<PaginationResult<Flight>> {
		try {
			var postBody = {
				page: page,
				recordsPerPage: recordsPerPage,
				userId: userId,
				searchFilter: filters.searchFilter,
				startDepartureDate: filters.startDepartureDate,
				endDepartureDate: filters.endDepartureDate,
				startArrivalDate: filters.startArrivalDate,
				endArrivalDate: filters.endArrivalDate,
				tenantIsDeparture: filters.tenantIsDeparture,
				orderFilter:
				{
					orderFieldName: filters.orderFieldName,
					isDescending: filters.isDescending
				}
			};
			const response = await axios.post<PaginationResult<Flight>>('getAllFlightsByUserId', postBody);

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAllflights(page: number, recordsPerPage: number, userId: string, tenantId: string, filters: any): Promise<PaginationResult<Flight>> {
		try {
			var postBody = {
				page: page,
				recordsPerPage: recordsPerPage,
				tenantId: tenantId,
				userId: userId,
				searchFilter: filters.searchFilter,
				startDepartureDate: filters.startDepartureDate,
				endDepartureDate: filters.endDepartureDate,
				startArrivalDate: filters.startArrivalDate,
				endArrivalDate: filters.endArrivalDate,
				tenantIsDeparture: filters.tenantIsDeparture,
				orderFilter:
				{
					orderFieldName: filters.orderFieldName,
					isDescending: filters.isDescending
				}
			};
			const response = await axios.post<PaginationResult<Flight>>('getAllFlights', postBody);

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async deleteFlightById(id: string, userId: string): Promise<boolean> {
		try {
			const response = await axios.post('deleteFlight',
				{
					id: id,
					userId: userId
				});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async updateApprovedFlightById(flightId: string, approved: boolean, userId: string) {
		try {
			const response = await axios.post('updateApprovedFlight', {
				flightId: flightId,
				approved: approved,
				loggedUserId: userId
			});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async updateIsCarriedFlightById(flightId: string, isCarried: boolean, userId: string) {
		try {
			const response = await axios.post('updateIsCarriedFlight', {
				flightId: flightId,
				isCarried: isCarried,
				userId: userId
			});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getCalendarFlights(userId: string, tenantId: string, filters: any): Promise<FlightsCalendar[]> {
		try {
			const response = await axios.post('GetFlightsInCalendar', {
				tenantId: tenantId,
				userId: userId,
				startDate: filters.startDate,
				endDate: filters.endDate,
				tenantIsDeparture: filters.tenantIsDeparture,
				orderFilter:
				{
					orderFieldName: filters.orderFieldName,
					isDescending: filters.isDescending
				}
			});
			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getCalendarPilotFlights(userId: string, filters: any): Promise<FlightsCalendar[]> {
		try {
			const response = await axios.post('GetPilotFlightsInCalendar', {
				userId: userId,
				startDate: filters.startDate,
				endDate: filters.endDate,
				tenantIsDeparture: filters.tenantIsDeparture,
				orderFilter:
				{
					orderFieldName: filters.orderFieldName,
					isDescending: filters.isDescending
				}
			});
			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getFlightsReport(userId: string, tenantId: string, email: string, filters: any, isShort: boolean): Promise<FlightsCalendar[]> {
		try {
			const response = await axios.post('GetReportFlights', {
				userId: userId,
				tenantId: tenantId,
				reportEmail: email,
				startDate: filters.startDate,
				endDate: filters.endDate,
				tenantIsDeparture: filters.tenantIsDeparture,
				orderFilter:
				{
					orderFieldName: filters.orderFieldName,
					isDescending: filters.isDescending
				},
				shortReport: isShort,
			});
			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

}
