import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Skeleton,
    Table,
    TableBody,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom";
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { FC, useEffect, useState } from "react";
import { Aircraft } from "../../models/Aircraft";
import TableRowsLoaderVariant from "../../components/TableRowsLoaderVariant"
import { useTranslation } from "react-i18next";
import { AircraftCategory } from "../../models/AircraftCategory";
import { AircraftTipology } from "../../models/AircraftTipology";
import { AircraftCategoryService } from "../../services/AircraftCategoryService";
import { AircraftTipologyService } from "../../services/AircraftTipologyService";

interface MyAircraftsCardsListProps {
    aircrafts: Aircraft[];
    isLoading: boolean;
}

const MyAircraftsCardsListMobile: FC<MyAircraftsCardsListProps> = (props) => {

    const { aircrafts, isLoading } = props;
    const currentTheme = useTheme();
    const isSmallMobile = useMediaQuery(currentTheme.breakpoints.down(550));
    const { t } = useTranslation();

    const aircraftCategoryService = new AircraftCategoryService();
    const aircraftTipologyService = new AircraftTipologyService();

    const [aircraftCategoryList, setAircraftCategoryList] = useState<Array<AircraftCategory>>([]);
    const [aircraftTipologyList, setAircraftTipologyList] = useState<Array<AircraftTipology>>([]);

    useEffect(() => {
        (async () => {
            await getAircraftTipology();
            await getAircraftCategory();
        })();
    }, []);

    const getAircraftCategory = async () => {
        let retrievedAircraftCategory = await aircraftCategoryService.getAllAircraftCategory();
        setAircraftCategoryList(retrievedAircraftCategory);
    }

    const getAircraftTipology = async () => {
        let retrievedAircraftTipology = await aircraftTipologyService.getAllAircraftTipology();
        setAircraftTipologyList(retrievedAircraftTipology);
    }

    return <>
        <Table>
            <TableBody>
                {isLoading
                    ? <>
                        <TableRowsLoaderVariant rowsNum={20} height={400} hasDivider={false} />
                    </>
                    : <>
                        {aircrafts.map((aircraft: Aircraft) => (
                            <Container>
                                <Card sx={{ mt: 3, mb: 2 }}>
                                    {aircraft.aircraftPhoto == undefined ? (<><Box sx={{ alignItems: 'flex-start' }}>
                                        <Skeleton variant="rectangular" animation="wave" height={250} /></Box></>) : (
                                        <>
                                            {!(aircraft.aircraftPhoto == "" || aircraft.aircraftPhoto == "https://cdn.jetphotos.com/full/null") ?
                                                <CardMedia
                                                    component="img"
                                                    sx={{ height: 250 }}
                                                    image={aircraft.aircraftPhoto}
                                                    title=""

                                                /> : <Box sx={{
                                                    mt: 7,
                                                    mb: 7,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <NoPhotographyIcon sx={{ fontSize: 80 }} />
                                                </Box>}
                                        </>)}

                                    <CardContent>
                                        {!isSmallMobile
                                            ? <><Grid container rowSpacing={4} alignItems="center"
                                                justifyContent="space-between">
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <Typography
                                                            align='center'><strong>{aircraft.aircraftRegistration.toUpperCase()}</strong></Typography>

                                                    </Box>
                                                </Grid>
                                                <Grid item md={5} width={155} justifyContent="center">
                                                    <Typography align='left'>
                                                        {t("AIRCRAFT_TIPOLOGY_SHORT")}: <br />{t(aircraftTipologyList.find(aircraftT => aircraftT.id == aircraft.aircraftTipologyId)?.name || "")}
                                                    </Typography>
                                                    <Typography align='left'
                                                        sx={{ mt: 2 }}>{t("AIRCRAFT_TYPE_SHORT")}: <br />{aircraft.aircraftType}
                                                    </Typography>
                                                    <Typography align='left'
                                                        sx={{ mt: 2 }}>{t("HAVE_RADIO")}: <br />{aircraft.haveRadio ? t("YES") : t("NO")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={5} width={155} justifyContent="center">
                                                    <Typography
                                                        align='right'>{t("AIRCRAFT_CATEGORY_SHORT")}:<br /> {t(aircraftCategoryList.find(aircraftC => aircraftC.id == aircraft.aircraftCategoryId)?.name || "")}
                                                    </Typography>
                                                    <Typography align='right'
                                                        sx={{ mt: 2 }}>{t("COLOR")}:<br /> {aircraft.color}
                                                    </Typography>
                                                    <Typography align='right'
                                                        sx={{ mt: 2 }}>{t("HAVE_TRANSPONDER")}: <br />{aircraft.haveTransponder ? t("YES") : t("NO")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            </>
                                            : <><Grid container rowSpacing={4} alignItems="center"
                                                justifyContent="space-between"></Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <Typography align='center' sx={{
                                                            mt: 4,
                                                            mb: 1
                                                        }}><strong>{aircraft.aircraftRegistration.toUpperCase()}</strong></Typography>

                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} justifyContent="center">
                                                    <Typography align='center'
                                                        sx={{ mt: 2 }}>{t("AIRCRAFT_TYPE_SHORT")}: {aircraft.aircraftType}</Typography>

                                                    <Typography align='center' sx={{ mt: 1 }}>
                                                        {t("AIRCRAFT_TIPOLOGY_SHORT")}: {t(aircraftTipologyList.find(aircraftT => aircraftT.id == aircraft.aircraftTipologyId)?.name || "")}
                                                    </Typography>

                                                    <Typography align='center' sx={{ mt: 1 }}>
                                                        {t("AIRCRAFT_CATEGORY_SHORT")}: {t(aircraftCategoryList.find(aircraftC => aircraftC.id == aircraft.aircraftCategoryId)?.name || "")}
                                                    </Typography>

                                                    <Grid item xs={12} justifyContent="center">
                                                        <Typography align='center'
                                                            sx={{ mt: 1 }}>{t("COLOR")}: {aircraft.color}</Typography>
                                                        <Typography align='center'
                                                            sx={{ mt: 1 }}>{t("HAVE_RADIO")}: {aircraft.haveRadio ? t("YES") : t("NO")}</Typography></Grid>
                                                    <Typography align='center'
                                                        sx={{ mt: 1 }}>{t("HAVE_TRANSPONDER")}: {aircraft.haveTransponder ? t("YES") : t("NO")}</Typography></Grid>

                                            </>}
                                    </CardContent>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <Tooltip title={t('EDIT') || ''} aria-label={t('EDIT') || ''}>
                                            <Button variant="contained" fullWidth sx={{ height: 40, color: "white" }}
                                                style={{ borderRadius: 0 }}
                                                component={RouterLink}
                                                to={`edit?aircraftId=${aircraft.id}`}>
                                                {t("EDIT")}
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </Card>
                            </Container>
                        ))}
                    </>}
            </TableBody>
        </Table>
    </>
}
export default MyAircraftsCardsListMobile;
