export interface Airfield {
    id?: string;
    code?: string;
    icaoCode?: string;
    latitude: number;
    longitude: number;
    name: string;
    region: string;
    province: string;
    municipality: string;
    firstPhotoUrl?: string;
    isRegistred?: string;
    tenantId?: string;
}

export const DefaultAirfield: Airfield = {
    id: '',
    icaoCode: '',
    latitude: 0,
    longitude: 0,
    name: '',
    region: '',
    province: '',
    municipality: '',
    tenantId: '',
};