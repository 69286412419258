import axios from 'axios';
import { PaginationResult } from '../types/paginationResult';
import { Airfield } from '../models/Airfield';

export class AirfieldService {
	async getAllAirfields(searchFilter: string = '', page: number = 1, recordsPerPage: number = 20): Promise<PaginationResult<Airfield>> {
		try {
			const response = await axios.post('getAllAirfields', {
				page: page,
				recordsPerPage: recordsPerPage,
				searchFilter: searchFilter
			});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAllRegistredAirfields(searchFilter: string = '', page: number = 1, recordsPerPage: number = 20): Promise<PaginationResult<Airfield>> {
		try {
			const response = await axios.post('GetAllRegistredAirfields', {
				page: page,
				recordsPerPage: recordsPerPage,
				searchFilter: searchFilter
			});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async upsertAirfieldPhotos(photos: string[], id: string) {
		try {
			var response = await axios.post('UpdateAirfieldPhotos', { photos: photos, id: id });
			return response.data;

		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAirfieldPhoto(id: string): Promise<string[]> {
		try {
			var response = await axios.post('GetAirfieldPhoto', { id: id });
			return response.data;

		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAirfieldById(id: string): Promise<Airfield> {
		try {
			const response = await axios.post('getAirfield', { id: id });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async upsertAirfield(airfield: Airfield): Promise<boolean> {
		try {
			const response = await axios.post('upsertAirfield', airfield);

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async deleteAirfieldById(id: string): Promise<boolean> {
		try {
			const response = await axios.post('deleteAirfield', { id: id });

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}

	async getAirfieldFirstPhoto(id: string): Promise<string> {
		try {
			var response = await axios.post('GetAirfieldFirstPhoto', { id: id });
			return response.data;

		} catch (error) {
			throw new Error("Error: " + error);
		}
	}
}