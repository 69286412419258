import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Airfield = createSvgIcon(
    <svg fill="currentColor"
        viewBox="0 0 512 512"
        enable-background="new 0 0 512 512"
        id="hangar" version="1.1"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M440.043,335.223v-18.881l-34.322-33.402l-0.008-0.007l-3.439-3.349l-0.053-4.811c-0.104-9.596-0.236-20.189-0.33-25.313  c-23.432-33.334-52.176-57.272-85.482-71.183c-26.891-11.229-48.695-12.442-56.822-12.442c-1.461,0-2.369,0.041-2.703,0.059  c-0.297,0.016-0.589,0.023-0.882,0.023s-0.586-0.008-0.894-0.024c-0.323-0.017-1.232-0.058-2.692-0.058  c-8.127,0-29.934,1.213-56.826,12.445c-33.302,13.908-62.042,37.844-85.478,71.181c-0.094,5.123-0.228,15.717-0.331,25.313  l-0.051,4.811l-3.447,3.355l-34.323,33.402v18.881v51.4h34.329h57.445v-99.672h184.535v99.672h57.445h34.33V335.223z   M298.225,230.104c0,0.92-0.744,1.665-1.664,1.665h-81.12c-0.919,0-1.664-0.745-1.664-1.665v-15.591c0-0.92,0.745-1.665,1.664-1.665  h81.12c0.92,0,1.664,0.745,1.664,1.665V230.104z"/><path d="M436.887,233.307c-17.09-27.8-74.203-106.096-180.886-107.93c-106.684,1.834-163.795,80.13-180.887,107.93l0.742,20.32  l-26.159,25.294c-2.321,2.147-3.641,5.165-3.641,8.327v27.471c0,2.716,2.224,4.522,4.546,4.522c1.09,0,2.202-0.398,3.117-1.288  l44.494-43.304c0,0,0.308-28.771,0.438-28.961c13.025-19.053,26.943-34.144,40.912-46.102  c49.319-42.22,99.274-45.319,112.852-45.319c1.702,0,2.829,0.049,3.315,0.075c0.091,0.005,0.181,0.007,0.271,0.007  s0.18-0.002,0.271-0.007c0.484-0.026,1.615-0.075,3.314-0.075c13.572,0,63.523,3.096,112.844,45.312  c13.971,11.959,27.893,27.052,40.92,46.109c0.131,0.19,0.438,28.961,0.438,28.961l44.496,43.304c0.914,0.89,2.025,1.288,3.115,1.288  c2.322,0,4.545-1.807,4.545-4.522v-27.471c0-3.162-1.318-6.18-3.641-8.327l-25.449-24.608L436.887,233.307z"
            />
    </svg>, 'Airfield'

);

export default Airfield;