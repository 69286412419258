import axios from 'axios';

export class FlightTypeService {
    async getAllFlightTypes() {
        try {
            const response = await axios.post('getAllFlightTypes');

            return response.data;
        } catch (error) {
            throw new Error("Error: " + error);
        }
    }
}