import type {FC} from "react";
import {useRef, useState} from "react";
import {Avatar, Box, Button, ButtonBase, Divider, Popover, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";

const AccountPopover: FC = () => {
  const { logout } = useAuth0();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);
  const { t } = useTranslation();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (event: any): Promise<void> => {
    await logout();
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user?.tenantUser?.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {user?.email}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            {t("LOGOUT")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
