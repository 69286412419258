export interface Tenant {
  id?: string;
  userId?: string,
  name: string;
  logo?: string;
  icaoCode?: string;
  latitude: number;
  longitude: number;
  region: string;
  province: string;
  municipality: string;
  isPublic: boolean;
  managerFullName: string;
  managerFiscalCode: string;
  managerPhoneNumber: string;
  airfieldId: string;
  airfieldName?: string;
  isPublicFormReleased?: boolean;
  regulationLink?: string;
}

export const DefaultTenant: Tenant = {
  name: '',
  latitude: 0,
  longitude: 0,
  region: '',
  province: '',
  municipality: '',
  isPublic: false,
  managerFullName: '',
  managerFiscalCode: '',
  managerPhoneNumber: '',
  airfieldId: ''
}