import { Skeleton, TableCell, TableRow } from "@material-ui/core";

const TableRowsLoader = ({ rowsNum, colsNum }) => {
    return <>
			{
				[...Array(rowsNum)].map((row, index) => (
					<TableRow key={index}>
						{
							[...Array(colsNum)].map((row, index) => (
								<TableCell scope="row" key={index}>
									<Skeleton animation="wave" variant="text" />
								</TableCell>
							))
						}
					</TableRow>
				))
			}
    </>
  };

export default TableRowsLoader;