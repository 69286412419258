import axios from 'axios';

export class RoleService {
	async getAllRoles(userId: string | undefined = undefined, tenantId: string | undefined = undefined) {
		try {
			const response = await axios.post('getAllRoles', {
				userId: userId,
				tenantId: tenantId
			});

			return response.data;
		} catch (error) {
			throw new Error("Error: " + error);
		}
	}
}