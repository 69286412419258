import {Container, Typography} from "@material-ui/core";
import {FC} from "react";
import UserUpsertForm from "../../components/user/UserUpsertForm";
import {useTranslation} from "react-i18next";
import PermissionsGuard from "../../components/PermissionGuard";

interface UserUpsertProps {
  isAdd: boolean
}

const UserUpsert: FC<UserUpsertProps> = ({ isAdd }) => {
  const { t } = useTranslation();

  return (
    <PermissionsGuard
      allowedRoles={['SYSTEMADMIN', 'ADMINISTRATOR']}
      environment={'UserUpsert'}
      needTenant={true}
    >
      <Container maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h5"
          sx={{ mb: 5 }}
        >
          {isAdd ? t('ADD_USER') : t('UPDATE_USER')}
        </Typography>

        <UserUpsertForm isAdd={isAdd} />
      </Container>
    </PermissionsGuard>
  );
}

export default UserUpsert;
