import type {FC} from 'react';
import {useEffect, useState} from 'react';
import {Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import {UserService} from '../services/UserService';
import {AppState} from "../store";
import {User} from "../models/User";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from 'react-i18next';
import {composeNotistackMessage} from '../utils/common';
import {useNavigate} from 'react-router-dom';
import {actionCreators as appInitAction} from '../store/AppInit';

interface ChangeTenantFormProps {
    onAddComplete: () => void;
    onCancel?: () => void;
}

const ModalChangeTenant: FC<ChangeTenantFormProps> = ({onAddComplete, onCancel}) => {
    const [selectedTenantSelectItem, setSelectedTenantSelectItem] = useState<string>('');
    const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);
    const {t} = useTranslation();
    const userService = new UserService();
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedTenantSelectItem(user?.currentTenantId ?? '');
    }, []);

    const history = useNavigate();

    async function updateCurrentTenant() {
        if (user) {
            let updateCurrentTenant: boolean = await userService.updateCurrentTenant(user.id, selectedTenantSelectItem);

            if (updateCurrentTenant) {
                user.currentTenantId = selectedTenantSelectItem;
                dispatch(appInitAction.setUser(user));

                composeNotistackMessage(t('TENANT_CHANGED'), 'success');
                onAddComplete();

                // TODO: Used to change tenant in Sidebar. To fix
                history('/smartairfield');

                history('/');
            } else {
                composeNotistackMessage(t('TENANT_NOT_CHANGED'), 'error');
            }
        }
    }

    return (
        <Box
            sx={{
                backgroundColor: '#2d3640',
                minHeight: '100%',
                p: 3
            }}
        >
            <Typography
                color="textPrimary"
                variant="h6"
            >
                {t('CHANGE_TENANT')}
            </Typography>
            <Box sx={{mt: 3, pb: 3}}>
                <FormControl fullWidth required>
                    <InputLabel>{t('TENANT')}</InputLabel>
                    <Select
                        label={t('TENANT')}
                        name="currentTenantId"
                        onChange={(e) => {
                            setSelectedTenantSelectItem(e.target.value);
                        }}
                        size='small'
                        sx={{color: 'white'}}
                        value={selectedTenantSelectItem || ''}
                    >
                        {user?.tenants?.sort(function (a, b) {
                            return a.name.localeCompare(b.name);
                        }).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Divider/>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    p: 2
                }}
            >
                <Box sx={{flexGrow: 1}}/>
                <Button
                    color="primary"
                    onClick={onCancel}
                    variant="text"
                >
                    {t('CANCEL')}
                </Button>
                <Button
                    color="primary"
                    sx={{ml: 1}}
                    type="submit"
                    variant="contained"
                    onClick={async () => {
                        updateCurrentTenant()
                    }}
                >
                    {t('CONFIRM')}
                </Button>
            </Box>
        </Box>
    )
};

export default ModalChangeTenant;
