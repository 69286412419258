import {Container, Typography} from "@material-ui/core";
import {FC} from "react";
import FlightUpsertCard from "../../components/flight/FlightUpsertCard";
import {useTranslation} from "react-i18next";
import PermissionsGuard from "../../components/PermissionGuard";

interface FlightUpsertProps {
  isAdd: boolean | null
}

const FlightUpsert: FC<FlightUpsertProps> = ({ isAdd }) => {
  const { t } = useTranslation();

  return (
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'USER']}
      environment={'FlightUpsert'}
      needTenant={true}

    >
      <Container maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h5"
          sx={{ mb: 4 }}
        >
          {isAdd == true ? t('ADD_FLIGHT') : isAdd == false ? t('UPDATE_FLIGHT') : t('RETURN_FLIGHT')}
        </Typography>




        <FlightUpsertCard isAdd={isAdd!} />
      </Container>
    </PermissionsGuard>
  );
}

export default FlightUpsert;
