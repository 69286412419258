import { Box, Divider, Skeleton } from "@material-ui/core";

const TableRowsLoaderVariant = ({ rowsNum, height, hasDivider }) => {
	return <>
		{
			[...Array(rowsNum)].map(() => (
				<>
					<Box sx={{ mb: 2, mx: 1.5 }}><Skeleton animation="wave" variant="rectangular" height={height} sx={{ mt: 2 }} /></Box>
					{hasDivider ? (<><Divider /></>) : <></>}

				</>
			))
		}
	</>
};

export default TableRowsLoaderVariant;
