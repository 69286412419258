import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    debounce,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Typography
} from "@material-ui/core";
import {DefaultFlight, Flight} from "../../models/Flight";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {MobileDateTimePicker} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import {Airfield} from "../../models/Airfield";
import {AirfieldService} from "../../services/AirfieldService";
import {FlightTypeService} from "../../services/FlightTypeService";
import {FlightType} from "../../models/FlightType";
import {DocumentType} from "../../models/DocumentType";
import {
    composeNotistackMessage,
    getFormValue,
    getUTCDate,
    isEmpty,
    StringIsNullOrEmpty,
    userCanUseIfRoles
} from "../../utils/common";
import {FlightValidator} from "../../validators/FlightValidator";
import {TenantService} from "../../services/TenantService";
import {Tenant} from "../../models/Tenant";
import {useSelector} from "react-redux";
import {User} from "../../models/User";
import {AppState} from "../../store";
import {FlightService} from "../../services/FlightService";
import {useNavigate} from "react-router";
import {DocumentTypeService} from "../../services/DocumentTypeService";
import {AircraftCategory} from "../../models/AircraftCategory";
import {AircraftTipology} from "../../models/AircraftTipology";
import {AircraftCategoryService} from "../../services/AircraftCategoryService";
import {AircraftTipologyService} from "../../services/AircraftTipologyService";
import CardContentLoader from "../CardContentLoader";
import {CheckCircle, RemoveCircle} from "@material-ui/icons";
import LoaderVariant from "../LoaderVariant";
import {AircraftService} from "../../services/AircraftService";

interface FlightUpsertFormProps {
    isAdd: boolean | null,
    isDeparture: boolean,
    getTabIndexByFlight: Function,
    getTenantIsDepartureByFlight: Function
}

const FlightUpsertForm: FC<FlightUpsertFormProps> = (props) => {

    const {isAdd, isDeparture, getTabIndexByFlight, getTenantIsDepartureByFlight} = props;

    const [flight, setFlight] = useState<Flight>(DefaultFlight);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isUpsertLoading, setIsUpsertLoading] = useState<boolean>(false);
    const [isLoadingAircraftPhoto, setIsLoadingAircraftPhoto] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<string>>([]);
    const [flightTypes, setFlightTypes] = useState<Array<FlightType>>([]);
    const [documentTypes, setDocumentTypes] = useState<Array<DocumentType>>([]);
    const [aircraftCategoryList, setAircraftCategoryList] = useState<Array<AircraftCategory>>([]);
    const [aircraftTipologyList, setAircraftTipologyList] = useState<Array<AircraftTipology>>([]);
    const [openDeparture, setOpenDeparture] = useState(false);
    const [openArrival, setOpenArrival] = useState(false);
    const [onFocusDeparture, setOnFocusDeparture] = useState(false);
    const [onFocusArrival, setOnFocusArrival] = useState(false);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
    const [removeFlightModalOpen, setRemoveFlightModalOpen] = useState(false);
    const [aircraftPhoto, setAircraftPhoto] = useState<string | undefined>('');
    const [approvedMenuAnchorEl, setApprovedMenuAnchorEl] = useState<null | HTMLElement>(null);

    const history = useNavigate();
    const {t} = useTranslation();

    const flightValidator = new FlightValidator(t);
    const airfieldService = new AirfieldService();
    const flightTypeService = new FlightTypeService();
    const tenantService = new TenantService();
    const flightService = new FlightService();
    const documentTypeService = new DocumentTypeService();
    const aircraftCategoryService = new AircraftCategoryService();
    const aircraftTipologyService = new AircraftTipologyService();
    const aircraftService = new AircraftService();

    const handleFlightModalOpen = () => setRemoveFlightModalOpen(true);
    const handleFlightModalClose = () => setRemoveFlightModalOpen(false);

    const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            await Promise.all([
                getAirfields(),
                getDocumentTypes(),
                getFlightTypes(),
                getAircraftTipology(),
                getAircraftCategory()
            ])

            if (isAdd) {
                setFlight((currentFormData: any) => ({
                    ...currentFormData,
                    userId: loggedUser?.id,
                    tenantIsDeparture: isDeparture
                }));
                setFlight((currentFormData: any) => ({
                    ...currentFormData,
                    copilotFullName: null,
                    copilotPhoneNumber: null,
                    copilotEmail: null,
                    copilotDocumentValue: null,
                }));

            } else {
                let flightId: string = window.location.href.split('flightId=')[1] ?? '';
                let retrievedFlight: Flight = await flightService.getFlightById(flightId);

                retrievedFlight.date = getUTCDate(retrievedFlight.date);

                if (isAdd == null) {
                    retrievedFlight.id = undefined;
                    if (retrievedFlight.tenantIsDeparture) {
                        retrievedFlight.departureAirfieldName = retrievedFlight.arrivalAirfieldName
                        retrievedFlight.arrivalAirfieldName = ""
                    } else {
                        retrievedFlight.arrivalAirfieldName = retrievedFlight.departureAirfieldName
                        retrievedFlight.departureAirfieldName = ""
                    }


                    if (retrievedFlight.nextMovementDate != null) {
                        retrievedFlight.date = retrievedFlight.nextMovementDate;
                        retrievedFlight.nextMovementDate = "";
                    }
                    retrievedFlight.tenantIsDeparture = !retrievedFlight.tenantIsDeparture
                }
                setFlight(retrievedFlight);

                await searchAircraftPhoto(retrievedFlight.userId!, retrievedFlight.aircraftRegistration);

                getTabIndexByFlight(retrievedFlight.tenantIsDeparture);
                getTenantIsDepartureByFlight(retrievedFlight.tenantIsDeparture);
            }

            setFlight(prevState => ({
                ...prevState,
                tenantId: loggedUser?.currentTenantId ?? '',
                loggedUserId: loggedUser?.id ?? ''
            }));

            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoadingOptions(true);

            await getAirfields(searchFilter);
        })()
    }, [searchFilter])

    useEffect(() => {
        (async () => {
            if (!openDeparture && !openArrival) {
                setOptions([]);
            } else if (openDeparture || openArrival) {
                setLoadingOptions(true);

                await getAirfields();
            }
        })();
    }, [openDeparture, openArrival])

    const fieldsNotToCheck = [

        //TODO: Remove tenantId e userId from here
        'tenantId',
        'userId',
        isDeparture ? 'departureAirfieldName' : 'arrivalAirfieldName',
    ];

    const handleChange = (e: any) => {
        let {name, value} = getFormValue(e);
        setFlight((currentFormData: any) => ({
            ...currentFormData,
            [name]: value
        }))
    }

    const handleCopilotChange = (e: any) => {
        let {name, value} = getFormValue(e);
        if (value == "") {
            setFlight((currentFormData: any) => ({
                ...currentFormData,
                [name]: null
            }))
        } else {
            handleChange(e);
        }
    }

    const searchAircraftPhoto = async (userId: string, aircraftReg: string) => {
        setIsLoadingAircraftPhoto(true);
        let urlAircraftPhoto = await aircraftService.GetAircraftPhotoUrl(userId, aircraftReg);
        if (urlAircraftPhoto == "https://cdn.jetphotos.com/full/null" || StringIsNullOrEmpty(urlAircraftPhoto) || StringIsNullOrEmpty(aircraftReg))
            setAircraftPhoto(undefined);
        else
            setAircraftPhoto(urlAircraftPhoto);
        setIsLoadingAircraftPhoto(false);
    }

    const searchAircraftPhotoWithInput = async (e: any) => {
        setIsLoadingAircraftPhoto(true);
        let {name, value} = getFormValue(e);

        let urlAircraftPhoto = await fetch("https://jet-photos.azurewebsites.net/api/get-jet-photo?query=" +
            value,
            {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
        .then(response => response.json())
        .then(result => {
            return result.url;
        })
        .catch(error => console.log('error', error));

        if (urlAircraftPhoto == "https://cdn.jetphotos.com/full/null" || urlAircraftPhoto == "" || value == "")
            setAircraftPhoto(undefined);
        else
            setAircraftPhoto(urlAircraftPhoto);
        setIsLoadingAircraftPhoto(false);
    }


    const handleDateChange = (date: any, name: string) => {
        let value = isEmpty(date) ? '' : date.format();
        setFlight(prevState => ({...prevState, [name]: value}))
    }

    const handleAutocompleteChange = (value: string | undefined, name: string) => {
        setFlight(prevState => ({...prevState, [name]: value}));
    }

    const getAirfields = useCallback(debounce(async (searchFilter: string = '') => {
            let [retrievedTenants, retrievedAirfields] = await Promise.all([
                tenantService.getAllTenants(searchFilter),
                airfieldService.getAllAirfields(searchFilter)
            ])

            let tenantOptions: Array<string> = retrievedTenants.items
            .filter((tenant: Tenant) => tenant.name != loggedUser?.tenantName)
            .map((tenant: Tenant) => {
                return tenant.name + ' (SmartAirfield)';
            });

            const currentTenantAirfieldId = loggedUser?.tenants.find(tenant => tenant.id === loggedUser.currentTenantId)?.airfieldId;

            let airfieldOptions: Array<string> = retrievedAirfields.items
            .filter((airfield: Airfield) => airfield.id != currentTenantAirfieldId)
            .map((airfield: Airfield) => airfield.name);

            setOptions([...tenantOptions, ...airfieldOptions]);
            setLoadingOptions(false);
        }, 700
    ), [])

    const getFlightTypes = async () => {
        let retrievedFlightTypes = await flightTypeService.getAllFlightTypes();
        setFlightTypes(retrievedFlightTypes);

        if (isAdd) {
            let defaultFlightTypeId = retrievedFlightTypes.find(flightType => flightType.name == 'TOURISTIC')?.id;
            setFlight(prevState => ({...prevState, flightTypeId: defaultFlightTypeId}))
        }
    }

    const getDocumentTypes = async () => {
        let retrievedDocumentTypes = await documentTypeService.getAllDocumentTypes();
        setDocumentTypes(retrievedDocumentTypes);
        if (isAdd) {
            setFlight(prevState => ({
                ...prevState,
                pilotDocumentTypeId: "000000000000000000000000",
                copilotDocumentTypeId: "000000000000000000000000"
            }));
        }
    }
    const getAircraftCategory = async () => {
        let retrievedAircraftCategory = await aircraftCategoryService.getAllAircraftCategory();
        setAircraftCategoryList(retrievedAircraftCategory);

        if (isAdd) {
            let defaultAircraftCategoryId = retrievedAircraftCategory.find(aircraftCategory => aircraftCategory
                .name == 'ULTRALIGHT')?.id;
            setFlight(prevState => ({...prevState, aircraftCategoryId: defaultAircraftCategoryId}))
        }
    }

    const getAircraftTipology = async () => {
        let retrievedAircraftTipology = await aircraftTipologyService.getAllAircraftTipology();
        setAircraftTipologyList(retrievedAircraftTipology);

        if (isAdd) {
            let defaultAircraftTipologyId = retrievedAircraftTipology.find(aircraftTypology => aircraftTypology
                .name == 'AIRPLANE')?.id;
            setFlight(prevState => ({...prevState, aircraftTipologyId: defaultAircraftTipologyId}))
        }
    }

    const upsertFlight = async () => {
        setIsUpsertLoading(true);
        let validateFlightResult = flightValidator.validateFlight(flight, fieldsNotToCheck);

        if (validateFlightResult) {
            let upsertFlightResult = await flightService.upsertFlight(flight);

            if (upsertFlightResult) {
                composeNotistackMessage(isAdd ? t('FLIGHT_ADDED') : t('FLIGHT_UPDATED'), 'success');
                history('/flights');
            } else {
                composeNotistackMessage(isAdd ? t('FLIGHT_NOT_ADDED') : t('FLIGHT_NOT_UPDATED'), 'error');
            }
        }
        setIsUpsertLoading(false);
    }

    const deleteFlight = async () => {

        let deleteFlightResult = await flightService.deleteFlightById(flight.id!, loggedUser?.id ?? '');

        if (deleteFlightResult) {
            composeNotistackMessage(t('FLIGHT_DELETED'), 'success');
            history('/flights');
        } else {
            composeNotistackMessage(t('FLIGHT_NOT_DELETED'), 'error');
        }
    }

    return <>
        <LoaderVariant isLoading={isUpsertLoading}/>
        <Dialog
            open={removeFlightModalOpen}
            onClose={handleFlightModalClose}
            aria-labelledby="alert-remove-flight-title"
            aria-describedby="alert-remove-flight-description"
        >
            <DialogTitle id="remove-flight-title">
                {t('TITLE_MODAL_DELETE_FLIGHT')}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleFlightModalClose} variant="outlined" color="error">{t('CANCEL')}</Button>
                <Button
                    onClick={async () => {
                        handleFlightModalClose();
                        await deleteFlight();
                    }}
                    variant="contained"
                    color="error">
                    {t('DELETE')}
                </Button>
            </DialogActions>
        </Dialog>
        {isLoading
            ? <CardContentLoader/>
            : <>
                {isLoadingAircraftPhoto
                    ? <>
                        <Skeleton sx={{height: 250,}} animation="wave" variant="rectangular"></Skeleton>
                    </>
                    : <>
                        {(aircraftPhoto != undefined)
                            &&
                            <Box sx={{mb: 3, display: 'flex', justifyContent: 'center'}}>
                                <img src={aircraftPhoto} style={{
                                    maxHeight: 250,
                                    objectFit: 'contain',
                                }}/>
                            </Box>
                        }
                    </>
                }
                <Divider sx={{mt: 2, mb: 2}}>{t("AIRCRAFT")}</Divider>

                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('AIRCRAFT_REGISTRATION')}
                            name="aircraftRegistration"
                            required
                            onChange={handleChange}
                            value={flight.aircraftRegistration}
                            onBlur={searchAircraftPhotoWithInput}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('AIRCRAFT_TYPE')}
                            name="aircraftType"
                            required
                            onChange={handleChange}
                            value={flight.aircraftType}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    > <FormControl fullWidth required size='small'>
                        <InputLabel>{t('AIRCRAFT_TIPOLOGY')}</InputLabel>
                        <Select
                            label={t('AIRCRAFT_TIPOLOGY')}
                            name="aircraftTipologyId"
                            onChange={handleChange}
                            sx={{color: 'white'}}
                            value={flight.aircraftTipologyId}
                        >
                            {aircraftTipologyList.map((aircraftT: AircraftTipology) => (
                                <MenuItem key={aircraftT.id} value={aircraftT.id}>
                                    {t(aircraftT.name)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <FormControl fullWidth required size='small'>
                            <InputLabel>{t('AIRCRAFT_CATEGORY')}</InputLabel>
                            <Select
                                label={t('AIRCRAFT_CATEGORY')}
                                name="aircraftCategoryId"
                                onChange={handleChange}
                                sx={{color: 'white'}}
                                value={flight.aircraftCategoryId}
                            >
                                {aircraftCategoryList.map((aircraftC: AircraftCategory) => (
                                    <MenuItem key={aircraftC.id} value={aircraftC.id}>
                                        {t(aircraftC.name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider sx={{mt: 2, mb: 2}}>{t("PILOT")}</Divider>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('PILOT_NAME')}
                            name="pilotFullName"
                            required
                            onChange={handleChange}
                            value={flight.pilotFullName}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('PHONE_NUMBER')}
                            name="pilotPhoneNumber"
                            required
                            onChange={handleChange}
                            value={flight.pilotPhoneNumber}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('EMAIL')}
                            name="pilotEmail"
                            required
                            onChange={handleChange}
                            value={flight.pilotEmail}
                        />
                    </Grid>


                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <FormControl fullWidth size='small'>
                            <InputLabel>{t('DOCUMENT_TYPES_LABEL')}</InputLabel>
                            <Select
                                label={t('DOCUMENT_TYPES_LABEL')}
                                name="pilotDocumentTypeId"
                                onChange={handleChange}
                                sx={{color: 'white'}}
                                value={flight.pilotDocumentTypeId}
                            >
                                <MenuItem key={"000000000000000000000000"} value={"000000000000000000000000"}>
                                    {t("SELECT_DOCUMENT_TYPE_OPTION")}
                                </MenuItem>
                                {documentTypes.map((documentType: DocumentType) => (
                                    <MenuItem key={documentType.id} value={documentType.id}>
                                        {t(documentType.name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        lg={8}
                        md={8}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('DOCUMENT_VALUE_LABEL')}
                            name="pilotDocumentValue"
                            type="text"
                            onChange={handleChange}
                            value={flight.pilotDocumentValue}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 2, mb: 2}}>{t("COPILOT")}</Divider>
                <Grid
                    container
                    spacing={2}
                >

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('COPILOT_NAME')}
                            name="copilotFullName"
                            onChange={handleCopilotChange}
                            value={flight.copilotFullName}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('PHONE_NUMBER')}
                            name="copilotPhoneNumber"
                            required
                            onChange={handleCopilotChange}
                            value={flight.copilotPhoneNumber}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('EMAIL')}
                            name="copilotEmail"
                            onChange={handleCopilotChange}
                            value={flight.copilotEmail}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={4}
                        md={4}
                        xs={12}
                    >
                        <FormControl fullWidth size='small'>
                            <InputLabel>{t('DOCUMENT_TYPES_LABEL')}</InputLabel>
                            <Select
                                label={t('DOCUMENT_TYPES_LABEL')}
                                name="copilotDocumentTypeId"
                                onChange={handleChange}
                                sx={{color: 'white'}}
                                value={flight.copilotDocumentTypeId}
                            >
                                <MenuItem key={"000000000000000000000000"} value={"000000000000000000000000"}>
                                    {t("SELECT_DOCUMENT_TYPE_OPTION")}
                                </MenuItem>
                                {documentTypes.map((documentType: DocumentType) => (
                                    <MenuItem key={documentType.id} value={documentType.id}>
                                        {t(documentType.name)}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        lg={8}
                        md={8}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('DOCUMENT_VALUE_LABEL')}
                            name="copilotDocumentValue"
                            type="text"
                            onChange={handleCopilotChange}
                            value={flight.copilotDocumentValue}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{mt: 4, mb: 4}}>{t("MOVEMENT")}</Divider>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <FormControl fullWidth required size='small'>
                            <InputLabel>{t('FLIGHT_TYPE')}</InputLabel>
                            <Select
                                label={t('FLIGHT_TYPE')}
                                name="flightTypeId"
                                onChange={handleChange}
                                sx={{color: 'white'}}
                                value={flight.flightTypeId}
                            >
                                {flightTypes.map((flightType: FlightType) => (
                                    <MenuItem key={flightType.id} value={flightType.id}>
                                        {t(flightType.name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>


                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('PEOPLE_ON_BOARD')}
                            name="peopleOnBoard"
                            type="number"
                            required
                            onChange={handleChange}
                            value={flight.peopleOnBoard}
                            InputProps={{inputProps: {min: 1}}}
                        />
                    </Grid>


                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <MobileDateTimePicker
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label={isDeparture ? t('DEPARTURE_TIME') : t('ARRIVAL_TIME')}
                            onChange={(date) => {
                                handleDateChange(date, 'date');
                            }}
                            renderInput={(params) => (
                                <TextField required fullWidth {...params} error={false} size='small'/>
                            )}
                            value={flight.date}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    >
                        <MobileDateTimePicker
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label={!isDeparture ? t('NEXT_DEPARTURE_TIME') : t('NEXT_ARRIVAL_TIME')}
                            onChange={(nextMovementDate) => {
                                handleDateChange(nextMovementDate, 'nextMovementDate');
                            }}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} error={false} size='small'/>
                            )}
                            value={flight.nextMovementDate}
                        />
                    </Grid>


                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                    >
                        {isDeparture ?
                            <Autocomplete
                                freeSolo
                                id="arrivalAirfield"
                                options={options}
                                renderInput={(params) =>
                                    <TextField {...params} required name="arrivalAirfield"
                                               fullWidth label={t('ARRIVAL_PLACE')} size='small'
                                               InputProps={{
                                                   ...params.InputProps,
                                                   endAdornment: (
                                                       <Fragment>
                                                           {loadingOptions && onFocusArrival ?
                                                               <CircularProgress sx={{marginRight: '30px'}} color="info"
                                                                                 size={20}/> : null}
                                                           {params.InputProps.endAdornment}
                                                       </Fragment>
                                                   ),
                                               }}
                                    />}
                                onKeyUp={(event: any) => {
                                    setSearchFilter(event.target.value);
                                }}
                                onInputChange={(_: any, value: string) => {
                                    handleAutocompleteChange(value, 'arrivalAirfieldName')
                                }}
                                onFocus={() => {
                                    setOnFocusArrival(true)
                                }}
                                onBlur={() => {
                                    setOnFocusArrival(false);
                                }}
                                onOpen={() => {
                                    setOpenArrival(true)
                                }}
                                onClose={() => {
                                    setOpenArrival(false)
                                }}
                                open={openArrival}
                                value={flight.arrivalAirfieldName}
                                isOptionEqualToValue={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const {inputValue} = params;
                                    const filtered = [...options];

                                    if (inputValue !== '') {
                                        filtered.push(inputValue);
                                    }

                                    return filtered;
                                }}
                            />
                            : <Autocomplete
                                freeSolo
                                id="departureAirfield"
                                options={options}
                                renderInput={(params) =>
                                    <TextField {...params} required name="departureAirfield"
                                               fullWidth label={t('DEPARTURE_PLACE')} size='small'
                                               InputProps={{
                                                   ...params.InputProps,
                                                   endAdornment: (
                                                       <Fragment>
                                                           {loadingOptions && onFocusDeparture
                                                               ? <CircularProgress
                                                                   sx={{marginRight: '30px'}}
                                                                   color="info"
                                                                   size={20}/>
                                                               : null}
                                                           {params.InputProps.endAdornment}
                                                       </Fragment>
                                                   )
                                               }}
                                    />}
                                onKeyUp={(event: any) => {
                                    setSearchFilter(event.target.value);
                                }}
                                onInputChange={(_: any, value: string) => {
                                    handleAutocompleteChange(value, 'departureAirfieldName')
                                }}
                                onFocus={() => {
                                    setOnFocusDeparture(true)
                                }}
                                onBlur={() => {
                                    setOnFocusDeparture(false)
                                }}
                                onOpen={() => {
                                    setOpenDeparture(true)
                                }}
                                onClose={() => {
                                    setOpenDeparture(false)
                                }}
                                open={openDeparture}
                                value={flight.departureAirfieldName}
                                isOptionEqualToValue={(option, value) => option === value}
                                filterOptions={(options, params) => {
                                    const {inputValue} = params;
                                    const filtered = [...options];

                                    if (inputValue !== '') {
                                        filtered.push(inputValue);
                                    }

                                    return filtered;
                                }}
                            />
                        }
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                    >
                        <TextField
                            fullWidth
                            size='small'
                            label={t('NOTE')}
                            name="note"
                            type="text"
                            required
                            inputProps={{
                                style: {
                                    height: 50
                                }
                            }}
                            onChange={handleChange}
                            value={flight.note}
                        />
                    </Grid>


                    <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                    ></Grid>
                </Grid>
                <Divider sx={{marginTop: '15px', marginBottom: '15px'}}/>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <>
                        {!isAdd && (
                            <>
                                <Button
                                    color="error"
                                    type="submit"
                                    variant="contained"
                                    disabled={isUpsertLoading}
                                    onClick={handleFlightModalOpen}
                                >
                                    {t('DELETE')}
                                </Button>
                                {userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['SYSTEMADMIN', 'ADMINISTRATOR', 'USER'])
                                    &&
                                    <>
                                        {flight.approved == null ?

                                            <Button
                                                size="small"
                                                sx={{bgcolor: "#404040"}}
                                                variant="contained"
                                                onClick={(event: any) => setApprovedMenuAnchorEl(event.currentTarget)}
                                                disabled={isUpsertLoading}
                                            >
                                                <Box color={"#CFCFCF"}>{t("UPDATE_FLIGHT_STATE")}</Box>
                                            </Button>
                                            :
                                            <Button
                                                size="small"
                                                color={!flight.approved ? "error" : "success"}
                                                variant="contained"
                                                onClick={(event: any) => setApprovedMenuAnchorEl(event.currentTarget)}
                                                disabled={isUpsertLoading}
                                            >

                                                {flight.approved ? t('APPROVED') : t('REJECTED')}
                                            </Button>}
                                        <Menu
                                            id="flights-approved-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'flight-approved-button',
                                            }}
                                            anchorEl={approvedMenuAnchorEl}
                                            open={Boolean(approvedMenuAnchorEl)}
                                            onClose={() => {
                                                setApprovedMenuAnchorEl(null)
                                            }}
                                        >
                                            <MenuItem
                                                disabled={flight.approved == true}
                                                key={'flightApproved'}
                                                onClick={() => {
                                                    setApprovedMenuAnchorEl(null);
                                                    setFlight(prevState => ({...prevState, approved: true}));
                                                }}>
                                                <CheckCircle fontSize="small" color="success"/>
                                                <Typography
                                                    sx={{ml: 1}}
                                                    fontSize="small">
                                                    {" " + t('APPROVE')}
                                                </Typography>

                                            </MenuItem>
                                            <MenuItem disabled={flight.approved == false}
                                                      key={'flightNotApproved'} onClick={() => {
                                                setApprovedMenuAnchorEl(null);
                                                setFlight(prevState => ({...prevState, approved: false}));

                                            }}>
                                                {<RemoveCircle fontSize="small" color="error"/>}
                                                <Typography sx={{ml: 1}}
                                                            fontSize="small">{" " + t('REJECT')}</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </>}
                            </>
                        )
                        }

                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={upsertFlight}
                            disabled={isUpsertLoading}
                        >
                            {t('SAVE')}
                        </Button>
                    </>
                </Box>
            </>
        }
    </>
}

export default FlightUpsertForm;
