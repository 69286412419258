import { User } from '../models/User';

const SET_ISINITIALIZED = 'SET_LOADING_STATE';
const SET_USER = 'SET_USER';
const SET_INITIALIZATIONERROR = 'SET_INITIALIZATIONERROR';
const SET_OFFLINECONFIGINITIALIZED = 'SET_OFFLINECONFIGINITIALIZED';

// STATE - This defines the type of data maintained in the Redux store.
export interface AppInitState {
  isInitialized: boolean;
  user?: User;
  isOfflineConfigInitialized: boolean;
  initializationError: boolean;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}
interface SetBooleanAction {
  type: typeof SET_INITIALIZATIONERROR | typeof SET_OFFLINECONFIGINITIALIZED | typeof SET_ISINITIALIZED;
  value: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AppInitActionTypes = SetUserAction | SetBooleanAction;

const initialState: AppInitState = {
  isInitialized: false,
  isOfflineConfigInitialized: false,
  initializationError: false
};

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  setIsInitialized: (isInitialized: boolean): AppInitActionTypes => ({
    type: SET_ISINITIALIZED,
    value: isInitialized
  }),
  setIsOfflineConfigInitialized: (isOfflineConfigInitialized: boolean): AppInitActionTypes => ({
    type: SET_OFFLINECONFIGINITIALIZED,
    value: isOfflineConfigInitialized
  }),
  setInitializationError: (isError: boolean): AppInitActionTypes => ({
    type: SET_INITIALIZATIONERROR,
    value: isError
  }),
  setUser: (user: User): AppInitActionTypes => ({
    type: SET_USER,
    user: user
  }),
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer = (state = initialState, action: AppInitActionTypes): AppInitState => {
  switch (action.type) {
    case SET_ISINITIALIZED:
      return { ...state, isInitialized: action.value };
    case SET_INITIALIZATIONERROR:
      return { ...state, initializationError: action.value };
    case SET_OFFLINECONFIGINITIALIZED:
      return { ...state, isOfflineConfigInitialized: action.value };
    case SET_USER:
      return { ...state, user: action.user };
    default:
      return state;
  }
};