import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	debounce,
	Drawer,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import {Flight} from '../../models/Flight';
import {PaginationResult} from '../../types/paginationResult';
import {useTranslation} from 'react-i18next';
import {CloseOutlined, FilterAlt, HighlightOff} from '@material-ui/icons';
import {FlightService} from '../../services/FlightService';
import {composeNotistackMessage, getFormValue, isEmpty, objectHasEmptyFields} from '../../utils/common';
import {DateRangePicker} from '@material-ui/lab';
import {toUpper} from 'lodash';
import {useNavigate} from 'react-router';
import 'dayjs/plugin/timezone';
import PilotFlightsListTableMobile from './PilotFlightsListTableMobile';
import PilotFlightsListTable from './PilotFlightsListTable';


interface IFilters {
	searchFilter: string,
	startDepartureDate: string | null,
	endDepartureDate: string | null,
	startArrivalDate: string | null,
	endArrivalDate: string | null,
	tenantIsDeparture: boolean | null,
	orderFieldName: string | null,
	isDescending: boolean | null
}

const defaultFilters: IFilters = {
	searchFilter: '',
	startDepartureDate: null,
	endDepartureDate: null,
	startArrivalDate: null,
	endArrivalDate: null,
	tenantIsDeparture: false,
	orderFieldName: 'Date',
	isDescending: true,
};


interface PilotFlightsListCardProps {
	loggedUser: any;
}

const PilotFlightsListCard: FC<PilotFlightsListCardProps> = ({ loggedUser }) => {
	const [flights, setFlights] = useState<PaginationResult<Flight>>();

	const [filters, setFilters] = useState<IFilters>(defaultFilters);
	const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [recordLimit, setRecordLimit] = useState<number>(10);
	const [isRecordsLoading, setIsRecordsLoading] = useState<boolean>(true);
	const { t, i18n } = useTranslation();
	const flightService = new FlightService();




	const history = useNavigate();

	var currentTheme = useTheme();
	const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));

	useEffect(() => {
		setIsPageLoading(true);
		getFlights(currentPage + 1, recordLimit, filters);
	}, [currentPage, recordLimit, filters]);

	const getFlights = useCallback(debounce(async (currentPage: number, recordLimit: number, filters: any) => {
		try {
			const flightService = new FlightService();
			const retrievedFlights = await flightService.getAllflightsByUserId(currentPage, recordLimit, loggedUser?.id, filters);
			setFlights(retrievedFlights);
			setIsPageLoading(false);
			setIsRecordsLoading(false);
		} catch (error) {
			//TODO: Handle errors
			console.log(error);
		}
	}, 700), []);

	const setAllFilters = (value: string | boolean, name: string) => {
		setFilters((currentFormData: any) => ({ ...currentFormData, [name]: value }));
	}


	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [fliterDialogModalOpen, setFliterDialogModalOpen] = useState(false);

	const areFiltersEmpty = () => {
		return objectHasEmptyFields(filters);
	}


	const updateApprovedFlight = async (flightId: string, approved: boolean) => {
		let updateApprovedFlightResult = await flightService.updateApprovedFlightById(flightId, approved, loggedUser?.id);

		if (updateApprovedFlightResult) {
			composeNotistackMessage(t('FLIGHT_UPDATED'), 'success');

			history('/pilotflights');
		} else {
			composeNotistackMessage(t('FLIGHT_NOT_UPDATED'), 'error');
		}
	}

	const handleChange = (e: any) => {
		let { name, value } = getFormValue(e);
		setFilters((currentFormData: any) => ({
			...currentFormData,
			[name]: value
		}));
	}

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		}
		window.addEventListener('resize', handleResize);
	}, []);


	const getMoreFlights = async () => {
		setIsRecordsLoading(true);
		try {
			if (flights && flights.items.length! > 0 &&
				flights?.items.length! <= flights.totalCount!) {
				const retrievedFlights = await flightService.getAllflightsByUserId(
					flights?.currentPage ? flights?.currentPage + 1 : 2,
					10,
					loggedUser?.id,
					filters);

				if (retrievedFlights && flights)
					retrievedFlights.items = flights.items.concat(retrievedFlights.items)

				//var moreFlight = paginatedFlights;
				setFlights(JSON.parse(JSON.stringify(retrievedFlights)));
			}
		} catch (error) {
			//TODO: Handle errors
			console.log(error);
		}
		setIsRecordsLoading(false);

	};


	return (
		<Card sx={{ mb: 4 }}>
			<CardContent sx={{ mb: 2, width: "100%" }}>
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexWrap: 'wrap',
						py: 2,
						maxWidth: '100%'
					}}
				>

					{/* FILTER DRAWER */}
					<Drawer
						anchor='right'
						open={fliterDialogModalOpen}
						onClose={() => setFliterDialogModalOpen(false)}
						PaperProps={{
							sx: { width: { xs: '75%', md: '50%', lg: '25%' } },
						}}
					>
						<Grid
							container
							direction="column"
							spacing={2}
							sx={{ mt: '60px', p: '15px' }}
						>
							<Grid item>
								<Typography textAlign="center" variant="h5">{toUpper(t('ADVANCED_FILTERS'))}</Typography>
							</Grid>
							<Grid
								item
							>


							</Grid>
							<Grid
								item
							>
								<DateRangePicker
									startText={t("START_ARRIVAL_TIME")}
									endText={t("END_ARRIVAL_TIME")}
									value={[filters.startArrivalDate, filters.endArrivalDate]}
									onChange={(newRange) => {
										setAllFilters(newRange[0] != null && !isEmpty(newRange[0]) ? newRange[0].toString() : '', 'startArrivalDate');
										setAllFilters(newRange[1] != null && !isEmpty(newRange[1]) ? newRange[1].toString() : '', 'endArrivalDate');
									}}
									inputFormat='DD/MM/YYYY'
									renderInput={(startProps, endProps) =>
										<>
											<Grid
												container
												spacing={2}
											>
												<Grid
													item
													lg={12}
													md={12}
													xs={12}
												>
													<TextField {...startProps} fullWidth sx={{ input: { color: 'white' } }} />
												</Grid>
												<Grid
													item
													lg={12}
													md={12}
													xs={12}
												>
													<TextField {...endProps} fullWidth sx={{ input: { color: 'white' } }} />
												</Grid>
											</Grid>
										</>}
								/>
							</Grid>

							<Grid
								item
							>
								<FormControl fullWidth size='small'>
									<InputLabel>{t('ORDER_BY')}</InputLabel>
									<Select
										label={t('ORDER_BY')}
										name="orderFieldName"
										onChange={handleChange}
										sx={{ color: 'white' }}
										value={filters.orderFieldName}
									>
										<MenuItem value={"TenantIsDeparture"}>
											{t("FLIGHT_DIRECTION")}
										</MenuItem>
										<MenuItem value={"AircraftRegistration"}>
											{t("AIRCRAFT_REGISTRATION")}
										</MenuItem>
										<MenuItem value={"PilotFullName"}>
											{t("PILOT_NAME")}
										</MenuItem>
										{
											<MenuItem value={("ArrivalAirfieldName")}>
												{t("AIRFIELD")}
											</MenuItem>}
										<MenuItem value={"Date"}>
											{t("TIMETABLE")}
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
							>
								<FormControl fullWidth size='small'>
									<InputLabel>{t('ORDER_METHOD')}</InputLabel>
									<Select
										label={t('ORDER_METHOD')}
										name="isDescending"
										onChange={handleChange}
										sx={{ color: 'white' }}
										value={filters.isDescending}
									>
										<MenuItem value={false as any}>
											{t("ASCENDING")}
										</MenuItem>
										<MenuItem value={true as any}>
											{t("DESCENDING")}
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
								sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
							>
								<Button
									startIcon={<CloseOutlined fontSize='large' />}
									variant="outlined"
									color="error"
									onClick={() => setFilters(defaultFilters)}
								>
									{t('REMOVE_FILTERS')}
								</Button>
							</Grid>
						</Grid>
					</Drawer>
					{/* END FILTER DRAWER */}

					<Grid container spacing={1} sx={{ mb: '1vh' }}
					>
						<Grid
							item
							lg={10}
							md={10}
							xs={8}
							alignItems='flex-start'
							alignContent='center'
						>
							<TextField
								fullWidth
								size='small'
								sx={{
									input: {
										color: 'white'
									}
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon fontSize="small" />
										</InputAdornment>
									)
								}}
								onChange={(e) => {
									setAllFilters(e.target.value, 'searchFilter');
								}}
								placeholder={t('SEARCH')}
								value={filters.searchFilter}
								variant="outlined"
							/>
						</Grid>

						<Grid
							item
							lg={2}
							md={2}
							xs={4}
							sx={{ display: 'flex', justifyContent: "flex-end" }}
						>
							<Stack
								direction="row"
								spacing={0}
							>
								<Tooltip title={t('FILTERS')}>
									<IconButton onClick={() => setFliterDialogModalOpen(true)}>
										<FilterAlt />
									</IconButton>
								</Tooltip>
								{
									areFiltersEmpty() ?
										<></> :
										<Tooltip title={t('REMOVE_FILTERS')}>
											<IconButton onClick={() => setFilters(defaultFilters)} >
												<HighlightOff fontSize="small" />
											</IconButton>
										</Tooltip>
								}
							</Stack>

						</Grid>
					</Grid>
				</Box>
				{mobileDevice
					&& <PilotFlightsListTableMobile
						paginationResult={flights}
						isPageLoading={isPageLoading}
						loggedUser={loggedUser}
						filters={filters}
						getMoreFlights={getMoreFlights}
						isRecordsLoading={isRecordsLoading}
						tenantIsDeparture={false}
						updateApprovedFlight={updateApprovedFlight}
					/>}
				{!mobileDevice
					&& <PilotFlightsListTable
						paginationResult={flights}
						isPageLoading={isPageLoading}
						loggedUser={loggedUser}
						tenantIsDeparture={false}
						getMoreFlights={getMoreFlights}
						updateApprovedFlight={updateApprovedFlight}
						isRecordsLoading={isRecordsLoading}
						setAllFilters={setAllFilters}
						filters={filters}
					/>}
			</CardContent>
		</Card>
	);
};

export default PilotFlightsListCard;
