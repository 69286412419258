import AuthGuard from "../components/auth/AuthGuard";
import Layout from "../components/Layout";
import AirfieldDetails from "../pages/Airfields/AirfieldDetails";
import AirfieldUpsert from "../pages/Airfields/AirfieldUpsert";
import AirfieldsList from "../pages/Airfields/AirfieldsList";
import FlightDetails from "../pages/Flights/FlightDetails";
import FlightUpsert from "../pages/Flights/FlightUpsert";
import FlightsList from "../pages/Flights/FlightsList";
import UserDetails from "../pages/Users/UserDetails";
import UserUpsert from "../pages/Users/UserUpsert";
import UsersList from "../pages/Users/UsersList";
import TenantDetails from "../pages/Tenants/TenantDetails";
import TenantUpsert from "../pages/Tenants/TenantUpsert";
import AccessDisabledPage from "../pages/AccessDisabledPage";
import TenantsList from "../pages/Tenants/TenantsList";
import PilotUser from "../components/user/PilotUserForm";
import MyAircrafts from "../pages/Aircraft/MyAircrafts";
import UpsertAircraft from "../pages/Aircraft/UpsertAircraft";
import PilotFlightsList from "../pages/Flights/PilotFlightsList";
import PilotFlightUpsert from "../pages/Flights/PilotFlightUpsert";
import Calendar from "../pages/Flights/Calendar";
import PilotCalendar from "../pages/Flights/PilotCalendar";

const MainRoutes = {
    path: "/",
    element: (
        <AuthGuard>
            <Layout />
        </AuthGuard>
    ),
    children: [
        // {
        //   path: "/",
        //   element: <FlightsList />,
        // },
        {
            path: "/users",
            element: <UsersList />,
        },
        {
            path: "/users/add",
            element: <UserUpsert isAdd={true} />,
        },
        {
            path: "/users/edit",
            element: <UserUpsert isAdd={false} />,
        },
        {
            path: "/users/detail",
            element: <UserDetails />,
        },
        {
            path: "/flights",
            element: <FlightsList />,
        },
        {
            path: "/flights/add",
            element: <FlightUpsert isAdd={true} />,
        },
        {
            path: "/flights/edit",
            element: <FlightUpsert isAdd={false} />,
        },
        {
            path: "/flights/reverseFlightEdit",
            element: <FlightUpsert isAdd={null} />,
        },
        {
            path: "/flights/detail",
            element: <FlightDetails />,
        },
        {
            path: "/airfields",
            element: <AirfieldsList />,
        },
        {
            path: "/airfields/add",
            element: <AirfieldUpsert isAdd={true} />,
        },
        {
            path: "/airfields/edit",
            element: <AirfieldUpsert isAdd={false} />,
        },
        {
            path: "/airfields/detail",
            element: <AirfieldDetails />,
        },
        {
            path: "/tenants",
            element: <TenantsList />,
        },
        {
            path: "/tenants/add",
            element: <TenantUpsert isAdd={true} />,
        },
        {
            path: "/tenants/edit",
            element: <TenantUpsert isAdd={false} />,
        },
        {
            path: "/myAirfield",
            element: <TenantDetails />,
        },
        {
            path: "/tenants/detail",
            element: <TenantDetails />,
        },
        {
            path: '/accessDisabled',
            element: <AccessDisabledPage />
        },
        {
            path: "/pilotUser/edit",
            element: <PilotUser isAdd={false} />,
        },
        {
            path: "/pilotflights",
            element: <PilotFlightsList />,
        },
        {
            path: "/pilotflights/detail",
            element: <FlightDetails />,
        },
        {
            path: "/pilotflights/add",
            element: <PilotFlightUpsert isAdd={true} />,
        },
        {
            path: "/pilotflights/edit",
            element: <FlightUpsert isAdd={false} />
            //<PilotFlightUpsert isAdd={false} />,
        },
        {
            path: "/myAircrafts",
            element: <MyAircrafts />,
        },
        {
            path: "/myAircrafts/add",
            element: <UpsertAircraft isAdd={true} />,
        },
        {
            path: "/myAircrafts/edit",
            element: <UpsertAircraft isAdd={false} />,
        },
        {
            path: "/flights/calendar",
            element: <Calendar flights={undefined} />,
        },
        {
            path: "/pilotflights/calendar",
            element: <PilotCalendar flights={undefined} />,
        }
    ]
};

export default MainRoutes;
