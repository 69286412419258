import axios from "axios";
import { User } from "../models/User";
import { PaginationResult } from "../types/paginationResult";

export class UserService {
  async userUpsert(user: User | any): Promise<boolean> {
    try {
      const response = await axios.post("upsertUser", user);

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async getUserByEmail(email: string | undefined): Promise<User | null> {
    try {
      const response = await axios.post("getUserByEmail", { email: email }).catch(() => {
        return undefined;
      });

      return response?.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async getUserById(id: string, tenantId: string | undefined = ''): Promise<User> {
    try {
      const response = await axios.post("getUser", {
        id: id,
        tenantId: tenantId
      });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async getAllUsers(
    page: number,
    recordsPerPage: number,
    tenantId: string,
    searchFilter: string
  ): Promise<PaginationResult<User>> {
    try {
      const response = await axios.post<PaginationResult<User>>("getAllUsers", {
        page: page,
        recordsPerPage: recordsPerPage,
        tenantId: tenantId,
        searchFilter: searchFilter,
      });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async updateCurrentTenant(userId: string | undefined, currentTenantId: string): Promise<boolean> {
    try {
      const response = await axios.post("updateCurrentTenant", {
        userId: userId,
        tenantId: currentTenantId,
      });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async deleteUserById(id: string): Promise<boolean> {
    try {
      const response = await axios.post('deleteUser', { id: id });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async deleteTenantUserById(userId: string, tenantId: string): Promise<boolean> {
    try {
      const response = await axios.post('deleteTenantUser', { userId: userId, tenantId: tenantId });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }

  async changeLanguage(id: string, lan: string): Promise<boolean> {
    try {
      const response = await axios.post('changeLanguage', { id: id, language: lan });

      return response.data;
    } catch (error) {
      throw new Error("Error: " + error);
    }
  }
}
