import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import {userCanUseIfRoles} from "../../utils/common";
import {Description, MoreVert, PictureAsPdf} from "@material-ui/icons";
import {useState} from "react";
import PermissionsGuard from "../../components/PermissionGuard";
import PilotFlightsListCard from "../../components/flight/PilotFlightsListCard";

const PilotFlightsList = () => {
  const { t } = useTranslation();
  const loggedUser = useSelector<AppState, User | undefined>((state) => state.appInit.user);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  var currentTheme = useTheme();
  const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('sm'));

  return (
    <PermissionsGuard
      allowedRoles={['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']}
      environment={'PilotFlightsList'}
    >
      <Container maxWidth={false}>
        <Grid
          container
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={6}
          >
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('MY_FLIGHTS')}
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Stack direction="row" spacing={mobileDevice ? 0 : 1}>
              <Menu
                id="flights-table-actions-menu"
                MenuListProps={{
                  'aria-labelledby': 'flights-table-action-button',
                }}
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => { setMenuAnchorEl(null) }}
              >
                <MenuItem key={'export-flights'} disabled>
                  <Typography variant="subtitle1">{t('EXPORT')}</Typography>
                </MenuItem>
                <MenuItem key={'export-excel'} onClick={() => { setMenuAnchorEl(null) }} >
                  <Description fontSize="small" sx={{ mr: '3px' }} />
                  <Typography variant="subtitle1">Excel</Typography>
                </MenuItem>
                <MenuItem key={'export-pdf'} onClick={() => { setMenuAnchorEl(null) }} >
                  <PictureAsPdf fontSize="small" sx={{ mr: '3px' }} />
                  <Typography variant="subtitle1">PDF</Typography>
                </MenuItem>
              </Menu>

              {
                !mobileDevice &&
                <Button variant="contained" color="info" disabled>
                  <Description />
                </Button>
              }
              {
                userCanUseIfRoles(loggedUser?.tenantUser?.role?.name, ['ADMINISTRATOR', 'SYSTEMADMIN', 'PILOT', 'USER']) ?
                  (
                    <>
                      <Grid
                        item
                        lg={2}
                        md={3}
                        xs={10}
                      >
                        <Box sx={{ m: -1 }}>
                          <Button
                            component={RouterLink}
                            to="add"
                            color="primary"
                            sx={{ m: 1, textAlign: 'center', width: '90px' }}
                            variant="contained"
                          >
                            {t('RECLAIM_FLIGHT')}
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  ) :
                  (<></>)
              }
              {
                mobileDevice &&
                <IconButton
                  onClick={(event: any) => setMenuAnchorEl(event.currentTarget)}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
              }
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          <PilotFlightsListCard
            loggedUser={loggedUser}
          />
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default PilotFlightsList;
