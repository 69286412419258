import {useCallback, useState} from "react";
import {Airfield} from "../../models/Airfield";
import {AirfieldService} from "../../services/AirfieldService";
import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Plus from '../../icons/Plus';
import {Link as RouterLink} from 'react-router-dom';
import AirfieldsListTable from "../../components/airfield/AirfieldsListTable";
import {PaginationResult} from "../../types/paginationResult";
import {useSelector} from "react-redux";
import {AppState} from "../../store";
import {User} from "../../models/User";
import PermissionsGuard from "../../components/PermissionGuard";

const AirfieldsList = () => {
	const [airfields, setAirfields] = useState<PaginationResult<Airfield>>();
	const { t } = useTranslation();
	const [isPageLoading, setIsPageLoading] = useState<boolean>(true);

	const user = useSelector<AppState, User | undefined>((state) => state.appInit.user);

	const getAirfields = useCallback(async (currentPage: number, recordLimit: number, searchFilter: string) => {
		try {
			setIsPageLoading(true);
			const airfieldService = new AirfieldService();

			const retrievedAirfields = await airfieldService.getAllAirfields(searchFilter, currentPage, recordLimit);
			setAirfields(retrievedAirfields);

			setIsPageLoading(false);
		} catch (error) {
			//TODO: Handle errors
			console.log(error);
		}
	}, []);

	return (
		<PermissionsGuard
			allowedRoles={['SYSTEMADMIN']}
			environment={'AirfieldsList'}
		>
			<Container maxWidth={false}>
				<Grid
					container
					justifyContent="space-between"
					spacing={3}
				>
					<Grid item>
						<Typography
							color="textPrimary"
							variant="h5"
						>
							{t('AIRFIELDS_LIST')}
						</Typography>
					</Grid>
					<Grid item>
						<Box sx={{ m: -1 }}>
							<Button
								component={RouterLink}
								to="add"
								color="primary"
								startIcon={<Plus fontSize="small" />}
								sx={{ m: 1 }}
								variant="contained"
							>
								{t('ADD_AIRFIELD')}
							</Button>
						</Box>
					</Grid>
				</Grid>
				<Box sx={{ mt: 3 }}>
					<AirfieldsListTable
						paginationResult={airfields}
						getAirfields={getAirfields}
						user={user}
						isPageLoading={isPageLoading}
					/>
				</Box>
			</Container>
		</PermissionsGuard>
	);
}

export default AirfieldsList;
