import type { FC } from 'react';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

interface LoaderVariantProps {
  isLoading: boolean
}

const LoaderVariant: FC<LoaderVariantProps> = (props) => {
  const { isLoading } = props;

  return (isLoading
    ? <Backdrop open={isLoading}>
      <CircularProgress color="secondary" />
    </Backdrop>
    : <></>
  );
};

export default LoaderVariant;
