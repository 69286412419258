import {FC, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {DocumentType} from "../../models/DocumentType";
import {Skeleton} from "@mui/material";


interface NoteFormProps {
    formik: any,
}

const NoteForm: FC<NoteFormProps> = (props) => {
    const { formik } = props
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);


    return <Grid container spacing={3} sx={{ mt: -3 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            {isLoading ? (
                <Skeleton animation="wave" variant="text" width="100%" height={50} />
            ) : (
                <TextField
                    fullWidth
                    size="medium"
                    autoComplete="off"
                    id="note"
                    multiline
                    rows={5}
                    label={t('NOTE')}
                    name="note"
                    onChange={formik.handleChange}
                    value={formik.values.note}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                />
            )}
        </Grid>

    </Grid>
}
export default NoteForm;
