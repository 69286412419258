import {
    Box,
    Button,
    Modal,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {FlightLog} from "../../models/FlightLog";
import Scrollbar from "../../components/Scrollbar";
import X from "../../icons/X";
import {FlightService} from "../../services/FlightService";
import {StringIsNullOrEmpty} from "../../utils/common";
import LoaderVariant from "../LoaderVariant";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: 550,
    maxHeight: "30rem",
    bgcolor: '#141414',
    border: '2px solid #000',
    boxShadow: 24,
};

interface FlightLogProps {
    airfieldId: string
    modalIsOpen: boolean,
    setModalIsOpen: Function
}

const FlightLogModal: FC<FlightLogProps> = (props) => {
    const { airfieldId, modalIsOpen, setModalIsOpen } = props
    const { t } = useTranslation();

    var currentTheme = useTheme();
    const flightService = new FlightService()
    const isMobile = useMediaQuery(currentTheme.breakpoints.down(500));
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [flightLogList, setFlightLogList] = useState<FlightLog[]>([]);

    const CloseModal = () => {
        setModalIsOpen(false);
    }

    useEffect(() => {
        (async () => {
            if ((!StringIsNullOrEmpty(airfieldId) && airfieldId != undefined))
                await getFlightLogList(airfieldId)
            setIsLoading(false);
        })();
    }, [airfieldId]);

    const getFlightLogList = async (airfieldId: string) => {
        let logList = await flightService.getFlightLogById(airfieldId);
        setFlightLogList(logList);
    }

    return (<>
        {isLoading ? <>
            <LoaderVariant isLoading={isLoading} />
        </> :
            isMobile ? <>
                <Modal open={modalIsOpen} onClose={CloseModal} >
                    <Box sx={style}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: 1,
                        }}>
                            <Button
                                startIcon={<X fontSize='small' />}
                                color="primary"
                                onClick={CloseModal}
                            >
                            </Button>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            mx: 2
                        }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t("LOG_TEXT_LABEL")}
                            </Typography>
                        </Box>
                        <Scrollbar>
                            <Box sx={{ mx: 4 }}>

                                <TableContainer sx={{
                                    width: "90%",
                                    maxHeight: "20rem",
                                    overflow: "auto",
                                    mx: 1, mb: 1
                                }}>
                                    {flightLogList?.map((value, index) => (
                                        <>
                                            <Typography id="modal-modal-description" sx={{ minWidth: 80, mt: 3 }}>
                                                {t("LOG_ACTION_DATE_LABEL") + ": " +
                                                    new Date(value.actionDate).toLocaleString('it-IT', {
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                    })}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ minWidth: 80 }}>
                                                {t("LOG_ACTION_TEXT_LABEL") + ": " + t(value.actionText)}
                                            </Typography>
                                            <Typography id="modal-modal-description" sx={{ minWidth: 80 }}>
                                                {t("LOG_ACTION_USERID_LABEL") + ": " + value.userName}
                                            </Typography>
                                        </>
                                    ))}
                                </TableContainer>
                            </Box>

                        </Scrollbar>

                    </Box>
                </Modal>
            </> :

                <>
                    <Modal open={modalIsOpen} onClose={CloseModal} >
                        <>
                            <Box sx={style}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: 1,
                                }}>
                                    <Button
                                        startIcon={<X fontSize='small' />}
                                        color="primary"
                                        onClick={CloseModal}
                                    >
                                    </Button>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    mx: 2
                                }}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        {t("LOG_TEXT_LABEL")}
                                    </Typography>
                                </Box>
                                <Scrollbar>
                                    <Box sx={{ mx: 4 }}>
                                        <TableContainer sx={{
                                            maxHeight: "20rem",
                                            overflow: "auto",
                                        }}>
                                            <Table>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography id="modal-modal-description" sx={{ minWidth: 80 }}>
                                                            {t("LOG_ACTION_DATE_LABEL")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>

                                                        <Typography id="modal-modal-description" sx={{ minWidth: 80 }}>
                                                            {t("LOG_ACTION_TEXT_LABEL")}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography id="modal-modal-description" sx={{ minWidth: 80 }}>
                                                            {t("LOG_ACTION_USERID_LABEL")}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                {
                                                    flightLogList?.map((value, index) => (

                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                        {new Date(value.actionDate).toLocaleString('it-IT', {
                                                                            year: 'numeric',
                                                                            month: 'numeric',
                                                                            day: 'numeric',
                                                                            hour: 'numeric',
                                                                            minute: 'numeric',
                                                                            second: 'numeric',
                                                                        })}
                                                                    </Typography>

                                                                </TableCell>

                                                                <TableCell>
                                                                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                        {t(value.actionText)}
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                                                                        {value.userName}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow >
                                                        </>

                                                    ))
                                                }
                                            </Table>

                                        </TableContainer>
                                    </Box>
                                </Scrollbar>

                            </Box>
                        </>
                    </Modal>
                </>}
    </>)
}

export default FlightLogModal
