import {Box, Button, Container, Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {Airfield, DefaultAirfield} from "../../models/Airfield";
import {isEmpty} from "../../utils/common";
import {AirfieldService} from "../../services/AirfieldService";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import AirfieldDetailsBox from "../../components/airfield/AirfieldDetailsBox";
import PencilAlt from "../../icons/PencilAlt";
import PermissionsGuard from "../../components/PermissionGuard";

const AirfieldDetails = () => {
  const [airfield, setAirfield] = useState<Airfield>(DefaultAirfield);
  const { t } = useTranslation();
  const airfieldService = new AirfieldService();

  useEffect(() => {
    (async () => {
      let airfieldId: string = window.location.href.split('airfieldId=')[1] ?? '';
      if (!isEmpty(airfieldId)) {
        let retrievedAirfield: Airfield = await airfieldService.getAirfieldById(airfieldId);
        setAirfield(retrievedAirfield);
      }
    })();
  }, []);

  return (
    <PermissionsGuard
      allowedRoles={['SYSTEMADMIN']}
      environment={'AirfieldDetails'}
      needTenant={true}
    >
      <Container >
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {t('AIRFIELD_DATA')}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ m: -1 }}>
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PencilAlt fontSize="small" />}
                sx={{ m: 1 }}
                to={`/airfields/edit?airfieldId=${airfield.id}`}
                variant="contained"
              >
                {t('EDIT')}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
          {
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <AirfieldDetailsBox airfield={airfield} />
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </PermissionsGuard>
  );
}

export default AirfieldDetails;
